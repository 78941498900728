import axios from "axios";
import { notify } from 'react-noti';
import React, { Component, Fragment } from "react";
import TextField from '@material-ui/core/TextField';
// import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Row, Col, Accordion, Button, Card, Image } from "react-bootstrap";
import IcoCheck from '../../images/ico_check.svg';
import IcoClose from '../../images/ico_close.svg';
import * as workerInterval from 'worker-interval';

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

class SM013 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // screen: "SM012",
      screenCheckout: "SM013",
      screenPrev: "SM027",
      payBy: "",
      premium: 0,
      premiumPayment: 0,
      channelType: "",
      channelCode: "",
      orderId: "",
      couponCode: "",
      referralCode: "",
      KPayPlusNumber: MainData.screenSM006.phoneNumber,
      applicationNumber: "",
      transactionNumber: "",
      refOrder: "",
      paymentChannelList: [],
      connectionId: "",
      failPhone:false,
      promotionCode: MainData.screenSM013.promotionCode?MainData.screenSM013.promotionCode:"",
      promotionStatus: "",
      promotionDetail:null,
      failPromotion:false,
      failPromotionDetail:"",
      //connectionId2: ""
    };
  }

  // เข้ามาถึงไปเรียก /Sales/SubmitTransaction

  // จ่ายผ่าน cardit
  // ไปเรียก token ของ kbank จาก <form method="POST" action="/checkout">
  // <script type="text/javascript"
  // src="https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js"
  // data-apikey="pkey_test_20781gjXfviUiQ5iJxELVJzB0jPJVBrDIruXC"
  // data-amount="74.00"
  // data-currency="THB"
  // data-payment-methods="card"
  // data-name="Your Shop Name"
  // data-mid="401012777779001"
  // >
  // </script>
  // </form>
  // จ่ายผ่าน cardit ไปยิง api/Payment/ChargePayment https://docs.google.com/spreadsheets/d/1rzUYp17KHcNEBIklz_btQ74wLqYcTGZznfy-CJtQQI4/edit#gid=498544685 ต่อ แล้วจะได้ url ให้ redirect ไป
  // หลังจากนั้น จะกลับมา callback ให้ไปยิงเชคครั้งสุดท้ายกับ api/Payment/GetInquiryData จบ


  // จ่ายผ่าน qr 
  // ถ้า qr code ให้ยิง api/Payment/CreateOrderPayment ก่อน
  // gen qr
  // <formmethod="POST"action="/checkout.html">
  // <scripttype="text/javascript"
  // src="https://dev-kpaymentgateway.kasikornbank.com/ui/v2/kpayment.min.js"
  // data-apikey="pkey_test_20781gjXfviUiQ5iJxELVJzB0jPJVBrDIruXC"
  // data-amount="200.00"
  // data-currency="THB"
  // data-payment-methods="qr"
  // data-name="Online Sale"
  // data-order-id="order_test_20781e76bbed7f6c7469d89f4ab3bb97f9fed"
  // >
  // </script>
  // </form>
  // หลังจากนั้น จะกลับมา callback ให้ไปยิงเชคครั้งสุดท้ายกับ api/Payment/GetInquiryData จบ


  componentDidMount() {
    this.props.loadController(true)
    this.getData();
    sessionStorage.removeItem("MainData");
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }

  getData = () => {
    let retryPayment = false;
    if (sessionStorage.getItem("paymentError") && sessionStorage.getItem("paymentError") == 'true') {
      sessionStorage.removeItem("paymentError");
      sessionStorage.removeItem("seqPage");
      retryPayment = true;
    }
    if(MainData.screenSM013.checkRetryPayment){
      retryPayment = true;
    }
    const headers = {
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "AuthId": MainData.screenSM005.cusId,
      "AuthRef": MainData.screenSM002.costPayment
    }
    const data = {
      "retryPayment": retryPayment,
      "productId": MainData.initialData.productId,
      "planId": MainData.screenSM002.planId,
      "grantee": "SELF",
      "premium": MainData.screenSM002.cost,
      //"premiumPayment": MainData.screenSM002.costPayment,
      "paymentMode": MainData.screenSM002.paymentCode,
      "paymentChannel": MainData.initialData.channelCode,
      "receiveInsuranceChannel": MainData.screenSM009.insuranceTrack,
      "taxIdentification": MainData.screenSM010.isAgreeRD === null ? false : MainData.screenSM010.isAgreeRD,
      "insuredInformation": {
        "titleCode": MainData.screenSM013.titleCode,
        "firstName": MainData.screenSM008.firstName,
        "lastName": MainData.screenSM008.lastName,
        "titleCodeEN": MainData.screenSM013.titleCodeEN,
        "firstNameEN": MainData.screenSM008.firstNameEN,
        "lastNameEN": MainData.screenSM008.lastNameEN,
        "gender": MainData.screenSM001.gender,
        "birthDate": MainFunc.getUserBirthDate("be"),
        "citizenNo": MainData.screenSM005.idCard,
        "isExpiredCitizenNo": MainData.screenSM008.idCardExpDateNone === true ? "Y" : "N", // ตลอดชีพ "Y"
        "citizenNoExpiredDate": MainData.screenSM008.idCardExpDateNone === true ? null : MainData.screenSM008.idCardExp.split("/")[2] + "-" + MainData.screenSM008.idCardExp.split("/")[1] + "-" + MainData.screenSM008.idCardExp.split("/")[0], // ตลอดชีพ null 
        "currentAddress": MainData.screenSM009.isSameAddress === false ? "Home" : "No",
        "docAddress": MainData.screenSM009.isDocToNowAddress === false ? "Home" : "Curr",
        "jobInformations": [
          {
            "type": "Main",
            "occupation": MainData.screenSM013.jobInformationsOccupation,
            "description": MainData.screenSM013.jobInformationsDescription,
            // "natureOfBusiness":  MainData.screenSM013.jobInformationsOccupation == "ข070008-000" ?MainData.screenSM013.jobInformationsOccupation:MainData.initialData.natureOfBusinesses?.find(item => item.natureOfBusinessesCode == MainData.screenSM008.jobDescription)?.natureOfBusinessesCode,
          }
        ],
        "contactInformation": {
          "email": MainData.screenSM008.email,
          "mobileNo": MainData.screenSM006.phoneNumber
        },
        "addresses": [
          {
            "type": "Home",
            "building": MainData.screenSM009.isSameAddress === true ? MainData.screenSM009.village : MainData.screenSM009.formalVillage,
            "no": MainData.screenSM009.isSameAddress === true ? MainData.screenSM009.address : MainData.screenSM009.formalAddress,
            "moo": MainData.screenSM009.isSameAddress === true ? MainData.screenSM009.moo : MainData.screenSM009.formalMoo,
            "soi": MainData.screenSM009.isSameAddress === true ? MainData.screenSM009.soi : MainData.screenSM009.formalSoi,
            "road": MainData.screenSM009.isSameAddress === true ? MainData.screenSM009.street : MainData.screenSM009.formalStreet,
            "provinceCode": MainData.screenSM009.isSameAddress === true ? MainData.screenSM013.provinceCode : MainData.screenSM013.formalProvinceCode,
            "districtCode": MainData.screenSM009.isSameAddress === true ? MainData.screenSM013.districtCode : MainData.screenSM013.formalDistrictCode,
            "subDistrictCode": MainData.screenSM009.isSameAddress === true ? MainData.screenSM013.subDistrictCode : MainData.screenSM013.formalSubDistrictCode,
            "zipcode": MainData.screenSM009.isSameAddress === true ? MainData.screenSM009.zipCode : MainData.screenSM009.formalZipCode,
            "phoneNo": "",
            "mobileNo": ""
          },
          {
            "type": "Curr",
            "building": MainData.screenSM009.village,
            "no": MainData.screenSM009.address,
            "moo": MainData.screenSM009.moo,
            "soi": MainData.screenSM009.soi,
            "road": MainData.screenSM009.street,
            "provinceCode": MainData.screenSM013.provinceCode,
            "districtCode": MainData.screenSM013.districtCode,
            "subDistrictCode": MainData.screenSM013.subDistrictCode,
            "zipcode": MainData.screenSM009.zipCode,
            "phoneNo": "",
            "mobileNo": ""
          }
        ],
        "hasBeneficiary": MainData.screenSM010.isHeire === true ? false : true,
        "beneficiariesOfOrder": MainData.screenSM010.isHeire === true ? [] : MainData.screenSM013.beneficiariesOfOrder,
        "questionnaire": {
          "healthQuestions": !MainData.screenSM013.healthQuestions ? [] : MainData.screenSM013.healthQuestions,
          "FatcaQuestions": !MainData.screenSM004.fatcaAnsForservice ? [] : MainData.screenSM004.fatcaAnsForservice,
          "consentInfo": [{ "consentCode": 1, "consentResult": true }, { "consentCode": 2, "consentResult": true }]
        }
      },
        // "insuredDocument" : MainData.screenSM002.insureRequiredIdentification?{
        //     "type": "1",
        //     "file": MainData.screenSM014.uploadedFilesId_card?MainData.screenSM014.uploadedFilesId_card[0] ?.data.split(",")[1]:"",
        // } : null,
        // "insuredKYCDocument" : MainData.screenSM002.insureSelfie?{
        //     "file": MainData.screenSM014.uploadedFilesFace_shape?MainData.screenSM014.uploadedFilesFace_shape[0] ?.data.split(",")[1]:"",
        //  } : null,
        
      "submenuCode": this.state.screenPrev,
      "channelCode": MainData.initialData.channelCode,
    }

    if (MainData.screenSM010.isAgreeRD != null) {
      data.insuredInformation.questionnaire.consentInfo.push({ "consentCode": 3, "consentResult": MainData.screenSM010.isAgreeRD })
    }
    
    var logData = data;
    axios.post(MainData.serviceURL + '/Sales/SubmitTransaction', data, {
      headers: headers
    })
      .then(res => {
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        logData.insuredInformation.contactInformation.email = MainFunc.blurEmail(logData.insuredInformation.contactInformation.email)
        logData.insuredInformation.contactInformation.mobileNo = MainFunc.blurPhoneNum(logData.insuredInformation.contactInformation.mobileNo)
        logData.insuredInformation.firstName = MainFunc.blurName(logData.insuredInformation.firstName)
        logData.insuredInformation.lastName = MainFunc.blurName(logData.insuredInformation.lastName)
        logData.insuredInformation.citizenNo = MainFunc.blurIdcard(logData.insuredInformation.citizenNo)
        logData.insuredInformation.addresses[0].building = MainFunc.blurAll(logData.insuredInformation.addresses[0].building)
        logData.insuredInformation.addresses[0].no = MainFunc.blurAll(logData.insuredInformation.addresses[0].no)
        logData.insuredInformation.addresses[0].moo = MainFunc.blurAll(logData.insuredInformation.addresses[0].moo)
        logData.insuredInformation.addresses[0].soi = MainFunc.blurAll(logData.insuredInformation.addresses[0].soi)
        logData.insuredInformation.addresses[0].road = MainFunc.blurAll(logData.insuredInformation.addresses[0].road)
        logData.insuredInformation.addresses[1].building = MainFunc.blurAll(logData.insuredInformation.addresses[1].building)
        logData.insuredInformation.addresses[1].no = MainFunc.blurAll(logData.insuredInformation.addresses[1].no)
        logData.insuredInformation.addresses[1].moo = MainFunc.blurAll(logData.insuredInformation.addresses[1].moo)
        logData.insuredInformation.addresses[1].soi = MainFunc.blurAll(logData.insuredInformation.addresses[1].soi)
        logData.insuredInformation.addresses[1].road = MainFunc.blurAll(logData.insuredInformation.addresses[1].road)
        let data = res?.data?.data;
        if (res.data.success === true && data?.messageCode != "MSG015") {
          this.setState({ paymentChannelList: data.paymentChannelList });
          // window.KPayment.setAmount(data.premiumPayment)
          this.setState({ premium: data.premium, premiumPayment: data.premiumPayment, applicationNumber: data.applicationNumber, transactionNumber: data.transactionNumber, refOrder: res.data.message })
          MainFunc.sendLogToService(this.props.name, 'Accept', 'Success', 'เก็บข้อมูลการยินยันซื้อประกัน', {}, { data: logData });
          this.props.loadController(false)
          MainData.screenSM014={uploadedFilesId_card: null,uploadedFilesFace_shape: null}
          if(MainData.screenSM013.promotionCode){
            MainData.screenSM013.promotionCode = "";
            this.checkPromotion();
          }
        } 
        else if (data?.messageCode == "MSG015" && data?.paymentStatus && data?.paymentDetail?.detail?.productName){
          sessionStorage.setItem("submitOrderPayment", JSON.stringify(data));
          window.location.href = MainData.mainPath + 'checkout';
        }
        else {
          this.props.loadController(false)
          MainFunc.sendLogToService(this.props.name, 'Accept', 'Fail', 'เก็บข้อมูลการยินยันซื้อประกัน', {}, { data: logData });
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription, ' ')
          this.props.setPage(this.props.prev)
        }
      })
      .catch(error => {
        if(error?.response?.status == 400){
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
          this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "SubmitTransaction"), ' ')
        }
        else{
          logData.insuredInformation.contactInformation.email = MainFunc.blurEmail(logData.insuredInformation.contactInformation.email)
          logData.insuredInformation.contactInformation.mobileNo = MainFunc.blurPhoneNum(logData.insuredInformation.contactInformation.mobileNo)
          logData.insuredInformation.firstName = MainFunc.blurName(logData.insuredInformation.firstName)
          logData.insuredInformation.lastName = MainFunc.blurName(logData.insuredInformation.lastName)
          logData.insuredInformation.citizenNo = MainFunc.blurIdcard(logData.insuredInformation.citizenNo)
          logData.insuredInformation.addresses[0].building = MainFunc.blurAll(logData.insuredInformation.addresses[0].building)
          logData.insuredInformation.addresses[0].no = MainFunc.blurAll(logData.insuredInformation.addresses[0].no)
          logData.insuredInformation.addresses[0].moo = MainFunc.blurAll(logData.insuredInformation.addresses[0].moo)
          logData.insuredInformation.addresses[0].soi = MainFunc.blurAll(logData.insuredInformation.addresses[0].soi)
          logData.insuredInformation.addresses[0].road = MainFunc.blurAll(logData.insuredInformation.addresses[0].road)
          logData.insuredInformation.addresses[1].building = MainFunc.blurAll(logData.insuredInformation.addresses[1].building)
          logData.insuredInformation.addresses[1].no = MainFunc.blurAll(logData.insuredInformation.addresses[1].no)
          logData.insuredInformation.addresses[1].moo = MainFunc.blurAll(logData.insuredInformation.addresses[1].moo)
          logData.insuredInformation.addresses[1].soi = MainFunc.blurAll(logData.insuredInformation.addresses[1].soi)
          logData.insuredInformation.addresses[1].road = MainFunc.blurAll(logData.insuredInformation.addresses[1].road)
          this.props.loadController(false)
          MainFunc.sendLogToService(this.props.name, 'Accept', 'Fail', 'เก็บข้อมูลการยินยันซื้อประกัน', {}, { data: logData });
          if (error.response.data.data?.messageCode == 'MSG005') {
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
            this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription,`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-9":"STEP2-9"}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-9_Click_BtnModalRejected":"STEP2-9_Click_BtnModalRejected"}`)
          }
          else {
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
            this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
          }
          this.props.setPage(this.props.prev)
        }
      });
  }


  // processNext = async () => {
  //   this.props.loadController(true);
  //   let statusSystemConfig = await MainFunc.getSystemConfigData();
  //   if(statusSystemConfig){
  //       window.location.href = MainData.mainPath + 'shutdown'
  //       return;
  //   }
  //   const headers = {
  //     "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
  //     "Content-Type": "application/json;charset=UTF-8",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Credentials": "true",
  //     "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
  //     "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  //     "AuthId": !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId
  //   }
  //   const data = {
  //     "agentCode": this.state.referralCode,
  //     "submenuCode": this.state.screenCheckout
  //   }
  //   axios.post(MainData.serviceURL + '/Customer/AddOrUpdateReferrer', data, {
  //     headers: headers
  //   })
  //   .then(res => {
  //     this.props.loadController(false)
  //     if (res.data.success === true) {
  //       this.startCreatePaymentCentralized();
  //     } else {
  //       this.props.errorAlert(true, MainData.masterMessage.find(x=>x.msgCode === res?.data?.message), ' ')
  //     }
  //   })
  //   .catch(error => {
  //     this.props.loadController(false)
  //     let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
  //     this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
  //   });
  // }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    const headers = {
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "AuthId": !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId
    }
    const data = {
      action: "payment",
      promotionMKTCode: this.state.promotionStatus && this.state.promotionStatus != "false" ?this.state.promotionCode:"",
      //productId: MainData.initialData.productId,
      //planId: MainData.screenSM002.planId,
      channelCode: MainData.initialData.channelCode,
      //premiumPayment:this.state.premiumPayment,
      paymentChannelCode:"",
      paymentCode:MainData.screenSM002.paymentCode,
      agentCode: this.state.referralCode,
      submenuCode: this.state.screenCheckout
    };
    axios.post(MainData.serviceURL + '/Promotion/PromotionCal', data, {
      headers: headers
    })
      .then(res => {
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        this.props.loadController(false)
        let data = res?.data?.data;
        if(res.data.success === true){
          sessionStorage.setItem("MainData", JSON.stringify(MainData));
          sessionStorage.setItem("seqPage", this.props.seq);
          sessionStorage.setItem("premium", this.state.premium);
          sessionStorage.setItem("premiumPayment", this.state.premiumPayment);
          sessionStorage.setItem("applicationNumber", this.state.applicationNumber);
          sessionStorage.setItem("refOrder", this.state.refOrder);
          sessionStorage.setItem("cusId", MainData.screenSM005.cusId);
          sessionStorage.setItem("homePage", window.location.href);
          sessionStorage.setItem("bearerAuth", MainData.initialData.bearerAuth);
          sessionStorage.setItem("channelCode", this.state.channelCode);
          sessionStorage.setItem("channelType", this.state.channelType);
          if(data.paymentUrl){
            sessionStorage.removeItem("submitOrderPayment");
            window.location.href = data.paymentUrl;
          }
          else if (data?.messageCode == "MSG015" && data?.paymentStatus && data?.paymentDetail?.detail?.productName){
            sessionStorage.setItem("submitOrderPayment", JSON.stringify(data));
            window.location.href = MainData.mainPath + 'checkout';
          }
        }
        else{
          let errMsg = MainData.masterMessage.find(x => x.msgCode === res.data.message);
          if(res?.data?.message == "MSG136" || res?.data?.message == "MSG135" || res?.data?.message == "MSG143"){
            this.props.errorAlert(true, errMsg.msgDescription, ' ')
          }
          else{
            this.setState({ promotionStatus: "false",promotionDetail:null,failPromotionDetail:errMsg.msgDescription,failPromotion:true });
          }
        }
      })
      .catch(error => {
        this.props.loadController(false)
        this.setState({ promotionStatus: "",promotionDetail:null,failPromotion:false,failPromotionDetail:"" });
        let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
      });
    }

  // startCreatePaymentCentralized = () => {
  //     const headers = {
  //       "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
  //       "Content-Type": "application/json;charset=UTF-8",
  //       "Access-Control-Allow-Origin": "*",
  //       "Access-Control-Allow-Credentials": "true",
  //       "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
  //       "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  //       "AuthId": !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId
  //     }
  //     const data = {}
  //     axios.post(MainData.serviceURL + '/Payment/CreatePaymentCentralized', data, {
  //       headers: headers
  //     })
  //       .then(res => {
  //         let data = res?.data?.data;
  //         if(res.data.success === true){
  //           sessionStorage.setItem("MainData", JSON.stringify(MainData));
  //           sessionStorage.setItem("seqPage", this.props.seq);
  //           sessionStorage.setItem("premium", this.state.premium);
  //           sessionStorage.setItem("premiumPayment", this.state.premiumPayment);
  //           sessionStorage.setItem("applicationNumber", this.state.applicationNumber);
  //           sessionStorage.setItem("refOrder", this.state.refOrder);
  //           sessionStorage.setItem("cusId", MainData.screenSM005.cusId);
  //           sessionStorage.setItem("homePage", window.location.href);
  //           sessionStorage.setItem("bearerAuth", MainData.initialData.bearerAuth);
  //           sessionStorage.setItem("channelCode", this.state.channelCode);
  //           sessionStorage.setItem("channelType", this.state.channelType);
  //           window.location.href = data.data.url;
  //           // console.log("data.url :",data.data.url)
  //         }
  //         else{
  //           let errMsg = MainData.masterMessage.find(x => x.msgCode === res.data.message);
  //           this.props.errorAlert(true, errMsg.msgDescription, ' ')
  //         }
  //       })
  //       .catch(error => {
  //         let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
  //         this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
  //       });
  // }

  handleKPlayPlusChange = (val) => {
    val = val.replace(/[^\d]/g, "");
    if(val.length == 1 && val != "0"){
      val = "";
    }
    if (val.length <= 10) {
      this.setState({ KPayPlusNumber: val })
    }
  }

  gotoEditingPage = (screenCode) => {
    let editingPage = MainData.masPage.find(x => x.subMenuCode === screenCode);
    if (!editingPage) {
      // err
    } else {
      MainData.screenSM012 = this.props.seq;
      this.props.setPage(editingPage.seq)
    }
  }

  checkPromotion = () => {
    const headers = {
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "AuthId": !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId
    }
    const data = {
      action: "check",
      promotionMKTCode: this.state.promotionCode,
      //productId: MainData.initialData.productId,
      //planId: MainData.screenSM002.planId,
      channelCode: MainData.initialData.channelCode,
     // premiumPayment:this.state.premiumPayment,
      paymentChannelCode:"",
      paymentCode:MainData.screenSM002.paymentCode
    };
    axios.post(MainData.serviceURL + '/Promotion/PromotionCal', data, {
      headers: headers
    })
      .then(res => {
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        let data = res?.data?.data;
        if(res.data.success === true){
          data.amountDiscount = Number(data.amountDiscount.toFixed(2))
          data.premiumDiscount = Number(data.premiumDiscount.toFixed(2))
          data.premiumPayment = Number(data.premiumPayment.toFixed(2))
          this.setState({ promotionStatus: "true",promotionDetail: data,failPromotion:false,failPromotionDetail:""});
        }
        else{
          let errMsg = MainData.masterMessage.find(x => x.msgCode === res.data.message);
          // this.props.errorAlert(true, errMsg.msgDescription, ' ')
          this.setState({ promotionStatus: "false",promotionDetail:null,failPromotionDetail:errMsg.msgDescription,failPromotion:true });
        }
      })
      .catch(error => {
        this.setState({ promotionStatus: "",promotionDetail:null,failPromotion:false,failPromotionDetail:"" });
        let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ " : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
      });
    }

  render() {
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{this.props.mainMenuName}</span>
            {this.props.maxPage > 1 ? <span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span> : null }
          </Col>
          <Col xs={12} className="pt-3">
            <small className="text--muted mt-3" dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0100") }}></small>
            <TextField 
              inputProps={{ autocomplete: 'off',maxLength: 100 }} 
              label={""}
              className={this.state.promotionStatus == "true"?"Mui-Success":""}
              placeholder={MainFunc.getLabelDtos("L0451")}
              value={this.state.promotionCode} 
              onChange={(e) => e.target.value[0]===" "?"":this.setState({ promotionCode: e.target.value, promotionStatus: "",promotionDetail: null,failPromotion:false,failPromotionDetail:"" })} InputLabelProps={{ shrink: true }} 
              fullWidth
              error={this.state.promotionStatus=="false"}
              InputProps={{
                endAdornment: <Fragment>
                                <Image className="img-center" src={this.state.promotionStatus != ""?this.state.promotionStatus == "true"?IcoCheck:IcoClose:""} style={{height: '25px', left: '0', top: '-5px'}}></Image>
                                <Button variant="" className="mainBtnRadiusV2 font-weight-bold" disabled={this.state.promotionCode?false:true} onClick={() => { this.checkPromotion()} }>Apply</Button>
                              </Fragment>
              }}
            />
            <small className={!this.state.failPromotion?"d-none":"text-danger"}>{this.state.failPromotionDetail}</small>
          </Col>
          <Col xs={12} className="pt-3">
            <small className="text--muted mt-3" dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0101") }}></small>
            <TextField inputProps={{ autocomplete: 'off' }} label={""} placeholder={MainFunc.getLabelDtos("L0450")} value={this.state.referralCode} onChange={(e) => e.target.value[0]===" "?"":this.setState({ referralCode: e.target.value })} InputLabelProps={{ shrink: true }} fullWidth inputProps={{ maxLength: 100 }} />
          </Col>

          <Col xs={12} className={"pt-3 pb-2"}>
            <Card className="p-3 heirBoxShadow mt-1">
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0105")} ({MainData.screenSM002.paymentName})</small>
              <span className="csMainColor font-weight-bold">{MainFunc.numberWithCommas(this.state.premium)} {MainFunc.getLabelDtos("L0104")}</span>
              {/* {this.state.promotionDetail && ( */}
                <Fragment>
                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0480") /** ได้รับส่วนลด */} {this.state.promotionDetail?`(${this.state.promotionDetail.promotionName})`:""}</small>
                    <span className="csMainColor font-weight-bold">{(!this.state.promotionDetail?"0.00":this.state.promotionDetail?.amountDiscount == 0 ? 
                            ""+MainFunc.numberWithCommas(this.state.promotionDetail?.amountDiscount) :
                            (this.state.promotionDetail?.amountDiscount > 0 ? 
                            "+"+MainFunc.numberWithCommas(this.state.promotionDetail?.amountDiscount) : 
                            MainFunc.numberWithCommas(Math.abs(this.state.promotionDetail?.amountDiscount))) || "").toLocaleString("en")} 
                            {" "+MainFunc.getLabelDtos("L0104")}
                    </span>
                </Fragment>
              {/* )} */}
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0168")}</small>
              <span className="csMainColor font-weight-bold">{MainFunc.numberWithCommas(this.state.promotionDetail?this.state.promotionDetail?.premiumDiscount:this.state.premiumPayment)} {MainFunc.getLabelDtos("L0104")}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0106")}</small>
              <span className="font-weight-bold">{this.state.applicationNumber}</span>
            </Card>
          </Col>
          {MainData.screenSM002.paymentCode != 'TWELVE_MONTHS' ?
            <Col xs={12} className={"pt-3 pb-2"} >
              <span className="mt-3">{MainFunc.getLabelDtos("L0169")} {MainData.screenSM002.paymentName}</span>
            </Col>
            : ''}
          <Col xs={12} className={"pt-3 pb-2 d-none"}>
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0107")}</span>
            {MainData.initialData.channelCode == "KBANK" && this.state.paymentChannelList.map((item, Key) => {
              if (item.channel == 'bancacredit' && item.channelType == 'other' || item.channel == 'linebkcredit' && item.channelType == 'other') {
                return (
                  <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP4_PAYMENT_Click_BtnCreditCard":"STEP3_PAYMENT_Click_BtnCreditCard"}`} variant="" value="CREDITCARD" onClick={(e) => { this.setState({ payBy: "CREDITCARD", channelCode: item.channel, channelType: item.channelType }) }} className={this.state.payBy === "CREDITCARD" ? "payActiveBtn w-100 mt-2" : "payBtn w-100 mt-2"}>{MainFunc.getLabelDtos("L0108")}</Button>
                )
              }
              else if (item.channel == 'bancaqr' && item.channelType == 'other' || item.channel == 'linebkqr' && item.channelType == 'other') {
                return (
                  <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP4_PAYMENT_Click_BtnQRCode":"STEP3_PAYMENT_Click_BtnQRCode"}`} variant="" value="QRCODE" onClick={(e) => { this.setState({ payBy: "QRCODE", channelCode: item.channel, channelType: item.channelType }) }} className={this.state.payBy === "QRCODE" ? "payActiveBtn w-100 mt-2" : "payBtn w-100 mt-2"}>{MainFunc.getLabelDtos("L0109")}</Button>
                )
              }
              else if (item.channel == 'kplus' && item.channelType == 'other') {
                return (
                  <Accordion className="mt-2">
                    <Card className={this.state.payBy === "KPAYPLUS" ? "KPayPlusCardActive " : "KPayPlusCard"}>
                      <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP4_PAYMENT_Click_BtnKPayPlus":"STEP3_PAYMENT_Click_BtnKPayPlus"}`} variant="" value="KPAYPLUS" onClick={(e) => { this.setState({ payBy: "KPAYPLUS", channelCode: item.channel, channelType: item.channelType }) }} className={this.state.payBy === "KPAYPLUS" ? "payActiveBtn w-100" : "payBtn w-100"} style={{ border: "0px", borderRadius: "0px" }}>ชำระผ่าน K PAY PLUS</Button>
                      <Accordion.Collapse eventKey={this.state.payBy} className={this.state.payBy === "KPAYPLUS" ? "show" : ""}>
                        <Card.Body>
                          <TextField  error={this.state.failPhone} inputProps={{ autocomplete: 'off' }} label="เบอร์โทรศัพท์จาก K PLUS" placeholder="เบอร์โทรศัพท์จาก K PLUS (โปรดระบุ)" helperText={this.state.failPhone?MainFunc.getLabelDtos("L0144"):""} value={MainFunc.phoneFormat(this.state.KPayPlusNumber)} onChange={(e) => this.handleKPlayPlusChange(e.target.value)} InputLabelProps={{ shrink: true }} fullWidth />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                )
              }
              else {
                return ('')
              }
            })}

          </Col>
          <Col xs={12} className={"d-flex justify-content-center pt-3 pl-2"}>
            <Button disabled={this.state.promotionStatus == "false"?true:false} id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP4_PAYMENT_Click_BtnConfirm":"STEP3_PAYMENT_Click_BtnConfirm"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext()} }>{MainFunc.getLabelDtos("L0153")}</Button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default SM013;