import React, { Component, Fragment } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

class MAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM014",
      inputValueDefault: "",
      value: "",
      inputValue: "",
      selectValue: "",
      dataSelect: this.props.dataSelect,
    };
  }

  componentDidMount() {
    // if(this.props.value && this.props.choices){
    //   this.setState({ inputValue: this.props.choices?.find((data) => data.value ==  props.value).name });
    // }
    
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.inputValue !== prevProps.inputValue) || (this.props.choices !== prevProps.choices)) {
      if(this.props.value && this.props.choices){
        this.setState({ inputValue: this.props.choices?.find((data) => data.value ==  this.props.value)?.name?this.props.choices?.find((data) => data.value ==  this.props.value)?.name:"" });
      }
    }
  }

  onChangeAutocomplete = (val) => {
    // this.setState({ value: val, selectValue: val.value });
    this.props.onChange(val.value);
  };

  onInputChangeAutocomplete = (val) => {
    this.setState({ inputValue: val, selectValue: val });
    // this.props.onChange(
    //   this.props.choices?.find((item)=>item.label==val)?this.props.choices?.find((item)=>item.label==val).code:"",
    //   val
    //   )
  };

  render() {
    return (
      <Fragment>
        {/* <div>
          <div>{`value: ${
            this.state.selectValue !== null
              ? `'${this.state.selectValue}'`
              : "null"
          }`}</div>
          <div>{`inputValue: '${this.state.inputValue}'`}</div>
          <br /> */}
        <Autocomplete
          value={this.props.value && this.props.choices ?this.props.choices?.find((data) => data.value ==  this.props.value):""}
          onChange={(event, newValue) => {
            this.onChangeAutocomplete(newValue);
          }}
          inputValue={this.state.inputValue}
          onInputChange={(event, newInputValue) => {
            this.onInputChangeAutocomplete(newInputValue);
          }}
          options={this.props.choices?this.props.choices:[]}
          getOptionLabel={(option) => option.name}
          disableClearable={true}
          disabled={this.props.disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={this.props.placeholder}
              name={this.props.name}
              id={this.props.id}
              margin="normal"
              error={this.props.error}
              type={this.props.type ?? "text"}
            />
          )}
          onKeyDown={(event) => { 
            if(this.props.onEnter){
              if (event.key === 'Enter' && !this.props.choices?.find((data)=>data.name == this.state.inputValue)) {
                this.props.onEnter();
              }
            }
            if(this.props.onEnterJobDescriptionText){
              if(event.key === 'Enter' && !this.props.choices?.find((data)=>data.name == this.state.inputValue)){
                this.props.onEnterJobDescriptionText(this.state.inputValue);
              }
            }
          }}
        />
        {/* </div> */}
      </Fragment>
    );
  }
}

export default MAutocomplete;
