// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultTextContainer = exports.DefaultHitContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    color: ", ";\n    font-size: 1rem;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    color: ", ";\n    font-weight: bold;\n    font-size: 1rem;\n    font-family: 'Lato Black';\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var DefaultHitContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject(), function (props) {
  return props.palette.text.main;
});
exports.DefaultHitContainer = DefaultHitContainer;
var DefaultTextContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject2(), function (props) {
  return props.palette.text.main;
});
exports.DefaultTextContainer = DefaultTextContainer;