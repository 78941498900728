import moment from "moment";
import { notify } from 'react-noti';
import React, { Component } from "react";
import MomentUtils from '@date-io/moment';
import localization from "moment/locale/th";
import { Row, Col, Button } from "react-bootstrap";
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MuiPickersUtilsProvider as MuiPickersUtilsProviderTH, DatePicker as DatePickerTH, KeyboardDatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import MAutocomplete from "../MAutocomplete"

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import styles from "../../styles/UploadFile.module.scss";

import expFaceShape from '../../images/exp_face_shape.svg';
import axios from "axios";

moment().locale("th", localization).add(543, "year").format('LLL')


class SM001 extends Component {
  constructor(props) {
    super(props);
    this.state = {
        screen: "SM001",
        validate: false,
        failAgeRange: false,
        failAgeRangeMsg: "",
        checkGender: MainData.checkGender,
        checkAgeMin: MainData.checkAgeMin,
        checkAgeMax: MainData.checkAgeMax,
        gender: MainData.screenSM001.gender,
        productTitle: MainData.productTitle,
        birthdate: MainData.screenSM001.birthdate,
        checkAgeMinUnit: MainData.checkAgeMinUnit,
        checkAgeMaxUnit: MainData.checkAgeMaxUnit,
        uploadedFiles:undefined,
    };
  }

  componentDidMount(){
    if(this.props.screenIndex===0){
      this.props.setHeaderText("Online Sales", MainFunc.getLabelDtos("L0465")/* ซื้อประกันออนไลน์ที่เหมาะกับคุณ */, "", false)
    }
    if(this.props.getProductFail){
      this.setState({validate: true, failAgeRange: true,failAgeRangeMsg:this.textfailAgeRange(true)});
      this.props.setGetProductFail(false);
    }
  }

  convertBirthDate(data){
    let year = data.split("/")[2];
      year = (parseInt(year) - 543).toString()
    let month = data.split("/")[1];
    let day = data.split("/")[0];

    return year+"-"+month+"-"+day;
  }

  processForNextPage = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    var getDate = this.state.birthdate.split("/");
    var nowDate = moment([new Date().getFullYear(), new Date().getMonth()+1, new Date().getDate() ], 'YYYY-MM-DDTHH:mm:ssZ');
    var birthDate = moment([parseInt(getDate[2])-543, parseInt(getDate[1]), parseInt(getDate[0]) ], 'YYYY-MM-DDTHH:mm:ssZ').format();
    var birthDateYears = moment([parseInt(getDate[2])-543], 'YYYY-MM-DDTHH:mm:ssZ');
    var diffYearsMin = nowDate.diff(birthDate, 'years'); 
    var diffYearsMax = nowDate.diff(birthDateYears, 'years'); 
    var diffMonths = nowDate.diff(birthDate, 'months'); 
    var diffDays = nowDate.diff(birthDate, 'days'); 
    var userAgeMin;
    var userAgeMax;
    switch(this.state.checkAgeMinUnit){
      case "M": userAgeMin = diffMonths; break;
      case "D": userAgeMin = diffDays; break;
      default: userAgeMin = diffYearsMin;
    }
    switch(this.state.checkAgeMaxUnit){
      case "M": userAgeMax = diffMonths; break;
      case "D": userAgeMax = diffDays; break;
      default: userAgeMax = diffYearsMax;
    }
    if(this.state.checkAgeMin != 20 && this.state.checkAgeMinUnit == "Y"){
      userAgeMin = moment([new Date().getFullYear()], 'YYYY-MM-DDTHH:mm:ssZ').diff(birthDateYears, 'years'); 
    }
    if(!this.state.gender || !this.state.birthdate){
      this.setState({validate: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG002');
      this.props.errorAlert(true, !errMsg?'กรุณาระบุข้อมูล':errMsg.msgDescription, ' ')
      if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA || MainData.screenSM001.birthdate || MainData.screenSM001.gender){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA?MainData.screenSM001.birthdatePDPA:MainData.screenSM001.birthdate), gender: MainData.screenSM001.genderPDPA?MainData.screenSM001.genderPDPA:MainData.screenSM001.gender, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Fail', errMsg.msgDescription)
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Fail', 'กรุณาระบุข้อมูล')
      }
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    }else if((this.state.birthdate.length<10) || (parseInt(getDate[1])>12) || (parseInt(getDate[0])>31) ){
      this.setState({validate: true, failAgeRange: true,failAgeRangeMsg:this.textfailAgeRange(true)})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
      // this.props.errorAlert(true, !errMsg?'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง':errMsg.msgDescription, ' ')
      if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA || MainData.screenSM001.birthdate || MainData.screenSM001.gender){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA?MainData.screenSM001.birthdatePDPA:MainData.screenSM001.birthdate), gender: MainData.screenSM001.genderPDPA?MainData.screenSM001.genderPDPA:MainData.screenSM001.gender, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Fail', errMsg.msgDescription)
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Fail', 'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง')
      }
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    }
    else if(
      (this.state.checkAgeMin != 20 && ((userAgeMin<this.state.checkAgeMin) || (userAgeMax>this.state.checkAgeMax))) ||
      (this.state.checkAgeMin == 20 && ((userAgeMin<this.state.checkAgeMin) || (userAgeMax>this.state.checkAgeMax))) ){
      if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA || MainData.screenSM001.birthdate || MainData.screenSM001.gender){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA?MainData.screenSM001.birthdatePDPA:MainData.screenSM001.birthdate), gender: MainData.screenSM001.genderPDPA?MainData.screenSM001.genderPDPA:MainData.screenSM001.gender, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Fail', 'รับประกันอายุระหว่าง 21 ปี - 60 ปี')
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Fail', 'รับประกันอายุระหว่าง 21 ปี - 60 ปี')
      }
      this.setState({validate: true, failAgeRange: true,failAgeRangeMsg:this.textfailAgeRange()})
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    }
    else if((userAgeMin>=this.state.checkAgeMin) || (userAgeMax<=this.state.checkAgeMax)){
      if(((this.props.checkCookie===true) && (!sessionStorage.getItem("_cookie"))) ||  (sessionStorage.getItem("_cookie") === "close")) {
        if(this.props.screenIndex===0){
          this.props.setHeaderText(MainData.productTitle, "", "", true)
        }
        if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA), gender: MainData.screenSM001.genderPDPA, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Success', null)
        }else if(MainData.screenSM001.birthdate || MainData.screenSM001.gender){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdate), gender: MainData.screenSM001.gender, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Success', null)
        }else{
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Success', null)
        }
        MainData.screenSM001.birthdatePDPA = '';
        MainData.screenSM001.genderPDPA = null;
        MainData.screenSM001.birthdate = this.state.birthdate;
        MainData.screenSM001.gender = this.state.gender;
        MainData.screenSM001.screen = this.state.screen;
        MainData.screenSM001.WindowLocationHref = window.location.href;
        this.props.setPage(this.props.next)
      }else{
        if(this.props.screenIndex===0 ){
          this.props.setHeaderText(MainData.productTitle, "", "", true)
        }
        if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA), gender: MainData.screenSM001.genderPDPA, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Success', null)
        }else if(MainData.screenSM001.birthdate || MainData.screenSM001.gender){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdate), gender: MainData.screenSM001.gender, grantee: "SELF" }, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Success', null)
        }else{
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), gender: this.state.gender, grantee: "SELF" }, 'Success', null)
        }
        MainData.screenSM001.birthdatePDPA = '';
        MainData.screenSM001.genderPDPA = null;
        MainData.screenSM001.birthdate = this.state.birthdate;
        MainData.screenSM001.gender = this.state.gender;
        MainData.screenSM001.screen = this.state.screen;
        MainData.screenSM001.WindowLocationHref = window.location.href;
        this.addOrUpdateCustomerBirthday();
      }
    }
  }

  addOrUpdateCustomerBirthday = () => {
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": MainData.initialData.productId,
      "gender":  this.state.gender,
      "birthDate": MainFunc.getUserBirthDate("ac"),
      "age": moment().diff( MainFunc.getUserBirthDate("ac"), "years"),
      "transUrl": window.location.href,
      "submenuCode": this.state.screen,
    };
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerBirthday', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
        this.props.setPage(this.props.next)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
      
    });
  }

  validateBadRequest = (fieldNameList) => {
    if(fieldNameList.find(data => data.field == "birthDate") || fieldNameList.find(data => data.field == "age")){
      if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA || MainData.screenSM001.birthdate || MainData.screenSM001.gender){
        MainFunc.logPDPA(
          'Edit', 
          this.props.mainMenuName, 
          this.props.name, 
          null, 
          {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA?MainData.screenSM001.birthdatePDPA:MainData.screenSM001.birthdate), 
          gender: MainData.screenSM001.genderPDPA?MainData.screenSM001.genderPDPA:MainData.screenSM001.gender, grantee: "SELF" },
          {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId,
          birthDate: this.convertBirthDate(this.state.birthdate), 
          gender: this.state.gender, grantee: "SELF" }, 'Fail', 'รับประกันอายุระหว่าง 21 ปี - 60 ปี'
        );
        // this.setState({validate: true, failAgeRange: true})
        // let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
        // this.props.errorAlert(true, !errMsg?'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง':errMsg.msgDescription, ' ')
      }else{
        MainFunc.logPDPA(
          'Add', 
          this.props.mainMenuName, 
          this.props.name, 
          null, 
          null, 
          {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(this.state.birthdate), 
          gender: this.state.gender, 
          grantee: "SELF" }, 
          'Fail', 
          'รับประกันอายุระหว่าง 21 ปี - 60 ปี'
        );
      }
      this.setState({validate: true, failAgeRange: true,failAgeRangeMsg:this.textfailAgeRange(true)})
      MainData.screenSM001.birthdatePDPA = this.state.birthdate;
      MainData.screenSM001.genderPDPA = this.state.gender;
    }
    else if (fieldNameList.find(data => data.field == "gender")) {
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
      this.props.errorAlert(true, !errMsg?'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง':errMsg.msgDescription, ' ');
      this.setState({gender: ""})
    }
  }

  handleBirthDate = (val,key) => {
    let valeplace = val.replace(/[^\d]/g, ""); 
    let setValNew = MainFunc.genTextToDate(valeplace);
    MainData.screenMenuKPlus.onKPlus=false;
    if(val == setValNew && setValNew.slice(-1) == '/'){
      setValNew = setValNew.slice(0, setValNew.length -1);
    }
    else if(key.inputType == 'deleteContentBackward' && setValNew.slice(-1) == '/'){
      setValNew = setValNew.slice(0, setValNew.length -2);
    }
    if(valeplace.length<=8) this.setState({birthdate: setValNew, failAgeRange: false,failAgeRangeMsg:this.textfailAgeRange(true)})
  }

  getRoundedSize = (size) => {
    return Math.round((size / 1000000) * 100) / 100;
  };

  onChangeFile = (name, files) => {
    this.setState({uploadedFiles: files})
  };

  textfailAgeRange = (IncorrectInformation) => {
    if(IncorrectInformation){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
      return errMsg.msgDescription;
    }
    else{
      return `${MainFunc.getLabelDtos("L0127")} ${this.state.checkAgeMin} ${this.state.checkAgeMinUnit==="M"?MainFunc.getLabelDtos("L0129"):this.state.checkAgeMinUnit==="D"?MainFunc.getLabelDtos("L0128"):MainFunc.getLabelDtos("L0130")} - ${this.state.checkAgeMax} ${this.state.checkAgeMaxUnit==="M"?MainFunc.getLabelDtos("L0129"):this.state.checkAgeMaxUnit==="D"?MainFunc.getLabelDtos("L0128"):MainFunc.getLabelDtos("L0130")}`;
    }
    
  }

  render(){
    return (

      <div className="container-fulid pt-4 mg--l">
        <Row>
          <Col xs={12}  className="d-flex justify-content-center">
            <Row>
              <Col xs={12} md={12} className="d-flex justify-content-center">
                <h4 className="font-weight-bold csMainColor">{this.state.productTitle}</h4>
              </Col>
              <Col xs={12} md={12} className="font-weight-bold mt-4 px-md-0">
                <span className={!this.state.gender&&this.state.validate?"text-danger font-weight-bold":"font-weight-bold"} style={{fontSize: "1.1rem"}}>{MainFunc.getLabelDtos("L0001")}</span>
              </Col>
              <Col xs={12}>
                <Row>
                  {this.state.checkGender.map((item, key)=>{
                    return(
                      <Col key={key} xs={this.state.checkGender.length>1?6:12} className="d-flex justify-content-center pr-2 pl-md-0">
                        <Button id={item==="MALE"?`${MainData.initialData.codeTracking}BasicInfo_Click_BtnMale`:`${MainData.initialData.codeTracking}BasicInfo_Click_BtnFeMale`} variant="" className={this.state.gender===item?"pSelectedBtn w-100":"pSelectBtn w-100"} onClick={()=>{this.setState({gender: item});MainData.screenMenuKPlus.onKPlus=false;}}>{item==="MALE"?MainFunc.getLabelDtos("L0002"):MainFunc.getLabelDtos("L0003")}</Button>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
              <Col xs={12} md={12} className="font-weight-bold mt-4 px-md-0 ">
                <span className={!this.state.birthdate&&this.state.validate?"text-danger font-weight-bold":this.state.failAgeRange===true?"text-danger font-weight-bold":"font-weight-bold"} style={{fontSize: "1.1rem"}}>{MainFunc.getLabelDtos("L0004")}</span>
              </Col>
              <Col xs={12} className="px-md-0"> 
                <TextField inputProps={{autocomplete: 'off'}} name="birthDate" label={""} autocomplete={"off"} placeholder={MainFunc.getLabelDtos("L0005")} value={this.state.birthdate} onChange={(e)=>this.handleBirthDate(e.target.value,e.nativeEvent)} InputLabelProps={{ shrink: true }} fullWidth error={!this.state.failAgeRange?false:true} />
                <small className={!this.state.failAgeRange?"d-none":"text-danger"}>{this.state.failAgeRangeMsg}</small>
              </Col>
            </Row>
          </Col>
          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className="d-flex justify-content-center pt-3">
              <Button variant="" className="subBtnRadius font-weight-bold" onClick={() => this.props.setPage(this.props.prev)}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className="d-flex justify-content-center pt-3">
              <Button id={`${MainData.initialData.codeTracking}BasicInfo_Click_BtnFindInsurance`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processForNextPage() }} disabled={!this.state.gender || !this.state.birthdate}>{MainFunc.getLabelDtos("L0007")}</Button>
            </Col>
          }
        </Row>
        {/* <Row className="w-100 oppositeWhite" style={{position: "absolute", bottom: "0px"}}>
          <Col xs={12} className="pt-3 pb-3 d-flex justify-content-center">
            <small className="text--muted text-center">{MainFunc.getLabelDtos("L0008")}</small>
          </Col>
        </Row> */}
      </div>
    )
  }
}
export default SM001;