// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Chip = _interopRequireDefault(require("../../../Chip"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-right: 8px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledChip = (0, _styledComponents["default"])(_Chip["default"])(_templateObject());
var _default = StyledChip;
exports["default"] = _default;