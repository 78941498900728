// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: inline-flex;\n\n    button {\n        border-radius: 0;\n        border-width: 1px;\n        margin-left: -1px;\n\n        &:focus {\n            z-index: 1;\n        }\n\n        &:hover {\n            z-index: 1;\n        }\n    }\n\n    > button:first-child,\n    > div:first-child > button {\n        border-radius: 100px 0 0 100px;\n    }\n\n    > button:last-child,\n    > div:last-child > button {\n        border-radius: 0 100px 100px 0;\n    }\n\n    > button:only-child,\n    > div:only-child > button {\n        border-radius: 100px;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].div(_templateObject());

var _default = StyledContainer;
exports["default"] = _default;