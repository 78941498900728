import { render } from 'react-dom';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import moment from "moment";

import Error404 from './components/404';
import Checkout from './components/checkout';
import Shutdown from './components/shutdown';
import NotFound from './components/notFound';
import MainPage from  './components/MainPage';
// import Inquiry from './components/Inquiry';
import Detail from './components/Detail';
import LandingPage from './components/Landing';
import ContinuePage from './components/Continue';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css'
import "./i18n";

import MainData from './models/MainData';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '-'
    };
  }

  UNSAFE_componentWillMount(){
  
  }

  componentDidMount(){

  }

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path={MainData.mainPath+":channelCode/:productId/:source"} component={MainPage} />
          <Route exact path={MainData.mainPath+":channelCode/:productId"} component={MainPage} />
          <Route exact path={MainData.mainPath} component={MainPage} />
          <Route exact path={MainData.mainPath+"checkout"} component={Checkout} />
          {/* <Route exact path={MainData.mainPath+"inquiry"} component={Inquiry} /> */}
          <Route exact path={MainData.mainPath+"detail"} component={Detail} />
          <Route exact path={MainData.mainPath+"landing"} component={LandingPage} />
          <Route exact path={MainData.mainPath+"continue"} component={ContinuePage} />
          <Route exact path={MainData.mainPath+"shutdown"} component={Shutdown} />
          <Route exact path={MainData.mainPath+"notfound"} component={NotFound} />
          <Route missing component={Error404} />
        </Switch>
      </Router >
    );
  }
}

render(<App />, document.getElementById('root'));