// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    align-items: center;\n    width: 2rem;\n\n    &::after {\n        content: '';\n        border: 0.5px solid ", ";\n        margin: 0 auto;\n        width: 1px;\n        height: 100%;\n        box-sizing: border-box;\n    }\n\n    @media (max-width: 800px) {\n        width: 100%;\n        height: 1rem;\n\n        &::after {\n            height: 1px;\n            width: 100%;\n        }\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledDivider = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.border.divider;
});
var _default = StyledDivider;
exports["default"] = _default;