// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border-top: 1px solid ", ";\n    list-style: none;\n    box-sizing: border-box;\n\n    &:first-child {\n        border-top: 0;\n    }\n\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledLi = (0, _attachThemeAttrs["default"])(_styledComponents["default"].li)(_templateObject(), function (props) {
  return props.palette.border.divider;
}, function (props) {
  return props.disabled && 'pointer-events: none;';
});
var _default = StyledLi;
exports["default"] = _default;