import axios from "axios";
import { notify } from 'react-noti';
import React, { Component } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { Button, Col, Row, Form, Accordion, Card } from "react-bootstrap";

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import moment from "moment";
import localization from "moment/locale/th";

import SweetAlert from 'react-bootstrap-sweetalert';
import { AiFillCloseSquare } from 'react-icons/ai';

import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { GoAlert } from 'react-icons/go';
import { ThreeSixtySharp, ThreeSixtyTwoTone } from "@material-ui/icons";

moment().locale("th", localization).add(543, "year").format('LLL')

class SM004 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM004",
      fatcaQuestion: [],
      crsQuestion: [],
      eventKeyActive: false,
      fatcaInformationConsent: "",
      fatcaAns: !MainData.screenSM004.fatcaAns ? [] : MainData.screenSM004.fatcaAns,
      crsAns: !MainData.screenSM004.crsAns ? [] : MainData.screenSM004.crsAns,
      fatcaAnsPDPA: {},
      crsAnsPDPA: {},
      acceptSensitive: !MainData.screenSM004.acceptSensitive ? false : MainData.screenSM004.acceptSensitive,
      acceptSensitiveDataModal: false,
      taxIdentificationNumber: !MainData.screenSM004.taxIdentificationNumber ? false : MainData.screenSM004.taxIdentificationNumber,
      conditionCrs: [],
      answerCondition: !MainData.screenSM004.answerCondition ? [] : MainData.screenSM004.answerCondition,
      dataCountry: {},
      radioTaxIdentification: "",
      validation: true,
      fieldNotValid: [],
      configCRSDescription:"",
      configCRSTitle:"",
      isErrorBirthday: false,
      isErrorBirthdayDesc: "",
    };
  }

  componentDidMount() {
    this.props.loadController(true)
    this.getData()
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }
  getData = () => {
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": parseInt(MainData.initialData.productId)
    };
    axios.post(MainData.serviceURL + '/Sales/GetFATCAQuestion', data, {
      headers: headers
    })
      .then(res => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        let data = res.data.data;
        if (!data) {
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription, ' ')
          this.props.setPage(this.props.prev)
          return;
        }
        let fatcaAns = [];
        let crsAns = [];
        let conditionCrs = [];
        let configCRSDescription = "";
        let configCRSTitle = "";
        if (MainData.screenSM004.fatcaAns.length !== data.fatcaQuestion.length) {
          for (let obj of data.fatcaQuestion) {
            fatcaAns.push({ questionCode: obj.fatcaQuestionCode, answerCode: obj.fatcaAnswer.find(x => x.isReject === "N").fatcaAnswerCode, condition: [] })
          }
        } else {
          fatcaAns = MainData.screenSM004.fatcaAns;
        }
        //CRS Service ที่จะส่งมาเพิ่ม
        if (MainData.screenSM004.crsAns.length !== data.crsQuestion?.length && data.crsQuestion) {
          for (let obj of data.crsQuestion) {
            crsAns.push({ questionCode: obj.crsQuestionCode, answerCode: obj.crsAnswer.find(x => x.isReject === "N").crsAnswerCode, condition: [] })
          }
        } else {
          crsAns = MainData.screenSM004.crsAns;
        }
        if(data.crsQuestion){
        let crsQuestion = data.crsQuestion[data.crsQuestion.findIndex(x => x.crsQuestionCode == "CRSQ001")]?.crsAnswer;
        let crsCondition = crsQuestion.find(x => x.crsAnswerCode == "CRSA002")?.conditions;
        conditionCrs.push(crsCondition);
        }
        if(data.configCRSDescription && data.configCRSTitle){
          configCRSDescription = data.configCRSDescription
          configCRSTitle = data.configCRSTitle
        }
        this.setState({ fatcaQuestion: data.fatcaQuestion, fatcaAns: fatcaAns, fatcaInformationConsent: data.fatcaInformationConsent, crsQuestion: data.crsQuestion, crsAns: crsAns, conditionCrs: conditionCrs, dataCountry: !data.country ? [] : data.country, configCRSDescription:configCRSDescription,configCRSTitle:configCRSTitle }, this.props.loadController(false),)

      })
      .catch(error => {
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
        this.props.setPage(this.props.prev)
      });
  }

  onAnswer = (qCode, aCode) => {
    let fatcaAns = this.state.fatcaAns;
    let index = fatcaAns.findIndex(x => x.questionCode === qCode);
    fatcaAns[index].answerCode = aCode;
    fatcaAns[index].condition = [];
    let findFatcaQuestion = this.state.fatcaQuestion.find(x => x.fatcaQuestionCode === qCode);
    let findAnswer = findFatcaQuestion.fatcaAnswer.find(ans => ans.fatcaAnswerCode === aCode);
    for (let obj of findAnswer.conditions) {
      fatcaAns[index].condition.push({ questionID: obj.fatcaQuestionCode, answerCode: null, ans: null })
    }
    this.setState({ fatcaAns: fatcaAns })
  }
  onCrsAnswer = (qCode, aCode) => {
    let crsAns = this.state.crsAns;
    let index = crsAns.findIndex(x => x.questionCode === qCode);
    crsAns[index].answerCode = aCode;
    crsAns[index].condition = [];
    // let findCRSQuestion = this.state.crsQuestion.find(x => x.crsQuestionCode === qCode);
    // let findAnswer = findCRSQuestion.crsAnswer.find(ans => ans.crsAnswerCode === aCode);
    // for (let obj of findAnswer.conditions) {
    //   crsAns[index].condition.push({ questionID: obj.fatcaQuestionCode, answerCode: null, ans: null })
    // }
    this.setState({ crsAns: crsAns })
  }

  conditionAnswerClick = (questionID, conditionQuestionID, conditionAnsCode, conditionAnsIsReject) => {
    let updateUserAnswer = this.state.fatcaAns;
    if (questionID && conditionAnsCode) {
      let index = updateUserAnswer.findIndex(x => x.questionCode === questionID);
      if (updateUserAnswer[index].condition.find(x => x.questionID === conditionQuestionID)) {
        let secIndex = updateUserAnswer[index].condition.findIndex(x => x.questionID === conditionQuestionID);
        updateUserAnswer[index].condition[secIndex].answerCode = conditionAnsCode;
        updateUserAnswer[index].condition[secIndex].ans = conditionAnsIsReject;
      } else {
        updateUserAnswer[index].condition.push({ questionID: conditionQuestionID, answerCode: conditionAnsCode, ans: conditionAnsIsReject })
      }
    }

    this.setState({ fatcaAns: updateUserAnswer })
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + 'shutdown'
      return;
    }
    else {
      this.props.loadController(false);
    }
    this.props.setPage(this.props.prev)
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + 'shutdown'
      return;
    }
    else {
      this.props.loadController(false);
    }
    if(this.state.crsQuestion) {
      if (!this.state.acceptSensitive) {
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0607")/* กรุณายอมรับเงื่อนไขการยืนยัน และการเปลี่ยนแปลงสถานะ */, ' ');
        return;
      }
      this.validateInput()
      this.validateDropdown()
      let checkChoice = this.state.crsAns.find(x => x.answerCode == "CRSA002");
      if (!checkChoice) {
        this.state.validation = false;
      }
      else {
        if (!this.state.taxIdentificationNumber) { //ติ๊กปุ่มมีบัตรประจำตัวผู้เสียภาษีหรือไม่
          if ((this.state.answerCondition.find(x => x.questionCode == "CRSQ002")?.answerValue && this.state.answerCondition.find(x => x.questionCode == "CRSQ005")?.answerValue && this.state.answerCondition.find(x => x.questionCode == "CRSQ003")?.answerValue && this.state.answerCondition.find(x => x.questionCode == "CRSQ004")?.answerValue) && checkChoice) {
            this.state.validation = false;
          }
        }
        else {
          if ((this.state.answerCondition.find(x => x.questionCode == "CRSQ002")?.answerValue && this.state.answerCondition.find(x => x.questionCode == "CRSQ003")?.answerValue && this.state.answerCondition.find(x => x.questionCode == "CRSQ004")?.answerValue && !this.state.answerCondition.find(x => x.questionCode == "CRSQ005")?.answerValue) && checkChoice) {
            if (this.state.answerCondition?.find(x=>x.questionCode == "CRSQ006")?.answerValue == "CRSA009") {
              if (this.state.answerCondition.find(x => x.questionCode == "CRSQ007")?.answerValue && this.state.answerCondition.find(x => x.questionCode == "CRSQ007")?.answerValue != "") {
                this.state.validation = false;
              }
            }
            else {
              this.state.validation = false;
            }
          }
        }
      }
    }else{ //กรณีที่ไม่ได้ส่ง CrsQuestion มา 
    this.state.validation = false;
    this.state.acceptSensitive = true;
    }
    if (this.state.validation) {
      let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG003'); //ถ้าเจอว่า input ไหนยังไม่กรอกจะลง validation ไว้ แสดง popup
      this.props.errorAlert(true, !errMsg ? "กรุณาตรวจสอบข้อมูล" : errMsg.msgDescription, " ");
    }

    if (this.state.validation === false) {
      let data = this.state.fatcaAns;
      let fatcaAnsPDPA = {};
      data.forEach((item, index) => {
        if (item.questionCode) {
          fatcaAnsPDPA[`questionCode_${index + 1}`] = item.questionCode.toString()
        }
        if (item.answerCode) {
          fatcaAnsPDPA[`answerCode_${index + 1}`] = item.answerCode.toString()
        }
        item.condition.forEach((subItem, subIndex) => {
          if (subItem.questionCode) {
            fatcaAnsPDPA[`questionCode_${index + 1}_${subIndex + 1}`] = subItem.questionCode.toString()
          }
          if (subItem.answerValue) {
            fatcaAnsPDPA[`answerValue_${index + 1}_${subIndex + 1}`] = subItem.answerValue.toString()
          }
        });
      })
      if(this.state.crsQuestion) {
      let dataCRS = this.state.crsAns;
      dataCRS.forEach((item, index) => {
        if (item.questionCode) {
          fatcaAnsPDPA[`crsquestionCode_${index + 1}`] = item.questionCode.toString()
        }
        if (item.answerCode) {
          fatcaAnsPDPA[`crsanswerCode_${index + 1}`] = item.answerCode.toString()
        }
        item.condition.forEach((subItem, subIndex) => {
          if (subItem.questionCode) {
            fatcaAnsPDPA[`crsquestionCode_${index + 1}_${subIndex + 1}`] = subItem.questionCode.toString()
          }
          if (subItem.answerValue) {
            fatcaAnsPDPA[`crsanswerValue_${index + 1}_${subIndex + 1}`] = subItem.answerValue.toString()
          }
        });
      })
    }

      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตอบคำถาม FATCA', MainData.screenSM004.fatcaAns, fatcaAnsPDPA);
      this.setState({ fatcaAnsPDPA: fatcaAnsPDPA })
      MainData.screenSM004.fatcaAns = this.state.fatcaAns;
      MainData.screenSM004.crsAns = this.state.crsAns;

      let isReject = 'Y';
      for (let obj of this.state.fatcaQuestion) {
        let fatcaAnsCode = this.state.fatcaAns.find(x => x.questionCode === obj.fatcaQuestionCode);
        for (let subObj of obj.fatcaAnswer) {
          if (fatcaAnsCode.answerCode === subObj.fatcaAnswerCode) {
            isReject = subObj.isReject;
            if (subObj.conditions) {
              if (subObj.conditions.length > 0) {
                for (let condi of subObj.conditions) {
                  for (let condiAns of condi.fatcaAnswer) {
                    if (fatcaAnsCode.condition.find(condi => condi.answerCode === condiAns.fatcaAnswerCode)) {
                      isReject = fatcaAnsCode.condition.find(condi => condi.answerCode === condiAns.fatcaAnswerCode).ans;
                    }
                  }
                }
              }
            }
          }
        }
      }
      let isRejectCRS = 'Y';
      if (this.state.crsQuestion?.length > 0) {
        for (let obj of this.state.crsQuestion) {  //วนลูปคำถาม
          let crsAnsCode = this.state.crsAns.find(x => x.questionCode === obj.crsQuestionCode); //เอาคำตอบ User มา Find ลูปคำถาม เช่น User เลือก ใช่ 
          for (let subObj of obj.crsAnswer) { //วนคำตอบทั้งหมดของคำถาม
            if (crsAnsCode.answerCode === subObj.crsAnswerCode) { //เทียบคำตอบกับคำตอบของคำถาม
              isRejectCRS = subObj.isReject; //เก็บ isReject ของคำตอบนั้น  //fix ค่าในการกดถัดไป  fixเอง
              if (subObj.conditions) {
                if (subObj.conditions.length > 0) {
                  for (let condi of subObj.conditions) {
                    for (let condiAns of condi.crsAnswer) {
                      if (crsAnsCode.condition.find(condi => condi.answerCode === condiAns.crsAnswerCode)) {
                        isRejectCRS = crsAnsCode.condition.find(condi => condi.answerCode === condiAns.crsAnswerCode).ans;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (isReject === 'N' && this.state.acceptSensitive) {
        this.props.loadController(true)
        if (MainData.screenSM004.fatcaAnsPDPA) {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Success', null)
        } else {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, this.state.fatcaAnsPDPA, 'Success', null)
        }

        this.checkAnswer();

      } else {
        this.props.loadController(true)
        let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
        if (MainData.screenSM004.fatcaAnsPDPA) {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM004.fatcaAnsPDPA, fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
        } else {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
        }
        MainData.screenSM004.fatcaAnsPDPA = fatcaAnsPDPA;

        let fatcaForservice = [];
        for (let [index, fatobj] of this.state.fatcaAns.entries()) {
          fatcaForservice.push({ questionCode: fatobj.questionCode, answerCode: fatobj.answerCode, condition: [] })
          if (fatobj.condition) {
            if (fatobj.condition.length > 0) {
              for (let fatAnsObj of fatobj.condition) {
                fatcaForservice[index].condition.push({ questionCode: fatAnsObj.questionID, answerCode: fatAnsObj.answerCode })
              }
            }
          }
        }

        let crsForservice = [];
        for (let [index, crsobj] of this.state.crsAns.entries()) {
          crsForservice.push({ questionCode: crsobj.questionCode, answerCode: crsobj.answerCode, condition: this.state.answerCondition })
          if (crsobj.condition) {
            if (crsobj.condition.length > 0) {
              for (let crsAnsObj of crsobj.condition) {
                crsForservice[index].condition.push({ questionCode: crsAnsObj.questionCode, answerValue: crsAnsObj.answerValue })
              }
            }
          }
        }
        if (sessionStorage.getItem('_cookie') === "true") {
          await this.addOrUpdateCustomerFatcaError(fatcaForservice, crsForservice);
        }
        else{ //ไม่ยอมรับ cookie
          this.props.loadController(false)
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription, `${MainData.initialData.codeTracking}STEP3-1`, "Click", `${MainData.initialData.codeTracking}STEP3-1_FATCA_Click_BtnCloseRejected`)
        }
      }
    }
  }

  addOrUpdateCustomerFatcaError = async(fatcaForservice, crsForservice) => { //service addOrUpdate sm004 
    this.props.loadController(true)
    const headers = {
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "AuthId": !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId
    }
    const data = {
      "FatcaQuestions": fatcaForservice,
      "CRSQuestions": crsForservice,
      "submenuCode": this.state.screen,
      "channelCode": MainData.initialData.channelCode,
      "transUrl": window.location.href,
    }
    axios.post(MainData.serviceURL + '/Customer/AddOrUpdateCustomerFatca', data, {
      headers: headers
    })
      .then(res => {
        this.props.loadController(false)
        if (res.status === 299) {
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        if (res.data.success === false){
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
          this.props.loadController(false)
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription, `${MainData.initialData.codeTracking}STEP3-1`, "Click", `${MainData.initialData.codeTracking}STEP3-1_FATCA_Click_BtnCloseRejected`)
        }
        if (res.data.success === true){
          this.props.setPage(this.props.next)
        }
      })
      .catch(error => {
        this.props.loadController(false)
        if (error?.response?.status == 400) {
          this.props.loadController(false)
          this.validateBadRequest(error.response.data.errors)
        }
        else {
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
        }
      });
  }

  checkAnswer = () => { //ถ้าตอบ ถูกตามเงื่อนไข จะมาที่นี้
    let fatcaForservice = [];
    for (let [index, fatobj] of this.state.fatcaAns.entries()) {
      fatcaForservice.push({ questionCode: fatobj.questionCode, answerCode: fatobj.answerCode, condition: [] })
      if (fatobj.condition) {
        if (fatobj.condition.length > 0) {
          for (let fatAnsObj of fatobj.condition) {
            fatcaForservice[index].condition.push({ questionCode: fatAnsObj.questionID, answerCode: fatAnsObj.answerCode })
          }
        }
      }
    }

    let crsForservice = [];
    for (let [index, crsobj] of this.state.crsAns.entries()) {
      crsForservice.push({ questionCode: crsobj.questionCode, answerCode: crsobj.answerCode, condition: this.state.crsAns.find(x => x.answerCode == "CRSA002")?this.state.answerCondition:[]})
      if (crsobj.condition) {
        if (crsobj.condition.length > 0) {
          for (let crsAnsObj of crsobj.condition) {
            crsForservice[index].condition.push({ questionCode: crsAnsObj.questionCode, answerValue: crsAnsObj.answerValue })
          }
        }
      }
    }

    let healthQuestions = [];
    if (MainData.screenSM003.userHealthAns.length > 0) {
      for (let [index, obj] of MainData.screenSM003.userHealthAns.entries()) {
        healthQuestions.push({ questionCode: obj.questionID, answerCode: obj.ansCode, condition: [] })
        for (let secObj of obj.condition) {
          healthQuestions[index].condition.push({ questionCode: secObj.questionID, answerCode: secObj.ansCode })
        }
      }
    }
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" 
  ,"authid": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId}
    const data = {
      "grantee": "SELF",
      "healthQuestions": healthQuestions,
      "FatcaQuestions": fatcaForservice,
      "productId": parseInt(MainData.initialData.productId),
      "channelCode": MainData.initialData.channelCode,
      "subMenu":this.state.screen
    }
    axios.post(MainData.serviceURL + '/Sales/CheckAnswer', data, {
      headers: headers
    })
      .then(async res => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        let data = res.data.data;
        if (data.totalResult === true) { 
          MainData.screenSM004.fatcaAnsForservice = fatcaForservice
          MainData.screenSM004.crsAnsForservice = crsForservice
          MainData.screenSM004.screen = this.state.screen
          MainData.screenSM004.WindowLocationHref = window.location.href
          MainData.screenSM004.answerCondition = this.state.answerCondition
          MainData.screenSM004.acceptSensitive = this.state.acceptSensitive
          MainData.screenSM004.taxIdentificationNumber = this.state.taxIdentificationNumber

          if (sessionStorage.getItem('_cookie') === "true") {
            await this.addOrUpdateCustomerFatca(fatcaForservice, crsForservice)
          }
          else{ //กรณีที่ไม่กดยอมรับ cookie ให้ไปต่อ
            this.props.setPage(this.props.next)
          }
        } else { 
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription, ' ')
        }
      })
      .catch(error => {
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
      });
  }

  addOrUpdateCustomerFatca = async (fatcaForservice, crsForservice) => { //service addOrUpdate sm004  ส่งข้อความ update หลังบ้าน
    const headers = {
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "AuthId": !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId
    }
    const data = {
      "FatcaQuestions": fatcaForservice,
      "CRSQuestions": crsForservice,
      "submenuCode": this.state.screen,
      "channelCode": MainData.initialData.channelCode,
      "transUrl": window.location.href,
    }
    axios.post(MainData.serviceURL + '/Customer/AddOrUpdateCustomerFatca', data, {
      headers: headers
    })
      .then(async res => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        if (res.data.success !== true) { 
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription, ' ')
          if (MainData.screenSM004.fatcaAnsPDPA) {
            MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
          } else {
            MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, this.state.fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
          }
        } else { 
          if (MainData.screenSM004.fatcaAnsPDPA) {
            MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Success', null)
          } else {
            MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, this.state.fatcaAnsPDPA, 'Success', null)
          }
          MainData.screenSM004.fatcaAnsPDPA = this.state.fatcaAnsPDPA
          MainData.screenSM005.cusId = res.data.data;
          
          this.props.loadController(false)
          await this.props.setPage(this.props.next)
        }
      })
      .catch(error => {
        if (error?.response?.status == 400) {
          this.props.loadController(false)
          this.validateBadRequest(error.response.data.errors)
        }
        else {
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
        }
      });
  }

  validateBadRequest = (fieldNameList) => {
    if (fieldNameList.find(data => data.field === "FatcaQuestions")
    ) {
      let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG019');
      this.props.errorAlert(true, !errMsg ? 'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง' : errMsg.msgDescription, ' ');
      this.setState({
        screen: "SM004",
        fatcaAns: [],
        fatcaAnsPDPA: {},
      })
    }
  }

  fatcaInformationConsent = () => {
    MainFunc.sendLogToService(this.props.name, 'Access', 'Success', 'เก็บข้อมูลการดูรายละเอียดคำยินยอมและตกลง', { isOpen: this.state.eventKeyActive }, { isOpen: !this.state.eventKeyActive });
    this.setState((state) => ({ eventKeyActive: !state.eventKeyActive }))
  }

  openAcceptSensitiveDataModal = () => {
    this.setState({ acceptSensitiveDataModal: true })
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการดูรายละเอียด เงื่อนไขการเปิดเผยข้อมูล', {}, { isOpen: true });
  }

  onCheckRadioCrsQuesionChange = (question, val) => {
    let mapingAnswerCondition = this.state.answerCondition;
    this.setState({ radioTaxIdentification: val });
    if (mapingAnswerCondition.findIndex(a => a.questionCode == question) > -1) {
      mapingAnswerCondition[mapingAnswerCondition.findIndex(a => a.questionCode == question)].answerValue = val;
    }
    else {
      mapingAnswerCondition.push({ questionCode: question, answerValue: val });
    }
    this.setState({ answerCondition: mapingAnswerCondition });
  }

  handleInputChange = (name, val) => {
    let mapingAnswerCondition = this.state.answerCondition;
    switch (name) {
      case "CRSQ002":
        if (val[0] === " ") { break; }
        let checkWordCity = /^[a-zA-Z ]+$/.test(val);

        if (mapingAnswerCondition.findIndex(a => a.questionCode == name) > -1) {
          mapingAnswerCondition[mapingAnswerCondition.findIndex(a => a.questionCode == name)].answerValue = !checkWordCity && val ? this.state.answerCondition.find(x => x.questionCode == "CRSQ002")?.answerValue : val;
        }
        else {
          mapingAnswerCondition.push({ questionCode: name, answerValue: !checkWordCity && val ? this.state.answerCondition.find(x => x.questionCode == "CRSQ002")?.answerValue: val });
        }
        break;
      case "CRSQ005":
        if (val[0] === " ") { break; }
        let checkTaxNumber = /^[a-zA-Z0-9 ]+$/.test(val);

        if (mapingAnswerCondition.findIndex(a => a.questionCode == name) > -1) {
          mapingAnswerCondition[mapingAnswerCondition.findIndex(a => a.questionCode == name)].answerValue = !checkTaxNumber && val ? this.state.answerCondition.find(x => x.questionCode == "CRSQ005")?.answerValue : val;
        }
        else {
          mapingAnswerCondition.push({ questionCode: name, answerValue: !checkTaxNumber && val ? this.state.answerCondition.find(x => x.questionCode == "CRSQ005")?.answerValue : val });
        }
        break;
      case "CRSQ007":
        if (val[0] === " ") { break; }
        let checkWord = val.replace(/[ก-๏]/g, "");
        if (mapingAnswerCondition.findIndex(a => a.questionCode == name) > -1) {
          mapingAnswerCondition[mapingAnswerCondition.findIndex(a => a.questionCode == name)].answerValue =  checkWord;
        }
        else {
          mapingAnswerCondition.push({ questionCode: name, answerValue: checkWord });
        }
        break;
    }
    this.setState({ answerCondition: mapingAnswerCondition });
  }

  handleDropDownChange = (name, val) => {
    let mapingAnswerCondition = this.state.answerCondition;
    switch (name) {
      case "CRSQ003":
        if (mapingAnswerCondition.findIndex(a => a.questionCode == name) > -1) {
          mapingAnswerCondition[mapingAnswerCondition.findIndex(a => a.questionCode == name)].answerValue = val;
        } else {
          mapingAnswerCondition.push({ questionCode: name, answerValue: val });
        }
        break;
      case "CRSQ004":
        if (mapingAnswerCondition.findIndex(a => a.questionCode == name) > -1) {
          mapingAnswerCondition[mapingAnswerCondition.findIndex(a => a.questionCode == name)].answerValue = val;
        } else {
          mapingAnswerCondition.push({ questionCode: name, answerValue: val });
        }
        break;
    }
    this.setState({ answerCondition: mapingAnswerCondition });
  }
  handleCheckBlock = (event)=>{
    let answerCheckBox = this.state.answerCondition;
    if(event.target.checked){
      if (answerCheckBox.findIndex(a => a.questionCode == "CRSQ005") > -1) {
        answerCheckBox[answerCheckBox.findIndex(a => a.questionCode == "CRSQ005")].answerValue = "";
      } else {
        answerCheckBox.push({ questionCode: "CRSQ005", answerValue: "" });
      }
    }
    else{
      if(this.state.answerCondition.findIndex(item => item.questionCode == "CRSQ006")> -1){
        this.state.answerCondition.splice(this.state.answerCondition.findIndex(item => item.questionCode == "CRSQ006"),1)
      }
      if(this.state.answerCondition.findIndex(item => item.questionCode == "CRSQ007")> -1){
        this.state.answerCondition.splice(this.state.answerCondition.findIndex(item => item.questionCode == "CRSQ007"),1)
      }
    }
    this.setState({ taxIdentificationNumber: event.target.checked,answerCondition:answerCheckBox})
  }

  validateInput = () => {
    this.setState(state => (!state.answerCondition.find(x => x.questionCode == "CRSQ002")?.answerValue ?
      { fieldNotValid: [...state.fieldNotValid, "CRSQ002"] } :
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "CRSQ002")] }
    ))
    if (!this.state.answerCondition?.find(x=>x.questionCode == "CRSQ006")?.answerValue) {
      this.setState(state => (!state.answerCondition.find(x => x.questionCode == "CRSQ005")?.answerValue ?
        { fieldNotValid: [...state.fieldNotValid, "CRSQ005"] } :
        { fieldNotValid: [...state.fieldNotValid.filter(f => f != "CRSQ005")] }
      ))
    }
    else{
      var indexToDelete = this.state.fieldNotValid.findIndex(x=>x =="CRSQ005"); // Find the index of the value
      if (indexToDelete !== -1) {
        this.state.fieldNotValid.splice(indexToDelete, 1); // Remove the element at the found index
      }
    }
    if (this.state.answerCondition.find(x => x.questionCode == "CRSQ006")?.answerValue == "CRSA009") {
      this.setState(state => (!state.answerCondition.find(x => x.questionCode == "CRSQ007")?.answerValue ?
        { fieldNotValid: [...state.fieldNotValid, "CRSQ007"] } :
        { fieldNotValid: [...state.fieldNotValid.filter(f => f != "CRSQ007")] }
      ))
    }
  }

  validateDropdown = () => {
    this.setState(state => (!state.answerCondition.find(x => x.questionCode == "CRSQ003")?.answerValue ?
      { fieldNotValid: [...state.fieldNotValid, "CRSQ003"] } :
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "CRSQ003")] }
    ))
    this.setState(state => (!state.answerCondition.find(x => x.questionCode == "CRSQ004")?.answerValue ?
      { fieldNotValid: [...state.fieldNotValid, "CRSQ004"] } :
      { fieldNotValid: [...state.fieldNotValid.filter(f => f != "CRSQ004")] }
    ))
  }

  skipFirthPage = () => {
    MainData.screenSM002.paymentCode = null;
    MainData.screenSM002.planId = null;
    MainData.screenSM002.productName = "";
    MainData.screenSM002.planName = "";
    MainData.screenSM002.paymentName = "";
    MainData.screenSM002.cost = 0;
    MainData.screenSM002.costPayment = 0;
    MainData.screenSM002.paymentId = null;
    MainData.screenSM002.insureRequiredIdentification = true;
    MainData.screenSM002.insureSelfie = true;
    MainData.screenSM002.screen = "";
    this.props.setPage("1")
  }


  render() {
    return (
      <div className="container-fulid">
        <Row>
          <Col xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{this.props.mainMenuName}</span>
            {this.props.maxPage > 1 ? <span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span> : null}
          </Col>
          <Col xs={12} className="pt-2">
            <span className="font-weight-bold">FATCA*</span>
          </Col>

          {this.state.fatcaQuestion.map((item, key) => {
            var conditions = item.fatcaAnswer.map((ansItemcon, ansKeycon) => {
              let showConQues = this.state.fatcaAns.find(x => x.answerCode === ansItemcon.fatcaAnswerCode);
              if (ansItemcon.conditions) {
                if ((ansItemcon.conditions.length > 0) && (showConQues)) {
                  return (
                    ansItemcon.conditions.map((condiItem, condiKey) => {
                      return (
                        <Row className="pt-2" key={condiKey}>
                          <Col xs={12} className="pt-2">
                            <p>{condiItem.fatcaQuestionTitle}</p>
                            <Form.Group as={Row} className="pl-3 mb-0" onClick={(e) => this.conditionAnswerClick(item.fatcaQuestionCode, e.target.name, e.target.id.split("::||::")[1], e.target.placeholder)} >
                              {condiItem.fatcaAnswer.map((condiAnsItem, condiAnsKey) => {
                                let showConQuesActive = showConQues.condition.find(x => x.answerCode === condiAnsItem.fatcaAnswerCode);
                                return (
                                  <Form.Check key={condiAnsKey} className="ml-3" custom type="radio" label={condiAnsItem.fatcaAnswerTitle} name={condiAnsItem.fatcaQuestionCode} id={"conDi::||::" + condiAnsItem.fatcaAnswerCode} placeholder={condiAnsItem.isReject} defaultChecked={showConQuesActive ? true : false} />
                                )
                              })}
                            </Form.Group>
                          </Col>
                        </Row>
                      )
                    })
                  )
                } else { return null }
              } else { return null }
            })
            let userAnswer = this.state.fatcaAns.find(x => x.questionCode === item.fatcaQuestionCode).answerCode;
            return (
              <Col xs={12} key={key} className="pt-2">
                <p>{item.fatcaQuestionTitle} </p>
                <Form.Group as={Row} className="pl-3 " onChange={(e) => this.onAnswer(item.fatcaQuestionCode, e.target.id)} >
                  {item.fatcaAnswer.map((ansItem, ansKey) => {
                    return (
                      <Form.Check key={ansKey} className="ml-3" custom type="radio" label={ansItem.fatcaAnswerTitle} name={"fatcaQuestion" + item.fatcaQuestionCode} id={ansItem.fatcaAnswerCode} defaultChecked={userAnswer === ansItem.fatcaAnswerCode ? true : false} />
                    )
                  })}
                </Form.Group>
                {conditions}
              </Col>
            )
          })}

          <Col xs={12} className="pt-2">
            <Accordion>
              <Card className="mt-2 mb-2" style={{ border: 'unset', borderRadius: "0" }}>
                <Accordion.Toggle id={`${MainData.initialData.codeTracking}STEP3-1_FATCA_Dropdown_BtnMoreDetail`} onClick={() => this.fatcaInformationConsent()} as={Card.Header} className={this.state.eventKeyActive ? "bg-dark" : "planBG"} eventKey={1}>
                  <Row>
                    <Col xs={10} className={this.state.eventKeyActive ? "text-white font-weight-bold" : "font-weight-bold"} >{MainFunc.getLabelDtos("L0022")}</Col>
                    <Col xs={2} className="text-right font-weight-bold">{this.state.eventKeyActive ? <FaAngleUp className={"text-white"} /> : <FaAngleDown />}</Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={1}>
                  <Card.Body className="pt-1 pb-2" style={{ border: "1px solid rgba(0,0,0,0.2)" }}>
                    <Row className="pt-2">
                      <Col xs={12}>
                        <div className="pt-1">
                          <span dangerouslySetInnerHTML={{ __html: this.state.fatcaInformationConsent }} ></span>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
          {/* คำถาม CRS */}
          <Col id="titelForScreen" xs={12} className="pt-2">
            <span className="font-weight-bold">{this.state.crsQuestion?.length > 0 ? MainFunc.getLabelDtos("L0452") : ""}</span>
          </Col>
          {this.state.crsQuestion && (this.state.crsQuestion.map((item, key) => {
            let userAnswer = this.state.crsAns.find(x => x.questionCode === item.crsQuestionCode).answerCode;
            return (
              <Col xs={12} key={key} className="pt-2">
                <p>{item.crsQuestionTitle} </p>
                <Form.Group as={Row} className="pl-3" onChange={(e) => this.onCrsAnswer(item.crsQuestionCode, e.target.id)} >
                  {item.crsAnswer.map((ansItem, ansKey) => {
                    return (
                      <Form.Check key={ansKey} className="ml-3" custom type="radio" label={ansItem.crsAnswerTitle} name={"crsQuestion" + item.crsQuestionCode} id={ansItem.crsAnswerCode} defaultChecked={userAnswer === ansItem.crsAnswerCode ? true : false} />
                    )
                  })}
                </Form.Group>
                <span className="" style={{ fontSize: 12 }}>{this.state.crsQuestion?.length > 0 ? MainFunc.getLabelDtos("L0579") : ""}</span>

                {/* checkBox เพิ่มในส่วนของ การยืนยันเปลี่ยนแปลงแสดงสถานะ*/}
                {this.state.crsAns.find(x => x.answerCode == "CRSA001") &&(
                <Form.Group controlId="checkBox" className="mt-2" >
                  <Form.Check checked={this.state.acceptSensitive} onChange={(e) => { this.setState({ acceptSensitive: e.target.checked }) }} type="checkbox" label={<> </>} custom style={{ display: 'initial' }} />
                  {/* <span>{MainFunc.getLabelDtos("L0357")}</span> */}
                  <span>{MainFunc.getLabelDtos("L0603")/* การยืนยันและการเปลี่ยนสถานะ */}</span>
                  <span className="underline-pink" style={{marginLeft:2}} onClick={() => this.openAcceptSensitiveDataModal()}>{MainFunc.getLabelDtos("L0562")/* เงื่อนไข */}</span>
                </Form.Group>
                )}
                {/* จับ ans จาก user ถ้าเกิดเป็นค่า CRSA002= ตอบว่าใช่จะ แสดงข้อความเพิ่มให้กรอก*/}
                {this.state.crsAns.find(x => x.answerCode == "CRSA002") && (
                  <Col className="QuestionCrsBox">
                    <span className="font-weight-bold">{MainFunc.getLabelDtos("L0608")/* กรุณากรอกรายละเอียดเพิ่มเติม */}</span>
                    <Row className="mt-2">
                      {this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ002") && (
                        <Col xs={6} className="CRSQ002">
                          <TextField inputProps={{ autocomplete: 'off' }} name="CRSQ002" label={this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ002").crsQuestionTitle} placeholder={this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ002").placeHolder} value={!this.state.answerCondition.find(x => x.questionCode == "CRSQ002")?.answerValue ? "" : this.state.answerCondition.find(x=>x.questionCode == "CRSQ002")?.answerValue} className="mt-3" onChange={(e) => this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth error={this.state.validation && this.state.fieldNotValid.includes('CRSQ002')} />
                        </Col>
                      )}
                      {this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ003") && (
                        <Col xs={6} className="CRSQ003">
                          <FormControl className="w-100" style={{ marginTop: 15 }} error={this.state.validation && this.state.fieldNotValid.includes('CRSQ003') ? true : false}>
                            <InputLabel id="CRSQ003">{ }</InputLabel>
                            <Select labelId="CRSQ003" name="CRSQ003" className={!this.state.answerCondition.find(x => x.questionCode == "CRSQ003")?.answerValue ? "text-placeholder-conditions" : null} value={!this.state.answerCondition.find(x => x.questionCode == "CRSQ003")?.answerValue ? "none" : this.state.answerCondition.find(x => x.questionCode == "CRSQ003")?.answerValue} onChange={(e) => this.handleDropDownChange(e.target.name, e.target.value)} error={this.state.validation && this.state.fieldNotValid.includes('CRSQ003')} >
                              <MenuItem value={"none"} hidden>{this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ003").placeHolder}</MenuItem>
                              {this.state.dataCountry?.map((item, key) => {
                                return (
                                  <MenuItem key={key} value={item.countryCode}>{item.countryName}</MenuItem>
                                )
                              })}
                            </Select>
                          </FormControl>
                        </Col>
                      )}
                    </Row>
                    {this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ004") && (
                      <Row className="mt-2">
                        <Col xs={6} className="CRSQ004 mt-3">
                          <FormControl className="w-100" error={this.state.validation && this.state.fieldNotValid.includes('CRSQ004') ? true : false}>
                            <InputLabel id="CRSQ004">{this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ004").crsQuestionTitle}</InputLabel>
                            <Select labelId="CRSQ004" name="CRSQ004" className={!this.state.answerCondition.find(x => x.questionCode == "CRSQ004")?.answerValue ? "text-placeholder-conditions" : null} value={!this.state.answerCondition.find(x => x.questionCode == "CRSQ004")?.answerValue ? "none" : this.state.answerCondition.find(x => x.questionCode == "CRSQ004")?.answerValue}  onChange={(e) => this.handleDropDownChange(e.target.name, e.target.value)} error={this.state.validation && this.state.fieldNotValid.includes('CRSQ004')}>
                              <MenuItem value={"none"} hidden>{this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ004").placeHolder}</MenuItem>
                              {this.state.dataCountry?.map((item, key) => {
                                if(item.countryCode != "UE"){
                                  return (
                                    <MenuItem key={key} value={item.countryCode}>{item.countryName}</MenuItem>
                                  )
                                }
                              })}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    )}
                    {this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ005") && (
                      <Row className="mt-2 mb-2">
                        <Col xs={7} sm={6} className="CRSQ005">
                          <TextField inputProps={{ autocomplete: 'off' }} name="CRSQ005" label={this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ005").crsQuestionTitle} placeholder={this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ005").placeHolder} value={!this.state.answerCondition.find(x => x.questionCode == "CRSQ005")?.answerValue ? "" :this.state.answerCondition?.find(x=> x.questionCode == "CRSQ005")?.answerValue} className="mt-3" onChange={(e) => this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth error={this.state.validation && this.state.fieldNotValid.includes('CRSQ005')} disabled={this.state.taxIdentificationNumber} />
                        </Col>
                        <Col xs={5} sm={6} style={{bottom:-10}} className="check_boxCrs">
                          <Form.Group controlId="confrim" className="mt-4 checkboxCrs" >
                            <Form.Check checked={this.state.taxIdentificationNumber} onChange={(e) => this.handleCheckBlock(e)} type="checkbox" label={<> </>} custom style={{ display: 'initial' }} />
                            {/* <span>{MainFunc.getLabelDtos("L0357")}</span> */}
                            <span>{MainFunc.getLabelDtos("L0204")/* ไม่มี */}</span>
                          </Form.Group>
                        </Col>
                      </Row>
                    )}

                    {/*ดักเงื่อนไขการติ๊กไม่ใช่ แล้วแสดงข้อมูลต่อ*/}
                    {!this.state.taxIdentificationNumber ? null :
                      this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ006") && (
                        <Col xs={12} className="mt-2 mb-2" style={{ padding: 0 }}>
                          <span className="font-weight-bold mt-2 mb-2">{this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ006").crsQuestionTitle}</span>
                          {this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ006") && (
                            <Form.Group as={Row} className="pl-3" onChange={(e) => this.onCheckRadioCrsQuesionChange(e.target.name, e.target.id)}>
                              {this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ006").crsAnswer.map((ansItem, ansKey) => {
                                return (
                                  <Form.Check key={ansKey} className="ml-3 mt-2" custom type="radio" label={ansItem.crsAnswerTitle} value={ansItem.crsAnswerCode} name={ansItem.crsQuestionCode} id={ansItem.crsAnswerCode} defaultChecked={ansItem.crsAnswerCode === this.state.answerCondition.find(x => x.questionCode == "CRSQ006")?.answerValue ? true : false} />
                                )
                              })}
                            </Form.Group>
                          )}
                          <span className="mt-2" style={{ fontSize: 16, display: "flex" }}>{MainFunc.getLabelDtos("L0604")/* เหตุผล(เอ) - ประเทศที่ท่านมีถิ่นที่อยู่ทางภาษี ไม่ได้ออกเลขประจำตัวผู้เสียภาษีให้กับผู้อาศัยอยู่ในประเทศนั้น */}</span>
                          <span className="mt-2" style={{ fontSize: 16, display: "flex" }}>{MainFunc.getLabelDtos("L0605")/* เหตุผล(บี) - ท่านยังไม่ได้รับเลขประจำตัวผู้เสียภาษีที่ออกโดยประเทศนั้น (หมายเหตุ: โปรดอธิบาย เหตุผลที่ทานไม่สามารถขอเลขประจำตัวผู้เสียภาษีได้) */}</span>
                          <span className="mt-2" style={{ fontSize: 16, display: "flex" }}>{MainFunc.getLabelDtos("L0606")/* เหตุผล(ซี) - ไม่จำเป็นต้องให้หรือเปิดเผยเลขประจำตัวผู้เสียภาษี (หมายเหตุ: เลือกเหตุผลนี้เฉพาะในกรณีที่กฏหมายภายในประเทศนั้น ไม่ได้บังคับให้จัดเก็บเลขประจำตัวผู้เสียภาษี) */}</span>
                          {this.state.answerCondition.find(x => x.questionCode == "CRSQ006")?.answerValue == "CRSA009" ?
                            this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ007") && (
                              <Col style={{ padding: 0 }} className="mt-2 CRSQ007">
                                <span className="font-weight-bold mt-2">{this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ007").crsQuestionTitle}</span>
                                <TextField inputProps={{ autocomplete: 'off' }}  name="CRSQ007" placeholder={this.state.conditionCrs[0]?.find(item => item.crsQuestionCode == "CRSQ007").placeHolder} value={!this.state.answerCondition.find(x => x.questionCode == "CRSQ007")?.answerValue ? "" : this.state.answerCondition?.find(x=> x.questionCode == "CRSQ007")?.answerValue } onChange={(e) => this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth  error={this.state.validation && this.state.fieldNotValid.includes('CRSQ007')} />
                              </Col>
                            ) : null
                          }
                        </Col>
                      )
                    }
                  </Col>
                )}
                {this.state.crsAns.find(x => x.answerCode == "CRSA002") && (
                 <Form.Group controlId="checkBox" className="mt-2" >
                  <Form.Check checked={this.state.acceptSensitive} onChange={(e) => { this.setState({ acceptSensitive: e.target.checked }) }} type="checkbox" label={<> </>} custom style={{ display: 'initial' }} />
                  {/* <span>{MainFunc.getLabelDtos("L0357")}</span> */}
                  <span>{MainFunc.getLabelDtos("L0603")/* การยืนยันและการเปลี่ยนสถานะ */}</span>
                  <span className="underline-pink" style={{marginLeft:2}} onClick={() => this.openAcceptSensitiveDataModal()}>{MainFunc.getLabelDtos("L0562")/* เงื่อนไข */}</span>
                </Form.Group>
                )}
              </Col>
            )
          }))}
          <SweetAlert show={this.state.acceptSensitiveDataModal} title={''} onConfirm={() => { this.setState({ acceptSensitiveDataModal: false }) }} onCancel={() => { this.setState({ acceptSensitiveDataModal: false }) }} customButtons={
            <Row>
              <Button onClick={() => { this.setState({ acceptSensitiveDataModal: false }) }} className="mainBtnRadius">{MainFunc.getLabelDtos("L0188") /* ปิด */}</Button>
            </Row>}>
            <div className="container-fulid" style={{textAlign:"left"}}>
              <div onClick={() => this.setState({ acceptSensitiveDataModal: false })} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare /></div>
              <span dangerouslySetInnerHTML={{ __html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.configCRSTitle}</h4>` + this.state.configCRSDescription }} style={{fontSize:16.9744}}></span>
            </div>
          </SweetAlert>
          <SweetAlert 
            show={this.state.isErrorBirthday} 
            title={""} 
            customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} 
            custom 
            onConfirm={() => {this.setState({isErrorBirthday: false});MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning1':'STEP2-1_Click_BtnCloseWarning1'}`)}} 
            showCancel 
            closeOnClickOutside={false} 
            customButtons={
              <Row>
                <Col xs={6}><Button onClick={()=>this.skipFirthPage()} className="subBtnRadius">{MainFunc.getLabelDtos("L0151")}</Button></Col>
                <Col xs={6}><Button onClick={() => {this.setState({isErrorBirthday: false});MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning1':'STEP2-1_Click_BtnCloseWarning1'}`)}} className="mainBtnRadius">{MainFunc.getLabelDtos("L0188") /** ปิด */}</Button></Col>
              </Row>
            } 
          >
              <h5 className="text-danger" dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0165") }}></h5><span className="text--muted" dangerouslySetInnerHTML={{ __html: this.state.isErrorBirthdayDesc}} ></span>
          </SweetAlert>
          {!this.props.prev ? null :
            <Col xs={!this.props.next ? 12 : 6} className={!this.props.next ? "d-flex justify-content-center pt-3" : "d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}STEP3-1_FATCA_Click_BtnBack`} variant="" className="subBtnRadius font-weight-bold" onClick={() => { this.processBack() }}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next ? null :
            <Col xs={!this.props.prev ? 12 : 6} className={!this.props.next ? "d-flex justify-content-center pt-3" : "d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}STEP3-1_FATCA_Click_BtnNext`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }
        </Row>
      </div>
    )
  }
}

export default SM004;