// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _borderRadius = require("../../../styles/borderRadius");

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 2.5rem;\n    background-color: ", ";\n    border: 1px solid ", ";\n    border-radius: ", ";\n    padding: 0.58rem 1rem 0 1rem;\n    cursor: text;\n\n    ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledCardInput = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.palette.border.main;
}, _borderRadius.BORDER_RADIUS_2, function (props) {
  return props.disabled && "\n        background-color: ".concat(props.palette.background.disabled, " !important;\n        border: 1px solid ").concat(props.palette.border.disabled, " !important;\n        cursor: not-allowed;\n        user-select: none;\n\n        &:focus,\n            &:active {\n                box-shadow: none;\n                background-color: ").concat(props.palette.background.disabled, " !important;\n                border: 1px solid ").concat(props.palette.border.disabled, " !important;\n            }\n    ");
});
var _default = StyledCardInput;
exports["default"] = _default;