// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledInput = exports.RelativeInputContainer = exports.StyledButtonIcon = exports.SpanContainer = exports.StyledSpan = exports.StyledEditIcon = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

var _ButtonIcon = _interopRequireDefault(require("../../../ButtonIcon"));

var _edit = _interopRequireDefault(require("../icons/edit"));

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 100%;\n    border: none;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    border-radius: 0.2rem;\n    background-color: ", ";\n    display: inline-block;\n    box-sizing: border-box;\n    margin: 0;\n    color: ", ";\n    border: 1px dotted ", ";\n    padding: 5px 25px 5px 4px;;\n\n    :focus{\n        outline: none;\n        caret-color: ", ";\n    }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: relative;\n    width: 100%;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    height: 100%;\n    top: 0;\n    position: absolute;\n    line-height: 1;\n    border: 0;\n    z-index: 2;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n    right: -0.4rem;\n    color: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between\n    align-items: center;\n    color: ", ";\n    min-height: 30px;\n    line-height: 18px;\n    max-width: 100%;\n    padding: 5px;\n    margin: auto;\n    border-radius: 0.2rem;\n    :hover {\n        padding: 5px 5px 5px 4px;\n        border: 1px dotted ", ";\n        cursor: pointer;\n        > svg { \n            display: inline;\n        }\n    }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    color: ", ";\n    padding: 0 10px 0 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    color: ", ";\n    flex-shrink: 0;\n    display: none;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledEditIcon = (0, _attachThemeAttrs["default"])((0, _styledComponents["default"])(_edit["default"]))(_templateObject(), function (props) {
  return props.palette.brand.main;
});
exports.StyledEditIcon = StyledEditIcon;
var StyledSpan = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject2(), function (props) {
  return props.palette.text.title;
});
exports.StyledSpan = StyledSpan;
var SpanContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject3(), function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.palette.border.main;
});
exports.SpanContainer = SpanContainer;
var StyledButtonIcon = (0, _attachThemeAttrs["default"])((0, _styledComponents["default"])(_ButtonIcon["default"]))(_templateObject4(), function (props) {
  return props.palette.border.main;
});
exports.StyledButtonIcon = StyledButtonIcon;

var RelativeInputContainer = _styledComponents["default"].div(_templateObject5());

exports.RelativeInputContainer = RelativeInputContainer;
var StyledInput = (0, _attachThemeAttrs["default"])(_styledComponents["default"].input)(_templateObject6(), function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.palette.text.title;
}, function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.palette.brand.main;
});
exports.StyledInput = StyledInput;