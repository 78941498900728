// CONFIGs
var isDev = false; // boolean
var isPRD = false; // boolean
var version = '0.0.1';
var mainPath = '/OnlineSalesWeb/';
var servicePath = '/OnlineSalesWSBanca/';
var servicePathLocal = 'https://localhost:5001/api';
var serviceURL = '';
// var serviceURLPRD = 'https://kbankonlinesale-uat.muangthai.co.th/OnlineSalesWS/api';
// var serviceURLUAT = 'https://kbankonlinesale-uat.muangthai.co.th/OnlineSalesWS/api';
// var serviceURLLocal = 'https://localhost:5001/api';
var lang = 'TH';
var firstPage = '1';
var masPage = [];
var channelLogo = null;
var productTitle = null;
var productList = [];
var checkGender = [ "MALE", "FEMALE" ];
var checkAgeMin = 0;
var checkAgeMinUnit = 'Y';
var checkAgeMax = 99;
var checkAgeMaxUnit = 'Y';
var clientIP = '0.0.0.0';

// เอาไว้เก็บข่้อมูลแต่ละหน้า
var masterMessage = [{ msgId: 0, msgCode: null, msgType: null, msgDescription: null }];
var headerText = { mainText: "Online Sales", subText: "ซื้อประกันออนไลน์ที่เหมาะกับคุณ", thirdText: "" };
var initialData = { bearerAuth: null, channelCode: null, productId: null, source: null, confirmMessage: "", importantInformation: "", occupation: [], province: [], relationship: [], title: [], labelDtos: [],configDtos:[],codeTracking:"",natureOfBusinesses:[],mappingRelationships:[]};
var messageErr = { errMessage: null}
var screenSM001 = { birthdate: '', gender : null , birthdatePDPA: '', genderPDPA: null, screen: '', WindowLocationHref: ''};
var screenSM002 = { paymentCode: null, planId : null, productName: "", planName: "", paymentName: "", cost: 0, costPayment: 0,sumInsured:0 ,paymentId:null, insureRequiredIdentification:true, insureSelfie:true, screen: '', WindowLocationHref: ''};
var screenSM003 = { userHealthAns: [], healthQuestions: [], dataPDPANewValue: null, UserAnsErr: null, screen: '', WindowLocationHref: ''};
var screenSM004 = { fatcaAns: [], fatcaAnsForservice: "", fatcaAnsPDPA: null, screen: '', WindowLocationHref: '', crsAns: [], crsAnsForservice: "",answerCondition:null ,taxIdentificationNumber:null,acceptSensitive:null};
var screenSM005 = { idCard: null, cusId: 0 };
var screenSM006 = { phoneNumber: null, refCode: null, otpCode: null, isChangeNumber: false };
var screenSM006Preview = { phoneNumber: "", refCode: null, otpCode: null, isChangeNumber: false };
var screenSM007 = { otpCode: null };
var screenSM008 = { titleName: null, firstName: null, lastName: null, idCardExpDateNone: true, idCardExp: "", job: null, jobDescription: null, email: null,laserId:""};
var screenSM008Preview = { titleName: "", firstName: "", lastName: "", idCardExpDateNone: true, idCardExp: "", job: "", jobDescription: "", email: ""};
var screenSM009 = { address: null, province: null, district: null, subDistrict: null, village: "", moo: "", soi: "", street: "", zipCode: null, isSameAddress: true, formalProvince: "", formalDistrict: "", formalSubDistrict: "", formalAddress: "", formalVillage: "", formalMoo: "", formalSoi: "", formalStreet: "", formalZipCode: "", isDocToNowAddress: true, insuranceTrack: 'electronic', insuranceTrack1: "electronic"};
var screenSM009Preview = { address: "", province: "", district: "", subDistrict: "", village: "", moo: "", soi: "", street: "", zipCode: "", isSameAddress: true, formalProvince: "", formalDistrict: "", formalSubDistrict: "", formalAddress: "", formalVillage: "", formalMoo: "", formalSoi: "", formalStreet: "", formalZipCode: "", isDocToNowAddress: true, insuranceTrack: 'electronic', insuranceTrack1: "electronic", receiveInsuranceQuestion: "" };
var screenSM009PDPA = { address: null, province: null, district: null, subDistrict: null, village: "", moo: "", soi: "", street: "", zipCode: null, isSameAddress: true, formalProvince: "", formalDistrict: "", formalSubDistrict: "", formalAddress: "", formalVillage: "", formalMoo: "", formalSoi: "", formalStreet: "", formalZipCode: "", isDocToNowAddress: true, insuranceTrack: 'electronic', insuranceTrack1: "electronic" };
var screenSM010 = { isAgreeRD: null, isHeire: true, heir: null , hasBeneficiary: false, heirPreview: [], taxBreakQuestionResponse: []};
var screenSM011 = {agreeTerms: ""};
var screenSM015 = {agreeTerms: ""};
// เอาไว้เชคว่ากดมาจากปุ่มแก้ไขหน้า sm012 รึเปล่า โดยเก็บ seq
var screenSM012 = null;
var screenMenuKPlus = {onKPlus:false} //ข้อมูลทั้งหมดที่ต้อง prefill 

// screen 013 needed
var screenSM013 = { jobInformationsType:"", jobInformationsOccupation: "", jobInformationsDescription: "", titleCode: "", titleCodeEN: "", provinceCode: "", districtCode: "", subDistrictCode: "", formalProvinceCode: "", formalDistrictCode: "", formalSubDistrictCode: "", healthQuestions: [], beneficiariesOfOrder: [], logHeire: [], promotionCode: "",checkRetryPayment:false };

var userSelectedData = { selectedPlan: null }
var Screen1 = { birthdate: '', gender : null };
var Screen3 = { answerList : null };
var Screen4 = {
    identified: false,
    idCard: null,
    phoneNumber: null, 
    nameTitle: null,
    firstName: null,
    lastName: null,
    idCardExpDate: null,
    idCardExpMonth: null,
    idCardExpYear: null,
    idCardExpDateNone: false,
    career: null,
    careerDetail: null,
    email: null,
    province: null,
    district: null,
    subDisrtict: null,
    addressNumber: null,
    addressBuilding: null,
    moo: null,
    soi: null,
    street: null,
    zipCode: null,
    formalAddress: true,
    formalProvince: null,
    formalDistrict: null,
    formalSubDisrtict: null,
    formalAddressNumber: null,
    formalAddressBuilding: null,
    formalMoo: null,
    formalSoi: null,
    formalStreet: null,
    formalZipCode: null,
    reciveAtNowAddress: true,
    fatca: true,
    isEqualBenefit: true,
    taxSupport: true,
    heir: [{isHeire: true, firstName: "", lastName: "", relation: "", percen: 100}],
}

// เก็บข้อมูลของหน้าติดต่อเจ้าหน้าที่
var dropleadDtos = [{contactTimeId: "", contactTimeCode: "", contactTimeName: ""}]

var screenSM014 = {
    uploadedFilesId_card: null,
    uploadedFilesFace_shape: null,
    flagUploadByKPLUS:true,
}

var screenSM014PDPA = {
    uploadedFilesId_card: null,
    uploadedFilesFace_shape: null,
    dataPDPAOldValue: null,
}
//เก็บข้อมูลโปรดัคดีเทล
var screenDetail = {
    bannerHighlightMB: null,
    bannerHighlightPC: null,
    coverageAndBenefit: {},
    highlight:[],
    insurance:[],
    isProductDetailStatus:false,
    keyHighlight:null,
    landingRemark:null,
    landingTitlePage:null,
    productName:null,
    promotion:{},
    remark:{},
}


const MainData = {
    get version() {
        return version;
    },
    set version(newVersion) {
        version = newVersion;
    },
    get clientIP() {
        return clientIP;
    },
    set clientIP(newclientIP) {
        clientIP = newclientIP;
    },
    get mainPath() {
        return mainPath;
    },
    get servicePath() {
        return servicePath;
    },
    get serviceURL() {
        // if(isDev){ return serviceURLLocal }else if(!isPRD){ return serviceURLUAT }else{ return serviceURLPRD }
        return isDev?servicePathLocal:serviceURL
    },
    set serviceURL(newServiceURL){
        serviceURL = newServiceURL;
    },
    get isDev() {
        return isDev;
    },
    get isPRD() {
        return isPRD;
    },
    get lang() {
        return lang;
    },
    set lang(str) {
        lang = str;
    },
    get channelLogo() {
        return channelLogo;
    },
    set channelLogo(str) {
        channelLogo = str;
    },
    get masterMessage() {
        return masterMessage;
    },
    set masterMessage(obj) {
        masterMessage = obj;
    },
    get headerText() {
        return headerText;
    },
    set headerText(obj) {
        headerText = obj;
    },
    get initialData() {
        return initialData;
    },
    set initialData(obj) {
        initialData = obj;
    },
    get screenSM001() {
        return screenSM001;
    },
    set screenSM001(obj) {
        screenSM001 = obj;
    },
    get screenSM002() {
        return screenSM002;
    },
    set screenSM002(obj) {
        screenSM002 = obj;
    },
    get screenSM003() {
        return screenSM003;
    },
    set screenSM003(obj) {
        screenSM003 = obj;
    },
    get screenSM004() {
        return screenSM004;
    },
    set screenSM004(obj) {
        screenSM004 = obj;
    },
    get screenSM005() {
        return screenSM005;
    },
    set screenSM005(obj) {
        screenSM005 = obj;
    },
    get screenSM006() {
        return screenSM006;
    },
    set screenSM006(obj) {
        screenSM006 = obj;
    },
    get screenSM006Preview() {
        return screenSM006Preview;
    },
    set screenSM006Preview(obj) {
        screenSM006Preview = obj;
    },
    get screenSM007() {
        return screenSM007;
    },
    set screenSM007(obj) {
        screenSM007 = obj;
    },
    get screenSM008() {
        return screenSM008;
    },
    set screenSM008(obj) {
        screenSM008 = obj;
    },
    get screenSM008Preview() {
        return screenSM008Preview;
    },
    set screenSM008Preview(obj) {
        screenSM008Preview = obj;
    },
    get screenSM009() {
        return screenSM009;
    },
    set screenSM009(obj) {
        screenSM009 = obj;
    },
    get screenSM009Preview() {
        return screenSM009Preview;
    },
    set screenSM009Preview(obj) {
        screenSM009Preview = obj;
    },
    get screenSM009PDPA(){
        return screenSM009PDPA;
    },
    set screenSM009PDPA(obj){
        screenSM009PDPA = obj;
    },
    get screenSM010() {
        return screenSM010;
    },
    set screenSM010(obj) {
        screenSM010 = obj;
    },
    get screenSM012() {
        return screenSM012;
    },
    set screenSM012(str) {
        screenSM012 = str;
    },
    get screenSM013() {
        return screenSM013;
    },
    set screenSM013(obj) {
        screenSM013 = obj;
    },
    get Screen1() {
        return Screen1;
    },
    set Screen1(obj) {
        Screen1 = obj;
    },
    get Screen3() {
        return Screen3;
    },
    set Screen3(obj) {
        Screen3 = obj;
    },
    get Screen4() {
        return Screen4;
    },
    set Screen4(obj) {
        Screen4 = obj;
    },
    get firstPage() {
        return firstPage;
    },
    set firstPage(str) {
        firstPage = str;
    },
    get masPage() {
        return masPage;
    },
    set masPage(arr) {
        masPage = arr;
    },
    get productList() {
        return productList;
    },
    set productList(arr) {
        productList = arr;
    },
    get userSelectedData() {
        return userSelectedData;
    },
    set userSelectedData(obj) {
        userSelectedData = obj;
    },
    get productTitle() {
        return productTitle;
    },
    set productTitle(str) {
        productTitle = str;
    },
    get checkGender() {
        return checkGender;
    },
    set checkGender(arr) {
        checkGender = arr;
    },
    get checkAgeMin() {
        return checkAgeMin;
    },
    set checkAgeMin(int) {
        checkAgeMin = int;
    },
    get checkAgeMinUnit() {
        return checkAgeMinUnit;
    },
    set checkAgeMinUnit(str) {
        checkAgeMinUnit = str;
    },
    get checkAgeMax() {
        return checkAgeMax;
    },
    set checkAgeMax(int) {
        checkAgeMax = int;
    },
    get checkAgeMaxUnit() {
        return checkAgeMaxUnit;
    },
    set checkAgeMaxUnit(str) {
        checkAgeMaxUnit = str;
    },
    get dropleadDtos() {
        return dropleadDtos;
    },
    set dropleadDtos(obj) {
        dropleadDtos = obj;
    },
    get screenSM014() {
        return screenSM014;
    },
    set screenSM014(obj) {
        screenSM014 = obj;
    },
    get messageErr(){
        return messageErr;
    },
    set messageErr(obj){
        messageErr = obj;
    },
    get screenSM014PDPA() {
        return screenSM014PDPA;
    },
    set screenSM014PDPA(obj) {
        screenSM014PDPA = obj;
    },
    get screenDetail() {
        return screenDetail;
    },
    set screenDetail(obj) {
        screenDetail = obj;
    },
    get screenSM011() {
        return screenSM011;
    },
    set screenSM011(obj) {
        screenSM011 = obj;
    },
    get screenSM015() {
        return screenSM015;
    },
    set screenSM015(obj) {
        screenSM015 = obj;
    },
    get screenMenuKPlus(){
        return screenMenuKPlus;
    },
    set screenMenuKPlus(obj){
        screenMenuKPlus = obj
    }
    
}

export default MainData;