// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledText = exports.StyledIconContainer = exports.StyledTitle = exports.StyledTooltip = exports.StyledButton = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: 0.8125rem;\n    line-height: 1.42;\n    color: ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-right: 8px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    font-size: 1rem;\n    line-height: 1.3rem;\n    margin-bottom: 5px;\n    color: ", ";\n    align-items: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 10px;\n    border-radius: 0.875rem;\n    box-shadow: ", ";\n    border: solid 1px ", ";\n    background-color: ", ";\n    min-width: 60px;\n    max-width: 400px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: none;\n    background: transparent;\n    height: fit-content;\n    padding: 0;\n    border-radius: 1rem;\n\n    &:focus,\n    &:active {\n        outline: 0;\n\n        ", ";\n\n        ", ";\n\n        ", ";\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledButton = (0, _attachThemeAttrs["default"])(_styledComponents["default"].button)(_templateObject(), function (props) {
  return props.variant === 'info' && "\n                box-shadow: ".concat(props.shadows.brand, ";\n        ");
}, function (props) {
  return props.variant === 'error' && "\n                box-shadow: ".concat(props.shadows.error, ";\n        ");
}, function (props) {
  return props.variant === 'question' && "\n                box-shadow: ".concat(props.shadows.shadow_10, ";\n        ");
});
exports.StyledButton = StyledButton;
var StyledTooltip = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject2(), function (props) {
  return props.shadows.shadow_2;
}, function (props) {
  return props.palette.border.divider;
}, function (props) {
  return props.palette.background.main;
});
exports.StyledTooltip = StyledTooltip;
var StyledTitle = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div).attrs(function (props) {
  var palette = props.palette,
      variant = props.variant;
  var variantColorMap = {
    info: palette.brand.main,
    error: palette.error.main,
    warning: palette.warning.main,
    question: palette.text.header
  };
  var variantColor = variantColorMap[variant] || variantColorMap.info;
  return {
    variantColor: variantColor
  };
})(_templateObject3(), function (props) {
  return props.variantColor;
});
exports.StyledTitle = StyledTitle;

var StyledIconContainer = _styledComponents["default"].span(_templateObject4());

exports.StyledIconContainer = StyledIconContainer;
var StyledText = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject5(), function (props) {
  return props.palette.text.main;
});
exports.StyledText = StyledText;