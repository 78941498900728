// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0;\n    padding: 0;\n    border: 0;\n    display: inline-flex;\n    flex-direction: column;\n    justify-content: center;\n    box-sizing: border-box;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].fieldset(_templateObject());

var _default = StyledContainer;
exports["default"] = _default;