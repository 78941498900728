// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledListItem = exports.StyledOrderedList = exports.StyledList = exports.StyledTableCell = exports.StyledTableHeadindCell = exports.StyledTableRow = exports.StyledTableBody = exports.StyledTable = exports.StyledHR = exports.StyledCode = exports.StyledParagraph = exports.StyledHeading = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

var _fontSizes = require("../../../styles/fontSizes");

var _borderRadius = require("../../../styles/borderRadius");

function _templateObject12() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-left: 1rem;\n    margin-bottom: 4px;\n\n    input[type='checkbox'] {\n        width: 18px;\n        height: 18px;\n        display: inline-block;\n        position: relative;\n        vertical-align: middle;\n        border: 1px solid ", ";\n        border-radius: ", ";\n        background-color: ", ";\n        margin-right: 6px;\n        transition: border 0.1s linear, background-color 0.1s linear;\n        box-sizing: border-box;\n        appearance: none;\n\n        :checked {\n            border: 2px solid ", ";\n\n            &::after {\n                display: block;\n                content: '';\n                height: 0.3rem;\n                width: 0.6rem;\n                position: absolute;\n                top: 46%;\n                left: 50%;\n                transform: translate3d(-50%, -50%, 0) rotate(-45deg);\n                border-bottom: 2px solid;\n                border-left: 2px solid;\n                box-sizing: border-box;\n                padding: 0;\n                border-color: ", ";\n            }\n        }\n\n        :focus {\n            border: 2px solid ", ";\n            box-shadow: ", ";\n        }\n    }\n"]);

  _templateObject12 = function _templateObject12() {
    return data;
  };

  return data;
}

function _templateObject11() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    list-style: decimal;\n"]);

  _templateObject11 = function _templateObject11() {
    return data;
  };

  return data;
}

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    list-style: circle;\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    text-align: ", ";\n    border: 1px solid transparent;\n    box-sizing: border-box;\n    padding: 0;\n    white-space: nowrap;\n    padding: 0 18px;\n    color: ", ";\n    min-height: 42px;\n    line-height: 40px;\n    max-width: 100%;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    font-weight: 400;\n    border-radius: 10px;\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    text-align: ", ";\n    border: 1px solid transparent;\n    box-sizing: border-box;\n    padding: 0;\n    white-space: nowrap;\n    padding: 0 18px;\n    text-transform: uppercase;\n    font-weight: 900;\n    color: ", ";\n    line-height: normal;\n    position: static;\n    border-top: 0;\n    outline: none;\n    height: 44px;\n    background-color: ", ";\n        \n    &:first-of-type {\n        border-radius: 10px 0 0 0;\n    }\n    \n    &:last-of-type {\n        border-radius: 0 10px 0 0;\n    }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    box-shadow: 0 1px 0 0 ", ";\n    transition: all 250ms cubic-bezier(0.4,0,0.2,1) 0ms;\n    box-sizing: border-box;\n    counter-increment: rowCounter;\n\n    &:last-of-type {\n        box-shadow: none;\n        border-bottom-left-radius: 10px;\n        border-bottom-right-radius: 10px;\n    }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background-color: ", ";\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    table-layout: fixed;\n    border-collapse: separate;\n    background-color: ", ";\n    border-spacing: 0;\n    width: 100%;\n    box-sizing: border-box;\n    border-radius: 10px;\n    border: 1px solid ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 1rem auto;\n    background: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background: ", ";\n    color: ", ";\n    font-size: ", ";\n    border-radius: 4px;\n    border: 1px solid ", ";\n    padding: 2px 6px;\n    overflow-x: auto;\n\n    pre > & {\n        display: block;\n        padding: 0.5rem 1rem;    \n    }\n\n    p > & {\n        margin-left: 4px;\n        margin-right: 4px;\n    }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    color: ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    color: ", ";\n    margin-top: 1rem;\n    margin-bottom: 0.75rem;\n    ", ";\n    ", ";\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledHeading = (0, _attachThemeAttrs["default"])(_styledComponents["default"].h1)(_templateObject(), _fontSizes.FONT_SIZE_HEADING_SMALL, function (props) {
  return props.palette.text.main;
}, function (props) {
  return props.level === 1 && "\n        font-size: ".concat(_fontSizes.FONT_SIZE_HEADING_X_LARGE, ";\n        ");
}, function (props) {
  return props.level === 2 && "\n        font-size: ".concat(_fontSizes.FONT_SIZE_HEADING_LARGE, ";\n        ");
}, function (props) {
  return props.level === 3 && "\n        font-size: ".concat(_fontSizes.FONT_SIZE_HEADING_MEDIUM, ";\n        ");
});
exports.StyledHeading = StyledHeading;
var StyledParagraph = (0, _attachThemeAttrs["default"])(_styledComponents["default"].p)(_templateObject2(), _fontSizes.FONT_SIZE_TEXT_MEDIUM, function (props) {
  return props.palette.text.main;
});
exports.StyledParagraph = StyledParagraph;
var StyledCode = (0, _attachThemeAttrs["default"])(_styledComponents["default"].code.attrs({
  className: 'hljs'
}))(_templateObject3(), function (props) {
  return props.palette.background.highlight;
}, function (props) {
  return props.palette.text.main;
}, _fontSizes.FONT_SIZE_TEXT_MEDIUM, function (props) {
  return props.palette.border.divider;
});
exports.StyledCode = StyledCode;
var StyledHR = (0, _attachThemeAttrs["default"])(_styledComponents["default"].hr)(_templateObject4(), function (props) {
  return props.palette.border.divider;
});
exports.StyledHR = StyledHR;
var StyledTable = (0, _attachThemeAttrs["default"])(_styledComponents["default"].table)(_templateObject5(), function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.palette.border.divider;
});
exports.StyledTable = StyledTable;
var StyledTableBody = (0, _attachThemeAttrs["default"])(_styledComponents["default"].tbody)(_templateObject6(), function (props) {
  return props.palette.background.main;
});
exports.StyledTableBody = StyledTableBody;
var StyledTableRow = (0, _attachThemeAttrs["default"])(_styledComponents["default"].tr)(_templateObject7(), function (props) {
  return props.palette.border.divider;
});
exports.StyledTableRow = StyledTableRow;
var StyledTableHeadindCell = (0, _attachThemeAttrs["default"])(_styledComponents["default"].td)(_templateObject8(), _fontSizes.FONT_SIZE_TEXT_MEDIUM, function (props) {
  return props.align;
}, function (props) {
  return props.palette.text.title;
}, function (props) {
  return props.palette.background.highlight;
});
exports.StyledTableHeadindCell = StyledTableHeadindCell;
var StyledTableCell = (0, _attachThemeAttrs["default"])(_styledComponents["default"].td)(_templateObject9(), _fontSizes.FONT_SIZE_TEXT_MEDIUM, function (props) {
  return props.align;
}, function (props) {
  return props.palette.text.label;
});
exports.StyledTableCell = StyledTableCell;

var StyledList = _styledComponents["default"].ul(_templateObject10());

exports.StyledList = StyledList;

var StyledOrderedList = _styledComponents["default"].ol(_templateObject11());

exports.StyledOrderedList = StyledOrderedList;
var StyledListItem = (0, _attachThemeAttrs["default"])(_styledComponents["default"].li)(_templateObject12(), function (props) {
  return props.palette.border.divider;
}, _borderRadius.BORDER_RADIUS_3, function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.shadows.brand;
});
exports.StyledListItem = StyledListItem;