import axios from "axios";
import { notify } from 'react-noti';
import React, { Component } from "react";
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import {Row, Col, Button} from "react-bootstrap";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import {ImRadioUnchecked, ImRadioChecked} from "react-icons/im";
import FormControlLabel from '@material-ui/core/FormControlLabel';

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

class SM009 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM009",
      validation: false,
      moo: MainData.screenSM009.moo?MainData.screenSM009.moo:MainData.screenSM009Preview.moo,
      soi: MainData.screenSM009.soi?MainData.screenSM009.soi:MainData.screenSM009Preview.soi,
      street: MainData.screenSM009.street?MainData.screenSM009.street:MainData.screenSM009Preview.street,
      zipCode: MainData.screenSM009.zipCode?MainData.screenSM009.zipCode?MainData.screenSM009.zipCode:"":MainData.screenSM009Preview.zipCode,
      village: MainData.screenSM009.village?MainData.screenSM009.village:MainData.screenSM009Preview.village,
      address: MainData.screenSM009.address?MainData.screenSM009.address?MainData.screenSM009.address:"":MainData.screenSM009Preview.address,
      province: MainData.screenSM009.province?MainData.screenSM009.province?MainData.screenSM009.province:"":MainData.screenSM009Preview.province,
      district: MainData.screenSM009.district?MainData.screenSM009.district?MainData.screenSM009.district:"":MainData.screenSM009Preview.district,
      formalSoi: MainData.screenSM009.formalSoi?MainData.screenSM009.formalSoi:MainData.screenSM009Preview.formalSoi,
      formalMoo: MainData.screenSM009.formalMoo?MainData.screenSM009.formalMoo:MainData.screenSM009Preview.formalMoo,
      subDistrict: MainData.screenSM009.subDistrict?MainData.screenSM009.subDistrict?MainData.screenSM009.subDistrict:"":MainData.screenSM009Preview.subDistrict,
      formalStreet: MainData.screenSM009.formalStreet?MainData.screenSM009.formalStreet:MainData.screenSM009Preview.formalStreet,
      formalZipCode: MainData.screenSM009.formalZipCode?MainData.screenSM009.formalZipCode:MainData.screenSM009Preview.formalZipCode,
      formalAddress: MainData.screenSM009.formalAddress?MainData.screenSM009.formalAddress:MainData.screenSM009Preview.formalAddress,
      formalVillage: MainData.screenSM009.formalVillage?MainData.screenSM009.formalVillage:MainData.screenSM009Preview.formalVillage,
      isSameAddress: MainData.screenSM009Preview.isSameAddress,
      formalProvince: MainData.screenSM009.formalProvince?MainData.screenSM009.formalProvince:MainData.screenSM009Preview.formalProvince,
      insuranceTrack: MainData.screenSM009Preview.insuranceTrack?MainData.screenSM009Preview.insuranceTrack:"electronic",
      insuranceTrack1: MainData.screenSM009Preview.insuranceTrack1?MainData.screenSM009Preview.insuranceTrack1:"electronic",
      formalDistrict: MainData.screenSM009.formalDistrict?MainData.screenSM009.formalDistrict:MainData.screenSM009Preview.formalDistrict,
      formalSubDistrict: MainData.screenSM009.formalSubDistrict?MainData.screenSM009.formalSubDistrict:MainData.screenSM009Preview.formalSubDistrict,
      isDocToNowAddress: MainData.screenSM009Preview.isDocToNowAddress,
      dataProvince: MainData.initialData.province,
      dataDistric: [],
      receiveInsuranceQuestion: [],
    };
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    this.props.setPage(this.props.prev)
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    if(!this.state.province || !this.state.district || !this.state.subDistrict || !this.state.address || !this.state.zipCode){
      this.setState({validation: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG003');
      if(MainData.screenSM009PDPA.province != null || MainData.screenSM009PDPA.district != null || MainData.screenSM009PDPA.subDistrict != null || MainData.screenSM009PDPA.address != null || MainData.screenSM009PDPA.zipCode != null)
    {
      MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {
        currentAddressType:"Home",
        currentAddressProvinceName: MainData.screenSM009PDPA.province,
        currentAddressDistrictName: MainData.screenSM009PDPA.district,
        currentAddressSubdistrictName: MainData.screenSM009PDPA.subDistrict,
        currentAddressNo: MainData.screenSM009PDPA.address,
        currentAddressBuilding: MainData.screenSM009PDPA.village,
        currentAddressMoo: MainData.screenSM009PDPA.moo,
        currentAddressSoi: MainData.screenSM009PDPA.soi,
        currentAddressRoad: MainData.screenSM009PDPA.street,
        currentAddressZipcode: MainData.screenSM009PDPA.zipCode,
        addressType: MainData.screenSM009PDPA.isSameAddress===false? "No" : "Home",
        addressProvinceName: MainData.screenSM009PDPA.formalProvince,
        addressDistrictName: MainData.screenSM009PDPA.formalDistrict,
        addressSubdistrictName: MainData.screenSM009PDPA.formalSubDistrict,
        addressNo: MainData.screenSM009PDPA.formalAddress,
        addressBuilding: MainData.screenSM009PDPA.formalVillage,
        addressMoo: MainData.screenSM009PDPA.formalMoo,
        addressSoi: MainData.screenSM009PDPA.formalSoi,
        addressRoad: MainData.screenSM009PDPA.formalStreet,
        addressZipcode: MainData.screenSM009PDPA.formalZipCode,
        contactAddress: MainData.screenSM009PDPA.isDocToNowAddress===false?"Home":"Curr",
        receiveInsuranceChannel: MainData.screenSM009PDPA.insuranceTrack

      }, 
      {    
          currentAddressType:"Home",
          currentAddressProvinceName: this.state.province,
          currentAddressDistrictName: this.state.district,
          currentAddressSubdistrictName: this.state.subDistrict,
          currentAddressNo: this.state.address,
          currentAddressBuilding: this.state.village,
          currentAddressMoo: this.state.moo,
          currentAddressSoi: this.state.soi,
          currentAddressRoad: this.state.street,
          currentAddressZipcode: this.state.zipCode,
          addressType: this.state.isSameAddress===false? "No" : "Home",
          addressProvinceName: this.state.isSameAddress===false?this.state.formalProvince:this.state.province,
          addressDistrictName: this.state.isSameAddress===false?this.state.formalDistrict:this.state.district,
          addressSubdistrictName: this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict,
          addressNo: this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
          addressBuilding: this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
          addressMoo: this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
          addressSoi: this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
          addressRoad: this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
          addressZipcode: this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
          contactAddress: this.state.isDocToNowAddress===false?"Home":"Curr",
          receiveInsuranceChannel: this.state.insuranceTrack
        },
     'Fail', errMsg.msgDescription)    }
    else
    {
      MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {    
        currentAddressType:"Home",
        currentAddressProvinceName: this.state.province,
        currentAddressDistrictName: this.state.district,
        currentAddressSubdistrictName: this.state.subDistrict,
        currentAddressNo: this.state.address,
        currentAddressBuilding: this.state.village,
        currentAddressMoo: this.state.moo,
        currentAddressSoi: this.state.soi,
        currentAddressRoad: this.state.street,
        currentAddressZipcode: this.state.zipCode,
        addressType: this.state.isSameAddress===false? "No" : "Home",
        addressProvinceName: this.state.isSameAddress===false?this.state.formalProvince:this.state.province,
        addressDistrictName: this.state.isSameAddress===false?this.state.formalDistrict:this.state.district,
        addressSubdistrictName: this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict,
        addressNo: this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
        addressBuilding: this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
        addressMoo: this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
        addressSoi: this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
        addressRoad: this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
        addressZipcode: this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
        contactAddress: this.state.isDocToNowAddress===false?"Home":"Curr",
        receiveInsuranceChannel: this.state.insuranceTrack
      },
   'Fail', errMsg.msgDescription)
    }
    
        MainData.screenSM009PDPA.address = this.state.address;
        MainData.screenSM009PDPA.province = this.state.province;
        MainData.screenSM009PDPA.district = this.state.district;
        MainData.screenSM009PDPA.subDistrict = this.state.subDistrict;
        MainData.screenSM009PDPA.village = this.state.village;
        MainData.screenSM009PDPA.moo = this.state.moo;
        MainData.screenSM009PDPA.soi = this.state.soi;
        MainData.screenSM009PDPA.street = this.state.street;
        MainData.screenSM009PDPA.zipCode = this.state.zipCode;
        MainData.screenSM009PDPA.isSameAddress = this.state.isSameAddress;
        MainData.screenSM009PDPA.formalProvince = this.state.isSameAddress===false?this.state.formalProvince:this.state.province;
        MainData.screenSM009PDPA.formalDistrict = this.state.isSameAddress===false?this.state.formalDistrict:this.state.district;
        MainData.screenSM009PDPA.formalSubDistrict = this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict;
        MainData.screenSM009PDPA.formalAddress = this.state.isSameAddress===false?this.state.formalAddress:this.state.address;
        MainData.screenSM009PDPA.formalVillage = this.state.isSameAddress===false?this.state.formalVillage:this.state.village;
        MainData.screenSM009PDPA.formalMoo = this.state.isSameAddress===false?this.state.formalMoo:this.state.moo;
        MainData.screenSM009PDPA.formalSoi = this.state.isSameAddress===false?this.state.formalSoi:this.state.soi;
        MainData.screenSM009PDPA.formalStreet = this.state.isSameAddress===false?this.state.formalStreet:this.state.street;
        MainData.screenSM009PDPA.formalZipCode = this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode;
        MainData.screenSM009PDPA.isDocToNowAddress = this.state.isDocToNowAddress;
        MainData.screenSM009PDPA.insuranceTrack = this.state.insuranceTrack;

      this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
    }else{
      if(this.state.isSameAddress===false){
        if(!this.state.formalProvince || !this.state.formalDistrict || !this.state.formalSubDistrict || !this.state.formalAddress || !this.state.formalZipCode){
          this.setState({validation: true})
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG003');
          if(MainData.screenSM009PDPA.province != null || MainData.screenSM009PDPA.district != null || MainData.screenSM009PDPA.subDistrict != null || MainData.screenSM009PDPA.address != null || MainData.screenSM009PDPA.zipCode != null)
          {
            MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {
              currentAddressType:"Home",
              currentAddressProvinceName: MainData.screenSM009PDPA.province,
              currentAddressDistrictName: MainData.screenSM009PDPA.district,
              currentAddressSubdistrictName: MainData.screenSM009PDPA.subDistrict,
              currentAddressNo: MainData.screenSM009PDPA.address,
              currentAddressBuilding: MainData.screenSM009PDPA.village,
              currentAddressMoo: MainData.screenSM009PDPA.moo,
              currentAddressSoi: MainData.screenSM009PDPA.soi,
              currentAddressRoad: MainData.screenSM009PDPA.street,
              currentAddressZipcode: MainData.screenSM009PDPA.zipCode,
              addressType: MainData.screenSM009PDPA.isSameAddress===false? "No" : "Home",
              addressProvinceName: MainData.screenSM009PDPA.formalProvince,
              addressDistrictName: MainData.screenSM009PDPA.formalDistrict,
              addressSubdistrictName: MainData.screenSM009PDPA.formalSubDistrict,
              addressNo: MainData.screenSM009PDPA.formalAddress,
              addressBuilding: MainData.screenSM009PDPA.formalVillage,
              addressMoo: MainData.screenSM009PDPA.formalMoo,
              addressSoi: MainData.screenSM009PDPA.formalSoi,
              addressRoad: MainData.screenSM009PDPA.formalStreet,
              addressZipcode: MainData.screenSM009PDPA.formalZipCode,
              contactAddress: MainData.screenSM009PDPA.isDocToNowAddress===false?"Home":"Curr",
              receiveInsuranceChannel: MainData.screenSM009PDPA.insuranceTrack,
              ReceiveInsuranceChannelOther: this.state.insuranceTrack1
      
            }, 
            {    
              currentAddressType:"Home",
              currentAddressProvinceName: this.state.province,
              currentAddressDistrictName: this.state.district,
              currentAddressSubdistrictName: this.state.subDistrict,
              currentAddressNo: this.state.address,
              currentAddressBuilding: this.state.village,
              currentAddressMoo: this.state.moo,
              currentAddressSoi: this.state.soi,
              currentAddressRoad: this.state.street,
              currentAddressZipcode: this.state.zipCode,
              addressType: this.state.isSameAddress===false? "No" : "Home",
              addressProvinceName: this.state.isSameAddress===false?this.state.formalProvince:this.state.province,
              addressDistrictName: this.state.isSameAddress===false?this.state.formalDistrict:this.state.district,
              addressSubdistrictName: this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict,
              addressNo: this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
              addressBuilding: this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
              addressMoo: this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
              addressSoi: this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
              addressRoad: this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
              addressZipcode: this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
              contactAddress: this.state.isDocToNowAddress===false?"Home":"Curr",
              receiveInsuranceChannel: this.state.insuranceTrack,
              ReceiveInsuranceChannelOther: this.state.insuranceTrack1
              
              },
           'Fail', errMsg.msgDescription)    }
          else
          {
            MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {    
              currentAddressType:"Home",
              currentAddressProvinceName: this.state.province,
              currentAddressDistrictName: this.state.district,
              currentAddressSubdistrictName: this.state.subDistrict,
              currentAddressNo: this.state.address,
              currentAddressBuilding: this.state.village,
              currentAddressMoo: this.state.moo,
              currentAddressSoi: this.state.soi,
              currentAddressRoad: this.state.street,
              currentAddressZipcode: this.state.zipCode,
              addressType: this.state.isSameAddress===false? "No" : "Home",
              addressProvinceName: this.state.isSameAddress===false?this.state.formalProvince:this.state.province,
              addressDistrictName: this.state.isSameAddress===false?this.state.formalDistrict:this.state.district,
              addressSubdistrictName: this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict,
              addressNo: this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
              addressBuilding: this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
              addressMoo: this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
              addressSoi: this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
              addressRoad: this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
              addressZipcode: this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
              contactAddress: this.state.isDocToNowAddress===false?"Home":"Curr",
              receiveInsuranceChannel: this.state.insuranceTrack,
              ReceiveInsuranceChannelOther: this.state.insuranceTrack1
            },
         'Fail', errMsg.msgDescription)
          }
          // MainData.screenSM009.address = this.state.address;
          // MainData.screenSM009.province = this.state.province;
          // MainData.screenSM009.district = this.state.district;
          // MainData.screenSM009.subDistrict = this.state.subDistrict;
          // MainData.screenSM009.zipCode = this.state.zipCode;
            MainData.screenSM009PDPA.address = this.state.address;
            MainData.screenSM009PDPA.province = this.state.province;
            MainData.screenSM009PDPA.district = this.state.district;
            MainData.screenSM009PDPA.subDistrict = this.state.subDistrict;
            MainData.screenSM009PDPA.village = this.state.village;
            MainData.screenSM009PDPA.moo = this.state.moo;
            MainData.screenSM009PDPA.soi = this.state.soi;
            MainData.screenSM009PDPA.street = this.state.street;
            MainData.screenSM009PDPA.zipCode = this.state.zipCode;
            MainData.screenSM009PDPA.isSameAddress = this.state.isSameAddress;
            MainData.screenSM009PDPA.formalProvince = this.state.isSameAddress===false?this.state.formalProvince:this.state.province;
            MainData.screenSM009PDPA.formalDistrict = this.state.isSameAddress===false?this.state.formalDistrict:this.state.district;
            MainData.screenSM009PDPA.formalSubDistrict = this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict;
            MainData.screenSM009PDPA.formalAddress = this.state.isSameAddress===false?this.state.formalAddress:this.state.address;
            MainData.screenSM009PDPA.formalVillage = this.state.isSameAddress===false?this.state.formalVillage:this.state.village;
            MainData.screenSM009PDPA.formalMoo = this.state.isSameAddress===false?this.state.formalMoo:this.state.moo;
            MainData.screenSM009PDPA.formalSoi = this.state.isSameAddress===false?this.state.formalSoi:this.state.soi;
            MainData.screenSM009PDPA.formalStreet = this.state.isSameAddress===false?this.state.formalStreet:this.state.street;
            MainData.screenSM009PDPA.formalZipCode = this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode;
            MainData.screenSM009PDPA.isDocToNowAddress = this.state.isDocToNowAddress;
            MainData.screenSM009PDPA.insuranceTrack = this.state.insuranceTrack;
            MainData.screenSM009PDPA.insuranceTrack1 = this.state.insuranceTrack1;
          this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
        }else{
          this.goNextNow();
        }
      }else{
        this.goNextNow();
      }
    }
  }

  componentDidMount(){
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
    this.getReceiveInsuranceQuestion()
  }

  goNextNow = () => {
    let newProvince = MainData.initialData.province.find(province=>province.provinceTitle===this.state.province);
    MainData.screenSM013.provinceCode = newProvince.provinceCode;
    let newDistrict = newProvince.district.find(district=>district.districtTitle===this.state.district);
    MainData.screenSM013.districtCode = newDistrict.districtCode;
    let newSubDistrictCode = newDistrict.subDistrict.find(subDistrict=>subDistrict.subDistrictTitle === this.state.subDistrict)
    MainData.screenSM013.subDistrictCode = newSubDistrictCode.subDistrictCode;
    let newFProvinceCode;
    let newFDistrictCode;
    let newFSubDistrictCodeCode;
    if(this.state.isSameAddress===false){
      let newFProvince = MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince);
      let newFDistrict = newFProvince.district.find(district=>district.districtTitle===this.state.formalDistrict);
      let newFSubDistrictCode = newFDistrict.subDistrict.find(subDistrict=>subDistrict.subDistrictTitle === this.state.formalSubDistrict)
      MainData.screenSM013.formalProvinceCode = newFProvince.provinceCode
      MainData.screenSM013.formalDistrictCode = newFDistrict.districtCode
      MainData.screenSM013.formalSubDistrictCode = newFSubDistrictCode.subDistrictCode
      newFProvinceCode = newFProvince.provinceCode
      newFDistrictCode = newFDistrict.districtCode
      newFSubDistrictCodeCode = newFSubDistrictCode.subDistrictCode
    }else{
      MainData.screenSM013.formalProvinceCode = newProvince.provinceCode;
      MainData.screenSM013.formalDistrictCode = newDistrict.districtCode;
      MainData.screenSM013.formalSubDistrictCode = newSubDistrictCode.subDistrictCode;
    }
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลที่อยู่ปัจจุบัน ที่อยู่ตามทะเบียนบ้าน สถานที่จัดส่งเอกสาร', {
      currentAddress: MainFunc.blurAll(MainData.screenSM009.address)+" "+MainFunc.blurAll(MainData.screenSM009.moo)+" "+MainFunc.blurAll(MainData.screenSM009.soi)+" "+MainFunc.blurAll(MainData.screenSM009.street)+" "+MainData.screenSM009.subDistrict+" "+MainData.screenSM009.district+" "+MainData.screenSM009.province,
      homeAddress: MainFunc.blurAll(MainData.screenSM009.formalAddress)+" "+MainFunc.blurAll(MainData.screenSM009.formalMoo)+" "+MainFunc.blurAll(MainData.screenSM009.formalSoi)+" "+MainFunc.blurAll(MainData.screenSM009.formalStreet)+" "+MainData.screenSM009.formalSubDistrict+" "+MainData.screenSM009.formalDistrict+" "+MainData.screenSM009.formalProvince,
      docAddress: this.state.insuranceTrack
    }, {
      currentAddress: MainFunc.blurAll(this.state.address)+" "+MainFunc.blurAll(this.state.moo)+" "+MainFunc.blurAll(this.state.soi)+" "+MainFunc.blurAll(this.state.street)+" "+this.state.subDistrict+" "+this.state.district+" "+this.state.province,
      homeAddress: !this.state.isSameAddress?MainFunc.blurAll(this.state.formalAddress)+" "+MainFunc.blurAll(this.state.formalMoo)+" "+MainFunc.blurAll(this.state.formalSoi)+" "+MainFunc.blurAll(this.state.formalStreet)+" "+this.state.formalSubDistrict+" "+this.state.formalDistrict+" "+this.state.formalProvince:MainFunc.blurAll(this.state.address)+" "+MainFunc.blurAll(this.state.moo)+" "+MainFunc.blurAll(this.state.soi)+" "+MainFunc.blurAll(this.state.street)+" "+this.state.subDistrict+" "+this.state.district+" "+this.state.province,
      docAddress: MainData.screenSM009.insuranceTrack
    });

    if(MainData.screenSM009PDPA.province != null || MainData.screenSM009PDPA.district != null || MainData.screenSM009PDPA.subDistrict != null || MainData.screenSM009PDPA.address != null || MainData.screenSM009PDPA.zipCode != null)
    {
      MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {
        currentAddressType:"Home",
        currentAddressProvinceName: MainData.screenSM009PDPA.province,
        currentAddressDistrictName: MainData.screenSM009PDPA.district,
        currentAddressSubdistrictName: MainData.screenSM009PDPA.subDistrict,
        currentAddressNo: MainData.screenSM009PDPA.address,
        currentAddressBuilding: MainData.screenSM009PDPA.village,
        currentAddressMoo: MainData.screenSM009PDPA.moo,
        currentAddressSoi: MainData.screenSM009PDPA.soi,
        currentAddressRoad: MainData.screenSM009PDPA.street,
        currentAddressZipcode: MainData.screenSM009PDPA.zipCode,
        addressType: MainData.screenSM009PDPA.isSameAddress===false? "No" : "Home",
        addressProvinceName: MainData.screenSM009PDPA.formalProvince,
        addressDistrictName: MainData.screenSM009PDPA.formalDistrict,
        addressSubdistrictName: MainData.screenSM009PDPA.formalSubDistrict,
        addressNo: MainData.screenSM009PDPA.formalAddress,
        addressBuilding: MainData.screenSM009PDPA.formalVillage,
        addressMoo: MainData.screenSM009PDPA.formalMoo,
        addressSoi: MainData.screenSM009PDPA.formalSoi,
        addressRoad: MainData.screenSM009PDPA.formalStreet,
        addressZipcode: MainData.screenSM009PDPA.formalZipCode,
        contactAddress: MainData.screenSM009PDPA.isDocToNowAddress===false?"Home":"Curr",
        receiveInsuranceChannel: MainData.screenSM009PDPA.insuranceTrack,
        ReceiveInsuranceChannelOther: this.state.insuranceTrack1

      }, 
      {    
        currentAddressType:"Home",
        currentAddressProvinceName: this.state.province,
        currentAddressDistrictName: this.state.district,
        currentAddressSubdistrictName: this.state.subDistrict,
        currentAddressNo: this.state.address,
        currentAddressBuilding: this.state.village,
        currentAddressMoo: this.state.moo,
        currentAddressSoi: this.state.soi,
        currentAddressRoad: this.state.street,
        currentAddressZipcode: this.state.zipCode,
        addressType: this.state.isSameAddress===false? "No" : "Home",
        addressProvinceName: this.state.isSameAddress===false?this.state.formalProvince:this.state.province,
        addressDistrictName: this.state.isSameAddress===false?this.state.formalDistrict:this.state.district,
        addressSubdistrictName: this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict,
        addressNo: this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
        addressBuilding: this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
        addressMoo: this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
        addressSoi: this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
        addressRoad: this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
        addressZipcode: this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
        contactAddress: this.state.isDocToNowAddress===false?"Home":"Curr",
        receiveInsuranceChannel: this.state.insuranceTrack,
        ReceiveInsuranceChannelOther: this.state.insuranceTrack1
        },
     'Success', null)    }
    else
    {
      MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {    
      currentAddressType:"Home",
      currentAddressProvinceName: this.state.province,
      currentAddressDistrictName: this.state.district,
      currentAddressSubdistrictName: this.state.subDistrict,
      currentAddressNo: this.state.address,
      currentAddressBuilding: this.state.village,
      currentAddressMoo: this.state.moo,
      currentAddressSoi: this.state.soi,
      currentAddressRoad: this.state.street,
      currentAddressZipcode: this.state.zipCode,
      addressType: this.state.isSameAddress===false? "No" : "Home",
      addressProvinceName: this.state.isSameAddress===false?this.state.formalProvince:this.state.province,
      addressDistrictName: this.state.isSameAddress===false?this.state.formalDistrict:this.state.district,
      addressSubdistrictName: this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict,
      addressNo: this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
      addressBuilding: this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
      addressMoo: this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
      addressSoi: this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
      addressRoad: this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
      addressZipcode: this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
      contactAddress: this.state.isDocToNowAddress===false?"Home":"Curr",
      receiveInsuranceChannel: this.state.insuranceTrack,
      ReceiveInsuranceChannelOther: this.state.insuranceTrack1
      },
   'Success', null)
    }

            MainData.screenSM009PDPA.address = this.state.address;
            MainData.screenSM009PDPA.province = this.state.province;
            MainData.screenSM009PDPA.district = this.state.district;
            MainData.screenSM009PDPA.subDistrict = this.state.subDistrict;
            MainData.screenSM009PDPA.village = this.state.village;
            MainData.screenSM009PDPA.moo = this.state.moo;
            MainData.screenSM009PDPA.soi = this.state.soi;
            MainData.screenSM009PDPA.street = this.state.street;
            MainData.screenSM009PDPA.zipCode = this.state.zipCode;
            MainData.screenSM009PDPA.isSameAddress = this.state.isSameAddress;
            MainData.screenSM009PDPA.formalProvince = this.state.isSameAddress===false?this.state.formalProvince:this.state.province;
            MainData.screenSM009PDPA.formalDistrict = this.state.isSameAddress===false?this.state.formalDistrict:this.state.district;
            MainData.screenSM009PDPA.formalSubDistrict = this.state.isSameAddress===false?this.state.formalSubDistrict:this.state.subDistrict;
            MainData.screenSM009PDPA.formalAddress = this.state.isSameAddress===false?this.state.formalAddress:this.state.address;
            MainData.screenSM009PDPA.formalVillage = this.state.isSameAddress===false?this.state.formalVillage:this.state.village;
            MainData.screenSM009PDPA.formalMoo = this.state.isSameAddress===false?this.state.formalMoo:this.state.moo;
            MainData.screenSM009PDPA.formalSoi = this.state.isSameAddress===false?this.state.formalSoi:this.state.soi;
            MainData.screenSM009PDPA.formalStreet = this.state.isSameAddress===false?this.state.formalStreet:this.state.street;
            MainData.screenSM009PDPA.formalZipCode = this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode;
            MainData.screenSM009PDPA.isDocToNowAddress = this.state.isDocToNowAddress;
            MainData.screenSM009PDPA.insuranceTrack = this.state.insuranceTrack;
            MainData.screenSM009PDPA.insuranceTrack1 = this.state.insuranceTrack1;

        MainData.screenSM009.address = this.state.address;
        MainData.screenSM009.province = this.state.province;
        MainData.screenSM009.district = this.state.district;
        MainData.screenSM009.subDistrict = this.state.subDistrict;
        MainData.screenSM009.village = this.state.village;
        MainData.screenSM009.moo = this.state.moo;
        MainData.screenSM009.soi = this.state.soi;
        MainData.screenSM009.street = this.state.street;
        MainData.screenSM009.zipCode = this.state.zipCode;
        MainData.screenSM009Preview.isSameAddress = this.state.isSameAddress;
        MainData.screenSM009.formalProvince = this.state.formalProvince;
        MainData.screenSM009.formalDistrict = this.state.formalDistrict;
        MainData.screenSM009.formalSubDistrict = this.state.formalSubDistrict;
        MainData.screenSM009.formalAddress = this.state.formalAddress;
        MainData.screenSM009.formalVillage = this.state.formalVillage;
        MainData.screenSM009.formalMoo = this.state.formalMoo;
        MainData.screenSM009.formalSoi = this.state.formalSoi;
        MainData.screenSM009.formalStreet = this.state.formalStreet;
        MainData.screenSM009.formalZipCode = this.state.formalZipCode;
        MainData.screenSM009.isDocToNowAddress = this.state.isDocToNowAddress;
        MainData.screenSM009Preview.isDocToNowAddress = this.state.isDocToNowAddress;
        MainData.screenSM009Preview.insuranceTrack = this.state.insuranceTrack;
        MainData.screenSM009Preview.insuranceTrack1 = this.state.insuranceTrack1;
        MainData.screenSM009.insuranceTrack = this.state.insuranceTrack;
        MainData.screenSM009.insuranceTrack1 = this.state.insuranceTrack1;
    this.props.loadController(true)
    this.AddOrUpdateCustomerAddress(newProvince.provinceCode, newDistrict.districtCode, newSubDistrictCode.subDistrictCode, newFProvinceCode, newFDistrictCode, newFSubDistrictCodeCode);
  }

  AddOrUpdateCustomerAddress = (provinceCode, districtCode, subDistrictCode, newFProvinceCode, newFDistrictCode, newFSubDistrictCodeCode) => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "currentAddressType": "Home",
      "currentAddressBuilding": this.state.village,
      "currentAddressNo": this.state.address,
      "currentAddressMoo": this.state.moo,
      "currentAddressSoi": this.state.soi,
      "currentAddressRoad": this.state.street,
      "currentAddressProvinceCode": provinceCode,
      "currentAddressDistrictCode": districtCode,
      "currentAddressSubdistrictCode": subDistrictCode,
      "currentAddressZipcode": this.state.zipCode,
      "addressType": this.state.isSameAddress===false? "No" : "Home",
      "addressBuilding": this.state.isSameAddress===false?this.state.formalVillage:this.state.village,
      "addressNo": this.state.isSameAddress===false?this.state.formalAddress:this.state.address,
      "addressMoo": this.state.isSameAddress===false?this.state.formalMoo:this.state.moo,
      "addressSoi": this.state.isSameAddress===false?this.state.formalSoi:this.state.soi,
      "addressRoad": this.state.isSameAddress===false?this.state.formalStreet:this.state.street,
      "addressProvinceCode": this.state.isSameAddress===false?newFProvinceCode:provinceCode,
      "addressDistrictCode": this.state.isSameAddress===false?newFDistrictCode:districtCode,
      "addressSubdistrictCode": this.state.isSameAddress===false?newFSubDistrictCodeCode:subDistrictCode,
      "addressZipcode": this.state.isSameAddress===false?this.state.formalZipCode:this.state.zipCode,
      "contactAddress": this.state.isDocToNowAddress===false?"Home":"Curr",
      "insuranceTrack": this.state.insuranceTrack,
      "ReceiveInsuranceChannelOther": this.state.insuranceTrack1,
      "submenuCode": this.state.screen,
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerAddress', data, {
      headers: headers
    })
    .then(res => {
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
        this.props.setPage(this.props.next)
        // this.props.setPage(!MainData.screenSM012?this.props.next:MainData.screenSM012)
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  validateBadRequest = (fieldNameList) => {
    let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
    this.props.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
    this.setState({validation: true})
    // if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressType".toLowerCase() )){
    //   this.setState({email: ""})
    // }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressBuilding".toLowerCase() )){
      this.setState({village: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressNo".toLowerCase() )){
      this.setState({address: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressMoo".toLowerCase() )){
      this.setState({moo: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressSoi".toLowerCase() )){
      this.setState({soi: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressRoad".toLowerCase() )){
      this.setState({titleName: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressProvinceCode".toLowerCase() )){
      this.setState({province: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressDistrictCode".toLowerCase() )){
      this.setState({district: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "currentAddressSubdistrictCode".toLowerCase() )){
      this.setState({subDistrict: ""});
    }
    if(this.state.isSameAddress===false){
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressBuilding".toLowerCase() )){
        this.setState({formalVillage: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressNo".toLowerCase() )){
        this.setState({formalAddress: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressMoo".toLowerCase() )){
        this.setState({formalMoo: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressSoi".toLowerCase() )){
        this.setState({formalSoi: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressRoad".toLowerCase() )){
        this.setState({formalStreet: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressProvinceCode".toLowerCase() )){
        this.setState({formalProvince: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressDistrictCode".toLowerCase() )){
        this.setState({formalDistrict: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressSubdistrictCode".toLowerCase() )){
        this.setState({formalSubDistrict: ""});
      }
      if(fieldNameList.find(data => data.field.toLowerCase() === "addressZipcode".toLowerCase() )){
        this.setState({formalZipCode: ""});
      }
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "insuranceTrack".toLowerCase() )){
      this.setState({insuranceTrack: ""});
    }
    if(fieldNameList.find(data => data.field.toLowerCase() === "ReceiveInsuranceChannelOther".toLowerCase() )){
      this.setState({ReceiveInsuranceChannelOther: ""});
    }
    // if(fieldNameList.find(data => data.field.toLowerCase() === "citizenNoExpiredDate".toLowerCase() )){
    //   this.setState({idCardExpDateNone: ""})
    // }
  }

  handleInputChange = (name, val) => {
    switch(name){
      case "province": this.setState({province: val,zipCode:val===MainData.screenSM009Preview.province?MainData.screenSM009Preview.zipCode:""}); break;
      case "district": this.setState({district: val}); break;
      case "subDistrict": this.setState({subDistrict: val, zipCode: MainData.initialData.province.find(province=>province.provinceTitle===this.state.province).district.find(district=>district.districtTitle===this.state.district).subDistrict.find(subDistrict=>subDistrict.subDistrictTitle===val).zipCode}); break;
      case "address": if(val[0] === " "){}else{this.setState({address: val});} break;
      case "village": if(val[0] === " "){}else{this.setState({village: val});} break;
      case "moo": if(val[0] === " "){}else{this.setState({moo: val});} break;
      case "soi": if(val[0] === " "){}else{this.setState({soi: val});} break;
      case "street": if(val[0] === " "){}else{this.setState({street: val});} break;
      case "zipCode": 
          if(val.length<=5){
            let checkWord = /^[0-9]+$/.test(val);
            this.setState({zipCode: !checkWord&&val?this.state.zipCode:val,subDistrict: ''}); 
          }
            break;
      case "isSameAddress": this.setState({isSameAddress: val==="true"?true:false}); break;
      case "formalProvince": this.setState({formalProvince: val,formalZipCode:val===MainData.screenSM009Preview.formalProvince?MainData.screenSM009Preview.formalZipCode:""}); break;
      case "formalDistrict": this.setState({formalDistrict: val}); break;
      case "formalSubDistrict": this.setState({formalSubDistrict: val, formalZipCode: MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince).district.find(district=>district.districtTitle===this.state.formalDistrict).subDistrict.find(subDistrict=>subDistrict.subDistrictTitle===val).zipCode}); break;
      case "formalAddress": if(val[0] === " "){}else{this.setState({formalAddress: val});} break;
      case "formalVillage": if(val[0] === " "){}else{this.setState({formalVillage: val});} break;
      case "formalMoo": if(val[0] === " "){}else{this.setState({formalMoo: val});} break;
      case "formalSoi": if(val[0] === " "){}else{this.setState({formalSoi: val});} break;
      case "formalStreet": if(val[0] === " "){}else{this.setState({formalStreet: val});} break;
      case "formalZipCode": 
            if(val.length<=5){
              let checkWord2 = /^[0-9]+$/.test(val);
              this.setState({formalZipCode: !checkWord2&&val?this.state.formalZipCode:val,formalSubDistrict: ''}); 
            }
            break;
      case "insuranceTrack": this.setState({insuranceTrack: val}); break;
      case "insuranceTrack1": this.setState({insuranceTrack1: val}); break;
      case "isDocToNowAddress": this.setState({isDocToNowAddress: val==="true"?true:false}); break;
      default: break;
    }
  }

  getReceiveInsuranceQuestion = () => {
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "lang" : "TH"
    };
    axios.post(MainData.serviceURL+'/Sales/GetReceiveInsuranceQuestion', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        this.props.loadController(false)
        this.setState({receiveInsuranceQuestion: res.data.data.receiveInsuranceQuestion})
        MainData.screenSM009Preview.receiveInsuranceQuestion = res.data.data.receiveInsuranceQuestion;
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
    });
}

  render(){
    return (
      <div className="container-fulid">
        <Row>

          <Col id="titelForScreen" xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{this.props.mainMenuName}</span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          <Col xs={12} className="pt-2 pb-3">
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0046")}</span>
            <form noValidate autoComplete="off" className="pt-2">

              <FormControl className="w-100" error={this.state.validation&&!this.state.province?true:false}>
                <InputLabel id="provinceLabel">{MainFunc.getLabelDtos("L0047")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="provinceLabel" name="province" className={!this.state.province?"text-placeholder":null} value={!this.state.province?"none":this.state.province} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0048")}</MenuItem>
                  {MainData.initialData.province.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.provinceTitle}>{item.provinceTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.district?true:false}>
                <InputLabel id="districtLabel">{MainFunc.getLabelDtos("L0049")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="districtLabel" name="district" className={!this.state.district?"text-placeholder":null} value={!this.state.district?"none":this.state.district} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0050")}</MenuItem>
                  {!MainData.initialData.province.find(province=>province.provinceTitle===this.state.province)?
                    <MenuItem value={"none"}>{MainFunc.getLabelDtos("L0050")}</MenuItem>:
                  MainData.initialData.province.find(province=>province.provinceTitle===this.state.province).district.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.districtTitle}>{item.districtTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.subDistrict?true:false}>
                <InputLabel id="subDistrictLabel">{MainFunc.getLabelDtos("L0051")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="subDistrictLabel" name="subDistrict" className={!this.state.subDistrict?"text-placeholder":null} value={!this.state.subDistrict?"none":this.state.subDistrict} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0052")}</MenuItem>
                  {!MainData.initialData.province.find(province=>province.provinceTitle===this.state.province)?
                    <MenuItem value={"none"}>{MainFunc.getLabelDtos("L0052")}</MenuItem>:
                  !MainData.initialData.province.find(province=>province.provinceTitle===this.state.province).district.find(district=>district.districtTitle===this.state.district)?
                    <MenuItem value={"none"}>{MainFunc.getLabelDtos("L0052")}</MenuItem>:
                  MainData.initialData.province.find(province=>province.provinceTitle===this.state.province).district.find(district=>district.districtTitle===this.state.district).subDistrict.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.subDistrictTitle}>{item.subDistrictTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <TextField inputProps={{autocomplete: 'off',maxLength: 30}} name="address" label={<span>{MainFunc.getLabelDtos("L0053")}<span className="text-danger">*</span></span>} placeholder={MainFunc.getLabelDtos("L0054")} value={this.state.address} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&!this.state.address?true:false} />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="village" label={MainFunc.getLabelDtos("L0055")} placeholder={MainFunc.getLabelDtos("L0056")} value={this.state.village} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="moo" label={MainFunc.getLabelDtos("L0057")} placeholder={MainFunc.getLabelDtos("L0058")} value={this.state.moo} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="soi" label={MainFunc.getLabelDtos("L0059")} placeholder={MainFunc.getLabelDtos("L0060")} value={this.state.soi} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="street" label={MainFunc.getLabelDtos("L0061")} placeholder={MainFunc.getLabelDtos("L0062")} value={this.state.street} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="zipCode" label={<span>{MainFunc.getLabelDtos("L0063")}<span className="text-danger">*</span></span>} placeholder={MainFunc.getLabelDtos("L0064")} value={this.state.zipCode} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&!this.state.zipCode?true:false} />
            
              <FormControl component="fieldset" className="w-100">
                <FormLabel component="label" className="pt-3 text-dark">{MainFunc.getLabelDtos("L0065")}<span className="text-danger">*</span></FormLabel>
                <RadioGroup aria-label="" name="isSameAddress" onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <FormControlLabel value={true} label={MainFunc.getLabelDtos("L0066")} checked={this.state.isSameAddress} control={<Radio icon={<ImRadioUnchecked size={18} />} checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} />} className="mb-0" />
                  <FormControlLabel value={false} label={MainFunc.getLabelDtos("L0067")} checked={!this.state.isSameAddress} control={<Radio icon={<ImRadioUnchecked size={18} />} checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} />} className="mb-0" />
                </RadioGroup>
              </FormControl>

              {!this.state.isSameAddress ?<>
              <FormControl className="w-100 mt-2" error={this.state.validation&&!this.state.formalProvince?true:false}>
                <InputLabel id="formalProvinceLabel">{MainFunc.getLabelDtos("L0047")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="formalProvinceLabel" name="formalProvince" className={!this.state.formalProvince?"text-placeholder":null} value={!this.state.formalProvince?"none":this.state.formalProvince} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0048")}</MenuItem>
                  {MainData.initialData.province.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.provinceTitle}>{item.provinceTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.formalDistrict?true:false}>
                <InputLabel id="formalDistrictLabel">{MainFunc.getLabelDtos("L0049")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="formalDistrictLabel" name="formalDistrict" className={!this.state.formalDistrict?"text-placeholder":null} value={!this.state.formalDistrict?"none":this.state.formalDistrict} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0050")}</MenuItem>
                  {!MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince)?
                    <MenuItem value={"none"}>{MainFunc.getLabelDtos("L0050")}</MenuItem>:
                  MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince).district.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.districtTitle}>{item.districtTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.formalSubDistrict?true:false}>
                <InputLabel id="formalSubDistrictLabel">{MainFunc.getLabelDtos("L0051")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="formalSubDistrictLabel" name="formalSubDistrict" className={!this.state.formalSubDistrict?"text-placeholder":null} value={!this.state.formalSubDistrict?"none":this.state.formalSubDistrict} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <MenuItem value={"none"} hidden>{MainFunc.getLabelDtos("L0052")}</MenuItem>
                  {!MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince)?
                    <MenuItem value={"none"}>{MainFunc.getLabelDtos("L0052")}</MenuItem>:
                  !MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince).district.find(district=>district.districtTitle===this.state.formalDistrict)?
                    <MenuItem value={"none"}>{MainFunc.getLabelDtos("L0052")}</MenuItem>:
                  MainData.initialData.province.find(province=>province.provinceTitle===this.state.formalProvince).district.find(district=>district.districtTitle===this.state.formalDistrict).subDistrict.map((item, key)=>{
                    return(
                      <MenuItem key={key} value={item.subDistrictTitle}>{item.subDistrictTitle}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <TextField inputProps={{autocomplete: 'off',maxLength: 30}} name="formalAddress" label={<span>{MainFunc.getLabelDtos("L0053")}<span className="text-danger">*</span></span>} placeholder={MainFunc.getLabelDtos("L0054")} value={this.state.formalAddress} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&!this.state.formalAddress?true:false} />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="formalVillage" label={MainFunc.getLabelDtos("L0055")} placeholder={MainFunc.getLabelDtos("L0056")} value={this.state.formalVillage} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="formalMoo" label={MainFunc.getLabelDtos("L0057")} placeholder={MainFunc.getLabelDtos("L0058")} value={this.state.formalMoo} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="formalSoi" label={MainFunc.getLabelDtos("L0059")} placeholder={MainFunc.getLabelDtos("L0060")} value={this.state.formalSoi} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="formalStreet" label={MainFunc.getLabelDtos("L0061")} placeholder={MainFunc.getLabelDtos("L0062")} value={this.state.formalStreet} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" />
              <TextField inputProps={{autocomplete: 'off',maxLength: 50}} name="formalZipCode" label={<span>{MainFunc.getLabelDtos("L0063")}<span className="text-danger">*</span></span>} placeholder={MainFunc.getLabelDtos("L0064")} value={this.state.formalZipCode} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} InputLabelProps={{ shrink: true }} fullWidth className="mt-3" error={this.state.validation&&!this.state.formalZipCode?true:false} />
              </>:null}

              <FormControl component="fieldset" className="w-100">
                <FormLabel component="label" className="pt-3 text-dark">{MainFunc.getLabelDtos("L0068")}<span className="text-danger">*</span></FormLabel>
                <RadioGroup aria-label="" name="isDocToNowAddress" onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                  <FormControlLabel value={true} label={MainFunc.getLabelDtos("L0066")} checked={this.state.isDocToNowAddress} control={<Radio icon={<ImRadioUnchecked size={18} />} checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} />} className="mb-0" />
                  <FormControlLabel value={false} label={MainFunc.getLabelDtos("L0065")} checked={!this.state.isDocToNowAddress} control={<Radio icon={<ImRadioUnchecked size={18} />} checkedIcon={<ImRadioChecked size={18} className="csMainColor" />} />} className="mb-0" />
                </RadioGroup>
              </FormControl>

              {this.state.receiveInsuranceQuestion.map((item, key)=>{
                const receiveInsuranceAnswer = item.receiveInsuranceAnswer.map((ansItem, ansKey)=>{
                  return(
                    <MenuItem value={ansItem.receiveInsuranceAnswerApiCode.trim()}>{ansItem.receiveInsuranceAnswerTitle}</MenuItem>
                    )
                })
                  return(
                    <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.insuranceTrack?true:false}>
                    <InputLabel id="insuranceTrackLabel">{item.receiveInsuranceQuestionTitle}<span className="text-danger">*</span></InputLabel>
                    <Select labelId="insuranceTrackLabel" name={key==0?"insuranceTrack":"insuranceTrack"+key} className={!this.state.insuranceTrack?"text-placeholder":null} value={key==0?this.state.insuranceTrack:this.state.insuranceTrack1} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                      {receiveInsuranceAnswer}
                    </Select>
                  </FormControl>
                  )
                  }
                  )}
              {/* <FormControl className="w-100 mt-3" error={this.state.validation&&!this.state.insuranceTrack?true:false}>
                <InputLabel id="insuranceTrackLabel">{MainFunc.getLabelDtos("L0122")}<span className="text-danger">*</span></InputLabel>
                <Select labelId="insuranceTrackLabel" name={"insuranceTrack"} className={!this.state.insuranceTrack?"text-placeholder":null} value={!this.state.insuranceTrack?"email":this.state.insuranceTrack} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} >
                        <MenuItem name={this.state.soi} value={"email"}>{MainFunc.getLabelDtos("L0123")}</MenuItem>
                        <MenuItem name={this.state.moo} value={"post"}>{MainFunc.getLabelDtos("L0124")}</MenuItem>
                </Select>
              </FormControl> */}
            </form>
          </Col>

          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-6_Click_BtnBack":"STEP2-5_Click_BtnBack"}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => {this.processBack()}}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-6_Click_BtnNext":"STEP2-5_Click_BtnNext"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }

        </Row>
      </div>
    )
  }
}

export default SM009;