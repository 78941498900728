var isPRD = false;
var scriptUATDefaultOld = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-T6HS6CQ');`;
var noscriptUATDefaultOld = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T6HS6CQ"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

var scriptUATDefault = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-UXXXXXX');`;
var noscriptUATDefault = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-UXXXXXX"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

var scriptUATLineBK = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-ULLLLLL');`;
var noscriptUATLineBK = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-ULLLLLL"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

var scriptPRDDefaultOld = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NQR5HDD');`;
var noscriptPRDDefaultOld = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NQR5HDD"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

var scriptPRDDefault = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-W3QKLWV');`;
var noscriptPRDDefault = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-W3QKLWV"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

var scriptPRDLineBK = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WFR2TJ2');`;
var noscriptPRDLineBK = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WFR2TJ2"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

const MainDataGTM = {
    get scriptDefaultOld() {
        return isPRD?scriptPRDDefaultOld:scriptUATDefaultOld;
    },
    get noscriptDefaultOld() {
        return isPRD?noscriptPRDDefaultOld:noscriptUATDefaultOld;
    },
    get scriptDefault() {
        return isPRD?scriptPRDDefault:scriptUATDefault;
    },
    get noscriptDefault() {
        return isPRD?noscriptPRDDefault:noscriptUATDefault;
    },
    get scriptLineBK() {
        return isPRD?scriptPRDLineBK:scriptUATLineBK;
    },
    get noscriptLineBK() {
        return isPRD?noscriptPRDLineBK:noscriptUATLineBK;
    },
}

export default MainDataGTM;