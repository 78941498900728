import axios from "axios";
import { notify } from 'react-noti';
import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import moment from "moment";
import localization from "moment/locale/th";

moment().locale("th", localization).add(543, "year").format('LLL')

class SM003 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM003",
      healthQuestion: [],
      userAnswer: !MainData.screenSM003.userHealthAns?[]:MainData.screenSM003.userHealthAns
    };
  }

  componentDidMount(){
    this.props.loadController(true)
    this.getData()
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }

  getData = () => {
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": MainData.initialData.productId
    };
    axios.post(MainData.serviceURL+'/Sales/GetQuestion', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      let data = res.data.data;
      if(!data){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
        this.props.setPage(this.props.prev)
        return;
      }
      let userAnswer = [];

      if(MainData.screenSM003.userHealthAns.length !== data.healthQuestion.length){
        for(let obj of data.healthQuestion){
          let healthAnswer = '';
          for(let objSub of obj.healthAnswer){
            if(objSub.isReject === "N"){
              healthAnswer = objSub.healthAnswerCode
            }
          }
          userAnswer.push({ questionID: obj.healthQuestionCode, ansCode: healthAnswer, ans: "N", condition: [] })
        }
      }else{
        userAnswer = MainData.screenSM003.userHealthAns;
      }
      this.setState({healthQuestion: data.healthQuestion, userAnswer: userAnswer}, this.props.loadController(false))
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      this.props.setPage(this.props.prev)
    });
  }

  answerClick = (questionID, ansCode, ansIsReject) => {
    let updateUserAnswer = this.state.userAnswer;
    let index = updateUserAnswer.findIndex(x=>x.questionID === questionID);
    updateUserAnswer[index].ansCode = ansCode;
    updateUserAnswer[index].ans = ansIsReject;
    updateUserAnswer[index].condition = [];

    let findHealthQuestion = this.state.healthQuestion.find(x=>x.healthQuestionCode === questionID);
    let findAnswer = findHealthQuestion.healthAnswer.find(ans=>ans.healthAnswerCode === ansCode);
    for(let obj of findAnswer.conditions){
      updateUserAnswer[index].condition.push({ questionID: obj.healthQuestionCode, ansCode: null, ans: null })
    }
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตอบคำถามสุขภาพ', this.state.userAnswer, updateUserAnswer);
    this.setState({userAnswer: updateUserAnswer})
  }

  conditionAnswerClick = (questionID, conditionQuestionID, conditionAnsCode, conditionAnsIsReject) => {
    let updateUserAnswer = this.state.userAnswer;
    let index = updateUserAnswer.findIndex(x=>x.questionID === questionID);
    if(updateUserAnswer[index].condition.find(x=>x.questionID === conditionQuestionID)){
      let secIndex = updateUserAnswer[index].condition.findIndex(x=>x.questionID === conditionQuestionID);
      updateUserAnswer[index].condition[secIndex].ansCode = conditionAnsCode;
      updateUserAnswer[index].condition[secIndex].ans = conditionAnsIsReject;
    }else{
      updateUserAnswer[index].condition.push({ questionID: conditionQuestionID, ansCode: conditionAnsCode, ans: conditionAnsIsReject })
    }
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตอบคำถามสุขภาพ', this.state.userAnswer, updateUserAnswer);
    this.setState({userAnswer: updateUserAnswer})
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    this.props.setPage(this.props.prev)
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    let ans = [];
      for (let [index, obj] of this.state.userAnswer.entries()) {
        ans.push({ questionCode: obj.questionID, answerCode: obj.ansCode, condition:[] })
        for(let secObj of obj.condition){
          ans[index].condition.push({ questionCode: secObj.questionID, answerCode: secObj.ansCode })
        }
      }

    let userans = {}
    ans.forEach((item,index)=>{
      if(item.questionCode){
        userans[`questionCode_${index+1}`] = item.questionCode.toString()
      }
      if(item.answerCode){
        userans[`answerCode_${index+1}`] = item.answerCode.toString()
      }
      item.condition.forEach((subItem,subIndex)=>{
        if(subItem.questionCode){
          userans[`questionCode_${index+1}_${subIndex+1}`] = subItem.questionCode.toString()
        }
        if(subItem.answerCode){
          userans[`answerValue_${index+1}_${subIndex+1}`] = subItem.answerCode.toString()
        }
      });
    })
    if(this.state.userAnswer.find(x=>x.ans===null)){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG026');
      this.props.errorAlert(true, !errMsg?"กรุณาตอบคำถามให้ครบทุกข้อ":errMsg.msgDescription, '');
    }else if(this.state.userAnswer.find(x=>x.condition.find(con=>con.ans===null) )){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG026');
      this.props.errorAlert(true, !errMsg?"กรุณาตอบคำถามให้ครบทุกข้อ":errMsg.msgDescription, '');
    }else{
      let healthQuestions = [];
      for (let [index, obj] of this.state.userAnswer.entries()) {
        healthQuestions.push({ questionCode: obj.questionID, answerCode: obj.ansCode, condition:[] })
        for(let secObj of obj.condition){
          healthQuestions[index].condition.push({ questionCode: secObj.questionID, answerCode: secObj.ansCode })
        }
      }

      let dataPDPANewValue = {};
        healthQuestions.forEach((item,index)=>{
          if(item.questionCode){
            dataPDPANewValue[`questionCode_${index+1}`] = item.questionCode.toString()
          }
          if(item.answerCode){
            dataPDPANewValue[`answerCode_${index+1}`] = item.answerCode.toString()
          }
          item.condition.forEach((subItem,subIndex)=>{
            if(subItem.questionCode){
              dataPDPANewValue[`questionCode_${index+1}_${subIndex+1}`] = subItem.questionCode.toString()
            }
            if(subItem.answerCode){
              dataPDPANewValue[`answerValue_${index+1}_${subIndex+1}`] = subItem.answerCode.toString()
            }
          });
        })
      if(this.state.userAnswer.find(x=>x.ans==="Y" && x.condition.length<1 )){
        this.props.loadController(true);
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        if(MainData.screenSM003.UserAnsErr || MainData.screenSM003.dataPDPANewValue){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM003.UserAnsErr?MainData.screenSM003.UserAnsErr:MainData.screenSM003.dataPDPANewValue, userans, 'Fail', errMsg.msgDescription)
        }else{
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, userans, 'Fail', errMsg.msgDescription)
        }
        MainData.screenSM003.UserAnsErr = userans;
        if(sessionStorage.getItem('_cookie') === "true"){ //cookie
          await this.addOrUpdateCustomerQuestionError(healthQuestions);
        }
        else{ //ไม่มี cookie
          this.props.loadController(false);
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}STEP2`,"Click",`${MainData.initialData.codeTracking}STEP2_Click_BtnCloseModalReject`);
        }
        return;
      }else if(this.state.userAnswer.find(x=>x.condition.find(con=>con.ans==="Y") )){
        this.props.loadController(true);
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        if(MainData.screenSM003.UserAnsErr || MainData.screenSM003.dataPDPANewValue){
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM003.UserAnsErr?MainData.screenSM003.UserAnsErr:MainData.screenSM003.dataPDPANewValue, userans, 'Fail', errMsg.msgDescription)
        }else{
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, userans, 'Fail', errMsg.msgDescription)
        }
        MainData.screenSM003.UserAnsErr = userans;
        if(sessionStorage.getItem('_cookie') === "true"){
          await this.addOrUpdateCustomerQuestionError(healthQuestions);
        }
        else{
          this.props.loadController(false);
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}STEP2`,"Click",`${MainData.initialData.codeTracking}STEP2_Click_BtnCloseModalReject`);
        }
        return;
      }
      if(MainData.screenSM003.UserAnsErr){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM003.UserAnsErr, dataPDPANewValue, 'Success', null)
      }else if(MainData.screenSM003.healthQuestions && MainData.screenSM003.userHealthAns && MainData.screenSM013.healthQuestions && MainData.screenSM003.dataPDPANewValue){
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM003.dataPDPANewValue, dataPDPANewValue, 'Success', null)
      }else{
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, dataPDPANewValue, 'Success', null)
      }
      MainData.screenSM003.UserAnsErr = null;
      MainData.screenSM013.healthQuestions = healthQuestions;
      MainData.screenSM003.dataPDPANewValue = dataPDPANewValue;
      MainData.screenSM003.healthQuestions = this.state.healthQuestion;
      MainData.screenSM003.userHealthAns = this.state.userAnswer;
      MainData.screenSM003.screen = this.state.screen;
      
      if(MainData.masPage[3].subMenuCode != "SM004" ){
        this.checkAnswer();
      }
      else{
        if(sessionStorage.getItem('_cookie') === "true"){
         this.props.loadController(true); //เนื่องจากเส้น updateต่างๆช้า เลยต้องใช้ await เพื่อรอให้ทำงานเสร็จถึงจะไปหน้าต่อไป
         await this.addOrUpdateCustomerQuestion(healthQuestions);
        }
        // else if(sessionStorage.getItem('_cookie') === "trueActionSM003"){
        //   this.addOrUpdateCustomerBirthday();
        // }
        else{
        this.props.setPage(this.props.next)
        }
      }
      
    }
  }

  addOrUpdateCustomerQuestionError = async (healthQuestions) => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": MainData.screenSM005.cusId
    }
    const data = {
      "healthQuestions": healthQuestions,
      "submenuCode": this.state.screen,
      "channelCode": MainData.initialData.channelCode,
      "transUrl": window.location.href,
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerQuestion', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === false){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}STEP2`,"Click",`${MainData.initialData.codeTracking}STEP2_Click_BtnCloseModalReject`);
      }
      if(res.data.success === true){
        this.props.setPage(this.props.next)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }
  
  addOrUpdateCustomerQuestion = async  (healthQuestions) => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": MainData.screenSM005.cusId
    }
    const data = {
      "healthQuestions": healthQuestions,
      "submenuCode": this.state.screen,
      "channelCode": MainData.initialData.channelCode,
      "transUrl": window.location.href,
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerQuestion', data, {
      headers: headers
    })
    .then(async res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success !== true){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription, ' ')
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
      }
      else{
        await this.props.setPage(this.props.next)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  validateBadRequest = (fieldNameList) => {
    if(fieldNameList.find(data => data.field === "healthQuestions") 
    ){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
      this.props.errorAlert(true, !errMsg?'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง':errMsg.msgDescription, ' ');
      this.setState({
        userAnswer: []
      })
    }
  }

  checkAnswer = () => {
    
    let healthQuestions = [];
    if(MainData.screenSM003.userHealthAns.length>0){
      for (let [index, obj] of MainData.screenSM003.userHealthAns.entries()) {
        healthQuestions.push({ questionCode: obj.questionID, answerCode: obj.ansCode, condition:[] })
        for(let secObj of obj.condition){
          healthQuestions[index].condition.push({ questionCode: secObj.questionID, answerCode: secObj.ansCode })
        }
      }
    }
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" 
    ,"authid": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId}
    const data = {
      "grantee": "SELF",
      "healthQuestions": healthQuestions,
      "productId": parseInt(MainData.initialData.productId),
      "channelCode": MainData.initialData.channelCode,
      "subMenu":this.state.screen
    }
    axios.post(MainData.serviceURL+'/Sales/CheckAnswer', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      let data = res.data.data;
      if(data.totalResult===true){
        if(sessionStorage.getItem('_cookie') === "true"){
           this.addOrUpdateCustomerQuestion(healthQuestions);
        }
        this.props.setPage(this.props.next)
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
    });
  }

  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold" dangerouslySetInnerHTML={{__html: MainFunc.getLabelDtos("L0170")}}></span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>
          <Col xs={12} className="pt-2">
            {this.state.healthQuestion.map((item, key)=>{
              var conditions = item.healthAnswer.map((ansItemcon, ansKeycon)=>{
                let showConQues = this.state.userAnswer.find(x=>x.ansCode === ansItemcon.healthAnswerCode);
                if(ansItemcon.conditions){
                  if((ansItemcon.conditions.length > 0)&&(showConQues)){
                    return(
                      ansItemcon.conditions.map((condiItem, condiKey)=>{
                        return(
                          <Col xs={12} key={condiKey} className="pt-3">
                            <Row>
                              <Col xs={12}><li dangerouslySetInnerHTML={{__html: condiItem.healthQuestionTitle}}></li></Col>
                              {
                                condiItem.healthAnswer.map((condiAnsItem, condiAnsKey)=>{
                                  let showConQuesActive = showConQues.condition.find(x=>x.ansCode === condiAnsItem.healthAnswerCode);
                                  return(
                                    <Col xs={condiItem.healthAnswer.length===2?6:12} className={"pt-2"} key={condiAnsKey}>
                                      <Button 
                                        id={item.healthQuestionCode == "HQ001" && ansItemcon.healthAnswerCode == "HA001" && condiItem.healthQuestionCode == "HQ004" && condiAnsItem.healthAnswerCode == "HA008" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnWasRejected`
                                            :item.healthQuestionCode == "HQ001" && ansItemcon.healthAnswerCode == "HA001" && condiItem.healthQuestionCode == "HQ004" && condiAnsItem.healthAnswerCode == "HA009" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnNeverReject`
                                            :""
                                        }
                                        variant="" 
                                        className={showConQuesActive?"ansBtnCheck w-100":"ansBtnY w-100"} 
                                        onClick={()=>this.conditionAnswerClick(item.healthQuestionCode, condiItem.healthQuestionCode, condiAnsItem.healthAnswerCode, condiAnsItem.isReject)}
                                      >
                                          {condiAnsItem.healthAnswerTitle}
                                      </Button>
                                    </Col>
                                  )
                                })
                              }
                            </Row>
                          </Col>
                        )
                      })
                    )
                  }else{ return null }
                }else{ return null }
              })
              var ans = item.healthAnswer.map((ansItem, ansKey)=>{
                let isAnsCheck = this.state.userAnswer.find(x=>x.ansCode === ansItem.healthAnswerCode);
                return(
                  <Col xs={item.healthAnswer.length===2?6:12} className={"pt-2"} key={ansKey}>
                    <Button 
                      id={item.healthQuestionCode == "HQ001" && ansItem.healthAnswerCode == "HA001" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnWasRegistered`
                          :item.healthQuestionCode == "HQ001" && ansItem.healthAnswerCode == "HA002" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnNeverRegister`
                          :item.healthQuestionCode == "HQ002" && ansItem.healthAnswerCode == "HA003" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnWasDiagnostic`
                          :item.healthQuestionCode == "HQ002" && ansItem.healthAnswerCode == "HA004" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnNeverDiagnostic`
                          :item.healthQuestionCode == "HQ003" && ansItem.healthAnswerCode == "HA005" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnCuring`
                          :item.healthQuestionCode == "HQ003" && ansItem.healthAnswerCode == "HA006" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnCured`
                          :item.healthQuestionCode == "HQ003" && ansItem.healthAnswerCode == "HA007" ?`${MainData.initialData.codeTracking}STEP2_Click_BtnNeverCured`
                          :""
                      }
                      variant="" 
                      className={isAnsCheck?"ansBtnCheck w-100":"ansBtnY w-100"} 
                      onClick={()=>this.answerClick(item.healthQuestionCode, ansItem.healthAnswerCode, ansItem.isReject)}
                    >
                        {ansItem.healthAnswerTitle}
                    </Button>
                  </Col>
                )
              })
              return(
                <Row key={key} className="pb-3">
                  {/* <Col xs={12}>{key+1}. {item.healthQuestionTitle.split('\n').map((itemsplit, keymsplit) => {return <React.Fragment key={keymsplit}>{itemsplit}<br/></React.Fragment>})}</Col> */}
                  <Col xs={12}> <div dangerouslySetInnerHTML={{__html: (key+1)+'. '+item.healthQuestionTitle}}></div></Col>
                  {ans}
                  {conditions}
                </Row>
              )
            })}
          </Col>
          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}STEP2_Click_BtnBack`} variant="" className="subBtnRadius font-weight-bold" onClick={() => { this.processBack() }}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}STEP2_Click_BtnNext`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }
        </Row>
      </div>
    )
  }
}

export default SM003;