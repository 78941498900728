import React, { Component, Fragment } from "react";
import {Row, Col, Button, Card, Modal, Image} from "react-bootstrap";
import FilePreviewer from "react-file-previewer";
import IcoModalClose from '../../images/ico_modal_close.svg';
import stylesModal from "../../styles/Modal.module.scss";
import IcoFile from '../../images/ico_file.png';
import axios from "axios";

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

class SM012 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM012",
      confirmModal: false,
      screenSM001: MainData.screenSM001,
      screenSM002: MainData.screenSM002,
      screenSM003: MainData.screenSM003,
      screenSM004: MainData.screenSM004,
      screenSM005: MainData.screenSM005,
      screenSM006: MainData.screenSM006,
      screenSM007: MainData.screenSM007,
      screenSM008: MainData.screenSM008,
      screenSM009: MainData.screenSM009,
      screenSM009Preview: MainData.screenSM009Preview,
      screenSM010: MainData.screenSM010,
      screenSM014: MainData.screenSM014,
      showModal: false,
      currentPreviewFile: null,
      mimeType:"",
      fatcaRes: [],
      crsRes: [],
      i:"",
    };
  }

  componentDidMount(){
    MainData.screenSM012 = null;
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
    if(MainData.screenSM004.fatcaAnsForservice){
      this.getFatcaAwns();
    }
    if(MainData.screenSM004.crsAnsForservice && MainData.screenSM004.crsAnsForservice.length > 0){
      this.getCRSAwns();
    }
  }

  gotoEditingPage = (screenCode) => {
    let editingPage = MainData.masPage.find(x=>x.subMenuCode===screenCode);
    if(!editingPage){
      // err
    }else{
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', screenCode==='SM008'?'แก้ไขข้อมูลผู้ขอเอาประกันภัย':screenCode==='SM009'?'แก้ไขข้อมูลที่อยู่':screenCode==='SM010'?'แก้ไขข้อมูลผู้รับผลประโยชน์':screenCode==='SM014'?'แก้ไขข้อมูลอัปโหลด':'x', {}, { menuCode: screenCode });
      MainData.screenSM012 = this.props.seq;
      this.props.setPage(editingPage.seq)
    }
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    if(MainData.screenSM002.insureSelfie === false && MainData.screenSM002.insureRequiredIdentification === false){
      this.props.setPage(parseInt(this.props.prev)-1)
    }
    else{
      this.props.setPage(this.props.prev);
    }
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    this.addOrUpdateCustomerSubmenu()
  }

  addOrUpdateCustomerSubmenu = () => {
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "submenuCode": this.state.screen,
    };
    axios.post(MainData.serviceURL+'/Customer/AddCustomerSubmenuCode', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        this.props.setPage(this.props.next)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',"AddCustomerSubmenuCode"), ' ')
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  getFatcaAwns = () => {
      this.props.loadController(true)
      const headers = { 
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": 
      "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
      }
      const data = {
        "questionCode": MainData.screenSM004.fatcaAnsForservice[0].questionCode,
        "answerCode": MainData.screenSM004.fatcaAnsForservice[0].answerCode,
        "channelCode": MainData.initialData.channelCode,
        "productId": MainData.initialData.productId,
        "lang" : "TH"
      };
      axios.post(MainData.serviceURL+'/Sales/GetFATCAQuestionEApp', data, {
        headers: headers
      })
      .then(res => { 
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        if(res.data.success === true){
          this.props.loadController(false)
          this.setState({fatcaRes: res.data.data.fatcaQuestionEAppInsure})
        }
      })
      .catch(error => {
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      });
  }

  getCRSAwns = () => {
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data ={}
    axios.post(MainData.serviceURL+'/Sales/GetCRSQuestionEApp',data,{
      headers: headers
    })
    .then(res => {
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        this.props.loadController(false)
        this.setState({crsRes: res.data.data.crsQuestionInsure})
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
    });
 }

  render(){
    var runNumber = 0;
    var answer = "";
    var receiveInsuranceAnswerTitle1 = this.state.screenSM009Preview.receiveInsuranceQuestion[0].receiveInsuranceAnswer[0].receiveInsuranceAnswerTitle;
    var receiveInsuranceAnswerTitle2 = this.state.screenSM009Preview.receiveInsuranceQuestion[0].receiveInsuranceAnswer[1].receiveInsuranceAnswerTitle;
    
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{this.props.mainMenuName}</span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          <Col xs={12} className="pt-2">
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0087")}</span>
          </Col>

          <Col xs={12} className={"pt-3 pb-2"}>
            <Card className="p-3 heirBoxShadow mt-1">
              <small className="text--muted">{MainFunc.getLabelDtos("L0089")}</small>
              <span className="csMainColor">{MainData.screenSM002.productName} {MainData.screenSM002.planName}</span>
              <small className="text--muted pt-2">{MainFunc.getLabelDtos("L0009")} ({MainData.screenSM002.paymentName})</small>
              <span className="csMainColor">{MainFunc.numberWithCommas(MainData.screenSM002.cost)} {MainFunc.getLabelDtos("L0104")}</span>
            </Card>
          </Col>

          <Col xs={12} className={"pt-3 pb-2"}>
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0090")} <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-8_Click_BtnEditInfo":"STEP2-8_Click_BtnEditInfo"}`} variant="" className="editInfoBtn" onClick={()=>{this.gotoEditingPage("SM008")}}>{MainFunc.getLabelDtos("L0151")}</Button></span>
            <Card className="p-3 heirBoxShadow mt-1">
              <small className="text--muted">{MainFunc.getLabelDtos("L0031")}</small>
              <span>{this.state.screenSM008.titleName}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0090")}</small>
              <span>{this.state.screenSM008.firstName}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0152")}</small>
              <span>{this.state.screenSM008.lastName}</span>
              {MainData.initialData.productId == 98 && ( 
                <Fragment>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0429")}</small>
              <span>{this.state.screenSM008.titleNameEN}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0435")}</small>
              <span>{this.state.screenSM008.firstNameEN}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0436")}</small>
              <span>{this.state.screenSM008.lastNameEN}</span>
              </Fragment>
              )}
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0639")}</small>
              <span>{MainData.screenSM008.laserId}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0041")}</small>
              <span>{MainData.initialData.occupation.find(x=>x.occupationCode === MainData.screenSM008.job)?.occupationTitle}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0042")}</small>
              <span>{MainData.screenSM008.jobDescription}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0044")}</small>
              <span>{this.state.screenSM008.email}</span>
            </Card>
            <Card className="p-3 heirBoxShadow mt-2">
              <small className="text--muted">{MainFunc.getLabelDtos("L0092")}</small>
              <span>{MainFunc.idcardFormat(this.state.screenSM005.idCard)}</span>
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0028")}</small>
              <span>{MainFunc.phoneFormat(this.state.screenSM006.phoneNumber)}</span>
            </Card>
          </Col>
          <Col xs={12} className={"pt-3 pb-2"}>
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0094")} <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-8_Click_BtnEditAddress":"STEP2-8_Click_BtnEditAddress"}`} variant="" className="editInfoBtn" onClick={()=>{this.gotoEditingPage("SM009")}}>{MainFunc.getLabelDtos("L0151")}</Button></span>
            <Card className="p-3 heirBoxShadow mt-1">
              <small className="text--muted">{MainFunc.getLabelDtos("L0068")}</small>
              <span>{this.state.screenSM009Preview.isDocToNowAddress===true?MainFunc.getLabelDtos("L0066"):MainFunc.getLabelDtos("L0065")}</span>
              <small className="text--muted mt-2">{MainFunc.getLabelDtos("L0046")}</small>
              <span>
                {this.state.screenSM009.address}
                {!this.state.screenSM009.moo?null:" "+MainFunc.getLabelDtos("L0057")+" "+this.state.screenSM009.moo}
                {!this.state.screenSM009.street?null:" "+MainFunc.getLabelDtos("L0061")+this.state.screenSM009.street}
                {!this.state.screenSM009.soi?null:" "+MainFunc.getLabelDtos("L0059")+this.state.screenSM009.soi}
                {!this.state.screenSM009.village?null:" "+MainFunc.getLabelDtos("L0055")+this.state.screenSM009.village}
                {" "+this.state.screenSM009.subDistrict}
                {" "+this.state.screenSM009.district}
                {" "+this.state.screenSM009.province}
                {" "+this.state.screenSM009.zipCode}
              </span>
              <small className="text--muted mt-2">{MainFunc.getLabelDtos("L0065")}</small>
              {this.state.screenSM009Preview.isSameAddress===true?<span>{MainFunc.getLabelDtos("L0066")}</span>:
                <span>
                  {this.state.screenSM009.formalAddress}
                  {!this.state.screenSM009.formalMoo?null:" "+MainFunc.getLabelDtos("L0057")+" "+this.state.screenSM009.formalMoo}
                  {!this.state.screenSM009.formalStreet?null:" "+MainFunc.getLabelDtos("L0061")+this.state.screenSM009.formalStreet}
                  {!this.state.screenSM009.formalSoi?null:" "+MainFunc.getLabelDtos("L0059")+this.state.screenSM009.formalSoi}
                  {!this.state.screenSM009.formalVillage?null:" "+MainFunc.getLabelDtos("L0055")+this.state.screenSM009.formalVillage}
                  {" "+this.state.screenSM009.formalSubDistrict}
                  {" "+this.state.screenSM009.formalDistrict}
                  {" "+this.state.screenSM009.formalProvince}
                  {" "+this.state.screenSM009.formalZipCode}
                </span>
              }
              {this.state.screenSM009Preview.receiveInsuranceQuestion.map((item, key)=>{
                if(key==0){
                  answer = this.state.screenSM009Preview.insuranceTrack
                }else{
                  answer = this.state.screenSM009Preview.insuranceTrack1
                }
                  return(
                      <Fragment>
                        <small className="text--muted mt-3">{item.receiveInsuranceQuestionEApp}</small>
                        <span>{answer==='electronic'?receiveInsuranceAnswerTitle2:receiveInsuranceAnswerTitle1}</span>
                        {answer==='electronic'?<small className="text--muted mt-2">{MainFunc.getLabelDtos("L0125")}</small>:<small>{MainFunc.getLabelDtos("L0126")}</small>}
                      </Fragment>
                  )
                })
              }
              <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0445")}</small>
            </Card>
          </Col>

          <Col xs={12} className={"pt-3"}>
            <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0069")} <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-8_Click_BtnEditBenefit":"STEP2-8_Click_BtnEditBenefit"}`} variant="" className="editInfoBtn" onClick={()=>{this.gotoEditingPage("SM010")}}>{MainFunc.getLabelDtos("L0097")}</Button></span>
          </Col>
          {this.state.screenSM010.isHeire===true?
            <Col xs={12} className={"pt-2 pb-2"}>
              <Card className="p-3 heirBoxShadow mt-1">
                {MainFunc.getLabelDtos("L0072")}
              </Card>
            </Col>
          :
            <>
              {this.state.screenSM010.heir && this.state.screenSM010.heir.map((item, key)=>{
                return(
                  <Col key={key} xs={12} className={"pt-2 pb-2"}>
                    <span className="pt-1 pl-1">{MainFunc.getLabelDtos("L0071")} {key+1}</span>
                    <Card className="p-3 heirBoxShadow mt-1">
                      <small className="text--muted">{MainFunc.getLabelDtos("L0095")}</small>
                      <span>{item.firstName} {item.lastName}</span>
                      <small className="text--muted mt-2">{MainFunc.getLabelDtos("L0084")}</small>
                      <span>{item.relation}</span>
                      {/* <small className="text--muted mt-2">{MainFunc.getLabelDtos("L0096")}</small>
                      <span>{item.percen} %</span> */}
                    </Card>
                  </Col>
                )})
              }
            </>
          }

          {this.state.screenSM002.insureSelfie || this.state.screenSM002.insureRequiredIdentification ? (
            <Col xs={12} className={"pt-3 pb-2"} >
              <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0261") /* อัปโหลดเอกสาร */} <Button variant="" className="editInfoBtn" onClick={()=>{this.gotoEditingPage("SM026")}}>{MainFunc.getLabelDtos("L0151")}</Button></span>
              <Card className="p-3 heirBoxShadow mt-1">
                {this.state.screenSM002.insureRequiredIdentification && this.state.screenSM014.uploadedFilesId_card && (
                  <Fragment>
                  <small className="text--muted">{MainFunc.getLabelDtos("L0264") /* บัตรประชาชน */}</small>
                  <div className="spanLink-blue" onClick={()=>{this.setState({currentPreviewFile:URL.createObjectURL(this.state.screenSM014.uploadedFilesId_card[0] ?.file),mimeType:this.state.screenSM014.uploadedFilesId_card[0] ?.data.includes("application/pdf")?"application/pdf":"",showModal:true})}}><Image src={IcoFile} style={{ height: "30px", width: "30px" }}/><span>{this.state.screenSM014.uploadedFilesId_card[0] ?.file ?.name}</span></div>
                  </Fragment>
                )}
                {this.state.screenSM002.insureSelfie && this.state.screenSM014.uploadedFilesFace_shape && (
                  <Fragment>
                  <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0479") /** รูปหน้าของผู้ขอเอาประกันภัย */}</small>
                <div className="spanLink-blue" onClick={()=>{this.setState({currentPreviewFile:URL.createObjectURL(this.state.screenSM014.uploadedFilesFace_shape[0] ?.file),mimeType:this.state.screenSM014.uploadedFilesFace_shape[0] ?.data.includes("application/pdf")?"application/pdf":"",showModal:true})}}><Image src={IcoFile} style={{ height: "30px", width: "30px" }}/><span>{this.state.screenSM014.uploadedFilesFace_shape[0] ?.file ?.name}</span></div>
                  </Fragment>
                )}
              </Card>
            </Col>
          ) : null } 

          {this.state.screenSM003.healthQuestions?.length? (
            <Fragment>
              <Col xs={12} className={"pt-3"}>
                <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0187")}</span>
              </Col>
              <Col xs={12} className={"pt-2 pb-2"}>
                <Card className="p-3 heirBoxShadow mt-1">
                  {this.state.screenSM003.healthQuestions.map((item, key)=>{
                    var checkConditions = false;
                    var conditions = item.healthAnswer.map((ansItemcon, ansKeycon)=>{
                      let showConQues = this.state.screenSM003.userHealthAns.find(x=>x.ansCode === ansItemcon.healthAnswerCode);
                      if(ansItemcon.conditions){
                        if((ansItemcon.conditions.length > 0)&&(showConQues)){
                          checkConditions = true;
                          runNumber = runNumber+1;
                          return(
                            ansItemcon.conditions.map((condiItem, condiKey)=>{
                              return(
                                <Col xs={12} key={condiKey} className="pt-3">
                                  <Row>
                                    <Col xs={12}><div dangerouslySetInnerHTML={{__html: (runNumber)+'. '+condiItem.healthQuestionTitleEApp}}></div></Col>
                                    {
                                      condiItem.healthAnswer.map((condiAnsItem, condiAnsKey)=>{
                                        let showConQuesActive = showConQues.condition.find(x=>x.ansCode === condiAnsItem.healthAnswerCode);
                                        if(showConQuesActive){
                                          return(
                                            <Col xs={12} className={"pt-2"} key={condiAnsKey}>
                                              <span className="font-weight-bold pt-1">{condiAnsItem.healthAnswerTitle}</span>
                                            </Col>
                                          )
                                        }
                                      })
                                    }
                                  </Row>
                                </Col>
                              )
                            })
                          )
                        }else{ return null }
                      }else{ return null }
                    })
                    if(!checkConditions){
                      runNumber = runNumber+1;
                      var runNumberAns = runNumber;
                      var ans = item.healthAnswer.map((ansItem, ansKey)=>{
                        let isAnsCheck = this.state.screenSM003.userHealthAns.find(x=>x.ansCode === ansItem.healthAnswerCode);
                        if(isAnsCheck){
                          return(
                              <Col xs={item.healthAnswer.length===2?6:12} className={"pt-2"} key={ansKey}>
                                <span className="font-weight-bold pt-1">{ansItem.healthAnswerTitle}</span>
                              </Col>
                          )
                        }
                      })
                    }
                    return(
                      <Row key={key} className="pb-3">
                        {checkConditions?'':<Col xs={12}><div dangerouslySetInnerHTML={{__html: (runNumberAns)+'. '+item.healthQuestionTitleEApp}}></div></Col>}
                        {checkConditions?conditions:ans}
                        {conditions}
                      </Row>
                    )
                  })}
                </Card>
              </Col>
            </Fragment>
          ) : null }

          <Col xs={12} className={"pt-3 pb-2"}>
            <small className="text--muted">{MainFunc.getLabelDtos("L0098")}</small>
          </Col>

          {MainData.screenSM004.fatcaAnsForservice?.length? (
            <Fragment>
              <Col xs={12} className="pt-2">
                <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0446")}</span>
              </Col>

              <Col xs={12} className={"pt-3 pb-2"}>
                <Card className="p-3 heirBoxShadow mt-1">
                {this.state.fatcaRes.map((item, key)=>{
                  return(
                      <Row>
                        <Col xs={12}><div>{item.question}</div></Col>
                          
                        <Col xs={12} className={"pt-2"}>
                          <span className="font-weight-bold pt-1">{item.answer}</span>
                        </Col>
                      </Row>
                  )
                  }
                  )}
                </Card>
              </Col>
            </Fragment>
          ) : null }

          {MainData.screenSM004.crsAnsForservice && MainData.screenSM004.crsAnsForservice.length > 0?
            <Fragment>
              <Col xs={12} className="pt-2">
                <span className="font-weight-bold pt-1">{MainFunc.getLabelDtos("L0453")}</span>
              </Col>
              <Col xs={12} className={"pt-3 pb-2"}>
                <Card className="p-3 heirBoxShadow mt-1">
                {this.state.crsRes.map((item, key)=>{
                  return(
                      <Row>
                        <Col xs={12}><div>{item.question}</div></Col>
                        <Col xs={12} className={"pt-2 pb-2"}>
                          <span className="font-weight-bold pt-1">{item.answer}</span>
                        </Col>
                      </Row>
                  )
                  }
                  )}
                </Card>
              </Col>
            </Fragment>:null }

          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-8_Click_BtnBack":"STEP2-8_Click_BtnBack"}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => {this.processBack()}}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?"STEP3-8_Click_BtnNext":"STEP2-8_Click_BtnNext"}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext() }}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }
        </Row>

        <Modal 
        scrollable={true}
        show={this.state.showModal}
        onHide={() => this.setState({showModal:!this.state.showModal})}
        backdrop={true}
        centered
        size="lg">

          <div className={stylesModal.modal}>
          <div className={stylesModal.modalHeader} >
            <img src={IcoModalClose} alt="" style={{cursor: "pointer"}} onClick={() => this.setState({showModal:!this.state.showModal})}/>
          </div>
          <div
            className={stylesModal.modalTitle}
          >
            {MainFunc.getLabelDtos("L0385") /* ตัวอย่างเอกสารที่อัปโหลด */}
          </div>
            <div className={stylesModal.modalContent}>
              {this.state.mimeType === "application/pdf" ? (
                <object
                  data={this.state.currentPreviewFile}
                  type="application/pdf"
                  style={{ width: "100%", height: "75vh" }}
                >
                  <embed type="application/pdf" src={this.state.currentPreviewFile} />
                </object>
              ) : (
                <FilePreviewer
                  hideControls
                  file={{
                    url: this.state.currentPreviewFile,
                  }}
                />
              )}
            </div>
        </div>
        </Modal>

      </div>
    )
  }
}

export default SM012;
