import axios from "axios";
import { notify } from 'react-noti';
import { GoAlert } from 'react-icons/go';
import React, { Component } from "react";
import { FcAssistant } from "react-icons/fc";
import { withTranslation } from 'react-i18next';
import ReactNoti, { POSITION } from 'react-noti';
import SweetAlert from 'react-bootstrap-sweetalert';
import TextField from '@material-ui/core/TextField';
import { Row, Col, Image, Button, Card, Form, Spinner, Modal } from "react-bootstrap";
import SyncLoader from '../../customLib/react-spinners/SyncLoader';
import { ProgressIndicator, ProgressStep, Input } from '../../customLib/react-rainbow-components';
import { AiFillCloseSquare } from 'react-icons/ai';
import moment from "moment";
import { CardHeader, Collapse } from "@material-ui/core";
import logo2 from '../../images/logo.png';
import IcoTel from '../../images/ico_tel.png';
import IcoTelGreen from '../../images/ico_tel_green.png';
import IcoFb from '../../images/ico_fb.png';
import IcoIg from '../../images/ico_ig.png';
import IcoLine from '../../images/ico_line.png';
import IcoLinkedin from '../../images/ico_linkedin.png';
import IcoYt from '../../images/ico_yt.png';
import IcoDBD from '../../images/logo_cf_dbd.png';
import IcoCS from '../../images/logo_pm_cs.png';
import IcoKbank from '../../images/logo_pm_kbank.png';
import IcoMC from '../../images/logo_pm_mc.png';
import IcoVS from '../../images/logo_pm_vs.png';
import IcoCrossSign from '../../images/ico_closesign.png';
import IcoCorrect from '../../images/ico_correct.png';
import IcoError from '../../images/ico_error.png';
import IcoCallCenter from '../../images/ico_callcenter.png';
import IcoClock from '../../images/ico_clock.png';
import MainData from '../../models/MainData';
import parse from "html-react-parser";

import SM001 from '../ScreenMenu001';
import SM002 from '../ScreenMenu002';
import SM003 from '../ScreenMenu003';
import SM004 from '../ScreenMenu004';
import SM005 from '../ScreenMenu005';
import SM006 from '../ScreenMenu006';
import SM007 from '../ScreenMenu007';
import SM008 from '../ScreenMenu008';
import SM009 from '../ScreenMenu009';
import SM010 from '../ScreenMenu010';
import SM011 from '../ScreenMenu011';
import SM012 from '../ScreenMenu012';
import SM013 from '../ScreenMenu013';
import SM014 from '../ScreenMenu014';
import SM015 from '../ScreenMenu015';
import SMKplus from '../ScreenMenuKPlus';

import 'react-noti/dist/react-noti.css';
import MainFunc from "../../models/MainFunc";
import { async } from "q";

class MainPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screen: "MainPage",
            errMsg: "",
            lang: "th",
            errSubMsg: "",
            masPage: [],
            menuStep: [],
            isError: false,
            isTimeout: false,
            conetntHeight: 0,
            isLoading: true,
            activePage: null,
            activeMenu: null,
            activeMenuIndex: null,
            serviceError: false,
            _cookie: sessionStorage.getItem('_cookie'),
            channel: { name: "", css: null, logo: null, font: null },
            mainHeaderText: "Online Sales",
            subHeaderText: "ซื้อประกันออนไลน์ที่เหมาะกับคุณ",
            thirdHeaderText: "",
            isSelectedForHeder: false,
            logoWidth: 70,
            channelName: '',
            channelCode: MainData.initialData.channelCode ? MainData.initialData.channelCode : /channelCode=([^&]+)/.exec(window.location.href) ? /channelCode=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.channelCode,
            productId: MainData.initialData.productId ? MainData.initialData.productId : /productId=([^&]+)/.exec(window.location.href) ? /productId=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.productId,
            source: MainData.initialData.source ? MainData.initialData.source : /utm_source=([^&]+)/.exec(window.location.href) ? /utm_source=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.source,
            channelCodeIni: /channelCode=([^&]+)/.exec(window.location.href) ? /channelCode=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.channelCode,
            productIdIni: /productId=([^&]+)/.exec(window.location.href) ? /productId=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.productId,
            sourceIni: /utm_source=([^&]+)/.exec(window.location.href) ? /utm_source=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.source,
            showfooter: false,
            TermAndConditionModal: false,
            termsAndConditionsDetail: null,
            utm_mediumIni: /utm_medium=([^&]+)/.exec(window.location.href) ? /utm_medium=([^&]+)/.exec(window.location.href)[1] : '',
            utm_campaignIni: /utm_campaign=([^&]+)/.exec(window.location.href) ? /utm_campaign=([^&]+)/.exec(window.location.href)[1] : '',
            utm_contentIni: /utm_content=([^&]+)/.exec(window.location.href) ? /utm_content=([^&]+)/.exec(window.location.href)[1] : '',
            utm_termIni: /utm_term=([^&]+)/.exec(window.location.href) ? /utm_term=([^&]+)/.exec(window.location.href)[1] : '',
            cIni: /c=([^&]+)/.exec(window.location.href) ? /c=([^&]+)/.exec(window.location.href)[1] : '',
            authIni: /auth=([^&]+)/.exec(window.location.href) ? /auth=([^&]+)/.exec(window.location.href)[1] : '',
            showDropLead: false,
            step: "1",
            name: "",
            phoneNumber: "",
            email: "",
            selectDate: "",
            validateDroplead: false,
            nameerror: "",
            emailerror: "",
            failPhone: "",
            failselectDate: "",
            checkboxDroplead: false,
            DropoffModal: false,
            checkInputname: false,
            checkInputemail: false,
            checkInputphoneNumber: false,
            notiMsg: "",
            minuteDropoff: null,
            birthDate: /birthday=([^&]+)/.exec(window.location.href) ? /birthday=([^&]+)/.exec(window.location.href)[1] : '',
            paymentId: /paymentId=([^&]+)/.exec(window.location.href) ? /paymentId=([^&]+)/.exec(window.location.href)[1] : null,
            planId: /planId=([^&]+)/.exec(window.location.href) ? /planId=([^&]+)/.exec(window.location.href)[1] : null,
            gender: /gender=([^&]+)/.exec(window.location.href) ? /gender=([^&]+)/.exec(window.location.href)[1].toUpperCase() : '',
            showScreen: false,
            categoryGTM:"",
            actionGTM:"",
            labelGTM:"",
            NotfoundProduct: false,
            cookieDetail: null,
            cookieCode: "",
            cookieVersionDisplay: "",
            cookieVersionOrder: 0,
            cookieDescription: "",
            cookieDescriptionCheck: false,
            checkKplus:false,
            device:"",
            showQrKplus:false,
            redirectFromKplus:false,
            kplusData:null,
            planData:null,
            isErrorBirthday: false,
            isErrorBirthdayDesc: "",
            getProductFail:false
        };
    }

    UNSAFE_componentWillMount() {
        let lang = /lang=([^&]+)/.exec(window.location.href);
        if (lang) {
            lang = lang[1]
            this.props.i18n.changeLanguage(lang);
        } else {
            lang = 'th'
            this.props.i18n.changeLanguage("th");
        }
        if(window.location.origin.includes("localhost")){
            MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
        }
        else{
            MainData.serviceURL = window.location.origin + `${MainData.servicePath}api`;
        }
        this.setState({ lang: lang })
        this.getData(lang);
        // var browser = this.get_browser();
        // if (parseInt(browser.version) < 83 && browser.name == "Chrome") {
        //     this.errorAlert(true, 'ระบบไม่รองรับ Chrome Version ต่ำกว่า 83', '<a href="https://www.google.com/intl/en_us/chrome/" target="_blank">คลิกที่นี่ เพื่อ Upload Version Chrome</a>')
        // }
    }

    // get_browser = () => {
    //     var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    //     if (/trident/i.test(M[1])) {
    //         tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    //         return { name: 'IE', version: (tem[1] || '') };
    //     }
    //     if (M[1] === 'Chrome') {
    //         tem = ua.match(/\bOPR|Edge\/(\d+)/)
    //         if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    //     }
    //     M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    //     if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    //     return {
    //         name: M[0],
    //         version: M[1]
    //     };
    // }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.calContentHeight();
        });
        this.calContentHeight();
        sessionStorage.setItem("homePage", window.location.href);
        this.checkTimeout();
    }

    componentDidUpdate() {
        this.calContentHeight();
    }

    checkTimeout = () => {
        sessionStorage.setItem("timeout", moment().add(+60, 'minute').format("YYYY/MM/DD/HH/mm/ss"));
        window.addEventListener('click', async (event) => {
            var nowDate = moment([new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()]);
            var timeout = sessionStorage.getItem("timeout").split("/");
            var timeoutDate = moment([parseInt(timeout[0]), parseInt(timeout[1]) - 1, parseInt(timeout[2]), parseInt(timeout[3]), parseInt(timeout[4]), parseInt(timeout[5])]);
            let newNowDate = moment(nowDate, 'DD/MM/YYYY HH:mm:ss');
            let newTimeoutDate = moment(timeoutDate, 'DD/MM/YYYY HH:mm:ss');
            if (moment(newNowDate).isAfter(newTimeoutDate)) {
                setTimeout(() => {
                    this.setState({ isTimeout: true })
                }, 1000);
            }
            else {
                sessionStorage.setItem("timeout", moment().add(+60, 'minute').format("YYYY/MM/DD/HH/mm/ss"));
            }
        });
    }

    dropoffTimeout = () => {
        sessionStorage.setItem("timeout1", moment().add(+this.state.minuteDropoff, 'seconds').format("YYYY/MM/DD/HH/mm/ss"));
        window.addEventListener('click', async (event) => {
            var nowDate = moment([new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), new Date().getHours(), new Date().getMinutes(), new Date().getSeconds()]);
            var timeout = sessionStorage.getItem("timeout1").split("/");
            var timeoutDate = moment([parseInt(timeout[0]), parseInt(timeout[1]) - 1, parseInt(timeout[2]), parseInt(timeout[3]), parseInt(timeout[4]), parseInt(timeout[5])]);
            let newNowDate = moment(nowDate, 'DD/MM/YYYY HH:mm:ss');
            let newTimeoutDate = moment(timeoutDate, 'DD/MM/YYYY HH:mm:ss');
            if (moment(newNowDate).isAfter(newTimeoutDate)) {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG093').msgDescription;
                this.setState({ DropoffModal: true, notiMsg: errMsg });
            }
            else {
                sessionStorage.setItem("timeout1", moment().add(+this.state.minuteDropoff, 'seconds').format("YYYY/MM/DD/HH/mm/ss"));
            }
        });
    }

    setTimeout = () => {
        this.setState({minuteDropoff:parseInt(MainFunc.getConfigDtos("CF026"))});
        this.dropoffTimeout();

    }

    calContentHeight = () => {
        let screenHeight = window.innerHeight;
        if (this.state.serviceError === false) {
            if (this.state.conetntHeight !== (screenHeight - document.getElementById('logoSection').offsetHeight - document.getElementById('stepSection').offsetHeight)) {
                this.setState({ conetntHeight: screenHeight - document.getElementById('logoSection').offsetHeight - document.getElementById('stepSection').offsetHeight })
            }
        }
    }

    getData = async (lang) => {
        this.setState({device:await this.getMobileOperatingSystem()})
        const headers = { "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "source": this.state.sourceIni,
            "lang": lang === 'en' ? "EN" : "TH",
            "channelCode": this.state.channelCodeIni,
            "productId": this.state.productIdIni,
            "utm_source": this.state.sourceIni,
            "utm_medium": this.state.utm_mediumIni,
            "utm_campaign": this.state.utm_campaignIni,
            "utm_content": this.state.utm_contentIni,
            "utm_term": this.state.utm_termIni,
            "c": this.state.cIni,
            "transUrl": window.location.href,
            "deviceType":this.state.device
        };
        var dataForGet = data;
        axios.post(MainData.serviceURL + '/Sales/InitialSales', data, {
            headers: headers
        })
            .then(async res => {
                let data = res.data.data;

                if (res.data.success !== true && data.messageCode === "MSG108"){
                    this.setState({ messageCode: data.messageCode, messageDescription: data.messageDescription, isLoading: false, NotfoundProduct: true })
                    return;
                }           
                else if (!data) {
                    this.setState({ isLoading: false, serviceError: true })
                    MainFunc.sendLogToService(null, 'Access', 'Fail', null, {}, dataForGet);
                    this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                    return;
                }
      
                if (data?.cookies?.cookieDetail != null)
                {
                    sessionStorage.setItem('_cookie', ''); this.setState({ _cookie: '' }); 
                }
                else
                {
                    sessionStorage.setItem('_cookie', 'true'); this.setState({ _cookie: 'true' }); 
                }

                let newMasPage = [];
                let newMenuStep = [];
                let genScreen;

                var head = document.getElementsByTagName('head')[0];
                var link = document.createElement('link');
                link.id = 'customCss';
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = data.channelCode?.toUpperCase() == "KBANK" ? window.location.origin + MainData.mainPath+'/css/kbankcustom.css' : window.location.origin + MainData.mainPath+'/css/pmibcustom.css';
                link.media = 'all';
                head.appendChild(link);
                sessionStorage.setItem("customCss", data.channelCode?.toUpperCase() == "KBANK" ? window.location.origin + MainData.mainPath+'/css/kbankcustom.css' : window.location.origin + MainData.mainPath+'/css/pmibcustom.css');
                sessionStorage.setItem("channelLogo", data.channel.logo);
                sessionStorage.setItem("channelName", data.channelCode);
                let statusSystemConfig = await MainFunc.getSystemConfigData();
                if(statusSystemConfig){
                    window.location.href = MainData.mainPath + 'shutdown'
                }

                if(data.productId == 98){
                    MainData.initialData.codeTracking = "KB-CSP_";
                }
                else{
                    MainData.initialData.codeTracking = "KBPMJ1_";
                }

   

                if (res.data.success !== true) {
                    this.errorAlert(true, "เกิดปัญหาในการเข้าใช้งาน กรุณาติดต่อเจ้าหน้าที่")
                    MainFunc.sendLogToService(null, 'Access', 'Fail', null, {}, dataForGet);
                }
            
                else {
                    if(MainData.isPRD){
                        MainFunc.SetScriptGTM(data.channelCode);
                    }
                    for (let obj of data.page) {
                        
                        if (obj.subMenu.length > 0) {
                            for (const [index, subObj] of obj.subMenu.entries()) {
                                if (subObj.subMenuCode === "SM001") genScreen = SM001
                                else if (subObj.subMenuCode === "SM002") genScreen = SM002
                                else if (subObj.subMenuCode === "SM003") genScreen = SM003
                                else if (subObj.subMenuCode === "SM004") genScreen = SM004
                                else if (subObj.subMenuCode === "SM005") genScreen = SM005
                                else if (subObj.subMenuCode === "SM006") genScreen = SM006
                                else if (subObj.subMenuCode === "SM007") genScreen = SM007
                                else if (subObj.subMenuCode === "SM008") genScreen = SM008
                                else if (subObj.subMenuCode === "SM009") genScreen = SM009
                                else if (subObj.subMenuCode === "SM010") genScreen = SM010
                                else if (subObj.subMenuCode === "SM011") genScreen = SM011
                                else if (subObj.subMenuCode === "SM012") genScreen = SM012
                                else if (subObj.subMenuCode === "SM013") genScreen = SM013
                                else if (subObj.subMenuCode === "SM026") genScreen = SM014
                                else if (subObj.subMenuCode === "SM027") genScreen = SM015
                                newMasPage.push({ menuCode: subObj.menuCode, subMenuCode: subObj.subMenuCode, mainMenuName: obj.name, name: subObj.name, seq: subObj.seq, prev: subObj.previous, next: subObj.next, screen: genScreen, nowPage: index + 1, maxPage: obj.subMenu.length })
                                if (!newMenuStep.find(x => x.menuCode === subObj.menuCode)) {
                                    newMenuStep.push({ id: obj.id, menuCode: obj.menuCode, name: obj.name, seq: subObj.seq })
                                }
                            }
                            
                        }
                    }
                    newMenuStep.sort((a, b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0));
                    newMasPage.sort((a, b) => (a.seq > b.seq) ? 1 : ((b.seq > a.seq) ? -1 : 0));



                    MainData.dropleadDtos = data.dropleadDtos;
                    MainData.configDtos = data.configDtos;

                    axios.get(MainData.serviceURL + '/MasterData/getMessage', {
                        headers: {
                            Authorization: `Bearer ${data.authKey}`
                        }
                    })
                        .then(res => {
                            if(res.status === 299){
                                window.location.href = MainData.mainPath + 'shutdown'
                                return;
                            }
                            let newmasterDataRes = res.data.data;
                            if (!newmasterDataRes) {
                                this.setState({ isLoading: false, serviceError: true })
                                this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                                return;
                            }
                            MainData.masterMessage = newmasterDataRes;
                        })
                        .catch(masterDataError => {
                            this.setState({ isLoading: false, serviceError: true })
                            this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                        });


                    MainData.masPage = newMasPage;
                    MainData.productTitle = data.title;
                    MainData.checkGender = data.gender;
                    MainData.checkAgeMin = data.ageMin;
                    MainData.checkAgeMax = data.ageMax;
                    MainData.firstPage = newMasPage[0].seq;
                    MainData.channelLogo = data.channel.logo;
                    MainData.checkAgeMinUnit = data.ageMinUnit;
                    MainData.checkAgeMaxUnit = data.ageMaxUnit;
                    MainData.initialData.source = data.source;
                    MainData.initialData.labelDtos = data.labelDtos;
                    MainData.initialData.bearerAuth = data.authKey;
                    MainData.initialData.channelCode = data.channelCode;
                    MainData.initialData.configDtos = data.configDtos;
                    MainData.initialData.productId = parseInt(data.productId);
                    document.title = data.title;
                    if(sessionStorage.getItem("MainDataFromContinue")){
                        this.setState({ channelName: data.channelCode, menuStep: newMenuStep, masPage: newMasPage, channel: data.channel,cookieCode: data.cookies.cookieCode, cookieVersionDisplay: data.cookies.cookieVersionDisplay, cookieVersionOrder: data.cookies.cookieVersionOrder, cookieDetail: data.cookies.cookieDetail, cookieDescription: data.cookies.cookieDescription })
                        this.getMasterData(newMenuStep);
                    }
                    else{
                        this.setState({ channelName: data.channelCode, menuStep: newMenuStep, masPage: newMasPage, activeMenu: newMasPage[0].menuCode, channel: data.channel,cookieCode: data.cookies.cookieCode, cookieVersionDisplay: data.cookies.cookieVersionDisplay, cookieVersionOrder: data.cookies.cookieVersionOrder, cookieDetail: data.cookies.cookieDetail, cookieDescription: data.cookies.cookieDescription })
                    }
                    MainFunc.sendLogToService(newMasPage[0].name, 'Access', 'Success', null, {}, dataForGet);
                    this.checkProductDetailStatus();
                    this.setTimeout();
                }
                if (sessionStorage.getItem("paymentError") && sessionStorage.getItem("paymentError") == 'true' && sessionStorage.getItem("MainData") && sessionStorage.getItem("seqPage")) {
                    MainFunc.SetMainDataFromStorage();
                    this.setPage(sessionStorage.getItem("seqPage"));
                    this.setState({ showfooter: false });
                    this.getDataTermsAndConditionsDetail();
                }
                else {
                    if(sessionStorage.getItem("paymentError")){
                        sessionStorage.removeItem("paymentError")
                    }
                    if(this.state.authIni){
                        this.getCustomerSelection();
                    }
                    else{
                        if(!sessionStorage.getItem("MainDataFromContinue")){
                            this.setState({ showfooter: true });
                            this.serviceValidate(data?.cookies?.cookieDetail);
                        }
                    }
                }
            })
            .catch(error => { 
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error?.response?.data?.data?.messageDescription), ' ')
                this.setState({ isLoading: false, serviceError: true })
            });

    }

    getMasterData =(newMenuStep) => {

        axios.get(MainData.serviceURL + '/MasterData/getMasterData', {
          headers: {
              Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
          }
        })
            .then(res => {
              if(res.status === 299){
                window.location.href = MainData.mainPath + 'shutdown'
                return;
              }
                let newmasterDataRes = res.data.data;
                MainData.initialData.title = newmasterDataRes.title;
                MainData.initialData.titleEN = newmasterDataRes.titleEN;
                MainData.initialData.province = newmasterDataRes.province;
                MainData.initialData.occupation = newmasterDataRes.occupation;
                MainData.initialData.relationship = newmasterDataRes.relationship;
                MainData.initialData.confirmMessage = newmasterDataRes.confirmMessage;
                MainData.initialData.importantInformation = newmasterDataRes.importantInformation;
                MainData.initialData.natureOfBusinesses = newmasterDataRes.natureOfBusinesses;
                MainData.initialData.mappingRelationships = newmasterDataRes.mappingRelationships;
                let MainDataFromContinue = JSON.parse(sessionStorage.getItem("MainDataFromContinue"));
                let subMenuCodeFromContinue = sessionStorage.getItem("subMenuCodeFromContinue");
                MainData.screenSM001 = MainDataFromContinue.screenSM001;
                MainData.screenSM002 = MainDataFromContinue.screenSM002;
                MainData.screenSM003 = MainDataFromContinue.screenSM003;
                MainData.screenSM004 = MainDataFromContinue.screenSM004;
                MainData.screenSM005 = MainDataFromContinue.screenSM005;
                MainData.screenSM006 = MainDataFromContinue.screenSM006;
                MainData.screenSM007 = MainDataFromContinue.screenSM007;
                MainData.screenSM008 = MainDataFromContinue.screenSM008;
                MainData.screenSM009 = MainDataFromContinue.screenSM009;
                MainData.screenSM010 = MainDataFromContinue.screenSM010;
                MainData.screenSM011 = MainDataFromContinue.screenSM011;
                MainData.screenSM012 = MainDataFromContinue.screenSM012;
                MainData.screenSM013 = MainDataFromContinue.screenSM013;
                MainData.screenSM014 = MainDataFromContinue.screenSM014;
                MainData.screenSM015 = MainDataFromContinue.screenSM015;
                MainData.screenSM009Preview = MainDataFromContinue.screenSM009Preview
                MainData.screenMenuKPlus = MainDataFromContinue.screenMenuKPlus
                this.loadController(true);
                setTimeout(() => {
                    this.loadController(false);
                    let active 
                    if(subMenuCodeFromContinue=="SM026-U"){
                        active = MainData.masPage.find(masPage => masPage.seq === parseInt(this.state.masPage.find(item => item.subMenuCode == "SM026")?.seq));
                    }
                    else if(subMenuCodeFromContinue == "SM006" && MainData.screenMenuKPlus.onKPlus){
                        active = MainData.masPage.find(masPage => masPage.seq === parseInt(this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.next
                        ?parseInt(this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.next)+1
                        :this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.seq));
                    }
                    else if (subMenuCodeFromContinue == "SM007" || subMenuCodeFromContinue == "SM027"){
                        active = MainData.masPage.find(masPage => masPage.seq === parseInt(this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.next
                        ?this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.next
                        :this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.seq));
                    }
                    else{
                        active = MainData.masPage.find(masPage => masPage.seq === parseInt(this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.next
                        ?this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.seq
                        :this.state.masPage.find(item => item.subMenuCode == subMenuCodeFromContinue)?.seq));
                    }
                    let activeIndex = newMenuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
                    this.setState({showScreen:true, activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: true, activeMenuIndex: activeIndex.toString()})
                    let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
                    this.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(MainData.screenSM002.cost)+" "+MainFunc.getLabelDtos("L0104"), MainData.screenSM002.planName, true)
                    sessionStorage.removeItem("MainDataFromContinue")
                }, 1000);
            })
            .catch(error => {
              if(error?.response?.status == 404 && error?.response?.data?.data?.messageCode  == "MSG1001"){
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
              }
              else {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
              }
            });
      }

    getDataTermsAndConditionsDetail = () => {
        const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "channel": MainData.initialData.channelCode
        }
        axios.post(MainData.serviceURL + '/VerifyIDCard/GetTermAndCondition', data, {
            headers: headers
        })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (!data) {
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription, ' ')
                    return;
                }
                if( this.state.paymentId === null && this.state.gender === null && this.state.planId === null && this.state.birthDate === null )
                {
                    this.setState({ termsAndConditionsDetail: data.termsAndConditionsDetail, redirectFromKplus: false })
                    this.loadController(false)     
                }
                else
                {
                    this.setState({ termsAndConditionsDetail: data.termsAndConditionsDetail, showScreen: true, redirectFromKplus: false })
                    this.loadController(false)    
                }
            })
            .catch(error => {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
            });
    }

    setPage = (seq) => {
        let active = this.state.masPage.find(masPage => masPage.seq === parseInt(seq));
        let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
        this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString()})
    }

    loadController = (isLoad) => {
        this.setState({ isLoading: isLoad })
    }

    errorAlert = (isErr, msg, subMsg, category, action, label) => {
        this.setState({ isError: isErr, errMsg: msg, errSubMsg: !subMsg ? null : subMsg, categoryGTM:category, actionGTM:action, labelGTM:label})
    }

    errorConfirm = () => {
        if(this.state.categoryGTM){
            MainFunc.sendDataToGTM(this.state.categoryGTM,this.state.actionGTM,this.state.labelGTM);
        }
        if(this.state.redirectFromKplus){
            if(this.state.getCustomerSelectionData){
                MainData.initialData.bearerAuth = this.state.authIni;
                MainData.screenSM005.cusId = this.state.getCustomerSelectionData.cusId;
                MainData.screenSM001.birthdate = MainFunc.formatDateTimeDisplay(
                  this.state.getCustomerSelectionData.birthDate,
                  "",

                  "TH"
                );
                MainData.screenSM001.gender = this.state.getCustomerSelectionData.gender;
                MainData.screenSM002.paymentCode = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentCode;
                MainData.screenSM002.planId = this.state.getCustomerSelectionData.planSelect.planId;
                MainData.screenSM002.productName = this.state.getCustomerSelectionData.planSelect.productName;
                MainData.screenSM002.planName = this.state.getCustomerSelectionData.planSelect.planName;
                MainData.screenSM002.paymentName = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentName;
                MainData.screenSM002.cost = this.state.getCustomerSelectionData.planSelect.planPremiumMode.premium;
                MainData.screenSM002.costPayment = this.state.getCustomerSelectionData.planSelect.planPremiumMode.premiumPayment;
                MainData.screenSM002.paymentId = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentId;
                MainData.screenSM002.insureRequiredIdentification = this.state.getCustomerSelectionData.planSelect.planPremiumMode.insureRequiredIdentification;
                MainData.screenSM002.insureSelfie = this.state.getCustomerSelectionData.planSelect.planPremiumMode.insureSelfie;
                let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
                this.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(MainData.screenSM002.cost)+" "+MainFunc.getLabelDtos("L0104"), MainData.screenSM002.planName, true)
                // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003")
                // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:false, redirectFromKplus: false })
                let active = this.state.masPage.find(masPage => masPage.seq === 3);
                let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
                this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(), checkKplus:false, redirectFromKplus: false})
                this.getDataTermsAndConditionsDetail(); 
            }
            else{
                this.serviceValidate(this.state.cookieDetail);
                this.setState({ showfooter: true, redirectFromKplus: false });
            }
        }
        this.setState({ isError: false, errMsg: "ERROR!" })
    }

    switchingLanguage = () => {
        if (this.props.i18n.language === "en") {
            this.props.i18n.changeLanguage("th");
        } else {
            this.props.i18n.changeLanguage("en");
        }
    }

    setHeaderText = (main, sec, third, isSelect) => {
        this.setState({
            mainHeaderText: main,
            subHeaderText: sec,
            thirdHeaderText: third,
            isSelectedForHeder: isSelect
        })
    }

    serviceDroplead = async () => {
        let statusSystemConfig = await MainFunc.getSystemConfigData();
        if(statusSystemConfig){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
        }
        this.setState({nameerror: "",emailerror: "",failPhone:"",failselectDate:""})
        this.setState({ validateDroplead: true ,checkInputname: false,checkInputemail: false,checkInputemail: false})
        let validate = false;
        if (!this.state.name || !this.state.phoneNumber || !this.state.email || !this.state.selectDate) {
            validate = true;
        }
        if (this.state.name === "")  {
            this.setState({ nameerror: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else if (!this.state.name.split(/ (.*)/s)[0] || !this.state.name.split(/ (.*)/s)[1]) {
            this.setState({ nameerror: 'กรุณาตรวจสอบข้อมูล' , checkInputname: true})
            validate = true;
        } 

        if (this.state.email === "") {
            this.setState({ emailerror: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else if (this.validateEmail(this.state.email) !== true) {
            this.setState({ emailerror: 'กรุณาตรวจสอบข้อมูล' , checkInputemail: true})
            validate = true;
        }

        if (this.state.phoneNumber == "") {
            this.setState({ failPhone: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else  if (this.state.phoneNumber.length <= 9) {
            this.setState({ failPhone: 'กรุณาตรวจสอบข้อมูล' , checkInputphoneNumber: true})
            validate = true;
        }

        if (this.state.selectDate == "") {
            this.setState({ failselectDate: 'กรุณาระบุข้อมูล' })
            validate = true;
        }
        
        if (validate) {
            return;
        }

        this.setState({ step: "2" })

        const headers = { "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "channel": MainData.initialData.channelCode,
            "firstName": this.state.name.split(" ")[0].trim(),
            "lastName": this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
            "phoneNumber": this.state.phoneNumber,
            "email": this.state.email,
            "productId": MainData.initialData.productId,
            "contactTimeName": this.state.selectDate,
        };

        
        axios.post(MainData.serviceURL + '/Customer/GetDropLead', data, {
            headers: headers
        })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (res.data.success) {
                    this.setState({ step: "3" })
                    this.setState({ contactTimeId: data.contactTimeId, contactTimeCode: data.contactTimeCode, contactTimeName: data.contactTimeName });
                } else {
                    this.setState({ step: "2" })
                }

            })
            .catch(error => {
                if(error?.response?.status == 400){
                    this.props.loadController(false)
                    this.validateBadRequest(error.response.data.errors);
                }
                else{
                    this.setState({ step: "4" });
                }
            });

    }

    validateBadRequest = (fieldNameList) => {
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
        this.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
        if(fieldNameList.find(data => data.field === "firstName") || fieldNameList.find(data => data.field === "lastName")){
            this.setState({ nameerror: errMsg , checkInputname: true})
        }
        if(fieldNameList.find(data => data.field === "phoneNumber")){
            this.setState({ failPhone: errMsg , checkInputphoneNumber: true})
        }
        if(fieldNameList.find(data => data.field === "email")){
            this.setState({ emailerror: errMsg , checkInputemail: true})
        }
        if(fieldNameList.find(data => data.field === "contactTimeName")){
            this.setState({ failselectDate: errMsg })
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleInputChange = (name, val) => {
        switch(name){
          case "name":
            if (val[0] === " ") { break; }
            let checkWord = /^[ก-์ ]+$/.test(val);
            this.setState((state) => ({name: !checkWord&&val?state.name:val}))
            this.setState({checkInputname: false})
            if(this.state.name.split(" ")[0] || this.state.name.split(" ")[1]){this.setState({nameerror: ""})}
            break;
          case "phoneNumber":
            val = val.replace(/[^\d]/g, ""); 
            if (val.length == 1 && val != "0") { this.setState({phoneNumber: "0"+val}) } 
            else if (val.length <= 10) { this.setState({ phoneNumber: val,failPhone: "" }) }
            this.setState({checkInputphoneNumber: false})
            break;
          case "email":
            let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
            this.setState((state) => ({email: !checkWord4&&val?state.email:val, emailerror: ""}))
            this.setState({checkInputemail: false})
            break;
          case "selectDate":
            this.setState({selectDate: val, failselectDate: ""})
            break;
          default:
            break;
        }
      }

    closeDroplead = () => {
        document.getElementById("dropLeadBox").click();
        setTimeout(() => {
            this.setState({showDropLead: false, step: "1", validateDroplead: false, checkboxDroplead: false, checkInputname: false, checkInputemail: false, checkInputphoneNumber: false })
            this.setState({name: "", phoneNumber: "", email: "", selectDate: "" })
            this.setState({nameerror: "", failPhone: "", emailerror: "", failselectDate: "" })
        }, 100);

    }

    getCustomerSelection = () => {
        const headers = { "Authorization": `Bearer ${this.state.authIni}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
    
        axios.post(MainData.serviceURL + '/KInfoSharing/GetCustomerSelection', {}, {
            headers: headers
        })
            .then(async res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (data) {
                    this.setState({redirectFromKplus:true,getCustomerSelectionData:data})
                    this.callServiceGetInfo(data)
                } else {
                    this.setState({ showfooter: true });
                    this.serviceValidate(this.state.cookieDetail);
                }
            })
            .catch(error => {
                this.setState({redirectFromKplus:true})
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                this.setState({ isLoading: false, serviceError: true })

            });

    }

    callServiceGetInfo = () => {
        const headers = {
            Authorization: `Bearer ${this.state.authIni}`,
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        };
        const data = {
            channel: MainData.initialData.channelCode,
            tokenId: this.state.getCustomerSelectionData.tokenId ? this.state.getCustomerSelectionData.tokenId : "",
            isCheckTimeOut: false
        };
        axios.post(MainData.serviceURL + "/KInfoSharing/getinfo", data, {
              headers: headers,
            })
            .then((res) => {
              if (res.status === 299) {
                window.location.href = MainData.mainPath + "shutdown";
                return;
              }
              let data = res?.data?.data;
              if (res?.data.status.status) {
                if (
                  MainFunc.formatDateTimeDisplay(
                    data.customerInfo.brthDt,
                    "",
                    "TH"
                  ) == MainFunc.formatDateTimeDisplay(this.state.getCustomerSelectionData.birthDate,
                    "",
                    "TH"
                  )
                ) {
                  MainData.initialData.bearerAuth = this.state.authIni;
                  MainData.screenMenuKPlus.onKPlus = true;
                  MainData.screenSM005.cusId = this.state.getCustomerSelectionData.cusId;
                  this.mapDataInFo(data);
                  MainData.screenSM001.birthdate = MainFunc.formatDateTimeDisplay(
                    this.state.getCustomerSelectionData.birthDate,
                    "",
                    "TH"
                  );
                  MainData.screenSM001.gender = this.state.getCustomerSelectionData.gender;
                  MainData.screenSM002.paymentCode = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentCode;
                  MainData.screenSM002.planId = this.state.getCustomerSelectionData.planSelect.planId;
                  MainData.screenSM002.productName = this.state.getCustomerSelectionData.planSelect.productName;
                  MainData.screenSM002.planName = this.state.getCustomerSelectionData.planSelect.planName;
                  MainData.screenSM002.paymentName = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentName;
                  MainData.screenSM002.cost = this.state.getCustomerSelectionData.planSelect.planPremiumMode.premium;
                  MainData.screenSM002.costPayment = this.state.getCustomerSelectionData.planSelect.planPremiumMode.premiumPayment;
                  MainData.screenSM002.paymentId = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentId;
                  MainData.screenSM002.insureRequiredIdentification = this.state.getCustomerSelectionData.planSelect.planPremiumMode.insureRequiredIdentification;
                  MainData.screenSM002.insureSelfie = this.state.getCustomerSelectionData.planSelect.planPremiumMode.insureSelfie;
                  let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
                  this.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(MainData.screenSM002.cost)+" "+MainFunc.getLabelDtos("L0104"), MainData.screenSM002.planName, true)
                //   let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003")
                //   this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:false })
                let active = this.state.masPage.find(masPage => masPage.seq === 3);
                let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
                this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(), checkKplus:false})
                  this.getDataTermsAndConditionsDetail();
                } else {
                  if (data.customerInfo.brthDt) {
                    this.setState({kplusData:data})
                    MainData.initialData.bearerAuth = this.state.authIni;
                    MainData.screenMenuKPlus.onKPlus = true;
                    MainData.screenSM005.cusId = this.state.getCustomerSelectionData.cusId;
                    MainData.screenSM001.birthdate = MainFunc.formatDateTimeDisplay(
                      data.customerInfo.brthDt,
                      "",
                      "TH"
                    );
                    MainData.screenSM001.gender = this.state.getCustomerSelectionData.gender;
                    this.getProductKplusData();
                  }
                  else {
                    MainData.initialData.bearerAuth = this.state.authIni;
                    MainData.screenSM005.cusId = this.state.getCustomerSelectionData.cusId;
                    MainData.screenSM001.birthdate = MainFunc.formatDateTimeDisplay(
                        this.state.getCustomerSelectionData.birthDate,
                        "",
                        "TH"
                    );
                    MainData.screenSM001.gender = this.state.getCustomerSelectionData.gender;
                    MainData.screenSM002.paymentCode = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentCode;
                    MainData.screenSM002.planId = this.state.getCustomerSelectionData.planSelect.planId;
                    MainData.screenSM002.productName = this.state.getCustomerSelectionData.planSelect.productName;
                    MainData.screenSM002.planName = this.state.getCustomerSelectionData.planSelect.planName;
                    MainData.screenSM002.paymentName = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentName;
                    MainData.screenSM002.cost = this.state.getCustomerSelectionData.planSelect.planPremiumMode.premium;
                    MainData.screenSM002.costPayment = this.state.getCustomerSelectionData.planSelect.planPremiumMode.premiumPayment;
                    MainData.screenSM002.paymentId = this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentId;
                    MainData.screenSM002.insureRequiredIdentification = this.state.getCustomerSelectionData.planSelect.planPremiumMode.insureRequiredIdentification;
                    MainData.screenSM002.insureSelfie = this.state.getCustomerSelectionData.planSelect.planPremiumMode.insureSelfie;
                    let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
                    this.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(MainData.screenSM002.cost)+" "+MainFunc.getLabelDtos("L0104"), MainData.screenSM002.planName, true)
                    // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003")
                    // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:false })
                    let active = this.state.masPage.find(masPage => masPage.seq === 3);
                    let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
                    this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(), checkKplus:false})
                    this.getDataTermsAndConditionsDetail();
                  }
                }
            } else {
                let errMsg = MainData.masterMessage.find(
                  (x) => x.msgCode === res?.data?.status?.messageCode
                );
                this.errorAlert(
                  true,
                  !errMsg
                    ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
                    : errMsg.msgDescription,
                  " ",
                  res?.data?.status?.messageCode
                );
                this.loadController(false);
            }
        })
        .catch((error) => {
            let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
            );
            this.errorAlert(
            true,
            !errMsg
                ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
                : errMsg.msgDescription.replace(
                    "{{Service}}",
                    error.response.data.data?.messageDescription
                ),
            " ",
            "MSG1001"
            );
        });
    };

    getProductKplusData = () => {
        this.loadController(true)
        const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
          "channelCode": MainData.initialData.channelCode,
          "productId": MainData.initialData.productId,
          "birthDate": MainFunc.getUserBirthDate("be"),
          "gender": MainData.screenSM001.gender,
          "grantee": "SELF"
        };
        var dataForGet = data;
        axios.post(MainData.serviceURL+'/Sales/GetProduct', data, {
          headers: headers
        })
        .then(res => { 
          if(res.status === 299){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
          }  
          let data = res.data.data;
          if(!data){
            MainFunc.sendLogToService(this.state.masPage[0].name, 'Search', 'Fail', 'เก็บข้อมูลการค้นหาแผนประกัน', {}, dataForGet);
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG004');
            this.errorAlert(true, !errMsg?"ขออภัย ไม่พบข้อมูลแบบประกันที่สามารถเลือกซื้อได้":errMsg.msgDescription, ' ','GetProduct')
          }
          else{
            let plan = data.planList.find(item => item.planId == this.state.getCustomerSelectionData.planSelect.planId);
            let planPremiumMode = plan.planPremiumMode.find(item => item.paymentId == this.state.getCustomerSelectionData.planSelect.planPremiumMode.paymentId);
            plan.planPremiumMode = planPremiumMode;
            plan.productName = data.productName;
            this.setState({planData:plan})
            let errMsg = MainData.masterMessage.find(
              (x) => x.msgCode === "MSG168"
            );
            this.setState({
              isErrorBirthday: true,
              isErrorBirthdayDesc: !errMsg
                ? "วัน เดือน ปีเกิด ที่คุณระบุไม่ตรงกับฐานข้อมูลของธนาคาร ระบบจะทําการคํานวณเบี้ยตามฐานข้อมูล วันที่ {{birthDateKplus}} ซึ่งค่าเบี้ยของคุณคือ {{premium}} กรุณากดปิดเพื่อเข้าสู่หน้าเลือกแผนความคุ้มครอง"
                : errMsg.msgDescription.replace(
                    "{{birthDateKplus}}",
                    MainData.screenSM001.birthdate.split("/")[0] +
                      " " +
                      MainFunc.setNewThaiMonth(
                        parseInt(
                          MainData.screenSM001.birthdate.split("/")[1]
                        ) - 1,
                        true
                      ) +
                      " " +
                      MainData.screenSM001.birthdate.split("/")[2]
                  ).replace("{{premium}}",MainFunc.numberWithCommas(plan.planPremiumMode.premium) + ` ${MainFunc.getLabelDtos("L0104")}`)
            });
          }
          this.loadController(false)
        })
        .catch(error => {
          this.loadController(false)
          MainFunc.sendLogToService(this.state.masPage[0].name, 'Search', 'Fail', 'เก็บข้อมูลการค้นหาแผนประกัน', {}, dataForGet);
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
          this.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
        });
    }

    mapDataInFo = async (data) => {
        axios.get(MainData.serviceURL + '/MasterData/getMasterData', {
            headers: {
                Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
            }
          })
            .then(async res => {
                if(res.status === 299){
                  window.location.href = MainData.mainPath + 'shutdown'
                  return;
                }
                  let newmasterDataRes = res.data.data;
                  MainData.initialData.title = newmasterDataRes.title;
                  MainData.initialData.titleEN = newmasterDataRes.titleEN;
                  MainData.initialData.province = newmasterDataRes.province;
                  MainData.initialData.occupation = newmasterDataRes.occupation;
                  MainData.initialData.relationship = newmasterDataRes.relationship;
                  MainData.initialData.confirmMessage = newmasterDataRes.confirmMessage;
                  MainData.initialData.importantInformation = newmasterDataRes.importantInformation;
                  MainData.initialData.natureOfBusinesses = newmasterDataRes.natureOfBusinesses;

                  const customerInfo = data.customerInfo;
                  const registrationAddr = data.registrationAddr;
                  const contactAddr = data.contactAddr;
                  MainData.screenSM005.idCard = customerInfo.citizenID;
              
                  MainData.screenSM006.phoneNumber = customerInfo.mobNo ?? "";
              
                  MainData.screenSM008.job = customerInfo.occupationCode ?? "";
                  MainData.screenSM008.email = customerInfo.email ?? "";
                  MainData.screenSM008.lastName = customerInfo.thLstName ?? "";
                  MainData.screenSM008.titleName = customerInfo.thTitle ?? "";
                  MainData.screenSM008.firstName = customerInfo.thFstName ?? "";
                  MainData.screenSM008.titleNameEN = customerInfo.enTitle ?? "";
                  MainData.screenSM008.firstNameEN = customerInfo.enFstName ?? "";
                  MainData.screenSM008.lastNameEN = customerInfo.enLstName ?? "";
                  MainData.screenSM008.idCardExp = "";
                  MainData.screenSM008.jobDescription = customerInfo.natureOfBusinessDescription;
                  MainData.screenSM008.idCardExpDateNone = "";
              
                  let checkRegistrationProvince = MainData.initialData.province.find(x=>x.provinceCode === contactAddr.provinceCd);
                  let checkRegistrationDistrict = checkRegistrationProvince?.district?.find(x=>x.districtCode === contactAddr.districtCd);
                  let checkRegistrationsubDistrict = checkRegistrationDistrict?.subDistrict?.find(x=>x.subDistrictCode === contactAddr.subDistrictCd);
                  MainData.screenSM009.address = contactAddr.mailNo ?? "";
                  MainData.screenSM009.province = checkRegistrationProvince?.provinceTitle ?? "";
                  MainData.screenSM009.district = checkRegistrationDistrict?.districtTitle ?? "";
                  MainData.screenSM009.subDistrict = checkRegistrationsubDistrict?.subDistrictTitle ?? "";
                  MainData.screenSM009.village = contactAddr.village ?? "";
                  MainData.screenSM009.moo = contactAddr.moo ?? "";
                  MainData.screenSM009.soi = contactAddr.soi ?? "";
                  MainData.screenSM009.street = contactAddr.road ?? "";
                  MainData.screenSM009.zipCode = contactAddr.postCd ?? "";
              
              
                  let checkCurrentProvince = MainData.initialData.province.find(x=>x.provinceCode === registrationAddr.provinceCd);
                  let checkCurrentDistrict = checkCurrentProvince?.district?.find(x=>x.districtCode === registrationAddr.districtCd);
                  let checkCurrentsubDistrict = checkCurrentDistrict?.subDistrict?.find(x=>x.subDistrictCode === registrationAddr.subDistrictCd);
                  MainData.screenSM009Preview.isSameAddress = false;
                  MainData.screenSM009.formalAddress = registrationAddr.mailNo ?? "";
                  MainData.screenSM009.formalProvince = checkCurrentProvince?.provinceTitle ?? "";
                  MainData.screenSM009.formalDistrict = checkCurrentDistrict?.districtTitle ?? "";
                  MainData.screenSM009.formalSubDistrict = checkCurrentsubDistrict?.subDistrictTitle ?? "";
                  MainData.screenSM009.formalVillage = registrationAddr.village ?? "";
                  MainData.screenSM009.formalMoo = registrationAddr.moo ?? "";
                  MainData.screenSM009.formalSoi = registrationAddr.soi ?? "";
                  MainData.screenSM009.formalStreet = registrationAddr.road ?? "";
                  MainData.screenSM009.formalZipCode = registrationAddr.postCd ?? "";
                  if(data.imageToken){
                    let base64 = await this.getFileFromUrl(data.imageToken);
                    let object_file = this.dataURLtoFile(base64, this.checkTypeFile(base64, "faceShape"));
                      MainData.screenSM014.uploadedFilesFace_shape = [{
                        data: base64,
                        file: object_file
                      }]
                  }
              })
              .catch(error => {
                if(error?.response?.status == 404 && error?.response?.data?.data?.messageCode  == "MSG1001"){
                  let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                  this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                }
                else {
                  let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                  this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                }
              });
    };

    getFileFromUrl = async (token) => {
        const data = await fetch(`${MainData.serviceURL}/Customer/GetPicture`, {
          headers: {
            Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
            AuthId: !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId,
            ImageToken: token,
            "language-locale": process.env.NEXT_PUBLIC_LANGUAGE_LOCALE,
          },
        });
        const blob = await data.blob();
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data);
          };
        });
      };
    
      dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
    
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
      }
    
      checkTypeFile = (base64, name) => {
        if (base64.includes("image/jpg")) {
          return name + ".jpg"
        }
        else if (base64.includes("image/png")) {
          return name + ".png"
        }
        else if (base64.includes("image/jpeg")) {
          return name + ".jpeg"
        }
        else if (base64.includes("application/pdf")) {
          return name + ".pdf"
        }
      }

    skipHAndFChangeBirthdatePage = () => {
        let planData = this.state.planData
        MainData.screenSM002.paymentCode = planData.planPremiumMode.paymentCode;
        MainData.screenSM002.planId = planData.planId;
        MainData.screenSM002.productName = planData.productName;
        MainData.screenSM002.planName = planData.planName;
        MainData.screenSM002.paymentName = planData.planPremiumMode.paymentName;
        MainData.screenSM002.cost = planData.planPremiumMode.premium;
        MainData.screenSM002.costPayment = planData.planPremiumMode.premiumPayment;
        MainData.screenSM002.paymentId = planData.planPremiumMode.paymentId;
        MainData.screenSM002.insureRequiredIdentification = planData.planPremiumMode.insureRequiredIdentification;
        MainData.screenSM002.insureSelfie = planData.planPremiumMode.insureSelfie;
        MainData.screenSM002.screen = "";
        this.mapDataInFo(this.state.kplusData);
        this.setState({isErrorBirthday: false,isErrorBirthdayDesc: ""});
        let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
        this.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(MainData.screenSM002.cost)+" "+MainFunc.getLabelDtos("L0104"), MainData.screenSM002.planName, true)
        if(sessionStorage.getItem('_cookie') == "true"){
          MainData.screenSM005.cusId = this.state.getCustomerSelectionData.cusId
          this.addOrUpdateCustomerBirthdayKplus();
        }
        else{
            // this.setPage("3")
            // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003");;
            // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:false })
            let active = this.state.masPage.find(masPage => masPage.seq === 3);
            let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
            this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(), checkKplus:false})
            this.getDataTermsAndConditionsDetail();
        }
      }
    
      addOrUpdateCustomerBirthdayKplus = () => {
        this.loadController(true);
        const headers = {
          Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
          "Access-Control-Allow-Headers":
            "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
        };
        const data = {
          channelCode: MainData.initialData.channelCode,
          productId: MainData.initialData.productId,
          gender: MainData.screenSM001.gender,
          birthDate: MainFunc.getUserBirthDate("ac"),
          age: moment().diff(MainFunc.getUserBirthDate("ac"), "years"),
          transUrl: window.location.href,
          submenuCode: "SM001",
        };
        axios
          .post(
            MainData.serviceURL + "/Customer/AddOrUpdateCustomerBirthday",
            data,
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.status === 299) {
              window.location.href = MainData.mainPath + "shutdown";
              return;
            }
            if (res.data.success === true) {
              sessionStorage.setItem("_cookie", "true");
              MainData.screenSM005.cusId = res.data.data;
              this.saveDataProductKplus();
            }
          })
          .catch((error) => {
            if (error?.response?.status == 400) {
              this.loadController(false);
              // this.validateBadRequest(error.response.data.errors)
            } else {
              this.loadController(false);
              let errMsg = MainData.masterMessage.find(
                (x) => x.msgCode === "MSG1001"
              );
              this.errorAlert(
                true,
                !errMsg
                  ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
                  : errMsg.msgDescription.replace(
                      "{{Service}}",
                      error.response.data.data?.messageDescription
                    ),
                " "
              );
            }
          });
      };
    
      saveDataProductKplus = () => {
        this.loadController(true)
        const headers = { 
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
        "Content-Type": "application/json;charset=UTF-8", 
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Credentials": "true", 
        "Access-Control-Allow-Methods": 
        "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
        "AuthId": MainData.screenSM005.cusId
        }
        const data = {
          "channelCode": MainData.initialData.channelCode,
          "productId": MainData.initialData.productId,
          "planId": MainData.screenSM002.planId,
          "premium": MainData.screenSM002.cost,
          "paymentId": MainData.screenSM002.paymentId,
          "paymentMode" : MainData.screenSM002.paymentCode,
          "transUrl": window.location.href,
          "submenuCode": "SM002",
        };
        axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerPremiumCal', data, {
          headers: headers
        })
        .then(res => { 
          if(res.status === 299){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
          }  
          if(res.data.success === true){
            MainData.screenSM005.cusId = res.data.data;
            this.loadController(false)
            // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003")
            // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:false })
            let active = this.state.masPage.find(masPage => masPage.seq === 3);
            let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
            this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(),checkKplus:false})
          }
          else{
            // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM002")
            // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:false })
            let active = this.state.masPage.find(masPage => masPage.seq === 1);
            let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
            this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(),checkKplus:false})
          }
          this.getDataTermsAndConditionsDetail();
        })
        .catch(error => {
          if(error?.response?.status == 400){
            this.loadController(false)
            this.validateBadRequest(error.response.data.errors)
          }
          else{
            this.loadController(false)
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
            this.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
          }
        });
      }

    serviceValidate = (cookieDetail) => {
        let birthDate = ""
        if(this.state.birthDate){
            if(this.state.birthDate.split("-")[2] && parseInt(this.state.birthDate.split("-")[2]) >1000){
                birthDate = `${this.state.birthDate.split("-")[2]}-${this.state.birthDate.split("-")[1]}-${this.state.birthDate.split("-")[0]}`;
            }
            else{
                birthDate = `${this.state.birthDate.split("-")[0]}-${this.state.birthDate.split("-")[1]}-${this.state.birthDate.split("-")[2]}`;
            }
        }
        const headers = { "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "channelCode": MainData.initialData.channelCode,
            "productId": MainData.initialData.productId,
            "birthDate": birthDate,
            "paymentId": this.state.paymentId,
            "planId": this.state.planId,
            "gender": this.state.gender,
        };
    
        axios.post(MainData.serviceURL + '/Sales/ValidateGetPremium', data, {
            headers: headers
        })
            .then(async res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (data.isContinue) {
                    let year = data.birthDate.split("-")[0]
                    let month = data.birthDate.split("-")[1]   
                    let day = data.birthDate.split("-")[2]      
                    MainData.screenSM001.birthdate = day+"/"+month+"/"+year
                    MainData.screenSM001.gender = data.gender

                    MainData.screenSM002.planName = data.planDetail.planName
                    MainData.screenSM002.planId = data.planDetail.planId
                    MainData.screenSM002.productName = data.productName
                    MainData.screenSM002.paymentCode = data.planDetail.planPremiumMode.paymentCode
                    MainData.screenSM002.paymentId = data.planDetail.planPremiumMode.paymentId
                    MainData.screenSM002.paymentName = data.planDetail.planPremiumMode.paymentName
                    MainData.screenSM002.cost = data.planDetail.planPremiumMode.premium
                    MainData.screenSM002.costPayment = data.planDetail.planPremiumMode.premiumPayment
                    MainData.screenSM002.sumInsured = data.planDetail.planPremiumMode.sumInsured
                    MainData.screenSM002.insureRequiredIdentification = data.planDetail.planPremiumMode.insureRequiredIdentification
                    MainData.screenSM002.insureSelfie = data.planDetail.planPremiumMode.insureSelfie
                    if(!cookieDetail){
                        await this.addOrUpdateCustomerBirthday();
                        await this.saveDataProduct();
                    }
                    let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
                    this.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(data.planDetail.planPremiumMode.premium)+" "+MainFunc.getLabelDtos("L0104"), data.planDetail.planName, true)
                    // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003")
                    // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false,checkKplus:true })
                    setTimeout(() => {
                        this.scrollContentSectionTop();
                    }, 100);
                    // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM003")
                    // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false })
                    let active = this.state.masPage.find(masPage => masPage.seq === 3);
                    let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
                    this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString(),checkKplus:true})
                } else {
                    // let active = this.state.masPage.find(masPage => masPage.subMenuCode === "SM001")
                    // this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false })
                    let active = this.state.masPage.find(masPage => masPage.seq === 1);
                    let activeIndex = this.state.menuStep.findIndex(menuStep => menuStep.menuCode === active.menuCode)+1
                    this.setState({ activePage: active.subMenuCode, activeMenu: active.menuCode, showfooter: false, activeMenuIndex: activeIndex.toString()})
                }
                this.getDataTermsAndConditionsDetail();
            })
            .catch(error => {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                this.setState({ isLoading: false, serviceError: true })

            });

    }

    SaveCookie = () => 
        {
            const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
            const data = {
                "cusId": null,
                "cookieCode": this.state.cookieCode,
                "cookieVersionDisplay": this.state.cookieVersionDisplay,
                "cookieVersionOrder": this.state.cookieVersionOrder,
                "channelCode": MainData.initialData.channelCode,
            };
            axios.post(MainData.serviceURL + '/Customer/AddCustomerCookie', data, {
                headers: headers
            })
            .then(async res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                if (!res.data.success)
                {
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                    this.setState({ isLoading: false, serviceError: true })
                }
                else
                {
                    sessionStorage.setItem('_cookie', 'true'); 
                    this.setState({ _cookie: 'true' }); 
                    if(this.state.activePage == "SM002"){
                        await this.addOrUpdateCustomerBirthday();
                    }else if(this.state.activePage == "SM003"){
                        await this.addOrUpdateCustomerBirthday();
                        await this.saveDataProduct();
                    }else if(this.state.activePage == "SM004"){
                        await this.addOrUpdateCustomerBirthday();
                        await this.saveDataProduct();
                        if(this.state.masPage.find(dataPage => dataPage.subMenuCode == "SM003")){
                            await this.addOrUpdateCustomerQuestion();
                        }
                    }else if(this.state.activePage == "SM005"){
                        await this.addOrUpdateCustomerBirthday();
                        await this.saveDataProduct();
                        if(this.state.masPage.find(dataPage => dataPage.subMenuCode == "SM003")){
                            await this.addOrUpdateCustomerQuestion();
                        }
                        if(this.state.masPage.find(dataPage => dataPage.subMenuCode == "SM004")){
                            await this.addOrUpdateCustomerFatca();
                        }
                    }

                }
            })
            .catch(error => {
                if(error?.response?.status == 400){
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                    this.setState({ isLoading: false, serviceError: true })
                }
                else{
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                    this.setState({ isLoading: false, serviceError: true })
                }
            });
        }

    closeCookie = () => {
            sessionStorage.setItem('_cookie', 'close'); this.setState({ _cookie: 'close' });
    }

    addOrUpdateCustomerBirthday = async () => { //service addOrUpdate sm001
        this.loadController(true)
        const headers = { 
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
        "Content-Type": "application/json;charset=UTF-8", 
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Credentials": "true", 
        "Access-Control-Allow-Methods": 
        "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
        "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
        }
        const data = {
          "channelCode": MainData.initialData.channelCode,
          "productId": MainData.initialData.productId,
          "gender":  MainData.screenSM001.gender,
          "birthDate": MainFunc.getUserBirthDate("ac"),
          "age": moment().diff( MainFunc.getUserBirthDate("ac"), "years"),
          "transUrl": window.location.href,
          "submenuCode":"SM001",
        };
        await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerBirthday', data, {
          headers: headers
        })
        .then(res => { 
          if(res.status === 299){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
          }  
          if(res.data.success === true){
            MainData.screenSM005.cusId = res.data.data;
            this.loadController(false)
          }
        })
        .catch(error => {
          this.loadController(false)
          if(error?.response?.status == 400){
            this.setPage(this.state.masPage.find(item => item.subMenuCode == "SM001"));
          }
          else{
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
            this.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
          }
        });
    }

    saveDataProduct = async () => { //service addOrUpdate sm002
        this.loadController(true)
        const headers = { 
        Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
        "Content-Type": "application/json;charset=UTF-8", 
        "Access-Control-Allow-Origin": "*", 
        "Access-Control-Allow-Credentials": "true", 
        "Access-Control-Allow-Methods": 
        "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
        "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
        }
        const data = {
          "channelCode": MainData.initialData.channelCode,
          "productId": MainData.initialData.productId,
          "planId": MainData.screenSM002.planId,
          "premium": MainData.screenSM002.cost,
          "paymentId": MainData.screenSM002.paymentId,
          "paymentMode" : MainData.screenSM002.paymentCode,
          "transUrl": window.location.href,
          "submenuCode": "SM002",
        };
        await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerPremiumCal', data, {
          headers: headers
        })
        .then(res => { 
            if(res.status === 299){
                window.location.href = MainData.mainPath + 'shutdown'
                return;
            }    
            if(res.data.success === true){
                MainData.screenSM005.cusId = res.data.data;
                this.loadController(false)
            }
        })
        .catch(error => {
            if(error?.response?.status == 400){
                this.setPage(this.state.masPage.find(item => item.subMenuCode == "SM002"));
            }
            else{
                this.loadController(false)
                let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
            }
        });
    }

    addOrUpdateCustomerQuestion = async () => { //service addOrUpdate sm003
        const headers = { 
          "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
          "Content-Type": "application/json;charset=UTF-8", 
          "Access-Control-Allow-Origin": "*", 
          "Access-Control-Allow-Credentials": "true", 
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
          "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
          "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
        }
        const data = {
          "healthQuestions": MainData.screenSM013.healthQuestions,
          "submenuCode": "SM003",
          "channelCode": MainData.initialData.channelCode,
          "transUrl": window.location.href,
        }
        await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerQuestion', data, {
          headers: headers
        })
        .then(res => { 
            if(res.status === 299){
                window.location.href = MainData.mainPath + 'shutdown'
                return;
            }    
            if(res.data.success !== true){
                this.loadController(false)
                let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
                this.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription, ' ')
                MainData.screenSM005.cusId = res.data.data;
                this.loadController(false)
            }
        })
        .catch(error => {
            if(error?.response?.status == 400){
                this.setPage(this.state.masPage.find(item => item.subMenuCode == "SM003"));
            }
            else{
                this.loadController(false)
                let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
            }
        });
    }

    addOrUpdateCustomerFatca = async () => { //service addOrUpdate sm004
        const headers = { 
          "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
          "Content-Type": "application/json;charset=UTF-8", 
          "Access-Control-Allow-Origin": "*", 
          "Access-Control-Allow-Credentials": "true", 
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
          "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
          "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
        }
        const data = {
          "FatcaQuestions": MainData.screenSM004.fatcaAnsForservice,
          "CRSQuestions": MainData.screenSM004.crsAnsForservice,
          "submenuCode": "SM004",
          "channelCode": MainData.initialData.channelCode,
          "transUrl": window.location.href
        }
        await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerFatca', data, {
          headers: headers
        })
        .then(res => {
            if(res.status === 299){
                window.location.href = MainData.mainPath + 'shutdown'
                return;
            }  
            if(res.data.success !== true){
                this.loadController(false)
                let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
                this.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription, ' ')
                if(MainData.screenSM004.fatcaAnsPDPA ){
                MainFunc.logPDPA('Edit', this.mainMenuName, this.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
                }else{
                MainFunc.logPDPA('Add', this.mainMenuName, this.name, null, null, this.state.fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
                }
            }else{
                if(MainData.screenSM004.fatcaAnsPDPA ){
                MainFunc.logPDPA('Edit', this.mainMenuName, this.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Success', null)
                }else{
                MainFunc.logPDPA('Add', this.mainMenuName, this.name, null, null, this.state.fatcaAnsPDPA, 'Success', null)
                }
                MainData.screenSM004.fatcaAnsPDPA = this.state.fatcaAnsPDPA
                MainData.screenSM005.cusId = res.data.data;
                this.loadController(false)
            }
        })
        .catch(error => {
            if(error?.response?.status == 400){
                this.setPage(this.state.masPage.find(item => item.subMenuCode == "SM004"));
            }
            else{
                this.loadController(false)
                let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
            }
        });
    }

    formatDateTimeDisplay = (date, time, format) => {
        let lang = "EN";
        let newFormat = '';
        let checkTime = false;
        let checkSecond = false;
        let SlashOrGrade = '';
        if (date) {
            if (date.indexOf(":") > 0) {
                checkTime = true
                var strLeDate = date.length;
                var strLeDateNew = date.split(':').join('').length;
                var num = strLeDate - strLeDateNew;
                if (num == 1) {
                    checkSecond = false;
                }
                else if (num == 2) {
                    checkSecond = true;
                }
            }
            if (checkTime) {
                let arrDateTime = date.split(' ');
                let arrDate = arrDateTime[0].split(/[/-]/);
                let arrTime = arrDateTime[1].split(':');
                let newDate = '';
                if (checkSecond) {
                    if (arrDate[0].length == 4) {
                        newDate = new Date((arrDate[0]), (arrDate[1] - 1), arrDate[2], arrTime[0], arrTime[1], arrTime[2]);
                    }
                    else {
                        newDate = new Date((arrDate[2]), (arrDate[1] - 1), arrDate[0], arrTime[0], arrTime[1], arrTime[2]);
                    }
                    if (lang.toUpperCase() == "TH") {
                        newFormat = moment(newDate).add(+543, 'year').format(format);
                    }
                    else {
                        newFormat = moment(newDate).format(format);
                    }
                }
                else {
                    if (arrDate[0].length == 4) {
                        newDate = new Date((arrDate[0]), (arrDate[1] - 1), arrDate[2], arrTime[0], arrTime[1]);
                    }
                    else {
                        newDate = new Date((arrDate[2]), (arrDate[1] - 1), arrDate[0], arrTime[0], arrTime[1]);
                    }
                    if (lang.toUpperCase() == "TH") {
                        newFormat = moment(newDate).add(+543, 'year').format(format);
                    }
                    else {
                        newFormat = moment(newDate).format(format);
                    }
                }
            }
            else {
                let arrDate = date.split(/[/-]/);
                let newDate = '';
                if (arrDate[0].length == 4) {
                    newDate = new Date((arrDate[0]), (arrDate[1] - 1), arrDate[2]);
                }
                else {
                    newDate = new Date((arrDate[2]), (arrDate[1] - 1), arrDate[0]);
                }
                if (lang.toUpperCase() == "TH") {
                    newFormat = moment(newDate).add(+543, 'year').format(format);
                    if (time) {
                        newFormat += '';
                    }
                }
                else {
                    newFormat = moment(newDate).format(format);
                }
            }
        }
        return newFormat;
    }

    openCookieDescription = () => {
        this.setState({cookieDescriptionCheck: true})
      }

      checkProductDetailStatus = () => {
        const headers = { 
            "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        }
        const data = {
            "channelCode" : MainData.initialData.channelCode,
            "source" : null,
            "productId": MainData.initialData.productId,
            "lang": "TH"
        }
        axios.post(MainData.serviceURL + '/Customer/GetProductdetail', data, {
        headers: headers
    
        })
        .then(res => {
          let data = res.data.data;
          if(!data.isProductDetailStatus){
            sessionStorage.setItem("bearerAuth", MainData.initialData.bearerAuth);
            sessionStorage.setItem("labelDtos", JSON.stringify(MainData.initialData.labelDtos));
            sessionStorage.setItem("configDtos", JSON.stringify(MainData.initialData.configDtos));
            sessionStorage.setItem("dropleadDtos", JSON.stringify(MainData.dropleadDtos));
            sessionStorage.setItem("productId", MainData.initialData.productId);
            window.location.href = MainData.mainPath + 'notfound'
          }

        })
        .catch(error => {

        });


    }

    getMobileOperatingSystem = async() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
          return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
        return "Browser";
      };
    
    scrollContentSectionTop = () => {
        document.getElementById("contentSection").scrollTo({ top: 0, behavior: 'smooth' });
    }
    

    render() {
        let screenHeight = window.innerHeight;
        const currentPage = this.state.masPage.filter(masP => this.state.activePage === masP.subMenuCode).map((item, key) => {
            return (
                <item.screen 
                    key={key} 
                    screenIndex={key} 
                    t={this.props.t} 
                    masPage={this.state.masPage} 
                    setPage={this.setPage} 
                    next={item.next} 
                    prev={item.prev} 
                    name={item.name} 
                    mainMenuName={item.mainMenuName} 
                    seq={item.seq} 
                    nowPage={item.nowPage} 
                    maxPage={item.maxPage} 
                    loadController={this.loadController} 
                    errorAlert={this.errorAlert} 
                    checkCookie={key === 0 ? true : false} 
                    setHeaderText={this.setHeaderText} 
                    calContentHeight={this.calContentHeight} 
                    setPremiumPayment={this.setPremiumPayment}
                    showKplus={()=> this.setState({ checkKplus:true })} 
                    scrollContentSectionTop={()=>this.scrollContentSectionTop()}
                    setGetProductFail={(data) => {this.setState({getProductFail:data})}}
                    getProductFail={this.state.getProductFail}
                />
            )
        })

        const progressStep = this.state.menuStep.filter(menuStep => menuStep.menuCode !== "M001").map((item, key) => {
            return (
                <ProgressStep key={key} name={item.menuCode} label={item.name} />
            )
        })

        return (
            <>
                {this.state.serviceError === true ?
                    <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                        <SweetAlert show={true} title={"ขออภัย"} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={<></>} ><h5 className="text-danger">{'ไม่สามารถเข้าสู่ระบบ Online Sales ได้ กรุณาเข้าระบบใหม่อีกครั้ง'}</h5><span className="text--muted">{"ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"}</span></SweetAlert>
                    </div>
                    : 
                     <div style={{ height: screenHeight, maxHeight: screenHeight }}>
                    { this.state.NotfoundProduct === true ?
                        <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                        <SweetAlert show={true} title={"ขออภัย"} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={<></>} ><h5 className="text-danger">{this.state.messageDescription.replace("\\n","")?.replace("\\","") && parse(this.state.messageDescription?.replace("\\n","")?.replace("\\",""))}</h5></SweetAlert>
                         </div>
                            : null }
                        <ReactNoti position={POSITION.TOP_RIGHT} showProgress={true} timeOut={3000} />
                        {this.state.isLoading ?
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading" style={{ zIndex: "99", position: "fixed" }}>
                                <Row >
                                    <Col xs={12} className="w-100 d-flex justify-content-center"><h3 className="csMainColor">Loading</h3></Col>
                                    <Col xs={12} className="w-100 d-flex justify-content-center"><SyncLoader size={15} loading={true} /></Col>
                                </Row>
                            </div>
                            : null}
                        <SweetAlert show={this.state.isError} title={""} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={this.errorConfirm} showCancel closeOnClickOutside={false} customButtons={
                            <Row>
                                <Button onClick={this.errorConfirm} className="mainBtnRadius">{MainFunc.getLabelDtos("L0188") /* ปิด */}</Button>
                            </Row>
                        } ><h5 className={!this.state.redirectFromKplus?"text-danger":""} dangerouslySetInnerHTML={{ __html: this.state.errMsg }}></h5><span className="text--muted" dangerouslySetInnerHTML={{ __html: !this.state.errSubMsg ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา" : this.state.errSubMsg }} ></span></SweetAlert>
                        {/* MODAL */}
                        <SweetAlert show={this.state.TermAndConditionModal} title={''} onConfirm={() => { this.setState({ TermAndConditionModal: false }) }} onCancel={() => { this.setState({ TermAndConditionModal: false }) }} customButtons={<></>} >
                            <div className="container-fulid">
                                <div onClick={() => this.setState({ TermAndConditionModal: false })} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare /></div>
                                <span dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionsDetail }} style={{ textAlign: 'left' }}></span>
                            </div>
                        </SweetAlert>
                        <SweetAlert show={this.state.DropoffModal} customIcon={<Image className="pb-2" style={{display: "block",marginLeft: "auto",marginRight: "auto",width: "100px"}} src={IcoClock} rounded />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={
                                <div>
                                    <Row>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { sessionStorage.setItem("timeout1", moment().add(+1, 'minute').format("YYYY/MM/DD/HH/mm/ss")); this.setState({ DropoffModal: false, showDropLead: true }) }} className="subBtnRadius">{"ออกจากหน้านี้"}</Button></Col>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { sessionStorage.setItem("timeout1", moment().add(+1, 'minute').format("YYYY/MM/DD/HH/mm/ss")); this.setState({ DropoffModal: false }) }} className="mainBtnRadius">{MainFunc.getLabelDtos("L0167")}</Button></Col>
                                    </Row>
                                </div>
                            } ><span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{this.state.notiMsg}</span><br></br>
                        </SweetAlert>
                        <div className="sweetalert--timeout">
                            <SweetAlert show={this.state.isTimeout} title={""} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={
                                <div>
                                    <Row>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { window.location.href = sessionStorage.getItem("homePage"); }} className="subBtnRadius">{MainFunc.getLabelDtos("L0166")}</Button></Col>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { sessionStorage.setItem("timeout", moment().add(+1, 'minute').format("YYYY/MM/DD/HH/mm/ss")); this.setState({ isTimeout: false }) }} className="mainBtnRadius">{MainFunc.getLabelDtos("L0167")}</Button></Col>
                                    </Row>
                                </div>
                                }>
                                <h5 className="text-danger">{'ใช้งานระบบ Online Sales ค้างไว้เกิน 1 ชั่วโมง โดยไม่ดำเนินการใดๆ'}</h5><span className="text--muted">{"ต้องการทำรายการต่อหรือไม่"}</span>
                            </SweetAlert>
                        </div>
                        <SweetAlert show={this.state.cookieDescriptionCheck} title={''} onConfirm={()=>{this.setState({cookieDescriptionCheck: false})}} onCancel={()=>{this.setState({cookieDescriptionCheck: false})}} customButtons={<></>} >
                            <div className="container-fulid">
                            <Image src={IcoCrossSign} rounded style={{ height: '10px', left: '0', top: '-5px' }} onClick={()=>this.setState({cookieDescriptionCheck: false})} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", width: 20, margin: 20, marginTop: 22 }}></Image>
                            <div onClick={()=>this.setState({cookieDescriptionCheck: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
                                <span dangerouslySetInnerHTML={{__html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">นโยบายคุกกี้และการตั้งค่า</h4>`+ this.state.cookieDescription}} style={{textAlign: 'left'}}></span>
                            </div>
                        </SweetAlert>
                        <SweetAlert
                            show={this.state.isErrorBirthday}
                            title={""}
                            customIcon={
                                <GoAlert size={70} className="text-center w-100 text-danger pb-2" />
                            }
                            custom
                            onConfirm={() => {
                                this.setState({ isErrorBirthday: false });
                            }}
                            showCancel
                            closeOnClickOutside={false}
                            customButtons={
                                <Row>
                                    <Col xs={12}>
                                        <Button
                                        onClick={() => this.skipHAndFChangeBirthdatePage()}
                                        className="mainBtnRadius"
                                        >
                                        {MainFunc.getLabelDtos("L0029") /* ยืนยัน */}
                                        </Button>
                                    </Col>
                                </Row>
                            }
                            >
                            {/* <h5
                                // className="text-danger"
                                dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0165") }}
                            ></h5> */}
                            <span
                                className="text--muted"
                                dangerouslySetInnerHTML={{ __html: this.state.isErrorBirthdayDesc }}
                            ></span>
                        </SweetAlert>
                        <div className={ !this.state.showScreen ? "d-none" : "" }>
                        <Row id="logoSection" className="pl-2 pr-2 pt-2 pb-2 csMainColorHeaderBG d-flex align-items-center" style={{ height: "90px" }}>
                            <Image id="channelLogo" src={!this.state.channel.logo ? logo2 : this.state.channel.logo} rounded style={{ height: '103px', position: 'absolute', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                            <div className="text-white mw-100" style={{ paddingLeft: "120px",fontSize: '20px' }}>
                                {!this.state.isSelectedForHeder || this.state.checkKplus ?
                                    <>
                                        <span className="mb-0 font-weight-bold pt-3 mt-0 d-block" style={{ lineHeight: "1.3rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "1.75rem" }}>Online Sales</span>
                                        <small className="d-block" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{MainFunc.getLabelDtos("L0465")/* ซื้อประกันออนไลน์ที่เหมาะกับคุณ */}</small>
                                    </>
                                    :
                                    <>
                                        <span className="mb-0 font-weight-bold pt-1 mt-0 d-block" style={{ lineHeight: "1.3rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "1.25rem" }}>{this.state.mainHeaderText}</span>
                                        <small className="d-block" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{this.state.subHeaderText}</small>
                                        <small className="d-block" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{!this.state.thirdHeaderText ? "" : this.state.thirdHeaderText}</small>
                                    </>
                                }
                            </div>
                        </Row>

                        <Row id="stepSection" className="bg-white" style={{ borderBottom: "2px solid rgb(221, 223, 227)" }}>
                            <Col xs={12} className={this.state.activeMenu === 'M001' || this.state.checkKplus ? 'd-none' : 'pb-3 mb-2 ml-auto mr-auto text--min'} style={{ maxWidth: "1140px" }}>
                                <ProgressIndicator currentStepName={this.state.activeMenu} currentStepIndex={this.state.activeMenuIndex}>
                                    {progressStep}
                                </ProgressIndicator>
                            </Col>
                        </Row>
                        <Row id="contentSection" style={{ height: this.state.conetntHeight, maxHeight: this.state.conetntHeight, overflowY: 'scroll' }}>
                            <Col xs={12} className={`${!this.state.checkKplus && !this.state.showQrKplus?"pb-4 pt-0":""} ml-auto mr-auto`} 
                                style={{ 
                                    maxWidth: this.state.checkKplus?"1200px":"960px", 
                                    paddingTop:this.state.checkKplus && !this.state.showQrKplus ? this.state.conetntHeight < 265 ? this.state.conetntHeight/2 : 265/2:0,
                                    paddingBottom:this.state.checkKplus && !this.state.showQrKplus ? this.state.conetntHeight < 265 ? this.state.conetntHeight/2 : 265/2:0,
                                }}
                            >
                                {this.state.checkKplus?
                                <SMKplus 
                                    onCancel={() => this.setState({checkKplus:false,showQrKplus:false})}
                                    loadController={this.loadController} 
                                    errorAlert={this.errorAlert} 
                                    setPage={this.setPage} 
                                    saveCookie={()=>this.SaveCookie()}
                                    showQrKplus={(data) => this.setState({showQrKplus:data})}
                                    scrollContentSectionTop={() => this.scrollContentSectionTop()}
                                    setHeaderText={this.setHeaderText}
                                    sourceIni={this.state.sourceIni}
                                /> : 
                                currentPage}
                            </Col>
                            <Col xs={12} className={this.state.checkKplus && !this.state.showQrKplus?"d-none":""} style={{ maxWidth: '100%', minHeight: '0px', maxHeight: '265px', height: this.state.conetntHeight - 688 }}></Col>
                            <Row className="w-100 oppositeWhite" style={{ position: "relative", bottom: "0px", left: "15px" }}>
                                <Col xs={12} className="pl-4 pt-4 pr-4 w-100 oppositeBlackBlue">
                                    <Row>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0181")}</span><br></br>
                                            <small className="">{MainFunc.getLabelDtos("L0182")}</small><br></br>
                                            <Image className="mb-2 mr-2" src={IcoTel} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <span className="font-weight-bold mt-1" style={{ fontSize: "1.8rem" }}>{MainFunc.getLabelDtos("L0183")}</span><br></br>
                                            <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0184")}</span>
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">Payment Method</span><br></br>
                                            <Image className="mt-2 mb-2 mr-2" src={IcoKbank} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoVS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoMC} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoCS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /><br></br>
                                            <span className="font-weight-bold m-0">Follow us</span><br></br>
                                            <a href={MainFunc.getConfigDtos("CF012")}><Image className="mt-2 mb-2 mr-2" src={IcoYt} rounded style={{ height: '20px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF013")}><Image className="mt-2 mb-2 mr-2" src={IcoIg} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF014")}><Image className="mt-2 mb-2 mr-2" src={IcoFb} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF015")}><Image className="mt-2 mb-2 mr-2" src={IcoLine} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF016")}><Image className="mt-2 mb-2 mr-2" src={IcoLinkedin} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">Certified</span><br></br>
                                            <Image className="mt-2 mb-2 mr-2" src={IcoDBD} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className="pl-4 pr-4 w-100 oppositeBlack">
                                    <Row>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a onClick={() => { this.setState({ TermAndConditionModal: true }) }} style={{ cursor: 'pointer' }}><span className=" m-0">{MainFunc.getLabelDtos("L0173")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a href={MainFunc.getConfigDtos("CF001")}><span className=" m-0">{MainFunc.getLabelDtos("L0171")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a href={MainFunc.getConfigDtos("CF002")}><span className=" m-0">{MainFunc.getLabelDtos("L0172")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a href={MainFunc.getConfigDtos("CF011")}><span className="m-0">{MainFunc.getLabelDtos("L0174")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={4} className="mt-2 mb-2">
                                            <span className="m-0">{MainFunc.getLabelDtos("L0180")}</span><br></br>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className={this.state.showfooter ? 'pt-3 pb-3 d-flex justify-content-center' : 'd-none'}>
                                    <small className="text--muted text-center">{MainFunc.getLabelDtos("L0008")}</small>
                                </Col>
                            </Row>
                        </Row>
                        {this.state._cookie === 'true' ? null 
                            :
                            [
                                this.state._cookie === 'close' ? null :
                                <div className="text-white w-100 d-flex justify-content-center" style={{ position: "absolute", bottom: 0, backgroundColor: "rgba(0,0,0,0.8)", left: 0 }}>
                                    <Image src={IcoCrossSign} rounded style={{position: "absolute", right: "0", padding: "10px", filter: "opacity(0.4) drop-shadow(0 0 0 #DDDEE0)", width: "30px", height: "30px"}} onClick={()=>this.closeCookie()} ></Image>
                                        <div className="pl-3 pr-3 pb-4 pt-3 mt-4">
                                            <span className="justify-content-center">{parse(this.state.cookieDetail?this.state.cookieDetail:"")}</span><span className="spanLink" onClick={()=>this.openCookieDescription()}>{MainFunc.getLabelDtos("L0440")}</span>
                                        <div className="w-100 d-flex justify-content-center pt-2">
                                            <Button variant="info pl-4 pr-4" style={{ borderRadius: "25px" }} onClick={() => {this.SaveCookie()}}>{MainFunc.getLabelDtos("L0260") /* ยอมรับ */}</Button>
                                        </div>
                                    </div>
                                </div>
                                ]
                        }
                        <Image  src={IcoCallCenter} className={"drop--lead--ico"} id={`${MainData.initialData.codeTracking}Click_BtnChat`} title="DropLead" onClick={()=>{this.setState({showDropLead: !this.state.showDropLead})}}></Image>

                        <Collapse in={this.state.showDropLead} timeout="auto" unmountOnExit>
                            <div className={"drop--lead--box"} id={"dropLeadBox"}>
                                <Card class="text-center csMainBG text-white card-header" border="light">
                                    <Card.Header class="text-center csMainBG text-white card-header">
                                        <Image src={IcoCrossSign} rounded style={{ height: '10px', left: '0', top: '-5px' }} onClick={() => this.closeDroplead()} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", width: 20, margin: 20, marginTop: 22 }}></Image>
                                        <Row id="logoSection" className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center" style={{ height: "45px" }}>
                                            <Image id="channelLogo" src={!this.state.channel.logo ? logo2 : this.state.channel.logo} rounded style={{ height: '95px', position: 'absolute', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <div>
                                                <Form.Label className="" style={{  fontSize: "1.2rem", fontWeight: "bold", marginLeft: 90}}>{MainFunc.getLabelDtos("L0466") /* ให้เจ้าหน้าที่ติดต่อกลับ */}</Form.Label>
                                            </div>
                                        </Row>
                                    </Card.Header>
                                    {
                                        (this.state.step == "1") ?
                                            <Card.Body >
                                                <div style={{ height: "420px", overflow: "hidden", overflowY: "scroll", padding: "12px" }}>
                                                    <Form>
                                                        <Form.Group className="font-weight-bold " hasValidation>
                                                            <Form.Label>{MainFunc.getLabelDtos("L0467") /* ชื่อ - นามสกุล */}
                                                            </Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.name || this.state.checkInputname ? "input--error" : ""}`} type="text" name="name" value={this.state.name} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.nameerror}</span>
                                                        </Form.Group>
                                                        <Form.Group className="font-weight-bold " >
                                                            <Form.Label >{MainFunc.getLabelDtos("L0468") /* เบอร์มือถือ */}</Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.phoneNumber || this.state.checkInputphoneNumber ? "input--error" : ""}`} type="phoneNumber" name="phoneNumber" value={this.state.phoneNumber} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.failPhone}</span>
                                                        </Form.Group>
                                                        <Form.Group className="font-weight-bold " >
                                                            <Form.Label>{MainFunc.getLabelDtos("L0469") /* อีเมล */}</Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.email || this.state.checkInputemail ? "input--error" : ""}`} type="text" name="email" placeholder="yourmail@mail.com" value={this.state.email} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.emailerror}</span>
                                                        </Form.Group>
                                                        <Form.Label className="font-weight-bold ">{MainFunc.getLabelDtos("L0470") /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */}</Form.Label>
                                                        <Form.Group>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.selectDate ? "input--error" : ""}`} as="select" name="selectDate" placeholder="ช่วงเวาลาที่สะดวกให้ติดต่อกลับ" value={this.state.selectDate} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)}>
                                                                <option value="" disabled selected>{MainFunc.getLabelDtos("L0470") /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */}</option>
                                                                {MainData.dropleadDtos.map((item, key) => {
                                                                    return (
                                                                        <option value={item.contactTimeName}>{item.contactTimeName}</option>
                                                                    )
                                                                })}
                                                            </Form.Control>
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.failselectDate}</span>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginTop: 20 }} className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox" value={this.state.checkboxDroplead} onChange={(e) => { this.setState({ checkboxDroplead: e.target.checked }) }} label="ข้าพเจ้ายินยอมให้ บมจ.เมืองไทยประกันชีวิต ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้ นายหน้าประกันชีวิต และพันธมิตร/คู่ค้า เพื่อวัตถุประสงค์ในการนำเสนอขายประกันชีวิตแบบต่างๆ และ/หรือนำเสนอสิทธิประโยชน์อื่นๆ ให้แก่ข้าพเจ้าได้" />
                                                        </Form.Group>
                                                    </Form>
                                                    <Col className="d-flex justify-content-center pt-3">
                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem" }} onClick={() => { this.serviceDroplead() }} disabled={!this.state.checkboxDroplead} >{MainFunc.getLabelDtos("L0471") /* ส่งข้อมูลสอบถาม */}</Button>
                                                    </Col><br></br>
                                                    <span className="" style={{ fontSize: "1.1rem",paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0472") /* ติดต่อเจ้าหน้าที่ */}</span><br></br>
                                                    <span className="" style={{ fontSize: "1.1rem",paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0473") /* ทุกวันทำการ จันทร์ - ศุกร์ เวลา 08:00 - 18:00 */}</span><br></br>
                                                    <Row>
                                                        <Col xs={1} style={{ marginTop: 10 }}>
                                                            <Image className="mb-2 mr-2" src={IcoTelGreen} rounded style={{ height: '30px', left: '0', top: '-5px',paddingLeft: "1.25rem" }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                        </Col>
                                                        <Col className="drop--lead--call">
                                                            <a href={`tel:${MainFunc.getLabelDtos("L0183")}`} className="font-weight-bold mt-1 telClass" style={{ fontSize: "1.6rem", width: 75,paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0183")}</a>
                                                            <a className="font-weight-bold mt-1 callToClass" style={{ fontSize: "1.6rem", width: 75,paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0183")}</a><br></br>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card.Body>
                                            :
                                            [
                                                (this.state.step == "2") ?
                                                    <Card.Body >
                                                        <div style={{ marginTop: 150 }}>
                                                            <Spinner className="img-center" animation="border" role="status" style={{ width: "60px", height: "60px" }}>
                                                                <span className="visually-hidden"></span>
                                                            </Spinner>
                                                        </div>
                                                    </Card.Body>
                                                    :
                                                    [
                                                        (this.state.step == "3") ?
                                                            <Card.Body >
                                                                <div style={{ margin: 40 }}>
                                                                    <Image className="img-center" src={IcoCorrect} ></Image>
                                                                    <div className="text-align-center ">
                                                                        <span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0474") /* บริษัทฯ ได้รับข้อมูลเรียบร้อยแล้ว กรุณารอเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ */}</span><br></br>
                                                                        <span className="font-weight-bold text-center font-collor1 " style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0475") /* ขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน) */}</span><br></br>
                                                                    </div>
                                                                    <Col className="d-flex justify-content-center pt-3">
                                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width: 200 }} onClick={() => this.closeDroplead() } >{MainFunc.getLabelDtos("L0185") /** ตกลง */}</Button>
                                                                    </Col>
                                                                </div>
                                                            </Card.Body>
                                                            :
                                                            <Card.Body >
                                                                <div style={{ margin: 70 }}>
                                                                    <Image className="img-center" src={IcoError} ></Image>
                                                                    <div className="text-align-center " style={{ marginTop: 30 }}>
                                                                        <span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0476") /** ขออภัยในความไม่สะดวก */}</span><br></br>
                                                                        <span className="font-weight-bold text-center font-collor1 " style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0477") /** กรุณาลองใหม่อีกครั้ง */}</span><br></br>
                                                                    </div>
                                                                    <Col className="d-flex justify-content-center pt-3" style={{ marginTop: 30 }}>
                                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width: 200 }} onClick={() => this.closeDroplead()} >{MainFunc.getLabelDtos("L0188") /** ปิด */}</Button>
                                                                    </Col>
                                                                </div>
                                                            </Card.Body>

                                                    ]
                                            ]
                                    }
                                </Card>
                            </div>
                        </Collapse>
                        </div>

                        {/* dropoff */}

                        {/* <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                            <SweetAlert show={this.state.DropoffModal} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={
                                <div>
                                    <Row>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { sessionStorage.setItem("timeout1", moment().add(+1, 'minute').format("YYYY/MM/DD/HH/mm/ss")); this.setState({ DropoffModal: false, showDropLead: !this.state.showDropLead }) }} className="subBtnRadius">{"ออกจากหน้านี้"}</Button></Col>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { sessionStorage.setItem("timeout1", moment().add(+1, 'minute').format("YYYY/MM/DD/HH/mm/ss")); this.setState({ DropoffModal: false }) }} className="mainBtnRadius">{MainFunc.getLabelDtos("L0167")}</Button></Col>
                                    </Row>
                                </div>
                            } ><span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{this.state.notiMsg}</span><br></br>
                            </SweetAlert>
                        </div> */}
                    </div>}
            </>
        )
    }
}

export default withTranslation()(MainPage);


