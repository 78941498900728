// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = useMarkdown2React;

var _react = _interopRequireWildcard(require("react"));

var _unified = _interopRequireDefault(require("unified"));

var _remarkParse = _interopRequireDefault(require("remark-parse"));

var _remarkRehype = _interopRequireDefault(require("remark-rehype"));

var _rehypeMinifyWhitespace = _interopRequireDefault(require("rehype-minify-whitespace"));

var _rehypeHighlight = _interopRequireDefault(require("rehype-highlight"));

var _rehypeReact = _interopRequireDefault(require("rehype-react"));

var _renderers = _interopRequireDefault(require("../renderers"));

function useMarkdown2React(source) {
  return (0, _react.useMemo)(function () {
    return (0, _unified["default"])().use(_remarkParse["default"]).use(_remarkRehype["default"]).use(_rehypeHighlight["default"], {
      subset: false
    }).use(_rehypeReact["default"], {
      createElement: _react["default"].createElement,
      components: _renderers["default"]
    }).use(_rehypeMinifyWhitespace["default"], {
      newlines: true
    }).processSync(source).result;
  }, [source]);
}