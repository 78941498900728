import axios from "axios";
import moment from "moment";
import { notify } from "react-noti";
import React, { Component, Fragment } from "react";
import { Row, Col, Button } from "react-bootstrap";
import IconDropdownSms from "../../images/dropdownSms.svg";
import IconCheckPink from "../../images/icon_check_pink.svg";
import IconCheck from "../../images/icon_check.svg";
import styles from "../../styles/CardHistoryProduct.module.scss";
import MainFunc from "../../models/MainFunc";

class CardHistoryProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "Otp",
    };
  }

  componentDidMount() {}

  toggleDetail = () => {
    if (
      document.documentElement.clientWidth <= 577 ||
      (this.props.data.orderAlive &&
        this.props.data.orderAlive > 0 &&
        this.props.data.paymentStatus != "success")
    ) {
      this.props.openToggle
        ? this.props.setOpenToggle(0, null)
        : this.props.setOpenToggle(
            this.props.index,
            this.props.data.orderAlive &&
              this.props.data.orderAlive > 0 &&
              this.props.data.paymentStatus != "success"
              ? this.props.data
              : null
          );
    }
  };

  setCreateDate = (val) => {
    let date = val.split(" ")[0].split(/[/-]/);
    let time = val.split(" ")[1].split(/[:]/);
    let newDateTime = new Date(
      date[2],
      date[1] - 1,
      date[0],
      time[0],
      time[1],
      time[2]
    );
    return `${moment(newDateTime).format("DD/MM/")}${moment(newDateTime).add(+543, 'year').format(
      "YYYY"
    )} ${moment(newDateTime).format("HH:mm:ss")}`;
  };
  setPaymentStatus = (val) => {
    let text = MainFunc.getLabelDtos("L0690"); //"รอชำระเงิน";
    if (val == "success") {
      text = MainFunc.getLabelDtos("L0375"); // "สำเร็จ";
    } else if (val == "fail") {
      text = MainFunc.getLabelDtos("L0691"); //"ชำระเงินไม่สำเร็จ";
    }
    return text;
  };
  setPremium = (data) => {
    let genText = (
      <span>
        {MainFunc.numberWithCommas(this.props.data.premium)} /{" "}
        {this.props.data.paymentModeText}
      </span>
    );
    if (data.premiumDiscount) {
      genText = (
        <Fragment>
          <span>
            {MainFunc.numberWithCommas(this.props.data.premiumDiscount)}
          </span>
          <span className={`${styles.lineThrough} ${styles.desktop}`}>
            {MainFunc.numberWithCommas(this.props.data.premium)}
          </span>
          <span> / {this.props.data.paymentModeText}</span>
          <span className={`${styles.lineThrough} ${styles.mobile}`}>
            {MainFunc.numberWithCommas(this.props.data.premium)}
          </span>
        </Fragment>
      );
    }
    return genText;
  };

  render() {
    return (
      <div
        className={`${styles.card} ${this.props.openToggle?styles.active:""} ${(this.props.data.paymentStatus == "success" || (this.props.data.orderAlive < 0 || !this.props.data.orderAlive))?styles.inActive:""}`}
        onClick={this.toggleDetail}
      >
        <div
          className={`${styles.cardHeader} ${this.props.openToggle?styles.active:""} ${(this.props.data.paymentStatus == "success" || (this.props.data.orderAlive < 0 || !this.props.data.orderAlive))?styles.inActive:""}`}
        >
          <div className={styles.productPlanAndChannel}>
            <div className={styles.productAndPlan}>
              <span className={styles.productName}>
                {this.props.data.productName}
              </span>
              <span className={styles.plan}>{this.props.data.planName}</span>
            </div>
            <div className={styles.channelAndStatus}>
              <span className={styles.channel}>
                {this.props.data.channelCode}
              </span>
            </div>
          </div>
          <div className={styles.statusAndIcon}>
            <div className={styles.icon}>
              <img
                className={`${styles.iconMobile} ${
                  this.props.openToggle && styles.active
                }`}
                src={IconDropdownSms}
              ></img>
              <div
                className={`${styles.iconDesktop} ${
                  this.props.openToggle && styles.active
                } ${
                  this.props.data.paymentStatus == "success" ||
                  this.props.data.orderAlive < 0 ||
                  !this.props.data.orderAlive
                    ? "d-none"
                    : ""
                }`}
              >
                <img className={styles.normal} src={IconCheck} />
                <img className={styles.hover} src={IconCheckPink} />
              </div>
            </div>
            <span
              className={`${styles.status} ${
                this.props.data.paymentStatus == "success"
                  ? styles.succeed
                  : this.props.data.orderAlive < 0 ||
                    !this.props.data.orderAlive
                  ? styles.expire
                  : ""
              }`}
            >
              {this.props.data.paymentStatus == "success"
                ? MainFunc.getLabelDtos("L0375")//`สำเร็จ`
                : this.props.data.orderAlive < 0 || !this.props.data.orderAlive
                ? MainFunc.getLabelDtos("L0689") //`หมดอายุ`
                : `${MainFunc.getLabelDtos("L0696") /** เหลือเวลาอีก */} ${parseInt(this.props.data.orderAlive)==0?1:parseInt(this.props.data.orderAlive)} ${MainFunc.getLabelDtos("L0128") /** วัน */}`}
            </span>
          </div>
        </div>
        <div
          className={`${styles.cardDetail} ${
            this.props.openToggle && styles.active
          } `}
        >
          <div className={styles.detailList}>
            <div className={styles.detail}>
              <span className={styles.title}>{MainFunc.getLabelDtos("L0692")/* วันที่ทำรายการ */}</span>
              <span className={styles.date}>
                {this.setCreateDate(this.props.data.createDate)}
              </span>
            </div>
            <div className={styles.detail}>
              <span className={styles.title}>{MainFunc.getLabelDtos("L0693")/* ขั้นตอนล่าสุด */}</span>
              <span className={styles.lastStep}>
                {this.props.data.currentPage}
              </span>
            </div>
            <div className={styles.detail}>
              <span className={styles.title}>{MainFunc.getLabelDtos("L0694")/* สถานะคำสั่งซื้อ */}</span>
              <span className={styles.status}>
                {this.setPaymentStatus(this.props.data.paymentStatus)}
              </span>
            </div>
            <div className={styles.detail}>
              <span className={styles.title}>{MainFunc.getLabelDtos("L0695")/* ค่าเบี้ย(บาท) */}</span>
              <div className={styles.premium}>
               ฿ {this.setPremium(this.props.data)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardHistoryProduct;
