// "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.normalizeData = normalizeData;

/* eslint-disable import/prefer-default-export */
function normalizeData(data) {
  if (Array.isArray(data)) {
    return data;
  }

  return [];
}