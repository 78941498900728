// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StyledIndicator", {
  enumerable: true,
  get: function get() {
    return _indicator["default"];
  }
});
Object.defineProperty(exports, "StyledInputContainer", {
  enumerable: true,
  get: function get() {
    return _inputContainer["default"];
  }
});
Object.defineProperty(exports, "StyledInput", {
  enumerable: true,
  get: function get() {
    return _input["default"];
  }
});
Object.defineProperty(exports, "StyledTrigger", {
  enumerable: true,
  get: function get() {
    return _trigger["default"];
  }
});
Object.defineProperty(exports, "StyledIconContainer", {
  enumerable: true,
  get: function get() {
    return _iconContainer["default"];
  }
});
Object.defineProperty(exports, "StyledFlagContainer", {
  enumerable: true,
  get: function get() {
    return _flagContainer["default"];
  }
});
exports.StyledFlagIcon = exports.StyledCountryCode = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _indicator = _interopRequireDefault(require("./indicator"));

var _inputContainer = _interopRequireDefault(require("./inputContainer"));

var _input = _interopRequireDefault(require("./input"));

var _trigger = _interopRequireDefault(require("./trigger"));

var _iconContainer = _interopRequireDefault(require("./iconContainer"));

var _flagContainer = _interopRequireDefault(require("./flagContainer"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    width: 28px;\n    height: 100%;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 0 0 auto;\n    padding: 0 8px 0 8px;\n    line-height: 2.2rem;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledCountryCode = _styledComponents["default"].div(_templateObject());

exports.StyledCountryCode = StyledCountryCode;

var StyledFlagIcon = _styledComponents["default"].img(_templateObject2());

exports.StyledFlagIcon = StyledFlagIcon;