import axios from "axios";
import { notify } from 'react-noti';
import React, { Component } from "react";
import {FaRegAddressCard} from "react-icons/fa";
import { AiFillCloseSquare } from 'react-icons/ai';
import TextField from '@material-ui/core/TextField';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Row, Col, Button, Form} from "react-bootstrap";
import { GoAlert } from 'react-icons/go';
import MainFunc from '../../models/MainFunc';
import MainData from "../../models/MainData";
import moment from "moment";
import { Container } from "@material-ui/core";

class SM005 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM005",
      agreeTerms: false,
      failIdCard: false,
      agreeAcceptSensitive: false,
      TermAndConditionModal: false,
      AcceptSensitiveDataModal: false,
      TermAndConditionConfigModal: false,
      isErrorBirthday: false,
      isErrorBirthdayDesc: "",
      termsAndConditionsDetail: null,
      termsAndConditionsConfigUrl: null,
      acceptPrivateDetail: null,
      acceptPrivateConfigDescription: null,
      termsAndConditionsConfigDescription: null,
      termsDescription: null,
      idCard: !MainData.screenSM005.idCard?null:MainData.screenSM005.idCard,
    };
  }

  componentDidMount(){
    this.props.loadController(true)
    this.getData()
    var elmnt = document.getElementById("titelForScreen");
    elmnt.scrollIntoView();
  }

  getData = () => {
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
    const data = {
      "channel": MainData.initialData.channelCode
    }
    axios.post(MainData.serviceURL+'/VerifyIDCard/GetTermAndCondition', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      let data = res.data.data;
      if(!data){
        this.props.setPage(this.props.prev)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
        return;
      }
      this.setState({termsDescription: data.configTerms.description, termsAndConditionsDetail: data.termsAndConditionsDetail, termsAndConditionsConfigUrl: data.config.url, termsAndConditionsConfigDescription: data.config.description,acceptPrivateDetail: data.acceptPrivateDetail,acceptPrivateConfigDescription: data.configPrivateDetail.description })
      this.props.loadController(false)
    })
    .catch(error => {
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      this.props.setPage(this.props.prev)
    });
  }

  handleChange = (val) => {
    val = val.replace(/[^\d]/g, "");
    if(val.length<=13){
      this.setState({idCard: val});
    }
  }

  processBack = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    this.props.setPage(this.props.prev)
  }

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    if(sessionStorage.getItem('_cookie') === "true"){
      this.checkIDCard(this.state.idCard);
    }else if(this.state.agreeTerms == true){
      this.callServiceAddOrUpdate(this.state.idCard);
    } else {
      this.props.setPage(this.props.next)
    }
  }

  callServiceAddOrUpdate = async (p_iPID) =>{
    if(!p_iPID){
      this.setState({failIdCard: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG007');
      this.props.errorAlert(true, !errMsg?"กรุณาระบุเลขบัตรประชาชน":errMsg.msgDescription, ' ')
      if(MainData.screenSM005.idCard != null)
      {
        MainData.screenSM005.idCard = this.state.idCard
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'กรุณาระบุเลขบัตรประชาชน')
      }
      else
      {
        this.state.idCard = "";
        MainData.screenSM005.idCard = this.state.idCard
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'กรุณาระบุเลขบัตรประชาชน')
      } 
      return;
    }
    else if(p_iPID.length !== 13){
      this.setState({failIdCard: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG017');
      this.props.errorAlert(true, !errMsg?"หมายเลขบัตรประชาชนไม่ถูกต้อง":errMsg.msgDescription, ' ',`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning3':'STEP2-1_Click_BtnCloseWarning3'}`)
      if(MainData.screenSM005.idCard != null)
      {
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      }
      else
      {
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      }  
      MainData.screenSM005.idCard = this.state.idCard
      return;
    }
    else{
      try {
        await this.addOrUpdateCustomerBirthday();
        await this.saveDataProduct();
        if(this.props.masPage.find(item => item.subMenuCode == "SM003")){
          this.props.loadController(true);
          await this.addOrUpdateCustomerQuestion();
        }
        if(this.props.masPage.find(item => item.subMenuCode == "SM004")){
          await this.addOrUpdateCustomerFatca();
        }
        await this.checkIDCard(this.state.idCard);
      } catch (err) {
        console.error(err);
  
      }
    }
  }

  addOrUpdateCustomerFatca = async () => { //service addOrUpdate sm004
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "FatcaQuestions": MainData.screenSM004.fatcaAnsForservice,
      "CRSQuestions": MainData.screenSM004.crsAnsForservice,
      "channelCode": MainData.initialData.channelCode,
      "submenuCode": "SM004",
      "transUrl": window.location.href  
    }
    await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerFatca', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success !== true){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription, ' ')
        // if(MainData.screenSM004.fatcaAnsPDPA ){
        //   MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
        // }else{
        //   MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, this.state.fatcaAnsPDPA, 'Fail', errMsg.msgDescription)
        // }
      }else{
        // if(MainData.screenSM004.fatcaAnsPDPA ){
        //   MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, MainData.screenSM004.fatcaAnsPDPA, this.state.fatcaAnsPDPA, 'Success', null)
        // }else{
        //   MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name, null, null, this.state.fatcaAnsPDPA, 'Success', null)
        // }
        MainData.screenSM004.fatcaAnsPDPA = this.state.fatcaAnsPDPA
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.setPage(this.props.masPage.find(item => item.subMenuCode == "SM004"));
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  addOrUpdateCustomerQuestion = async () => { //service addOrUpdate sm003
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "healthQuestions": MainData.screenSM013.healthQuestions,
      "channelCode": MainData.initialData.channelCode,
      "submenuCode": "SM003",
      "transUrl": window.location.href
    }
    await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerQuestion', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success !== true){
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"บริษัท ไม่สามารถรับประกันชีวิตของท่านได้ ตามเกณฑ์ที่บริษัทฯ กำหนดขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription, ' ')
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.setPage(this.props.masPage.find(item => item.subMenuCode == "SM003"));
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  addOrUpdateCustomerBirthday = async () => { //service addOrUpdate sm001
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": MainData.initialData.productId,
      "gender":  MainData.screenSM001.gender,
      "birthDate": MainFunc.getUserBirthDate("ac"),
      "age": moment().diff( MainFunc.getUserBirthDate("ac"), "years"),
      "submenuCode": this.state.screen,
      "transUrl": window.location.href
    };
    await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerBirthday', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.setPage(this.props.masPage.find(item => item.subMenuCode == "SM001"));
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  saveDataProduct = async () => { //service addOrUpdate sm002
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": MainData.initialData.productId,
      "planId": MainData.screenSM002.planId,
      "premium": MainData.screenSM002.cost,
      "paymentId": MainData.screenSM002.paymentId,
      "paymentMode" : MainData.screenSM002.paymentCode,
      "submenuCode": this.state.screen,
      "transUrl": window.location.href  
    };
    await axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerPremiumCal', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.setPage(this.props.masPage.find(item => item.subMenuCode == "SM002"));
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }
  

  checkIDCard = async(p_iPID) => {
    if(!p_iPID) p_iPID = "";
    if(!p_iPID){
      this.setState({failIdCard: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG007');
      this.props.errorAlert(true, !errMsg?"กรุณาระบุเลขบัตรประชาชน":errMsg.msgDescription, ' ')
      if(MainData.screenSM005.idCard != null)
      {
        MainData.screenSM005.idCard = this.state.idCard
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'กรุณาระบุเลขบัตรประชาชน')
      }
      else
      {
        this.state.idCard = "";
        MainData.screenSM005.idCard = this.state.idCard
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'กรุณาระบุเลขบัตรประชาชน')
      } 
      return;
    }
    else if(p_iPID.length !== 13){
      this.setState({failIdCard: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG017');
      this.props.errorAlert(true, !errMsg?"หมายเลขบัตรประชาชนไม่ถูกต้อง":errMsg.msgDescription, ' ',`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning3':'STEP2-1_Click_BtnCloseWarning3'}`)
      if(MainData.screenSM005.idCard != null)
      {
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      }
      else
      {
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      }  
      MainData.screenSM005.idCard = this.state.idCard
      return;
    }
    let i;
    let sum;
    for(i=0, sum=0; i < 12; i++)
    sum += parseFloat(p_iPID.charAt(i))*(13-i);
    if((11-sum%11)%10!==parseFloat(p_iPID.charAt(12))){
      this.setState({failIdCard: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG017');
      this.props.errorAlert(true, MainFunc.getLabelDtos("L0165") ,!errMsg?"หมายเลขบัตรประชาชนไม่ถูกต้อง":errMsg.msgDescription,`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning3':'STEP2-1_Click_BtnCloseWarning3'}`)
      if(MainData.screenSM005.idCard != null )
      {
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      }
      else
      {
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      } 
      MainData.screenSM005.idCard = this.state.idCard 

    }else{
      if(this.state.agreeTerms===true){
        this.props.loadController(true)
        MainFunc.sendLogToService(this.props.name, 'Accept', 'Success', 'เก็บข้อมูลการยอมรับเงื่อนไขและนโยบายความเป็นส่วนตัว', {}, {agreeTerms: this.state.agreeTerms});
        this.checkDopa()
      }else{
        MainFunc.sendLogToService(this.props.name, 'Accept', 'Fail', 'เก็บข้อมูลการยอมรับเงื่อนไขและนโยบายความเป็นส่วนตัว', {}, {agreeTerms: this.state.agreeTerms});
      }
    }
  }

  checkDopa = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": MainData.screenSM005.cusId 
  }
    const data = {
      "idCard" : this.state.idCard,
      "planId" : MainData.screenSM002.planId,
      "birthDate" : MainFunc.getUserBirthDate("ac"),
      "productId" : MainData.initialData.productId,
      "channelCode" : MainData.initialData.channelCode,
      "PaymentMode": MainData.screenSM002.paymentCode,
      "PaymentId":MainData.screenSM002.paymentId,
      "premium": MainData.screenSM002.cost,
      "submenuCode": this.state.screen,
      "laserId": "",
      "firstName": "",
      "lastName": "",
    }
    axios.post(MainData.serviceURL+'/VerifyIDCard/VerifyID', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      let data = res.data.data;
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตรวจสอบข้อมูลบัตรประชาชนกับ Service DOPA', {}, {idCard: MainFunc.blurIdcard(this.state.idCard), result: data.checkCustomer.blackList});
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลบัตรประชาชนกับ Service MIB', {}, {idCard: MainFunc.blurIdcard(this.state.idCard), result: data.checkCustomer.checkMIB});
      MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการตรวจสอบบัตรประชาชนกับ Service Backlists', {}, {idCard: MainFunc.blurIdcard(this.state.idCard), result: data.checkCustomer.dopa});
      if(res.data.success===true){ //check res เพิ่ม dopa+MIB
        if(data.checkCustomerStatus===true){
          if(MainData.screenSM005.idCard != null)
          {
            MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Success', null)
          }
          else
          {
            MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Success', null)
          }
          if(data.checkCustomer.blackList.isValid && data.checkCustomer.checkMIB.isValid){ //check BL || MIB
            this.checkService();
          }
          else{
            this.props.loadController(false);
            let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
            this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}STEP2`,"Click",`${MainData.initialData.codeTracking}STEP2_Click_BtnCloseModalReject`);
          }
        }else{
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning2':'STEP2-1_Click_BtnCloseWarning2'}`)
          if(MainData.screenSM005.idCard != null)
            {
              MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)')
            }
          else
            {
              MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)')
            }  
        }
      }
      else if(res.data.data.messageCode == 'MSG009'){ //กรณี Dopaบอกวันเดือนปีเกิดไม่ถูกต้อง
        this.props.loadController(false)
        if(MainData.screenMenuKPlus.onKPlus){
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',""))
        }
        else{
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG009');
          this.setState({isErrorBirthday: true,isErrorBirthdayDesc: !errMsg?"วันเกิดที่ระบุไม่ถูกต้อง {{birthDate}} กรุณาระบุให้ถูกต้อง":errMsg.msgDescription.replace('{{birthDate}}',MainData.screenSM001.birthdate.split("/")[0]+' '+(MainFunc.setNewThaiMonth(parseInt(MainData.screenSM001.birthdate.split("/")[1])-1, true))+' '+MainData.screenSM001.birthdate.split("/")[2])})
        }
        if(MainData.screenSM005.idCard != null)
        {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'วันเกิดที่ระบุไม่ถูกต้อง {{birthDate}} กรุณาระบุให้ถูกต้อง')
        }
      else
        {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'วันเกิดที่ระบุไม่ถูกต้อง {{birthDate}} กรุณาระบุให้ถูกต้อง')
        }
        MainData.screenSM005.idCard = this.state.idCard 
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG005');
        this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg?"ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)":errMsg.msgDescription,`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning2':'STEP2-1_Click_BtnCloseWarning2'}`)
        if(MainData.screenSM005.idCard)
        {
          MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)')
        }
      else
        {
          MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)')
        }
        MainData.screenSM005.idCard = this.state.idCard
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription))
    });
  }

  checkService = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "AcceptCookies": true, 
      "AcceptTeamAndCondition": true, 
      "CitizenNo": this.state.idCard,
      "PlanId": MainData.screenSM002.planId,
      "Gender": MainData.screenSM001.gender, 
      "Dob": MainFunc.getUserBirthDate("ac"), 
      "ProductId": MainData.initialData.productId,
      "PaymentMode": MainData.screenSM002.paymentCode,
      "PaymentChannelCode": MainData.initialData.channelCode,
      "PaymentId":MainData.screenSM002.paymentId,
      "age": moment().diff( MainFunc.getUserBirthDate("ac"), "years"),
      "submenuCode": this.state.screen,
      "AcceptSensitiveData": this.state.agreeAcceptSensitive,
      "submenuCode": this.state.screen,
      "transUrl": window.location.href      
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomer', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success === true) {
        MainData.screenSM005.cusId = res.data.data;
        MainData.screenSM005.idCard = this.state.idCard
        this.getMasterData();
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  validateBadRequest = (fieldNameList) => {
    if(fieldNameList.find(data => data.field === "CitizenNo") 
    ){
      this.setState({failIdCard: true})
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG017');
      this.props.errorAlert(true, MainFunc.getLabelDtos("L0165") ,!errMsg?"หมายเลขบัตรประชาชนไม่ถูกต้อง":errMsg.msgDescription,`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning3':'STEP2-1_Click_BtnCloseWarning3'}`)
      if(MainData.screenSM005.idCard != null )
      {
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name , null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: MainData.screenSM005.idCard}, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      }
      else
      {
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {birthDate: MainFunc.getUserBirthDate("ac"), idCard: this.state.idCard}, 'Fail', 'หมายเลขบัตรประชาชนไม่ถูกต้อง')
      } 
    }
  }

  getMasterData =() => {

    axios.get(MainData.serviceURL + '/MasterData/getMasterData', {
      headers: {
          Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
      }
    })
        .then(res => {
          if(res.status === 299){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
          }
            let newmasterDataRes = res.data.data;
            MainData.initialData.title = newmasterDataRes.title;
            MainData.initialData.titleEN = newmasterDataRes.titleEN;
            MainData.initialData.province = newmasterDataRes.province;
            MainData.initialData.occupation = newmasterDataRes.occupation;
            MainData.initialData.relationship = newmasterDataRes.relationship;
            MainData.initialData.confirmMessage = newmasterDataRes.confirmMessage;
            MainData.initialData.importantInformation = newmasterDataRes.importantInformation;
            MainData.initialData.natureOfBusinesses = newmasterDataRes.natureOfBusinesses;
            MainData.initialData.mappingRelationships = newmasterDataRes.mappingRelationships;
            this.checkInfoCustomer()
        })
        .catch(error => {
          if(error?.response?.status == 404 && error?.response?.data?.data?.messageCode  == "MSG1001"){
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
            this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
          }
          else {
            let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
            this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
          }
        });
  }
  
  checkInfoCustomer = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      "AuthId": MainData.screenSM005.cusId

     }
    const data = {
      // "citizenNo" : this.state.idCard ,
      "channelCode": MainData.initialData.channelCode
    };

    let logData = data
    axios.post(MainData.serviceURL+'/Customer/Preview', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      let data = res.data.data;
          if (data.isSaved) {
            let dataSM008 = data.dataPages.find((item) => item.page === "SM008").pageDetail
            let dataSM009 = data.dataPages.find((item) => item.page === "SM009").pageDetail
            let dataSM010 = data.dataPages.find((item) => item.page === "SM010").pageDetail
            if(data.phoneNumber){
              MainData.screenSM006Preview.phoneNumber = data.phoneNumber
            }
            if(dataSM008 !=null){
              let checkTitle = MainData.initialData.title.find(x=>x.titleCode === dataSM008.titleCode);
              let checkTitleEN = MainData.initialData.titleEN.find(x=>x.titleCode === dataSM008.titleCode);
              let checkJob = MainData.initialData.occupation.find(x=>x.occupationCode === dataSM008.occupationCode);
              MainData.screenSM008Preview.firstName = dataSM008.firstName
              MainData.screenSM008Preview.lastName = dataSM008.lastName
              MainData.screenSM008Preview.titleName = checkTitle.titleTitles
              MainData.screenSM008Preview.firstNameEN = dataSM008.firstNameEn
              MainData.screenSM008Preview.lastNameEN = dataSM008.lastNameEn
              MainData.screenSM008Preview.titleNameEN = checkTitleEN.titleTitles
              MainData.screenSM008Preview.job = MainData.screenMenuKPlus.onKPlus?MainData.screenSM008.job:dataSM008.occupationCode
              MainData.screenSM008Preview.jobDescription = MainData.screenMenuKPlus.onKPlus?MainData.screenSM008.jobDescription:dataSM008.natureOfBusiness
              MainData.screenSM008Preview.email = dataSM008.email
            }
            if(dataSM009 != null && dataSM009.currentAddressProvinceCode != null && !MainData.screenMenuKPlus.onKPlus){
              if((MainData.screenMenuKPlus.onKPlus && !MainData.screenSM009.province && !MainData.screenSM009.district && !MainData.screenSM009.subDistrict) || !MainData.screenMenuKPlus.onKPlus){
                let checkCurrentProvince = MainData.initialData.province.find(x=>x.provinceCode === dataSM009.currentAddressProvinceCode);
                let checkCurrentDistrict = checkCurrentProvince.district.find(x=>x.districtCode === dataSM009.currentAddressDistrictCode);
                let checkCurrentsubDistrict = checkCurrentDistrict.subDistrict.find(x=>x.subDistrictCode === dataSM009.currentAddressSubdistrictCode);
                MainData.screenSM009Preview.address = dataSM009.currentAddressNo?.substring(0, 30);
                MainData.screenSM009Preview.province = checkCurrentProvince.provinceTitle
                MainData.screenSM009Preview.district = checkCurrentDistrict.districtTitle
                MainData.screenSM009Preview.subDistrict = checkCurrentsubDistrict.subDistrictTitle
                MainData.screenSM009Preview.village = dataSM009.currentAddressBuilding.substring(0, 50);
                MainData.screenSM009Preview.moo = dataSM009.currentAddressMoo.substring(0, 50);
                MainData.screenSM009Preview.soi = dataSM009.currentAddressSoi.substring(0, 50);
                MainData.screenSM009Preview.street = dataSM009.currentAddressRoad.substring(0, 50);
                MainData.screenSM009Preview.zipCode = dataSM009.currentAddressZipcode.substring(0, 50);
              }
              
              if((MainData.screenMenuKPlus.onKPlus && !MainData.screenSM009.formalProvince && !MainData.screenSM009.formalDistrict && !MainData.screenSM009.formalSubDistrict) || !MainData.screenMenuKPlus.onKPlus){
                let checkAddressProvince = MainData.initialData.province.find(x=>x.provinceCode === dataSM009.addressProvinceCode);
                let checkAddressDistrict = checkAddressProvince.district.find(x=>x.districtCode === dataSM009.addressDistrictCode);
                let checkAddresssubDistrict = checkAddressDistrict.subDistrict.find(x=>x.subDistrictCode === dataSM009.addressSubdistrictCode);
                MainData.screenSM009Preview.formalProvince = checkAddressProvince.provinceTitle
                MainData.screenSM009Preview.formalDistrict = checkAddressDistrict.districtTitle
                MainData.screenSM009Preview.formalSubDistrict = checkAddresssubDistrict.subDistrictTitle
                MainData.screenSM009Preview.formalAddress = dataSM009.addressNo.substring(0, 30);
                MainData.screenSM009Preview.formalVillage = dataSM009.addressBuilding.substring(0, 50);
                MainData.screenSM009Preview.formalMoo = dataSM009.addressMoo.substring(0, 50);
                MainData.screenSM009Preview.formalSoi = dataSM009.addressSoi.substring(0, 50);
                MainData.screenSM009Preview.formalStreet = dataSM009.addressRoad.substring(0, 50);
                MainData.screenSM009Preview.formalZipCode = dataSM009.addressZipcode.substring(0, 50);
                MainData.screenSM009Preview.isSameAddress = dataSM009.addressType==="Home"? true : false
                MainData.screenSM009Preview.isDocToNowAddress = dataSM009.contactAddress==="Home"? false : true 
                MainData.screenSM009Preview.insuranceTrack = dataSM009.receiveInsuranceChannel==="email"? "electronic" : dataSM009.receiveInsuranceChannel==="post"? "paper" : dataSM009.receiveInsuranceChannel==="paper"? "paper" : "electronic"
                MainData.screenSM009Preview.insuranceTrack1 = dataSM009.receiveInsuranceChannelOther
              }
            }
            if(dataSM010 != null){
              if(dataSM010.hasBeneficiary){
                let data1 = dataSM010.beneficiariesOfOrder
                let i;
                MainData.screenSM010.heirPreview = []
                MainData.screenSM010.hasBeneficiary = dataSM010.hasBeneficiary
                if(MainData.screenSM010.hasBeneficiary){
                  for(i=0 ; i < data1.length; i++){
                    let titleId = data1[i].beneficiaryInformation.titleId
                    let checkTitle = MainData.initialData.title.find(x=>x.titleCode === titleId);
                    let relationShipCode = data1[i].relationShipCode
                    let checkrelationShip = MainData.initialData.relationship.find(x=>x.relationshipCode === relationShipCode);
                    MainData.screenSM010.heirPreview.push({titleName: checkTitle.titleTitles, firstName: data1[i].beneficiaryInformation.firstName, lastName: data1[i].beneficiaryInformation.lastName, relation: checkrelationShip.relationshipTitle,})
                  }
                }
              }
            }
            //ของเดิม
            //แยก flow จากการเล่นปกติ หรือมาจาก k-plus
            if( !data.isNext && !MainData.screenMenuKPlus.onKPlus){ // ยังไม่เคยซื้อ K-plus false flow เดิม
              this.props.setPage(this.props.next)
              this.props.loadController(false)
            }
            else if(data.isNext && !MainData.screenMenuKPlus.onKPlus){ // เคยซื้อแล้ว k-plus false flow เดิม
              this.verifyOTP();
            }
            else if(MainData.screenMenuKPlus.onKPlus && !MainData.screenSM006.phoneNumber){ //กรณีที่เข้าผ่าน k-plus แล้วไม่มี เบอร์โทรก็ให้เข้า flow เดิม
              this.props.setPage(this.props.next)
              this.props.loadController(false)
            }
            else{ //กรณีที่เล่นผ่าน K-plus ต้องมีเบอร์โทรด้วย จะskip เบอร์ , OTP
              MainData.screenSM009Preview.isSameAddress = false;
              this.AddOrUpdateCustomerPhoneNo();
            }
          }
          else
          {
            MainData.screenSM006Preview.phoneNumber = ""
            MainData.screenSM008Preview.firstName = ""
            MainData.screenSM008Preview.lastName = ""
            MainData.screenSM008Preview.titleName = ""
            MainData.screenSM008Preview.job = ""
            MainData.screenSM008Preview.jobDescription = ""
            MainData.screenSM008Preview.email = ""
                      
            MainData.screenSM009Preview.address = ""
            MainData.screenSM009Preview.province = ""
            MainData.screenSM009Preview.district = ""
            MainData.screenSM009Preview.subDistrict = ""
            MainData.screenSM009Preview.village = ""
            MainData.screenSM009Preview.moo = ""
            MainData.screenSM009Preview.soi = ""
            MainData.screenSM009Preview.street = ""
            MainData.screenSM009Preview.zipCode = ""

            MainData.screenSM009Preview.formalProvince = ""
            MainData.screenSM009Preview.formalDistrict = ""
            MainData.screenSM009Preview.formalSubDistrict = ""
            MainData.screenSM009Preview.formalAddress = ""
            MainData.screenSM009Preview.formalVillage = ""
            MainData.screenSM009Preview.formalMoo = ""
            MainData.screenSM009Preview.formalSoi = ""
            MainData.screenSM009Preview.formalStreet = ""
            MainData.screenSM009Preview.formalZipCode = ""
            MainData.screenSM009Preview.isSameAddress = true

            MainData.screenSM010.heirPreview = []
            MainData.screenSM010.hasBeneficiary = false
            if (MainData.screenMenuKPlus.onKPlus && MainData.screenSM006.phoneNumber) {
              MainData.screenSM009Preview.isSameAddress = false;
              this.AddOrUpdateCustomerPhoneNo();
            }
            else {
              this.props.loadController(false)
              this.props.setPage(this.props.next) 
            }
                   
          }
        })
      .catch(error => {
        
        this.props.loadController(false)
        MainFunc.sendLogToService(this.props.name, 'Accept', 'Fail', 'เก็บข้อมูลการยินยันซื้อประกัน', {}, { data: logData });
        if (error.response.data.data?.messageCode == 'MSG005') {
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG005');
          this.props.errorAlert(true, MainFunc.getLabelDtos("L0165"), !errMsg ? "ข้อมูลของท่านไม่อยู่ในเกณฑ์ที่บริษัทสามารถพิจารณารับประกันได้ ขอขอบคุณที่ท่านได้ให้ความไว้วางใจในการทำประกันกับบริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน)" : errMsg.msgDescription)
        }
        else {
          let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
        }
      });

  }

  opentermsAndConditionsConfigModal = () => {
    this.setState({TermAndConditionConfigModal: true})
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการดูรายละเอียด นโยบายความเป็นส่วนตัว', {}, {});
  }

  openTermAndConditionModal = () => {
    this.setState({TermAndConditionModal: true})
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการดูรายละเอียด เงื่อนไขการสมัครเอาประกันภัย', {}, {isOpen: true});
  }

  openAcceptSensitiveDataModal = () => {
    this.setState({AcceptSensitiveDataModal: true})
    MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลการดูรายละเอียด เงื่อนไขการเปิดเผยข้อมูล', {}, {isOpen: true});
  }


  //skip page
  verifyOTP = () => {
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId }
    const data = {
      "phoneNumber": MainData.screenSM006Preview.phoneNumber,
      "productId": MainData.initialData.productId,
      "channelCode": MainData.initialData.channelCode,
      "isChangeNumber": true
    }
    axios.post(MainData.serviceURL+'/VerifyOTP/OTP', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      let data = res.data.data;
      if(res.data.success === true){
        let skipPage = MainData.masPage.find(masPage => masPage.seq === 6)
        if(MainData.screenSM006.phoneNumber){
          MainFunc.logPDPA('Edit', this.props.mainMenuName,skipPage.name , null, {phoneNumber: MainData.screenSM006.phoneNumber}, {phoneNumber: MainData.screenSM006Preview.phoneNumber}, 'Success', null)
        } else {
          MainFunc.logPDPA('Add', this.props.mainMenuName,skipPage.name , null, null, {phoneNumber: MainData.screenSM006Preview.phoneNumber}, 'Success', null)
        }
        MainData.screenSM006.isChangeNumber = false;
        if(data.status === true){
          //MainFunc.sendLogToService(this.props.name, 'Insert', 'Success', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
          MainData.screenSM006.phoneNumber = MainData.screenSM006Preview.phoneNumber;
          MainData.screenSM006.refCode = data.refCode; 
          MainData.screenSM006.otpCode = data.otpCode; 
          this.AddOrUpdateCustomerPhoneNo()
        }else{
         // MainFunc.sendLogToService(this.props.name, 'Insert', 'Fail', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
        }
      }else{
        if(data.messageCode =='MSG022' || data.messageCode =='MSG023'){
          this.props.loadController(false)
          this.AddOrUpdateCustomerPhoneNo()
        }
        else{
          //MainFunc.sendLogToService(this.props.name, 'Insert', 'Fail', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
          this.props.loadController(false)
          this.props.errorAlert(true, 'พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ', ' ')
        }
        
      }
    })
    .catch(error => {
      this.props.loadController(false)
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
      this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
    });
  }

  AddOrUpdateCustomerPhoneNo = () => {
    const headers = { 
      "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, 
      "Content-Type": "application/json;charset=UTF-8", 
      "Access-Control-Allow-Origin": "*", 
      "Access-Control-Allow-Credentials": "true", 
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
      "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
      "AuthId": !MainData.screenSM005.cusId?0:MainData.screenSM005.cusId
    }
    const data = {
      "phoneNumber": MainData.screenSM006.phoneNumber,
      "submenuCode": "SM006",
    }
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerPhoneNo', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
          if(!MainData.screenMenuKPlus.onKPlus){ //flow เดิมจะข้ามไปหน้า OTP
          this.props.setPage((parseInt(this.props.next) + 1).toString())
          }
          else{ //flowใหม่ เข้าผ่าน kplus skip page เบอร์โทร + OTP 
            this.props.setPage((parseInt(this.props.next) + 2).toString())
          }
      }else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription, ' ')
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
        this.props.errorAlert(true, !errMsg?'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง':errMsg.msgDescription, ' ');
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  skipFirthPage = () => {
    MainData.screenSM002.paymentCode = null;
    MainData.screenSM002.planId = null;
    MainData.screenSM002.productName = "";
    MainData.screenSM002.planName = "";
    MainData.screenSM002.paymentName = "";
    MainData.screenSM002.cost = 0;
    MainData.screenSM002.costPayment = 0;
    MainData.screenSM002.paymentId = null;
    MainData.screenSM002.insureRequiredIdentification = true;
    MainData.screenSM002.insureSelfie = true;
    MainData.screenSM002.screen = "";
    this.props.setPage("1")
  }

  render(){
    return (
      <div className="container-fulid">
        <Row>
          <Col id="titelForScreen" xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{this.props.mainMenuName}</span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>

          <Col xs={12} className="d-flex justify-content-center pt-3">
            <FaRegAddressCard className="csMainColor" size={50} />
          </Col>
          <Col xs={12} className="d-flex justify-content-center pt-3">
            <h5 className="font-weight-bold">{MainFunc.getLabelDtos("L0137")}</h5>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <small className="text--muted">{MainFunc.getLabelDtos("L0023")}</small>
          </Col>

          <Col xs={12} className="pt-3">
            <TextField inputProps={{autocomplete: 'off'}}
              fullWidth
              id="idCard"
              defaultValue=""
              autoComplete={"off"}
              error={this.state.failIdCard}
              label={MainFunc.getLabelDtos("L0024")}
              placeholder={MainFunc.getLabelDtos("L0025")}
              onChange={(e)=>{this.handleChange(e.target.value)}}
              helperText={this.state.failIdCard?"หมายเลขบัตรประชาชนไม่ถูกต้อง":""}
              value={!this.state.idCard?"":MainFunc.idcardFormat(this.state.idCard)}
              disabled={this.state.idCard && MainData.screenMenuKPlus.onKPlus ? true : false}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>

          <Col xs={12} className="pt-4 pb-4" >
            <div className="pt-4 pb-2 pl-3 pr-3" style={{backgroundColor: "#e8e8e8"}}>
              <Form noValidate validated >
                <Form.Group controlId="terms">
                  <Form.Check checked={this.state.agreeTerms} onChange={(e)=>{this.setState({agreeTerms: e.target.checked})}} type="checkbox" label={<> <span>{MainFunc.getLabelDtos("L0139")}</span> </>} custom style={{display: 'initial'}} />
                  <span id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_LinkCondition':'STEP2-1_Click_LinkCondition'}`} className="spanLink-pink" onClick={()=>this.openTermAndConditionModal()}> {MainFunc.getLabelDtos("L0138")} </span>
                  <span>{MainFunc.getLabelDtos("L0140")} </span>
                  <a id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_LinkPrivacy':'STEP2-1_Click_LinkPrivacy'}`} className="spanLink-pink" onClick={()=>this.opentermsAndConditionsConfigModal()}>{this.state.termsAndConditionsConfigDescription}</a>
                  <span className="text-danger"> *</span>
                </Form.Group>
                <Form.Group controlId="acceptSensitive" >
                  <Form.Check checked={this.state.agreeAcceptSensitive} onChange={(e)=>{this.setState({agreeAcceptSensitive: e.target.checked})}} type="checkbox" label={<>  </>} custom style={{display: 'initial'}} />
                  <span>{MainFunc.getLabelDtos("L0357")}</span>
                  <span className="spanLink-pink " onClick={()=>this.openAcceptSensitiveDataModal()}> {MainFunc.getLabelDtos("L0356")} </span>
                </Form.Group>

              </Form>
            </div>
          </Col>

          {/* MODAL */}
          {/* เงื่อนไข */}
          <SweetAlert show={this.state.TermAndConditionModal} title={''} onConfirm={()=>{this.setState({TermAndConditionModal: false})}} onCancel={()=>{this.setState({TermAndConditionModal: false})}} customButtons={<></>} >
            <div className="container-fulid">
            <div onClick={()=>this.setState({TermAndConditionModal: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
              <span dangerouslySetInnerHTML={{__html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.acceptPrivateConfigDescription}</h4>`+ MainFunc.getConfigDtos("CF004")}} style={{textAlign: 'left'}}></span>
            </div>
          </SweetAlert>
          {/* รายละเอียด */}
          <SweetAlert show={this.state.AcceptSensitiveDataModal} title={''} onConfirm={()=>{this.setState({AcceptSensitiveDataModal: false})}} onCancel={()=>{this.setState({AcceptSensitiveDataModal: false})}} customButtons={<></>} >
            <div className="container-fulid">
            <div onClick={()=>this.setState({AcceptSensitiveDataModal: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
              <span dangerouslySetInnerHTML={{__html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.termsDescription}</h4>`+this.state.acceptPrivateDetail}} style={{textAlign: 'left'}}></span>
            </div>
          </SweetAlert>
          {/* นโยบาย */}
          <SweetAlert show={this.state.TermAndConditionConfigModal} title={''} onConfirm={()=>{this.setState({TermAndConditionConfigModal: false})}} onCancel={()=>{this.setState({TermAndConditionConfigModal: false})}} customButtons={<></>} >
            <div className="container-fulid">
            <div onClick={()=>this.setState({TermAndConditionConfigModal: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
              <span dangerouslySetInnerHTML={{__html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.termsAndConditionsConfigDescription}</h4>`+this.state.termsAndConditionsConfigUrl}} style={{textAlign: 'left'}}></span>
            </div>
          </SweetAlert>
          <SweetAlert 
            show={this.state.isErrorBirthday} 
            title={""} 
            customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} 
            custom 
            onConfirm={() => {this.setState({isErrorBirthday: false});MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning1':'STEP2-1_Click_BtnCloseWarning1'}`)}} 
            showCancel 
            closeOnClickOutside={false} 
            customButtons={
              <Row>
                <Col xs={6}><Button onClick={()=>this.skipFirthPage()} className="subBtnRadius">{MainFunc.getLabelDtos("L0151")}</Button></Col>
                <Col xs={6}><Button onClick={() => {this.setState({isErrorBirthday: false});MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2':'STEP2-1'}`,"Click",`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnCloseWarning1':'STEP2-1_Click_BtnCloseWarning1'}`)}} className="mainBtnRadius">{MainFunc.getLabelDtos("L0188") /** ปิด */}</Button></Col>
              </Row>
            } 
          >
              <h5 className="text-danger" dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0165") }}></h5><span className="text--muted" dangerouslySetInnerHTML={{ __html: this.state.isErrorBirthdayDesc}} ></span>
          </SweetAlert>

          {!this.props.prev?null:
            <Col xs={!this.props.next?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnBack':'STEP2-1_Click_BtnBack'}`} variant="" className="subBtnRadius font-weight-bold" onClick={() => { this.processBack() }}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
          {!this.props.next?null:
            <Col xs={!this.props.prev?12:6} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pl-2"}>
              <Button id={`${MainData.initialData.codeTracking}${MainData.initialData.productId != 98?'STEP3-2_Click_BtnNext':'STEP2-1_Click_BtnNext'}`} variant="" className="mainBtnRadius font-weight-bold" onClick={() => { this.processNext()}} disabled={!this.state.agreeTerms}>{MainFunc.getLabelDtos("L0021")}</Button>
            </Col>
          }
        </Row>
      </div>
    )
  }
}

export default SM005;