// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledRgbaColorContainer = exports.StyledHexColorContainer = exports.StyledSlidersContainer = exports.StyledSaturationContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 0 4 auto;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 0 3 auto;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 1 0 auto;\n    margin-right: 15px;\n    margin-top: 5px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: relative;\n    flex: 1 0 auto;\n    border-radius: 12px;\n    overflow: hidden;\n    border: 1px solid ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledSaturationContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.border.divider;
});
exports.StyledSaturationContainer = StyledSaturationContainer;

var StyledSlidersContainer = _styledComponents["default"].div(_templateObject2());

exports.StyledSlidersContainer = StyledSlidersContainer;

var StyledHexColorContainer = _styledComponents["default"].div(_templateObject3());

exports.StyledHexColorContainer = StyledHexColorContainer;

var StyledRgbaColorContainer = _styledComponents["default"].div(_templateObject4());

exports.StyledRgbaColorContainer = StyledRgbaColorContainer;