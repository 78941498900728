// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border-radius: 4px;\n    border: dashed 1px ", ";\n    background-color: ", ";\n    height: 260px;\n    margin: 0 16px 24px 16px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.background.highlight;
}, function (props) {
  return props.palette.background.secondary;
}, function (props) {
  return props.isDragOver && "\n            background-color: ".concat(props.palette.action.active, ";\n            border: dashed 1px ").concat(props.palette.brand.main, ";\n        ");
});
var _default = StyledContainer;
exports["default"] = _default;