// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    margin-left: ", ";\n    padding-left: ", ";\n\n    &:hover {\n        cursor: pointer;\n        background-color: ", ";\n    }\n\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function getMarginLeft(props) {
  return "-".concat((props.ariaLevelValue - 1) * 20 + props.ariaLevelValue - 1, "px");
}

function getPaddingLeft(props) {
  if (props.hasChildren) {
    return "".concat((props.ariaLevelValue - 1) * 20 + props.ariaLevelValue - 1, "px}");
  }

  return "".concat((props.ariaLevelValue - 1) * 20 + props.ariaLevelValue - 1 + 28, "px}");
}

var NodeContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), getMarginLeft, getPaddingLeft, function (props) {
  return props.palette.background.highlight;
}, function (props) {
  return props.isSelected && "\n        background-color: ".concat(props.palette.brand.light, ";\n\n        &:hover {\n            background-color: ").concat(props.palette.brand.light, ";\n        }\n    ");
});
var _default = NodeContainer;
exports["default"] = _default;