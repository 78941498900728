import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import styles from "../../styles/InsuranceItem.module.scss";
import MainFunc from "../../models/MainFunc";

class InsuranceItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){

  }

  onProceed(action){
    let data = this.props.data
    data.view = action;
    this.props.clickView(data)
  }


  render(){
    return (
      <div className={styles.contentBox}>
      <Container fluid>
        <Row className={styles.imageContainer} style={{cursor: "pointer"}} onClick={() => this.onProceed("read")}>
          <img src={this.props.data ?.productPic} className={styles.image} />
        </Row>
        <Row className={styles.detailContainer} style={{cursor: "pointer"}} onClick={() => this.onProceed("read")}>
          <div
            className={`${styles.typeBadge}`}
            style={{ backgroundColor: this.props.data.cateColour }}
          >
            {this.props.data ?.cateName}
          </div>
          <div className={styles.title}>{this.props.data ?.productName}</div>
          {/* <div className={`${styles.desc} ${styles.titleDesc}`}>
            {data?.subtitle}
          </div> */}
          <div className={styles.desc}>{this.props.data ?.productDes}</div>
        </Row>
        <Row className={styles.actionsContainer}>
          <Col
            className={`${styles.button} ${styles.left}`}
            onClick={() => this.onProceed("calculate")}
          >
            <span>{MainFunc.getLabelDtos("L0449")/*คำนวณเบี้ยประกัน*/}</span>
          </Col>
          <Col
            className={`${styles.button} ${styles.right}`}
            onClick={() => this.onProceed("read")}
          >
            <span>{MainFunc.getLabelDtos("L0455")/*อ่านเพิ่มเติม*/}</span>
          </Col>
        </Row>
      </Container>
    </div>
    )
  }
}

export default InsuranceItem;