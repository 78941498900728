// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _label = _interopRequireDefault(require("../../Input/label"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-left: 0;\n    margin-right: 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledLabel = (0, _styledComponents["default"])(_label["default"])(_templateObject());
var _default = StyledLabel;
exports["default"] = _default;