import axios from "axios";
import { GoAlert } from 'react-icons/go';
import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import ReactNoti, { POSITION } from 'react-noti';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Row, Col, Image, Button, Card, Form, Spinner } from "react-bootstrap";
import SyncLoader from '../../customLib/react-spinners/SyncLoader';
import { AiFillCloseSquare } from 'react-icons/ai';
import { Collapse } from "@material-ui/core";
import logo2 from '../../images/logo.png';
import IcoTel from '../../images/ico_tel.png';
import IcoTelGreen from '../../images/ico_tel_green.png';
import IcoFb from '../../images/ico_fb.png';
import IcoIg from '../../images/ico_ig.png';
import IcoLine from '../../images/ico_line.png';
import IcoLinkedin from '../../images/ico_linkedin.png';
import IcoYt from '../../images/ico_yt.png';
import IcoDBD from '../../images/logo_cf_dbd.png';
import IcoCS from '../../images/logo_pm_cs.png';
import IcoKbank from '../../images/logo_pm_kbank.png';
import IcoMC from '../../images/logo_pm_mc.png';
import IcoVS from '../../images/logo_pm_vs.png';
import IcoCrossSign from '../../images/ico_closesign.png';
import IcoCorrect from '../../images/ico_correct.png';
import IcoError from '../../images/ico_error.png';
import IcoCallCenter from '../../images/ico_callcenter.png';
import MainData from '../../models/MainData';
import parse from "html-react-parser";

import ScLanding from '../ScreenLanding';

import 'react-noti/dist/react-noti.css';
import MainFunc from "../../models/MainFunc";

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            screen: "LandingPage",
            errMsg: "",
            lang: /lang=([^&]+)/.exec(window.location.href) ? /lang=([^&]+)/.exec(window.location.href)[1] : 'th',
            errSubMsg: "",
            isError: false,
            conetntHeight: 0,
            isLoading: true,
            serviceError: false,
            _cookie: sessionStorage.getItem('_cookie'),
            channel: { name: "", css: null, logo: null, font: null },
            mainHeaderText: "Online Sales",
            subHeaderText: "ซื้อประกันออนไลน์ที่เหมาะกับคุณ",
            thirdHeaderText: "",
            isSelectedForHeder: false,
            logoWidth: 70,
            channelName: '',
            channelCode: MainData.initialData.channelCode ? MainData.initialData.channelCode : /channelCode=([^&]+)/.exec(window.location.href) ? /channelCode=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.channelCode,
            productId: MainData.initialData.productId ? MainData.initialData.productId : /productId=([^&]+)/.exec(window.location.href) ? /productId=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.productId,
            source: MainData.initialData.source ? MainData.initialData.source : /source=([^&]+)/.exec(window.location.href) ? /source=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.source,
            channelCodeIni: /channelCode=([^&]+)/.exec(window.location.href) ? /channelCode=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.channelCode,
            productIdIni: /productId=([^&]+)/.exec(window.location.href) ? /productId=([^&]+)/.exec(window.location.href)[1] : this.props.match.params.productId,
            sourceIni: /utm_source=([^&]+)/.exec(window.location.href) ? /utm_source=([^&]+)/.exec(window.location.href)[1] : "",
            showfooter: false,
            TermAndConditionModal: false,
            termsAndConditionsDetail: null,
            utm_mediumIni: /utm_medium=([^&]+)/.exec(window.location.href) ? /utm_medium=([^&]+)/.exec(window.location.href)[1] : '',
            utm_campaignIni: /utm_campaign=([^&]+)/.exec(window.location.href) ? /utm_campaign=([^&]+)/.exec(window.location.href)[1] : '',
            utm_contentIni: /utm_content=([^&]+)/.exec(window.location.href) ? /utm_content=([^&]+)/.exec(window.location.href)[1] : '',
            utm_termIni: /utm_term=([^&]+)/.exec(window.location.href) ? /utm_term=([^&]+)/.exec(window.location.href)[1] : '',
            cIni: /c=([^&]+)/.exec(window.location.href) ? /c=([^&]+)/.exec(window.location.href)[1] : '',
            showDropLead: false,
            step: "1",
            name: "",
            phoneNumber: "",
            email: "",
            selectDate: "",
            validateDroplead: false,
            nameerror: "",
            emailerror: "",
            failPhone: "",
            failselectDate: "",
            checkboxDroplead: false,
            checkInputname: false,
            checkInputemail: false,
            checkInputphoneNumber: false,
            showScreen: false,
            categoryGTM:"",
            actionGTM:"",
            labelGTM:"",
            scroll:0,
            successInitialSale: false,
            NotfoundProduct: false,
            cookieCode: "",
            cookieVersionDisplay: "",
            cookieVersionOrder: 0,
            cookieDescription: "",
            cookieDescriptionCheck: false,
            device:"",
        };
    }

    UNSAFE_componentWillMount() {
        let lang = /lang=([^&]+)/.exec(window.location.href);
        if (lang) {
            lang = lang[1]
            this.props.i18n.changeLanguage(lang);
        } else {
            lang = 'th'
            this.props.i18n.changeLanguage("th");
        }
        if(window.location.origin.includes("localhost")){
            MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
        }
        else{
            MainData.serviceURL = window.location.origin + `${MainData.servicePath}api`;
        }
        this.setState({ lang: lang })
        this.getData(lang);
    }

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.calContentHeight();
        });
        this.calContentHeight();
        // sessionStorage.setItem("homePage", window.location.href);
        document.getElementById('stepSection').addEventListener("scroll", () => {
            this.setState({ scroll: this.state.scroll += 1})
        });
    }

    componentDidUpdate() {
        this.calContentHeight();
    }

    calContentHeight = () => {
        let screenHeight = window.innerHeight;
        if (this.state.serviceError === false) {
            if (this.state.conetntHeight !== (screenHeight - document.getElementById('logoSection').offsetHeight - document.getElementById('stepSection').offsetHeight)) {
                this.setState({ conetntHeight: screenHeight - document.getElementById('logoSection').offsetHeight - document.getElementById('stepSection').offsetHeight })
            }
        }
    }

    getData = async (lang) => {
        this.setState({device:await this.getMobileOperatingSystem()})
        const headers = { "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "source": this.state.sourceIni,
            "lang": lang === 'en' ? "EN" : "TH",
            "channelCode": this.state.channelCodeIni,
            // "productId": this.state.productIdIni,
            "utm_source": this.state.sourceIni,
            "utm_medium": this.state.utm_mediumIni,
            "utm_campaign": this.state.utm_campaignIni,
            "utm_content": this.state.utm_contentIni,
            "utm_term": this.state.utm_termIni,
            "c": this.state.cIni,
            "transUrl": window.location.href,
            "deviceType":this.state.device
        };
        var dataForGet = data;
        axios.post(MainData.serviceURL + '/Landing/InitialSalesLanding', data, {
            headers: headers
        })
            .then(async res => {
                let data = res.data.data;
                sessionStorage.setItem("bearerAuth", data?.authKey);
                if (res.data.success !== true && data.messageCode === "MSG108"){
                    this.setState({ messageCode: data.messageCode, messageDescription: data.messageDescription, isLoading: false, NotfoundProduct: true })
                    return;
                }
                else if (!data) {
                    this.setState({ isLoading: false, serviceError: true })
                    MainFunc.sendLogToService(null, 'Access', 'Fail', null, {}, dataForGet);
                    this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                    return;
                }

                if (data?.cookies?.cookieDetail != null)
                {
                    sessionStorage.setItem('_cookie', ''); this.setState({ _cookie: '' }); 
                }
                else
                {
                    sessionStorage.setItem('_cookie', 'true'); this.setState({ _cookie: 'true' }); 
                }

                var head = document.getElementsByTagName('head')[0];
                var link = document.createElement('link');
                link.id = 'customCss';
                link.rel = 'stylesheet';
                link.type = 'text/css';
                link.href = data.channelCode?.toUpperCase() == "KBANK" ? window.location.origin + MainData.mainPath+'/css/kbankcustom.css' : window.location.origin + MainData.mainPath+'/css/pmibcustom.css';
                link.media = 'all';
                head.appendChild(link);
                sessionStorage.setItem("customCss", data.channelCode?.toUpperCase() == "KBANK" ? window.location.origin + MainData.mainPath+'/css/kbankcustom.css' : window.location.origin + MainData.mainPath+'/css/pmibcustom.css');
                sessionStorage.setItem("channelLogo", data.channel.logo);
                sessionStorage.setItem("channelName", data.channelCode);
                let statusSystemConfig = await MainFunc.getSystemConfigData();
                if(statusSystemConfig){
                    window.location.href = MainData.mainPath + 'shutdown'
                }

                if (res.data.success !== true) {
                    this.errorAlert(true, "เกิดปัญหาในการเข้าใช้งาน กรุณาติดต่อเจ้าหน้าที่")
                    MainFunc.sendLogToService(null, 'Access', 'Fail', null, {}, dataForGet);
                } else {
                    if(MainData.isPRD){
                        MainFunc.SetScriptGTM(data.channelCode);
                    }
                    MainData.dropleadDtos = data.dropleadDtos;

                    axios.get(MainData.serviceURL + '/MasterData/getMessage', {
                        headers: {
                            Authorization: `Bearer ${data.authKey}`
                        }
                    })
                    .then(res => {
                        if(res.status === 299){
                            window.location.href = MainData.mainPath + 'shutdown'
                            return;
                        }
                        let newmasterDataRes = res.data.data;
                        if (!newmasterDataRes) {
                            this.setState({ isLoading: false, serviceError: true })
                            this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                            return;
                        }
                        MainData.masterMessage = newmasterDataRes;
                    })
                    .catch(masterDataError => {
                        this.setState({ isLoading: false, serviceError: true })
                        this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                    });
                    MainData.channelLogo = data.channel.logo;
                    MainData.initialData.source = data.source;
                    MainData.initialData.labelDtos = data.labelDtos;
                    MainData.initialData.configDtos = data.configDtos;
                    MainData.initialData.bearerAuth = data.authKey;
                    MainData.initialData.channelCode = data.channelCode;
                    MainFunc.sendLogToService("LandingPage", 'Access', 'Success', null, {}, dataForGet);
                    this.setState({ channelName: data.channelCode, channel: data.channel,cookieCode: data.cookies.cookieCode, cookieVersionDisplay: data.cookies.cookieVersionDisplay, cookieVersionOrder: data.cookies.cookieVersionOrder, cookieDetail: data.cookies.cookieDetail, cookieDescription: data.cookies.cookieDescription })
                }
                this.setState({ showfooter: false });
                this.getDataTermsAndConditionsDetail();
                this.setState({successInitialSale: true});
            })
            .catch(error => {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                this.setState({ isLoading: false, serviceError: true })
            });
    }

    getDataTermsAndConditionsDetail = () => {
        const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "channel": MainData.initialData.channelCode
        }
        axios.post(MainData.serviceURL + '/VerifyIDCard/GetTermAndCondition', data, {
            headers: headers
        })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (!data) {
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription, ' ')
                    return;
                }
                this.setState({ termsAndConditionsDetail: data.termsAndConditionsDetail, showScreen: true })
                this.loadController(false)    
            })
            .catch(error => {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
            });
    }

    loadController = (isLoad) => {
        this.setState({ isLoading: isLoad })
    }

    errorAlert = (isErr, msg, subMsg, category, action, label) => {
        this.setState({ isError: isErr, errMsg: msg, errSubMsg: !subMsg ? null : subMsg, categoryGTM:category, actionGTM:action, labelGTM:label})
    }

    serviceDroplead = () => {
       this.setState({nameerror: "",emailerror: "",failPhone:"",failselectDate:""})
        this.setState({ validateDroplead: true ,checkInputname: false,checkInputemail: false,checkInputemail: false})
        let validate = false;
        if (!this.state.name || !this.state.phoneNumber || !this.state.email || !this.state.selectDate) {
            validate = true;
        }
        if (this.state.name === "")  {
            this.setState({ nameerror: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else if (!this.state.name.split(/ (.*)/s)[0] || !this.state.name.split(/ (.*)/s)[1]) {
            this.setState({ nameerror: 'กรุณาตรวจสอบข้อมูล' , checkInputname: true})
            validate = true;
        } 

        if (this.state.email === "") {
            this.setState({ emailerror: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else if (this.validateEmail(this.state.email) !== true) {
            this.setState({ emailerror: 'กรุณาตรวจสอบข้อมูล' , checkInputemail: true})
            validate = true;
        }

        if (this.state.phoneNumber == "") {
            this.setState({ failPhone: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else  if (this.state.phoneNumber.length <= 9) {
            this.setState({ failPhone: 'กรุณาตรวจสอบข้อมูล' , checkInputphoneNumber: true})
            validate = true;
        }

        if (this.state.selectDate == "") {
            this.setState({ failselectDate: 'กรุณาระบุข้อมูล' })
            validate = true;
        }
        
        if (validate) {
            return;
        }

        this.setState({ step: "2" })

        const headers = { "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "channel": MainData.initialData.channelCode,
            "firstName": this.state.name.split(" ")[0].trim(),
            "lastName": this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
            "phoneNumber": this.state.phoneNumber,
            "email": this.state.email,
            "productId": MainData.initialData.productId,
            "contactTimeName": this.state.selectDate,
        };

        
        axios.post(MainData.serviceURL + '/Customer/GetDropLead', data, {
            headers: headers
        })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (res.data.success) {
                    this.setState({ step: "3" })
                    this.setState({ contactTimeId: data.contactTimeId, contactTimeCode: data.contactTimeCode, contactTimeName: data.contactTimeName });
                } else {
                    this.setState({ step: "2" })
                }

            })
            .catch(error => {
                if(error?.response?.status == 400){
                    this.loadController(false)
                    this.validateBadRequest(error.response.data.errors);
                }
                else{
                    this.setState({ step: "4" })
                }
            });

    }

    validateBadRequest = (fieldNameList) => {
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG018');
        this.errorAlert(true, !errMsg?"กรุณาตรวจสอบข้อมูล":errMsg.msgDescription, " ");
        if(fieldNameList.find(data => data.field === "firstName") || fieldNameList.find(data => data.field === "lastName")){
            this.setState({ nameerror: errMsg , checkInputname: true})
        }
        if(fieldNameList.find(data => data.field === "phoneNumber")){
            this.setState({ failPhone: errMsg , checkInputphoneNumber: true})
        }
        if(fieldNameList.find(data => data.field === "email")){
            this.setState({ emailerror: errMsg , checkInputemail: true})
        }
        if(fieldNameList.find(data => data.field === "contactTimeName")){
            this.setState({ failselectDate: errMsg })
        }
    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    handleInputChange = (name, val) => {
        switch(name){
          case "name":
            if (val[0] === " ") { break; }
            let checkWord = /^[ก-์ ]+$/.test(val);
            this.setState((state) => ({name: !checkWord&&val?state.name:val}))
            this.setState({checkInputname: false})
            if(this.state.name.split(" ")[0] || this.state.name.split(" ")[1]){this.setState({nameerror: ""})}
            break;
          case "phoneNumber":
            val = val.replace(/[^\d]/g, ""); 
            if (val.length === 1 && val !== "0") { this.setState({phoneNumber: "0"+val}) } 
            else if (val.length <= 10) { this.setState({ phoneNumber: val,failPhone: "" }) } 
            this.setState({checkInputphoneNumber: false})
            break;
          case "email":
            let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
            this.setState((state) => ({email: !checkWord4&&val?state.email:val, emailerror: ""}))
            this.setState({checkInputemail: false})
            break;
          case "selectDate":
            this.setState({selectDate: val, failselectDate: ""})
            break;
          default:
            break;
        }
      }

    closeDroplead = () => {
        this.setState({showDropLead: false, step: "1", validateDroplead: false, checkboxDroplead: false, checkInputname: false, checkInputemail: false, checkInputphoneNumber: false })
        this.setState({name: "", phoneNumber: "", email: "", selectDate: "" })
        this.setState({nameerror: "", failPhone: "", emailerror: "", failselectDate: "" })

    }

    SaveCookie = () => 
        {
            const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
            const data = {
                "cusId": null,
                "cookieCode": this.state.cookieCode,
                "cookieVersionDisplay": this.state.cookieVersionDisplay,
                "cookieVersionOrder": this.state.cookieVersionOrder,
                "channelCode": MainData.initialData.channelCode,
            };
            axios.post(MainData.serviceURL + '/Customer/AddCustomerCookie', data, {
                headers: headers
            })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                if (!res.data.success)
                {
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                    this.setState({ isLoading: false, serviceError: true })
                }
                else
                {
                    sessionStorage.setItem('_cookie', 'true'); this.setState({ _cookie: 'true' }); 
                }
            })
            .catch(error => {
                if(error?.response?.status == 400){
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                    this.setState({ isLoading: false, serviceError: true })
                }
                else{
                    let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                    this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                    this.setState({ isLoading: false, serviceError: true })
                }
            });
        }

    closeCookie = () => {
            sessionStorage.setItem('_cookie', 'close'); this.setState({ _cookie: 'close' });
    }

    openCookieDescription = () => {
        this.setState({cookieDescriptionCheck: true})
    }

    showMainPageOrDetail = (data) => {
        let parameterUrl = ""
        if(this.state.channelCodeIni){
            parameterUrl += "&channelCode="+this.state.channelCodeIni;
        }
        if(this.state.source){
            parameterUrl += "&source="+this.state.source;
        }
        if(this.state.sourceIni){
            parameterUrl += "&utm_source="+this.state.sourceIni;
        }
        if(this.state.utm_mediumIni){
            parameterUrl += "&utm_medium="+this.state.utm_mediumIni;
        }
        if(this.state.utm_campaignIni){
            parameterUrl += "&utm_campaign="+this.state.utm_campaignIni;
        }
        if(this.state.utm_contentIni){
            parameterUrl += "&utm_content="+this.state.utm_contentIni;
        }
        if(this.state.utm_termIni){
            parameterUrl += "&utm_term="+this.state.utm_termIni;
        }
        if(this.state.cIni){
            parameterUrl += "&c="+this.state.cIni;
        }
        if(this.state.lang){
            parameterUrl += "&lang="+this.state.lang;
        }
        if(data.view === "read"){
            window.location.href = `${MainData.mainPath}detail/?productId=${data.productIdEn?data.productIdEn:data.productEn}${parameterUrl}`
        }
        else if(data.view === "calculate") {
            window.location.href = `${MainData.mainPath}?productId=${data.productIdEn}${parameterUrl}`
        }
        if(data.view == "readBanner" && data.productEn == null){
            window.location.href = `${data.url}`
        }
        if(data.view == "readBanner" && data.productEn != null){
            window.location.href = `${MainData.mainPath}detail/?productId=${data.productIdEn?data.productIdEn:data.productEn}${parameterUrl}`
        }
        
    }

    getMobileOperatingSystem = async() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
          return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
        return "Browser";
      };
    

    render() {
        let screenHeight = window.innerHeight;

        return (
            <>
                {this.state.serviceError === true ?
                    <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                        <SweetAlert show={true} title={"ขออภัย"} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={<></>} ><h5 className="text-danger">{'ไม่สามารถเข้าสู่ระบบ Online Sales ได้ กรุณาเข้าระบบใหม่อีกครั้ง'}</h5><span className="text--muted">{"ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"}</span></SweetAlert>
                    </div>
                    : <div style={{ height: screenHeight, maxHeight: screenHeight }}>
                         { this.state.NotfoundProduct === true ?
                        <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                        <SweetAlert show={true} title={"ขออภัย"} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false} customButtons={<></>} ><h5 className="text-danger">{this.state.messageDescription.replace("\\n","")?.replace("\\","") && parse(this.state.messageDescription?.replace("\\n","")?.replace("\\",""))}</h5></SweetAlert>
                         </div>
                            : null }
                        <ReactNoti position={POSITION.TOP_RIGHT} showProgress={true} timeOut={3000} />
                        {this.state.isLoading ?
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading" style={{ zIndex: "99", position: "fixed" }}>
                                <Row >
                                    <Col xs={12} className="w-100 d-flex justify-content-center"><h3 className="csMainColor">Loading</h3></Col>
                                    <Col xs={12} className="w-100 d-flex justify-content-center"><SyncLoader size={15} loading={true} /></Col>
                                </Row>
                            </div>
                            : null}
                        {/* MODAL */}
                        <SweetAlert show={this.state.TermAndConditionModal} title={''} onConfirm={() => { this.setState({ TermAndConditionModal: false }) }} onCancel={() => { this.setState({ TermAndConditionModal: false }) }} customButtons={<></>} >
                            <div className="container-fulid">
                                <div onClick={() => this.setState({ TermAndConditionModal: false })} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare /></div>
                                <span dangerouslySetInnerHTML={{ __html: this.state.termsAndConditionsDetail }} style={{ textAlign: 'left' }}></span>
                            </div>
                        </SweetAlert>
                        <SweetAlert show={this.state.cookieDescriptionCheck} title={''} onConfirm={()=>{this.setState({cookieDescriptionCheck: false})}} onCancel={()=>{this.setState({cookieDescriptionCheck: false})}} customButtons={<></>} >
                            <div className="container-fulid">
                            <div onClick={()=>this.setState({cookieDescriptionCheck: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
                                <span dangerouslySetInnerHTML={{__html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">นโยบายคุกกี้และการตั้งค่า</h4>`+ this.state.cookieDescription}} style={{textAlign: 'left'}}></span>
                            </div>
                        </SweetAlert>
                        <div className={ !this.state.showScreen ? "d-none" : "" }>
                        <Row id="logoSection" className="pl-2 pr-2 pt-2 pb-2 csMainColorHeaderBG d-flex align-items-center" style={{ height: "80px" }}>
                            <Image id="channelLogo" src={!this.state.channel.logo ? logo2 : this.state.channel.logo} rounded style={{ height: '103px', position: 'absolute', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                            <div className="text-white mw-100" style={{ paddingLeft: "120px",fontSize: '18px' }}>
                                {!this.state.isSelectedForHeder ?
                                    <>
                                        <span className="mb-0 font-weight-bold pt-3 mt-0 d-block" style={{ lineHeight: "1.3rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "1.75rem" }}>Online Sales</span>
                                        <small className="d-block" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{MainFunc.getLabelDtos("L0465")/* ซื้อประกันออนไลน์ที่เหมาะกับคุณ */}</small>
                                    </>
                                    :
                                    <>
                                        <span className="mb-0 font-weight-bold pt-1 mt-0 d-block" style={{ lineHeight: "1.3rem", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", fontSize: "1.25rem" }}>{this.state.mainHeaderText}</span>
                                        <small className="d-block" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{this.state.subHeaderText}</small>
                                        <small className="d-block" style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{!this.state.thirdHeaderText ? " ⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀" : this.state.thirdHeaderText}</small>
                                    </>
                                }
                            </div>
                        </Row>
                        <Row id="stepSection" className="bg-white" style={{ borderBottom: "0px solid rgb(221, 223, 227)" }}>
                        </Row>
                        <Row id="contentSection" style={{ height: this.state.conetntHeight, maxHeight: this.state.conetntHeight, overflowY: 'scroll' }}>
                            <Col xs={12} className="pb-4 pt-0 p-2">
                                {this.state.successInitialSale && (
                                    <ScLanding scroll={this.state.scroll} loadController={this.loadController} isLoading={this.state.isLoading} showMainPageOrDetail={this.showMainPageOrDetail} errorAlert={this.errorAlert}/>
                                )}
                                
                            </Col>
                            {/* <Col xs={12} style={{ maxWidth: '100%', minHeight: '0px', maxHeight: '265px', height: this.state.conetntHeight - 688 }}></Col> */}
                            <Row className="w-100 oppositeWhite" style={{ position: "relative", bottom: "0px", left: "15px" }}>
                                <Col xs={12} className="pl-4 pt-4 pr-4 w-100 oppositeBlackBlue">
                                    <Row>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0181")}</span><br></br>
                                            <small className="">{MainFunc.getLabelDtos("L0182")}</small><br></br>
                                            <Image className="mb-2 mr-2" src={IcoTel} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <span className="font-weight-bold mt-1" style={{ fontSize: "1.8rem" }}>{MainFunc.getLabelDtos("L0183")}</span><br></br>
                                            <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0184")}</span>
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">Payment Method</span><br></br>
                                            <Image className="mt-2 mb-2 mr-2" src={IcoKbank} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoVS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoMC} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoCS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /><br></br>
                                            <span className="font-weight-bold m-0">Follow us</span><br></br>
                                            <a href={MainFunc.getConfigDtos("CF012")}><Image className="mt-2 mb-2 mr-2" src={IcoYt} rounded style={{ height: '20px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF013")}><Image className="mt-2 mb-2 mr-2" src={IcoIg} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF014")}><Image className="mt-2 mb-2 mr-2" src={IcoFb} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF015")}><Image className="mt-2 mb-2 mr-2" src={IcoLine} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF016")}><Image className="mt-2 mb-2 mr-2" src={IcoLinkedin} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">Certified</span><br></br>
                                            <Image className="mt-2 mb-2 mr-2" src={IcoDBD} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className="pl-4 oppositeBlack">
                                    <Row>
                                        <div className="mt-2" style={{paddingLeft: '20px'}}>
                                            <a onClick={() => { this.setState({ TermAndConditionModal: true }) }} style={{ cursor: 'pointer' }}><span className=" m-0">{MainFunc.getLabelDtos("L0173")}</span></a><br></br>
                                        </div>
                                        <div className="mt-2" style={{paddingLeft: '20px'}}>
                                            <a href={MainFunc.getConfigDtos("CF001")}><span className=" m-0">{MainFunc.getLabelDtos("L0171")}</span></a><br></br>
                                        </div>
                                        <div className="mt-2" style={{paddingLeft: '20px'}}>
                                            <a href={MainFunc.getConfigDtos("CF002")}><span className=" m-0">{MainFunc.getLabelDtos("L0172")}</span></a><br></br>
                                        </div>
                                        <Col className="mt-2" style={{paddingLeft: '20px'}}>
                                            <a href={MainFunc.getConfigDtos("CF011")}><span className="m-0">{MainFunc.getLabelDtos("L0174")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={4} className="mt-2 mb-2">
                                            <span className="m-0">{MainFunc.getLabelDtos("L0180")}</span><br></br>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className={this.state.showfooter ? 'pt-3 pb-3 d-flex justify-content-center' : 'd-none'}>
                                    <small className="text--muted text-center">{MainFunc.getLabelDtos("L0008")}</small>
                                </Col>
                            </Row>
                        </Row>
                         {this.state._cookie === 'true' ? null 
                            :
                            [
                                this.state._cookie === 'close' ? null :
                                <div className="text-white w-100 d-flex justify-content-center" style={{ position: "absolute", bottom: 0, backgroundColor: "rgba(0,0,0,0.8)", left: 0 }}>
                                    <Image src={IcoCrossSign} rounded style={{position: "absolute", right: "0", padding: "10px", filter: "opacity(0.4) drop-shadow(0 0 0 #DDDEE0)", width: "30px", height: "30px"}} onClick={()=>this.closeCookie()} ></Image>
                                        <div className="pl-3 pr-3 pb-4 pt-3 mt-4">
                                        <span className="justify-content-center">{parse(this.state.cookieDetail?this.state.cookieDetail:"")}</span><span className="spanLink" onClick={()=>this.openCookieDescription()}>{MainFunc.getLabelDtos("L0440")}</span>
                                        <div className="w-100 d-flex justify-content-center pt-2">
                                            <Button variant="info pl-4 pr-4" style={{ borderRadius: "25px" }} onClick={() => {this.SaveCookie()}}>{MainFunc.getLabelDtos("L0260") /* ยอมรับ */}</Button>
                                        </div>
                                    </div>
                                </div>
                                ]
                        }
                        <Image  src={IcoCallCenter} className={"drop--lead--ico"} id={`${MainData.initialData.codeTracking}Click_BtnChat`} title="DropLead" onClick={()=>{this.setState({showDropLead: !this.state.showDropLead})}}></Image>

                        <Collapse in={this.state.showDropLead} timeout="auto" unmountOnExit>
                            <div className={"drop--lead--box"}>
                                <Card class="text-center csMainBG text-white card-header" border="light">
                                    <Card.Header class="text-center csMainBG text-white card-header">
                                        <Image src={IcoCrossSign} rounded onClick={() => this.closeDroplead()} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", width: 20, margin: 20, marginTop: 22 }}></Image>
                                        <Row id="logoSection" className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center" style={{ height: "45px" }}>
                                            <Image id="channelLogo" src={!this.state.channel.logo ? logo2 : this.state.channel.logo} rounded style={{ height: '95px', position: 'absolute', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <div>
                                                <Form.Label className="" style={{  fontSize: "1.2rem", fontWeight: "bold", marginLeft: 90}}>{MainFunc.getLabelDtos("L0466") /* ให้เจ้าหน้าที่ติดต่อกลับ */}</Form.Label>
                                            </div>
                                        </Row>
                                    </Card.Header>
                                    {
                                        (this.state.step == "1") ?
                                            <Card.Body >
                                                <div style={{ height: "420px", overflow: "hidden", overflowY: "scroll", padding: "12px" }}>
                                                    <span className="" style={{ fontSize: "1.1rem" }}>{MainFunc.getLabelDtos("L0472") /* ติดต่อเจ้าหน้าที่ */}</span><br></br>
                                                    <span className="" style={{ fontSize: "1.1rem" }}>{MainFunc.getLabelDtos("L0473") /* ทุกวันทำการ จันทร์ - ศุกร์ เวลา 08:00 - 18:00 */}</span><br></br>
                                                    <Row>
                                                        <Col xs={1} style={{ marginTop: 10 }}>
                                                            <Image className="mb-2 mr-2" src={IcoTelGreen} rounded style={{ height: '30px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                        </Col>
                                                        <Col className="drop--lead--call">
                                                            <a href={`tel:${MainFunc.getLabelDtos("L0183")}`} className="font-weight-bold mt-1 telClass" style={{ fontSize: "1.6rem", width: 55 }}>{MainFunc.getLabelDtos("L0183")}</a>
                                                            <a className="font-weight-bold mt-1 callToClass" style={{ fontSize: "1.6rem", width: 55 }}>{MainFunc.getLabelDtos("L0183")}</a><br></br>
                                                        </Col>
                                                    </Row>
                                                    <Form>
                                                        <Form.Group className="font-weight-bold " hasValidation>
                                                            <Form.Label>{MainFunc.getLabelDtos("L0467") /* ชื่อ - นามสกุล */}
                                                            </Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.name || this.state.checkInputname ? "input--error" : ""}`} type="text" name="name" value={this.state.name} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.nameerror}</span>
                                                        </Form.Group>
                                                        <Form.Group className="font-weight-bold " >
                                                            <Form.Label >{MainFunc.getLabelDtos("L0468") /* เบอร์มือถือ */}</Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.phoneNumber || this.state.checkInputphoneNumber ? "input--error" : ""}`} type="phoneNumber" name="phoneNumber" value={this.state.phoneNumber} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.failPhone}</span>
                                                        </Form.Group>
                                                        <Form.Group className="font-weight-bold " >
                                                            <Form.Label>{MainFunc.getLabelDtos("L0469") /* อีเมล */}</Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.email || this.state.checkInputemail ? "input--error" : ""}`} type="text" name="email" placeholder="yourmail@mail.com" value={this.state.email} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.emailerror}</span>
                                                        </Form.Group>
                                                        <Form.Label className="font-weight-bold ">{MainFunc.getLabelDtos("L0470") /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */}</Form.Label>
                                                        <Form.Group>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.selectDate ? "input--error" : ""}`} as="select" name="selectDate" placeholder="ช่วงเวาลาที่สะดวกให้ติดต่อกลับ" value={this.state.selectDate} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)}>
                                                                <option value="" disabled selected>{MainFunc.getLabelDtos("L0470") /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */}</option>
                                                                {MainData.dropleadDtos.map((item, key) => {
                                                                    return (
                                                                        <option value={item.contactTimeName}>{item.contactTimeName}</option>
                                                                    )
                                                                })}
                                                            </Form.Control>
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.failselectDate}</span>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginTop: 20 }} className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox" value={this.state.checkboxDroplead} onChange={(e) => { this.setState({ checkboxDroplead: e.target.checked }) }} label="ข้าพเจ้ายินยอมให้ บมจ.เมืองไทยประกันชีวิต ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้ นายหน้าประกันชีวิต และพันธมิตร/คู่ค้า เพื่อวัตถุประสงค์ในการนำเสนอขายประกันชีวิตแบบต่างๆ และ/หรือนำเสนอสิทธิประโยชน์อื่นๆ ให้แก่ข้าพเจ้าได้" />
                                                        </Form.Group>
                                                    </Form>
                                                    <Col className="d-flex justify-content-center pt-3">
                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem" }} onClick={() => { this.serviceDroplead() }} disabled={!this.state.checkboxDroplead} >{MainFunc.getLabelDtos("L0471") /* ส่งข้อมูลสอบถาม */}</Button>
                                                    </Col>
                                                </div>
                                            </Card.Body>
                                            :
                                            [
                                                (this.state.step == "2") ?
                                                    <Card.Body >
                                                        <div style={{ marginTop: 150 }}>
                                                            <Spinner className="img-center" animation="border" role="status" style={{ width: "60px", height: "60px" }}>
                                                                <span className="visually-hidden"></span>
                                                            </Spinner>
                                                        </div>
                                                    </Card.Body>
                                                    :
                                                    [
                                                        (this.state.step == "3") ?
                                                            <Card.Body >
                                                                <div style={{ margin: 40 }}>
                                                                    <Image className="img-center" src={IcoCorrect} ></Image>
                                                                    <div className="text-align-center ">
                                                                        <span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0474") /* บริษัทฯ ได้รับข้อมูลเรียบร้อยแล้ว กรุณารอเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ */}</span><br></br>
                                                                        <span className="font-weight-bold text-center font-collor1 " style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0475") /* ขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน) */}</span><br></br>
                                                                    </div>
                                                                    <Col className="d-flex justify-content-center pt-3">
                                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width: 200 }} onClick={() => this.closeDroplead() } >{MainFunc.getLabelDtos("L0185") /** ตกลง */}</Button>
                                                                    </Col>
                                                                </div>
                                                            </Card.Body>
                                                            :
                                                            <Card.Body >
                                                                <div style={{ margin: 70 }}>
                                                                    <Image className="img-center" src={IcoError} ></Image>
                                                                    <div className="text-align-center " style={{ marginTop: 30 }}>
                                                                        <span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0476") /** ขออภัยในความไม่สะดวก */}</span><br></br>
                                                                        <span className="font-weight-bold text-center font-collor1 " style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0477") /** กรุณาลองใหม่อีกครั้ง */}</span><br></br>
                                                                    </div>
                                                                    <Col className="d-flex justify-content-center pt-3" style={{ marginTop: 30 }}>
                                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width: 200 }} onClick={() => this.closeDroplead()} >{MainFunc.getLabelDtos("L0188") /* ปิด */}</Button>
                                                                    </Col>
                                                                </div>
                                                            </Card.Body>

                                                    ]
                                            ]
                                    }
                                </Card>
                            </div>
                        </Collapse>
                        </div>
                    </div>}
            </>
        )
    }
}

export default withTranslation()(Landing);


