// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

var _borderRadius = require("../../../styles/borderRadius");

var _fontSizes = require("../../../styles/fontSizes");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border-radius: ", ";\n    background-color: transparent;\n    border: none;\n    width: 100%;\n    padding: 0.625rem 1rem;\n    resize: none;\n    font-size: ", ";\n    line-height: 1.57;\n    color: ", ";\n    box-sizing: border-box;\n    margin: 0;\n    overflow: auto;\n\n    :focus,\n    :active {\n        outline: 0;\n        padding: 0.563rem 0.9375rem;\n    }\n\n    ::placeholder {\n        color: ", ";\n        font-size: ", ";\n    }\n\n    &[disabled] {\n        cursor: not-allowed;\n        user-select: none;\n\n        &:focus,\n        &:active {\n            padding: 0.625rem 1rem;\n        }\n\n        &::placeholder {\n            color: ", ";\n        }\n    }\n\n    &[readonly] {\n        padding: 0;\n    }\n\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledTextarea = (0, _attachThemeAttrs["default"])(_styledComponents["default"].textarea)(_templateObject(), _borderRadius.BORDER_RADIUS_1, _fontSizes.FONT_SIZE_TEXT_LARGE, function (props) {
  return props.palette.text.main;
}, function (props) {
  return props.palette.text.header;
}, _fontSizes.FONT_SIZE_TEXT_LARGE, function (props) {
  return props.palette.text.disabled;
}, function (props) {
  return props.error && "\n            background-clip: padding-box;\n\n            &:focus,\n            &:active {\n                padding: 0.625rem 1rem;\n            }\n        ";
});
var _default = StyledTextarea;
exports["default"] = _default;