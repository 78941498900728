// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _fontSizes = require("../../../styles/fontSizes");

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: absolute;\n    right: 8px;\n    width: 57px;\n    text-align: right;\n    color: ", ";\n    font-size: ", ";\n    z-index: 504;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledClock = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.error.main;
}, _fontSizes.FONT_SIZE_TEXT_SMALL);
var _default = StyledClock;
exports["default"] = _default;