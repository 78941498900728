import axios from "axios";
import React, { Component } from "react";

import { Row, Col, Image, Button, Card } from "react-bootstrap";
import SyncLoader from '../../customLib/react-spinners/SyncLoader';

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import logo2 from '../../images/logo.png';
import logocheckout0 from '../../images/undraw_Maintenance_re_59vn.svg';



class Shutdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conetntHeight: 0,
            screen: "SM013",
            isLoading: true,
            isLoadingPage: true,
            serviceError: false,
            serviceKPayPlusError: false,
            css: sessionStorage.getItem("customCss"),
            logo: sessionStorage.getItem("channelLogo"),
            configDes:""
        };
    }


    componentDidMount() {
        window.addEventListener("resize", () => {
            this.calContentHeight();
        });
        this.calContentHeight();

        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.id = 'customCss';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = this.state.css;
        link.media = 'all';
        head.appendChild(link);
        // MainFunc.SetMainDataFromStorage();
        if(window.location.origin.includes("localhost")){
            MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
        }
        else{
            MainData.serviceURL = window.location.origin + `${MainData.servicePath}api`;
        }
        this.getSystemConfigData();
    }

    componentDidUpdate() {
        this.calContentHeight();
    }

    calContentHeight = () => {
        let screenHeight = window.innerHeight;
        if (this.state.serviceError === false) {
            if (this.state.conetntHeight !== (screenHeight - document.getElementById('logoSection').offsetHeight)) {
                this.setState({ conetntHeight: screenHeight - document.getElementById('logoSection').offsetHeight })
            }
        }
    }


    getSystemConfigData = () => {
        const headers = { 
            Authorization: `Bearer ${sessionStorage.getItem("bearerAuth")}`,
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
            "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept,X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        }
        const data = {
            "channelCode": sessionStorage.getItem("channelName"),
            lang:"TH"
        };
        axios.post(MainData.serviceURL + '/Sales/SystemConfig', data, {
            headers: headers
        })
        .then(response => {
            if(response.data.success && response.data.data.sysStatus && response.status == 299){
                this.setState({ configDes: response.data.data.configDescriptionTh,isLoading: false });
            }
            else{
                this.setState({isLoading: false });
                window.location.href = sessionStorage.getItem("homePage");
            }
            // else{
            //     window.location.href = sessionStorage.getItem("homePage");
            // }
        })
        .catch(error => {});
    }

    render() {
        return (
            <>
                {this.state.isLoading ?
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: "99", position: "fixed", backgroundColor: "white", left: 0, top: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading" style={{ zIndex: "99", position: "fixed" }}>
                            <Row>
                                <Col xs={12} className="w-100 d-flex justify-content-center"><h3 className="csMainColor">Loading</h3></Col>
                                <Col xs={12} className="w-100 d-flex justify-content-center"><SyncLoader size={15} loading={true} /></Col>
                            </Row>
                        </div>
                    </div>
                    : null}
                    <div className="container-fulid">
                        <Row id="logoSection" className="pl-2 pt-2 pb-2 csMainColorHeaderBG" style={{ height: "90px" }}>
                            <Image src={!this.state.logo ? logo2 : this.state.logo} rounded style={this.state.channelName === 'KBANK' ? { height: '103px', position: 'absolute', left: '0', top: '-5px' } : this.state.channelName === 'MTL' ? { height: '103px', position: 'absolute', left: '0', top: '-5px' } : { height: '65px', position: "absolute" }} />
                            <div className="text-white" style={{ paddingLeft: "120px",fontSize: '20px' }}>
                                <h3 className="mb-0 font-weight-bold pt-3 mt-1" style={{ lineHeight: "1.3rem" }}>Online Sales</h3>
                                <small>{MainFunc.getLabelDtos("L0465")/* ซื้อประกันออนไลน์ที่เหมาะกับคุณ */}</small>
                            </div>
                        </Row>
                        <Row style={{ height: this.state.conetntHeight, maxHeight: this.state.conetntHeight, overflowY: 'scroll' }}>
                            <Col xs={12}>
                                <div className="p-3 mt-3 mb-3 w-100 container">
                                    <Row className="d-flex justify-content-center pt-3 text--shutdown" dangerouslySetInnerHTML={{ __html: this.state.configDes }}>
                                    </Row>
                                    <Row className="d-flex justify-content-center pt-3">
                                        <Image className="mt-2 mb-2" src={logocheckout0} rounded style={{ width: '50%' }} />
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    
            </>

        )
    }
}

export default Shutdown;


