// "use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = customWarning;

/* eslint-disable no-console */
function customWarning() {
  console.warn('You have entered an invalid country code or empty input');
}