// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-direction: column;\n    position: relative;\n\n    .StripeElement--focus {\n        padding: 0.5175rem 1rem 0 0.9375rem;\n        border: 2px solid ", ";\n        background-color: ", ";\n        box-shadow: ", ";\n        outline: 0;\n    }\n\n    .StripeElement--webkit-autofill {\n        background-color: ", " !important;\n    }\n\n    ", "\n\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject(), function (props) {
  return props.palette.brand.main;
}, function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.shadows.brand;
}, function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.error && "\n        .StripeElement--empty {\n            padding: 0.5175rem 1rem 0 0.9375rem;\n            border: 2px solid ".concat(props.palette.error.main, ";\n        }\n\n        .StripeElement--invalid {\n            padding: 0.5175rem 1rem 0 0.9375rem;\n            background-color: ").concat(props.palette.background.main, ";\n            border: 2px solid ").concat(props.palette.error.main, ";\n            background-clip: padding-box;\n        }\n\n        .StripeElement--focus {\n            background-color: ").concat(props.palette.background.main, ";\n            border: 2px solid ").concat(props.palette.error.main, ";\n            box-shadow: ").concat(props.shadows.error, ";\n            outline: 0;\n        }\n    ");
});
var _default = StyledContainer;
exports["default"] = _default;