// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _paddings = require("../../../../styles/paddings");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    border: 1px transparent solid;\n    display: flex;\n    align-items: center;\n    height: 44px;\n    padding: 0 ", ";\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledHeaderContainer = _styledComponents["default"].div(_templateObject(), _paddings.PADDING_X_SMALL, function (props) {
  return props.theme.variant === 'listview' && "\n            justify-content: center;\n            text-transform: capitalize;\n        ";
});

var _default = StyledHeaderContainer;
exports["default"] = _default;