import React, { Component } from "react";
import { Row, Tab, Nav } from "react-bootstrap";
import styles from "../../styles/CategoryTabs.module.scss";

class CategoryTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount(){

  }

  // gotoProductDetail = (id,idEn) => {
  //   if(id){
  //     console.log("id : ",id);
  //   }
  //   else if (idEn) {
  //     console.log("idEn : ",idEn);
  //   }
  // }


  render(){
    return (
      <Tab.Container activeKey={this.props.currentTab} onSelect={(key) => this.props.onChangeTab(key)}>
      <Row className={styles.tabsContainer}>
        {this.props.tabsList !== undefined &&
          this.props.tabsList.length !== 0 &&
          this.props.tabsList.map((tab, index) => (
            <div key={index} className={styles.tab}>
              <Nav.Link className="p-0" eventKey={tab?.cateCode}>
                <div className={styles.content}>
                  <img
                    className={styles.image}
                    src={
                      this.props.currentTab === tab?.cateCode
                        ? tab.cateIconActive
                        : tab.cateIconInactive
                    }
                  />
                  <div
                    className={
                      this.props.currentTab === tab?.cateCode
                        ? `${styles.labelContainer} ${styles.active}`
                        : styles.labelContainer
                    }
                  >
                    <p className={styles.tabLabel}>{tab.cateName}</p>
                  </div>
                </div>
              </Nav.Link>
            </div>
          ))}
          {this.props.tabsList !== undefined &&
          this.props.tabsList.length !== 0 &&
          this.props.tabsList.length%3 == 2 && (
            <div className={styles.tabDisable}>

            </div>
          )
          }
      </Row>
    </Tab.Container>
    )
  }
}

export default CategoryTabs;