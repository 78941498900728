import axios from "axios";
import moment from "moment";
import parse from "html-react-parser";
import { notify } from 'react-noti';
import React, { Component, useRef, useEffect } from "react";
import MomentUtils from '@date-io/moment';
import localization from "moment/locale/th";
import { Row, Col, Button, Accordion, Card, Image, Container } from "react-bootstrap";
import { MuiPickersUtilsProvider as MuiPickersUtilsProviderTH, DatePicker as DatePickerTH, KeyboardDatePicker } from "material-ui-thai-datepickers";
import 'moment/locale/th';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import btn_cal_img from '../../images/btn_cal_img.png';
import btn_dl_img from '../../images/btn_dl_img.png';

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
moment().locale("th", localization).add(543, "year").format('LLL')

class ScDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
        screen: "ScDetail",
        eventKeyActive: null,
        activeId: null,
        url: window.location.href.split('detail/'),
        productBenefit: [ {data: [], groupCode: "", header: "" } ],
    };
    this.promotionBlock = React.createRef();
    this.buttonRow = React.createRef();
    this.colBlock = React.createRef();
    this.informationBlock = React.createRef();
  }

    componentDidMount = () =>{
        this.getProductdetail();
        
    }

    toggleActive = (id) =>{
        if (this.state.activeId === id) {
            this.setState({activeId: null});
        } else {
            this.setState({activeId: id});
        }
        
    }

    nextPage = async () =>{
      this.props.loadController(true);
      let statusSystemConfig = await MainFunc.getSystemConfigData();
      if(statusSystemConfig){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
      }
      else{
        this.props.loadController(false);
      }
      if(this.state.url){
          MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}Detail`,"Click",`${MainData.initialData.codeTracking}Detail_Click_BtnCalculate`)
          window.location.href = MainData.mainPath+this.state.url[1];
      } 
    }

    getProductdetail = () => {
        const headers = { 
            "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        }
        const data = {
            "channelCode" : MainData.initialData.channelCode,
            "source" : null,
            "productId": MainData.initialData.productId,
            "lang": "TH"
        }
        let logData = data
        axios.post(MainData.serviceURL + '/Customer/GetProductdetail', data, {
        headers: headers
    
        })
        .then(res => {
          if(res.status === 299){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
          }
          let data = res.data.data;
          if(!data.isProductDetailStatus){
            sessionStorage.setItem("bearerAuth", MainData.initialData.bearerAuth);
            sessionStorage.setItem("labelDtos", JSON.stringify(MainData.initialData.labelDtos));
            sessionStorage.setItem("configDtos", JSON.stringify(MainData.initialData.configDtos));
            sessionStorage.setItem("dropleadDtos", JSON.stringify(MainData.dropleadDtos));
            sessionStorage.setItem("productId", MainData.initialData.productId);
            window.location.href = MainData.mainPath + 'notfound'
          }
          else{
            MainData.screenDetail.bannerHighlightMB = data.bannerHighlightMB;
            MainData.screenDetail.bannerHighlightPC = data.bannerHighlightPC;
            MainData.screenDetail.coverageAndBenefit = data.coverageAndBenefit;
            MainData.screenDetail.highlight = data.highlight;
            MainData.screenDetail.insurance = data.insurance;
            MainData.screenDetail.isProductDetailStatus = data.isProductDetailStatus;
            MainData.screenDetail.keyHighlight = data.keyHighlight;
            MainData.screenDetail.landingRemark = data.landingRemark;
            MainData.screenDetail.landingTitlePage = data.landingTitlePage;
            MainData.screenDetail.productName = data.productName;
            MainData.screenDetail.promotion = data.promotion;
            MainData.screenDetail.remark = data.remark;
            this.setState({productBenefit: data.productBenefit})
          }

        })
        .catch(error => {

        });


    }
    componentWillReceiveProps(nextProps){
      if (this.promotionBlock === null || this.promotionBlock.current === null) {
        return;
      }
      if (this.promotionBlock.current.getBoundingClientRect().top <= 100 && this.informationBlock.current.getBoundingClientRect().top <= 200) {
        this.colBlock.current.className = sessionStorage.getItem('_cookie') ? `buttonContainer fixed` : `buttonContainer fixed-cookie`;
      } else {
        this.buttonRow.current.className = `detailBtnRadius`;
        this.colBlock.current.className = sessionStorage.getItem('_cookie') ? `buttonContainer fixed` : `buttonContainer fixed-cookie`;
      }
    }
  render(){
    var productBenefit = null;
    if(this.state.productBenefit){
      productBenefit = this.state.productBenefit.map((item, key)=>{
        const productBenefitDetail = item.data.map((subItem, subKey)=>{
          let newVal = subItem.value.replace("\\n","").replace('\\"',"");
          return(
            <Row key={subKey} className="pt-2">
              <Col xs={12} className="text--muted">{subItem.title}</Col>
              <Col xs={12}><span dangerouslySetInnerHTML={{__html: newVal}}></span></Col>
            </Row>
          )
        })
        return(
          <Card key={key} className="mt-2 mb-2" style={this.state.eventKeyActive===item.groupCode?{border: "solid 1px #343a40", borderRadius: "0"}:{border: "solid 1px rgb(241, 241, 241)", borderRadius: "0"}}>
            <Accordion.Toggle onClick={()=>this.setState((state)=>({eventKeyActive: state.eventKeyActive===item.groupCode?null:item.groupCode}))} as={Card.Header} className={this.state.eventKeyActive===item.groupCode?"bg-dark":"planBG"} eventKey={item.groupCode}>
              <Row>
                <Col xs={10} className={this.state.eventKeyActive===item.groupCode?"text-white font-weight-bold":"font-weight-bold"} >{item.header}</Col>
                <Col xs={2} className="text-right font-weight-bold">{this.state.eventKeyActive===item.groupCode?<FaAngleUp className={"text-white"} />:<FaAngleDown />}</Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.groupCode}>
              <Card.Body className="pt-1">
                {productBenefitDetail}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })
    }
    const productInformation = MainData.screenDetail ?.remark ?.productInformation ?.map((item, key)=>{
        let neVal = item.value.replace("\\n","").replace('\\"',"");
        return(
          <Card key={key} className="mt-2 mb-2" style={this.state.eventKeyActive===item ?.seq?{border: "solid 1px #343a40", borderRadius: "0"}:{border: "solid 1px rgb(241, 241, 241)", borderRadius: "0"}}>
            <Accordion.Toggle onClick={()=>this.setState((state)=>({eventKeyActive: state.eventKeyActive===item ?.seq?null:item ?.seq}))} as={Card.Header} className={this.state.eventKeyActive===item ?.seq?"bg-dark":"planBG"} eventKey={item ?.seq}>
              <Row>
                <Col xs={10} className={this.state.eventKeyActive===item ?.seq?"text-white font-weight-bold":"font-weight-bold"} >{item ?.title}</Col>
                <Col xs={2} className="text-right font-weight-bold">{this.state.eventKeyActive===item ?.seq?<FaAngleUp className={"text-white"} />:<FaAngleDown />}</Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item ?.seq}>
              <Card.Body className="pt-1">
                <Row className="pt-2">
                  <Col xs={12}><span dangerouslySetInnerHTML={{__html: neVal}}></span></Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })
    return (
<>
      <Container className="titleContainer">
                <Row>
                    <Col xs={12} md={12} className="d-flex justify-content-center">
                        <span className="font-weight-extra-bold detail--header--text csMainColor text-center">{MainData.screenDetail ?.productName}</span>
                    </Col>
                    <Col xs={12} md={12} className="d-flex justify-content-center">
                        <span className="font-weight-demi-bold detail--title--text csDetailColor text-center">{MainData.screenDetail ?.landingTitlePage}</span>
                    </Col>
                </Row>
      </Container>
        <img className="img img--desktop"
            src={MainData.screenDetail ?.bannerHighlightPC}
            />
        <img className="img img--mobile"
            src={MainData.screenDetail ?.bannerHighlightMB}
            />
      <div>
            <Col xs={12} className="pt-4 pb-4 ml-auto mr-auto" style={{backgroundColor: "#F5F9FD"}}>
                <div  className="featuresWrapper">
                    <Container className={"featuresContainer"}>
                        <h4 className="font-weight-bold detail--header--text">จุดเด่น</h4>
                        <Row>
                        {MainData.screenDetail ?.highlight ?.map((item, key) => {
                            return (
                                <Col xs={6} md={4} className="text-center ">
                                    <Image className="detail--img--size" src={item ?.icon}></Image><br/>
                                    <span className="font-weight-bold detail--subheader--text">{item ?.title}</span><br/>
                                    <span className="font-weight-demi-bold detail--subdetail--title--text">{item ?.description ?.replace("\\n", "")?.replace('\\"', "'") && parse(item ?.description  ?.replace("\\n", "")?.replace('\\"', ""))}</span><br/>
                                </Col>
                            )
                        })}
                        </Row>
                            <Row ref={this.colBlock} className="buttonContainer">
                                    <Button className="detailBtnRadius" ref={this.buttonRow} onClick={() => { this.nextPage() }}> 
                                                <img className="buttonImg" src={btn_cal_img} /> 
                                                <span className="btn--detail--header--text">{MainFunc.getLabelDtos("L0449")}</span>
                                    </Button>
                            </Row>
                    </Container>
                </div>
            </Col>
                <Container className={MainData.screenDetail ?.promotion ?.description ?"promotionContainer":"d-none"} ref={this.promotionBlock}>
                    <h4 className="font-weight-bold csMainColor detail--header--text">โปรโมชั่น</h4>
                    <h6 className="detail--subtitle--text">{MainData.screenDetail ?.promotion ?.description ?.replace("\\n", "")?.replace('\\"', "'") && parse(MainData.screenDetail ?.promotion ?.description ?.replace("\\n", "")?.replace('\\"', ""))}</h6>
                    <div>
                        <Col className="d-flex justify-content-center pt-3">
                            <Button className="dldetailBtnRadius " > 
                                <div>
                                        <img src={btn_dl_img} /> 
                                        <span className="font-weight-extra-bold btndl--detail--header--text csMainColor">ดาวน์โหลดรายละเอียดเพิ่มเติม</span>
                                </div>
                            </Button>
                        </Col>
                    </div>
                </Container>
            <div>
                <Container className="informationContainer" style={{marginTop: 50}} ref={this.informationBlock}>
                        <h4 className="font-weight-bold detail--header--text">ข้อมูลแบบประกัน</h4>
                            <Row>
                            {MainData.screenDetail ?.insurance ?.map((item, key) => {
                                return (
                                    <Col  xs={6} md={4}>
                                        <Image className="detail--img--size" src={item ?.icon}></Image><br/>
                                        <span className="font-weight-bold detail--subheader--text">{item ?.title}</span><br/>
                                        <span className="font-weight-demi-bold detail--subdetail--title--text">{item ?.description}</span><br/>
                                    </Col>
                                )
                            })}
                            </Row>
                </Container>
            </div>
            <div>
                <Container className={MainData.screenDetail ?.landingRemark ?"landingRemarkContainer":"d-none setFullImgAndTable"}>
                    <h4 className="font-weight-bold detail--header--text">{MainData.screenDetail ?.productName}</h4>
                    <h6 className="detail--subtitle--text setFullImgAndTable">{MainData.screenDetail ?.landingRemark ?.replace("\\n", "")?.replace('\\"', "'") && parse(MainData.screenDetail ?.landingRemark ?.replace("\\n", "")?.replace('\\"', ""))}</h6>
                </Container>
                <div className="">
                    
                </div>
            </div>  
            <div>
                <Container className="promotionContainer">
                            <Accordion>
                                {!productBenefit?null:productBenefit}
                                {!productInformation?null:productInformation}
                            </Accordion>
                </Container>
            </div>
      </div>
          
        
    </>
    )
  }
}

export default ScDetail;
