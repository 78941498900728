// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _truncatedText = _interopRequireDefault(require("../../Structural/truncatedText"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: flex-start;\n    flex: 1;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledActionContainer = (0, _styledComponents["default"])(_truncatedText["default"])(_templateObject());
var _default = StyledActionContainer;
exports["default"] = _default;