import axios from "axios";
import { notify } from 'react-noti';
import React, { Component , Fragment} from "react";
import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";
import { AiFillCloseSquare } from 'react-icons/ai';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import {Row, Col, Button, Accordion, Card, Image} from "react-bootstrap";
import {Link} from 'react-router-dom';
import { scroller } from "react-scroll";
import parse from "html-react-parser";
import moment from "moment";
import localization from "moment/locale/th";

moment().locale("th", localization).add(543, "year").format('LLL')

class SM002 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "SM002",
      cost: 0,
      costPayment: 0,
      sumInsured: 0,
      paymentId: null,
      planName: null,
      productName: null,
      paymentName: null,
      productPlanList: [],
      selectedPlanId: null,
      eventKeyActive: null,
      userSelectPlan: null,
      getdataSuccess: false,
      selectedPlanCode: null,
      productPromotion: null,
      isModalPromotion: false,
      isModalPlanDetail: false,
      screenWidth: window.screen.width,
      productInformation: [{seq: null, title: "", value: ""}],
      modalPlanDetailList: {planPremiumMode: [], planDetail:[]},
      productBenefit: [ {data: [], groupCode: "", header: "" } ],
      insureRequiredIdentification: null,
      insureSelfie: null,
    };
  }

  UNSAFE_componentWillMount(){
    this.props.loadController(true)
    this.getData()
  }

  componentDidMount(){
    this.planClick(MainData.screenSM002.paymentCode,MainData.screenSM002.planId,'',MainData.screenSM002.paymentName,MainData.screenSM002.cost,MainData.screenSM002.planName,MainData.screenSM002.sumInsured,MainData.screenSM002.costPayment,MainData.screenSM002.paymentId,MainData.screenSM002.insureRequiredIdentification, MainData.screenSM002.insureSelfie);
  }

  getData = () => {
    const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": MainData.initialData.productId,
      "birthDate": MainFunc.getUserBirthDate("be"),
      "gender": MainData.screenSM001.gender,
      "grantee": "SELF"
    };
    var dataForGet = data;
    axios.post(MainData.serviceURL+'/Sales/GetProduct', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      let data = res.data.data;
      if(!data){
        this.props.loadController(false)
        MainFunc.sendLogToService(this.props.name, 'Search', 'Fail', 'เก็บข้อมูลการค้นหาแผนประกัน', {}, dataForGet);
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG004');
        this.props.errorAlert(true, !errMsg?"ขออภัย ไม่พบข้อมูลแบบประกันที่สามารถเลือกซื้อได้":errMsg.msgDescription, ' ')
        this.props.setPage(this.props.prev)
        return;
      }
      this.setState({productName: data.productName, productBenefit: data.productBenefit, productInformation: data.productInformation, productPromotion: data.productPromotion, productPlanList: data.planList, getdataSuccess: true})
      MainFunc.sendLogToService(this.props.name, 'Search', 'Success', 'เก็บข้อมูลการค้นหาแผนประกัน', {}, dataForGet);
      this.props.loadController(false)
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        console.log("error : ",error.response)
        this.validateBadRequest(error.response.data.errors);
        this.props.setPage(this.props.prev)
      }
      else{
        this.props.loadController(false)
        MainFunc.sendLogToService(this.props.name, 'Search', 'Fail', 'เก็บข้อมูลการค้นหาแผนประกัน', {}, dataForGet);
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
        this.props.setPage(this.props.prev)
      }
     
    });
  }

  saveDataProduct = () => {
    this.props.loadController(true)
    const headers = { 
    Authorization: `Bearer ${MainData.initialData.bearerAuth}`, 
    "Content-Type": "application/json;charset=UTF-8", 
    "Access-Control-Allow-Origin": "*", 
    "Access-Control-Allow-Credentials": "true", 
    "Access-Control-Allow-Methods": 
    "GET,HEAD,OPTIONS,POST,PUT,DELETE", 
    "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers", 
    "AuthId": MainData.screenSM005.cusId
    }
    const data = {
      "channelCode": MainData.initialData.channelCode,
      "productId": MainData.initialData.productId,
      "planId": this.state.selectedPlanId,
      "premium": this.state.cost,
      "paymentId": this.state.paymentId,
      "paymentMode" : this.state.selectedPlanCode,
      "transUrl": window.location.href,
      "submenuCode": this.state.screen,
    };
    axios.post(MainData.serviceURL+'/Customer/AddOrUpdateCustomerPremiumCal', data, {
      headers: headers
    })
    .then(res => { 
      if(res.status === 299){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
      }  
      if(res.data.success === true){
        MainData.screenSM005.cusId = res.data.data;
        this.props.loadController(false)
      }
    })
    .catch(error => {
      if(error?.response?.status == 400){
        this.props.loadController(false)
        this.validateBadRequest(error.response.data.errors)
      }
      else{
        this.props.loadController(false)
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      }
    });
  }

  validateBadRequest = (fieldNameList) => {
    if(fieldNameList.find(data => data.field === "channelCode") || 
    fieldNameList.find(data => data.field === "productId") ||
    fieldNameList.find(data => data.field === "planId") ||
    fieldNameList.find(data => data.field === "premium") ||
    fieldNameList.find(data => data.field === "paymentId") ||
    fieldNameList.find(data => data.field === "paymentMode")
    ){
      let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG019');
      this.props.errorAlert(true, !errMsg?'ขออภัย ท่านกรอกข้อมูลไม่ถูกต้อง':errMsg.msgDescription, ' ');
      this.setState({
      screen: "SM002",
      cost: 0,
      costPayment: 0,
      sumInsured: 0,
      paymentId: null,
      planName: null,
      productName: null,
      paymentName: null,
      productPlanList: [],
      selectedPlanId: null,
      eventKeyActive: null,
      userSelectPlan: null,
      getdataSuccess: false,
      selectedPlanCode: null,
      productPromotion: null,
      isModalPromotion: false,
      isModalPlanDetail: false,
      screenWidth: window.screen.width,
      productInformation: [{seq: null, title: "", value: ""}],
      modalPlanDetailList: {planPremiumMode: [], planDetail:[]},
      productBenefit: [ {data: [], groupCode: "", header: "" } ],
      insureRequiredIdentification: null,
      insureSelfie: null,})
    }
    if(fieldNameList.find(data => data.field == "birthDate")){
      if(MainData.screenSM001.birthdatePDPA || MainData.screenSM001.genderPDPA || MainData.screenSM001.birthdate || MainData.screenSM001.gender){
        MainFunc.logPDPA(
          'Edit', 
          this.props.mainMenuName, 
          this.props.name, 
          null, 
          {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdatePDPA?MainData.screenSM001.birthdatePDPA:MainData.screenSM001.birthdate), 
          gender: MainData.screenSM001.genderPDPA?MainData.screenSM001.genderPDPA:MainData.screenSM001.gender, grantee: "SELF" },
          {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId,
          birthDate: this.convertBirthDate(MainData.screenSM001.birthdate), 
          gender: this.state.gender, grantee: "SELF" }, 'Fail', 'รับประกันอายุระหว่าง 21 ปี - 60 ปี'
        );
      }else{
        MainFunc.logPDPA(
          'Add', 
          this.props.mainMenuName, 
          this.props.name, 
          null, 
          null, 
          {channelCode: MainData.initialData.channelCode, productId: MainData.initialData.productId, birthDate: this.convertBirthDate(MainData.screenSM001.birthdate), 
          gender: this.state.gender, 
          grantee: "SELF" }, 
          'Fail', 
          'รับประกันอายุระหว่าง 21 ปี - 60 ปี'
        );
      }
      this.props.setGetProductFail(true);
    }
  }

  convertBirthDate(data){
    let year = data.split("/")[2];
    year = (parseInt(year) - 543).toString()
    let month = data.split("/")[1];
    let day = data.split("/")[0];

    return year+"-"+month+"-"+day;
  }

  scopePlan = (planId) => {
    let newProductPlan = this.state.productPlanList.find(planObj => planObj["planId"] === planId);
    MainFunc.sendLogToService(this.props.name, 'Access', 'Success', 'เก็บข้อมูลการดูรายละเอียดของแบบประกัน', {}, newProductPlan);
    this.setState({modalPlanDetailList: newProductPlan, isModalPlanDetail: true})
  }

  planClick = (planPremiumCode, planId, e, paymentName, cost, planName, sumInsured, costPayment, paymentId, insureRequiredIdentification, insureSelfie) => {
    if(e){
      if(planPremiumCode == "ONE_MONTH"){
        MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}STEP1`,"Click",`${MainData.initialData.codeTracking}STEP1_Click_${planId}_BtnMonthlyPlan`);
      }
      else{
        MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}STEP1`,"Click",`${MainData.initialData.codeTracking}STEP1_Click_${planId}_BtnYearlyPlan`);
      }
    }
    MainFunc.sendLogToService(this.props.name, 'Select', 'Success', 'เก็บข้อมูลการเลือกแผนประกัน', {planPremiumCode: MainData.screenSM002.paymentCode, planId: MainData.screenSM002.planId, paymentName: MainData.screenSM002.paymentName, cost: MainData.screenSM002.cost, planName: MainData.screenSM002.planName, costPayment: MainData.screenSM002.costPayment, paymentId: MainData.screenSM002.paymentId}, {planPremiumCode: planPremiumCode, planId: planId, paymentName: paymentName, cost: cost, planName: planName,costPayment: costPayment,paymentId: paymentId});
    if(this.state.productPromotion){
      this.setState({isModalPromotion: true, selectedPlanId: planId, selectedPlanCode: planPremiumCode, planName: planName, paymentName: paymentName, cost: cost, sumInsured: sumInsured,costPayment: costPayment,paymentId:paymentId, insureRequiredIdentification:insureRequiredIdentification, insureSelfie:insureSelfie})
    }else{
      this.setState({userSelectPlan: planPremiumCode+"||"+planId, selectedPlanId: planId, selectedPlanCode: planPremiumCode, planName: planName, paymentName: paymentName, cost: cost, sumInsured: sumInsured,costPayment: costPayment,paymentId:paymentId, insureRequiredIdentification:insureRequiredIdentification, insureSelfie:insureSelfie})
    }
  }

  conFirmPromo = async (planPremiumCode, planId) => {
    await this.setState({userSelectPlan: planPremiumCode+"||"+planId, selectedPlanId: planId, selectedPlanCode: planPremiumCode, isModalPromotion: false, modalPromotionText: ""})
    this.nextPage();
  }
  
  nextPage = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if(statusSystemConfig){
        window.location.href = MainData.mainPath + 'shutdown'
        return;
    }
    else{
      this.props.loadController(false);
    }
    MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}STEP1`,"Click",`${MainData.initialData.codeTracking}STEP1_Click_${this.state.selectedPlanId}_BtnBuy`);
    if(!this.state.userSelectPlan){
      if(MainData.screenSM002.planId && MainData.screenSM002.cost && MainData.screenSM002.paymentName)
      {
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {planPremiumCode: MainData.screenSM002.paymentCode, planId: MainData.screenSM002.planId , paymentName: MainData.screenSM002.paymentName, premium: MainData.screenSM002.cost, planName: MainData.screenSM002.planName, premiumPayment: "" }
        , {planPremiumCode: this.state.selectedPlanCode, planId: this.state.selectedPlanId, paymentName: this.state.paymentName, premium: this.state.cost, planName: this.state.planName, premiumPayment: "" }, 'Fail', 'กรุณาเลือกแผนแบบประกันก่อน')
      }
      else
      {
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {planPremiumCode: this.state.selectedPlanCode, planId: this.state.selectedPlanId, paymentName: this.state.paymentName, premium: this.state.cost, planName: this.state.planName, premiumPayment: "" }, 'Fail', 'กรุณาเลือกแผนแบบประกันก่อน')
      }
      this.props.errorAlert(true, "กรุณาเลือกแผนแบบประกันก่อน")
      
    }else{
      if(MainData.screenSM002.planId && MainData.screenSM002.cost && MainData.screenSM002.paymentName)
      {
        MainFunc.logPDPA('Edit', this.props.mainMenuName, this.props.name, null, {planPremiumCode: MainData.screenSM002.paymentCode, planId: MainData.screenSM002.planId , paymentName: MainData.screenSM002.paymentName, premium: MainData.screenSM002.cost, planName: MainData.screenSM002.planName, premiumPayment: "" }
        , {planPremiumCode: this.state.selectedPlanCode, planId: this.state.selectedPlanId, paymentName: this.state.paymentName, premium: this.state.cost, planName: this.state.planName, premiumPayment: "" }, 'Success', null)
      }
      else
      {
        MainFunc.logPDPA('Add', this.props.mainMenuName, this.props.name , null, null, {planPremiumCode: this.state.selectedPlanCode, planId: this.state.selectedPlanId, paymentName: this.state.paymentName, premium: this.state.cost, planName: this.state.planName, premiumPayment: "" }, 'Success', null)
      }
      MainData.screenSM002.cost = this.state.cost
      MainData.screenSM002.costPayment = this.state.costPayment
      MainData.screenSM002.planName = this.state.planName
      MainData.screenSM002.planId = this.state.selectedPlanId
      MainData.screenSM002.productName = this.state.productName
      MainData.screenSM002.paymentName = this.state.paymentName
      MainData.screenSM002.paymentCode = this.state.selectedPlanCode
      MainData.screenSM002.sumInsured = this.state.sumInsured
      MainData.screenSM002.paymentId = this.state.paymentId
      MainData.screenSM002.insureRequiredIdentification = this.state.insureRequiredIdentification
      MainData.screenSM002.insureSelfie = this.state.insureSelfie
      MainData.screenSM002.screen = this.state.screen
      MainData.screenSM002.WindowLocationHref = window.location.href
      // let subHeader = '';
      // if(this.state.selectedPlanCode==="TWELVE_MONTHS"){
      //   subHeader = MainFunc.getLabelDtos("L0133")+" : ";
      // }else if("ONE_MONTH"){
      //   subHeader = MainFunc.getLabelDtos("L0134")+" : ";
      // }else if("THREE_MONTHS"){
      //   subHeader = MainFunc.getLabelDtos("L0135")+" : ";
      // }else if("SIX_MONTHS"){
      //   subHeader = MainFunc.getLabelDtos("L0136")+" : ";
      // }
      
      let subHeader = MainFunc.getLabelDtos("L0112")+" : ";
      this.props.setHeaderText(MainData.productTitle, subHeader+MainFunc.numberWithCommas(this.state.cost)+" "+MainFunc.getLabelDtos("L0104"), this.state.planName, true)
      if(sessionStorage.getItem('_cookie') === "true"){
        this.saveDataProduct();
      }
      if(!MainData.screenMenuKPlus.onKPlus){
        this.props.showKplus();
        setTimeout(() => {
          this.props.scrollContentSectionTop();
        }, 100);
      }
      this.props.setPage(this.props.next)
    }
  }

  render(){
    var productBenefit = null;
    if(this.state.productBenefit){
      productBenefit = this.state.productBenefit.map((item, key)=>{
        const productBenefitDetail = item.data.map((subItem, subKey)=>{
          let newVal = subItem.value.replace("\\n","").replace('\\"',"");
          return(
            <Row key={subKey} className="pt-2">
              <Col xs={12} className="text--muted">{subItem.title}</Col>
              <Col xs={12}><span dangerouslySetInnerHTML={{__html: newVal}}></span></Col>
            </Row>
          )
        })
        return(
          <Card key={key} className="mt-2 mb-2" style={this.state.eventKeyActive===item.groupCode?{border: "solid 1px #343a40", borderRadius: "0"}:{border: "solid 1px rgb(241, 241, 241)", borderRadius: "0"}}>
            <Accordion.Toggle onClick={()=>{this.setState((state)=>({eventKeyActive: state.eventKeyActive===item.groupCode?null:item.groupCode})); setTimeout(() => {scroller.scrollTo(`accordion--productBenefit${key}`, {duration: 800,smooth: "easeInOutQuart",offset: -10,containerId: 'contentSection'});}, 300);}} as={Card.Header} className={`${this.state.eventKeyActive===item.groupCode?"bg-dark":"planBG"} accordion--productBenefit${key}`} eventKey={item.groupCode}>
              <Row>
                <Col xs={10} className={this.state.eventKeyActive===item.groupCode?"text-white font-weight-bold":"font-weight-bold"} >{item.header}</Col>
                <Col xs={2} className="text-right font-weight-bold">{this.state.eventKeyActive===item.groupCode?<FaAngleUp className={"text-white"} />:<FaAngleDown />}</Col>
              </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={item.groupCode}>
              <Card.Body className="pt-1">
                {productBenefitDetail}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        )
      })
    }

    const productInformation = this.state.productInformation.map((item, key)=>{
      let neVal = item.value.replace("\\n","").replace('\\"',"");
      return(
        <Card key={key} className="mt-2 mb-2" style={this.state.eventKeyActive===item.seq?{border: "solid 1px #343a40", borderRadius: "0"}:{border: "solid 1px rgb(241, 241, 241)", borderRadius: "0"}}>
          <Accordion.Toggle onClick={()=>{this.setState((state)=>({eventKeyActive: state.eventKeyActive===item.seq?null:item.seq})); setTimeout(() => {scroller.scrollTo(`accordion--productInformation${key}`, {duration: 800,smooth: "easeInOutQuart",offset: -10,containerId: 'contentSection'});}, 300);} } as={Card.Header} className={`${this.state.eventKeyActive===item.seq?"bg-dark":"planBG"} accordion--productInformation${key}`} eventKey={item.seq}>
            <Row>
              <Col xs={10} className={this.state.eventKeyActive===item.seq?"text-white font-weight-bold":"font-weight-bold"} >{item.title}</Col>
              <Col xs={2} className="text-right font-weight-bold">{this.state.eventKeyActive===item.seq?<FaAngleUp className={"text-white"} />:<FaAngleDown />}</Col>
            </Row>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey={item.seq}>
            <Card.Body className="pt-1">
              <Row className="pt-2">
                <Col xs={12}><span dangerouslySetInnerHTML={{__html: neVal}}></span></Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )
    })

    return (
      <div className="container-fulid">
        {this.state.getdataSuccess===true?
        <Row>
          <Col xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{this.props.mainMenuName}</span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col>
          {this.state.productPlanList.map((item, key)=>{
            let canBuy = false;
            let userSelectMain = !this.state.userSelectPlan?'':this.state.userSelectPlan.split("||");
            let checkPlanId = false;
            if(userSelectMain[1]===item.planId.toString()){
              canBuy = true;
            }
            if(key == 0){
              checkPlanId = true;
            }
            if(key != 0){
              if(this.state.productPlanList[(key-1)].planId != this.state.productPlanList[(key)].planId){
                checkPlanId = true;
              }
            }
            // else{
            //   checkPlanId = false;
            // }
            if(checkPlanId){
              return(
                <Col key={key} xs={12} lg={6} className="pt-4">
                  <Card className="w-100">
                    <Card.Header className="text-center csMainBG text-white">{this.state.productName} <br></br>{item.planName}</Card.Header>
                    {item.planBenefit.map((subItem, subKey)=>{
                      let indexSubKey = 0;
                      return(
                        <Row key={subKey} className="pt-3 pb-3 ml-2 mr-2" style={{borderBottom: "0.2px solid rgba(0,0,0,0.1)"}}>
                          {/* <Col xs={!subItem.groupValue?12:8} className="pr-1 pl-0 font-weight-bold">{subKey+1}. {subItem.groupTitle}</Col> */}
                          <Col xs={!subItem.groupValue?12:8} className="pr-1 pl-0 font-weight-bold" onClick={() => { if(subItem.groupTitle.includes(`KBPMJ1_STEP1_Click_{PlanID}_BtnCovidProtection`)){MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}STEP1`,"Click",`${MainData.initialData.codeTracking}STEP1_Click_${item.planId}_BtnCovidProtection`)} }}>{subKey+1}. {parse(subItem.groupTitle?subItem.groupTitle.includes("KBPMJ1_STEP1_Click_{PlanID}_BtnCovidProtection")?subItem.groupTitle.replace("KBPMJ1_STEP1_Click_{PlanID}_BtnCovidProtection",`KBPMJ1_STEP1_Click_${subItem.planId}_BtnCovidProtection`):subItem.groupTitle: "")}</Col>
                          <Col xs={4} className="align-self-center text-right pl-1 pr-0 font-weight-bold">{parse(subItem.groupValue?subItem.groupValue: "")}</Col>
                            {subItem.description.map((secSubItem, secSubKey)=>{       
                              if (secSubItem.title) {
                                indexSubKey++
                              }
                              return(
                                <Row key={secSubKey} className="pl-1 ml-0 mr-0 w-100">
                                  {secSubItem.title?
                                  <Fragment>
                                    <Col xs={!secSubItem.titleValue?12:8} className="pt-2 pl-1 " style={{fontSize: "0.9rem"}}>{subKey+1+"."+(indexSubKey)}. {parse(secSubItem.title?secSubItem.title: "")}</Col>                       
                                    <Col xs={4} className="align-self-center text-right font-weight-bold pl-1 pr-0 pt-2" style={{fontSize: "0.95rem"}}>{parse(secSubItem.titleValue?secSubItem.titleValue: "")}</Col>
                                  </Fragment>
                                  :null}
                                  {secSubItem.subTitleDescription.map((thirdSubItem, thirdSubKey)=>{
                                    return(
                                      <Row key={thirdSubKey} className="pr-1 pl-0 w-100">
                                        <Col xs={!thirdSubItem.subTitleValue?12:8} style={{fontSize: "0.9rem"}}>- {parse(thirdSubItem.subTitle?thirdSubItem.subTitle: "")}</Col>
                                        <Col xs={4} className="align-self-center text-right font-weight-bold pl-1 pr-0" style={{fontSize: "0.95rem"}}>{parse(thirdSubItem.subTitleValue?thirdSubItem.subTitleValue: "")}</Col>
                                      </Row>
                                    )
                                  })}
                                </Row>
                              )
                            })}
                        </Row>
                      )
                    })}
                    <Row className="ml-2 mr-2">
                      <Col xs={12} className="font-weight-bold pt-3 pl-2">{MainFunc.getLabelDtos("L0009")}</Col>
                      {item.planPremiumMode.map((ppItem, ppKey)=>{
                        let selected = false;
                        let userSelect = !this.state.userSelectPlan?'':this.state.userSelectPlan.split("||");
                        if((userSelect[0]===ppItem.paymentCode)&&(userSelect[1]===item.planId.toString())){
                          selected = true;
                        }
                        return(
                          <Col key={ppKey} xs={6} className="pt-2" >
                            <div id={ppItem.paymentCode == "ONE_MONTH"?`${MainData.initialData.codeTracking}STEP1_Click_${ppItem.planId}_BtnMonthlyPlan`:`${MainData.initialData.codeTracking}STEP1_Click_${ppItem.planId}_BtnYearlyPlan`} className={"pt-2 pb-2"} style={!selected?{backgroundColor: "rgb(234,234,234)", borderRadius: "5px", cursor: 'pointer'}:{backgroundColor: "rgb(232,244,250)", borderRadius: "5px", cursor: 'pointer'}} onClick={(e)=>this.planClick(ppItem.paymentCode, item.planId, e, ppItem.paymentName, ppItem.premium, item.planName, ppItem.sumInsured, ppItem.premiumPayment, ppItem.paymentId, ppItem.insureRequiredIdentification, ppItem.insureSelfie)}>
                              <span className={"d-block text-center font-weight-bold"}>{ppItem.paymentName}</span>
                              <span className={"d-block text-center font-weight-bold csMainColor"} style={{fontSize: '1.2rem'}}>{MainFunc.numberWithCommas(ppItem.premium)} {MainFunc.getLabelDtos("L0104")}</span>
                            </div>
                          </Col>
                        )
                      })}
                      <Col xs={12} className="d-flex justify-content-center pt-3">
                        <Button id={`${MainData.initialData.codeTracking}STEP1_Click_${item.planId}_BtnBuy`} variant="" className="mainBtnRadius font-weight-bold pt-1 pb-1" onClick={() => { this.nextPage() }} disabled={!canBuy?true:false}>ซื้อเลย</Button>
                      </Col>
                      <Col xs={12} className="text-center pt-3 pb-3">
                        <span id={`${MainData.initialData.codeTracking}STEP1_Click_${item.planId}_BtnMoreDetail`} className="csMainColor font-weight-bold" style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>{this.scopePlan(item.planId);MainFunc.sendDataToGTM(`${MainData.initialData.codeTracking}STEP1`,"Click",`${MainData.initialData.codeTracking}STEP1_Click_${item.planId}_BtnMoreDetail`)}}>{MainFunc.getLabelDtos("L0114")}</span>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              )
            }
          })}
          <Col xs={12} className="pt-4">
            <Accordion>
              {!productBenefit?null:productBenefit}
              {!productInformation?null:productInformation}
            </Accordion>
          </Col>
          {!this.props.prev?null:
            <Col xs={12} className={!this.props.next?"d-flex justify-content-center pt-3":"d-flex justify-content-center pt-3 pr-2"}>
              <Button id={`${MainData.initialData.codeTracking}STEP1_Click_BtnBack`} variant="" className="subBtnRadius font-weight-bold" onClick={() => this.props.setPage(this.props.prev)}>{MainFunc.getLabelDtos("L0020")}</Button>
            </Col>
          }
        </Row>
        :null}

        {/* MODAL Promotion */}
        <SweetAlert show={this.state.isModalPromotion} title={''} onConfirm={()=>{this.conFirmPromo(this.state.selectedPlanCode, this.state.selectedPlanId)}} onCancel={()=>{this.setState({isModalPromotion: false, productPromotion: ""})}} customButtons={<React.Fragment>
          <Button variant="" className="mainBtnRadius mainShadow" onClick={()=>{this.conFirmPromo(this.state.selectedPlanCode, this.state.selectedPlanId)}}>ซื้อเลย</Button>
          </React.Fragment>}>
          <div className="container-fulid">
            <span dangerouslySetInnerHTML={{__html: this.state.productPromotion}} style={{fontWeight: "bold"}}></span>
          </div>
        </SweetAlert>

        {/* MODAL คลิกดูรายละเอียดแผน */}
        <SweetAlert show={this.state.isModalPlanDetail} title={""} onConfirm={()=>{this.setState({isModalPlanDetail: false, modalPlanDetailList: {planPremiumMode: [], planDetail:[]}})}} customButtons={<></>} >
          <div className="container-fulid">
            <Row>
              <div onClick={()=>this.setState({isModalPlanDetail: false, modalPlanDetailList: {planPremiumMode: [], planDetail:[]}})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
              <Col xs={12} className="text-left csMainColor pb-3 font-weight-bold" style={{fontSize: "1.5rem"}}>
                <Image src={this.state.logo} rounded style={{height: '40px'}} className="pr-2" />
                <span>{this.state.productName} {this.state.modalPlanDetailList.planName}</span>
              </Col>
              <Row className="w-100 ml-0 mr-0 pb-2">
                {this.state.modalPlanDetailList.planPremiumMode.map((item, key)=>{
                return(
                  <Col xs={6} key={key} className="text-left">
                    <span style={{fontSize: "0.9rem"}}>{item.paymentName}</span>
                    <br />
                    <span>{MainFunc.numberWithCommas(item.premium)} {MainFunc.getLabelDtos("L0104")}</span>
                  </Col>
                  )
                })} 
              </Row>
              {/* {this.state.modalPlanDetailList.planDetail.find(detailItem => detailItem["groupValue"] === null)?<div className="w-100 ml-3 mr-3 mb-2" style={{border: "1px solid rgba(0,0,0,0.1)"}}></div>:null} */}
              {/* <Row className="w-100 ml-0 mr-0 pb-2">
                {this.state.modalPlanDetailList.planDetail.map((item, key)=>{
                  if(item.groupValue!==null){
                    return(
                      <Col key={key} xs={6} className="text-left">
                        <span style={{fontSize: "0.8rem"}}>{item.groupTitle}</span>
                        <br/>
                        <span className="csMainColor">{item.groupValue}</span>
                      </Col>
                    )
                  }else{
                    return null;
                  }
                })}                 
              </Row> */}
              <div className="w-100 ml-3 mr-3 mb-2" style={{border: "1px solid rgba(0,0,0,0.1)"}}></div>
              <Row className="w-100 ml-0 mr-0">
                {/* {this.state.modalPlanDetailList.planDetail.map((item, key)=>{
                  if(item.groupValue===null){
                    return(
                      <Col key={key} xs={12} className="text-left pt-3">
                        <span className="font-weight-bold">{item.groupTitle}</span>
                        <div className="mainShadow mt-1" style={{border: "1px solid rgba(0,0,0,0.1)", borderRadius: '5px'}}>
                          {item.description.map((subItem, subKey)=>{
                            return(
                              <div key={subKey} className="m-2">
                                <span style={{fontSize: "0.8rem"}}>{subItem.title}</span>
                                <br/>
                                <span>{subItem.titleValue}</span>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                    )
                  }else{
                    return null;
                  }
                })}       */}
                {/* <Col xs={12} className="text-left pt-2">
                  <span style={{fontSize: "0.8rem"}}>{MainFunc.getLabelDtos("L0131")}</span>
                </Col>
                <Col xs={12} className="text-left pt-2">
                  <span style={{fontSize: "0.8rem"}}>{MainFunc.getLabelDtos("L0132")}</span>
                </Col> */}
                {this.state.modalPlanDetailList.planDetail.map((item, key)=>{    
                    let indexTitle = 0;
                    return(
                      <Col key={key} xs={12} className="text-left pt-3">
                        <div className="mainShadow mt-1" style={{border: "1px solid rgba(0,0,0,0.1)", borderRadius: '5px'}}>
                        <Row className="m-2">
                          <Col xs={7} className="p-0">           
                          {/* <span className="font-weight-bold">{(key+1)+'.'} {item.groupTitle}</span> */}
                          <span className="font-weight-bold">{(key+1)+'.'} {parse(item.groupTitle?item.groupTitle:"")}</span>
                          </Col>
                          <Col xs={5} className="p-0 text-right">
                            <span className="font-weight-bold">{parse(item.groupValue?item.groupValue : "")}</span>
                          </Col>
                        </Row>
                          {item.description.map((titleItem, titleKey)=>{
                            if(titleItem.title){
                              indexTitle++
                            }
                            return(
                              <div key={titleKey} className="m-2" onClick={() => {console.log("asdasd")}}>
                                {titleItem.title?
                                <Row className="m-2">
                                  <Col xs={7} className="p-0">
                                    <span style={{fontSize: "0.9rem"}}>{(key+1)+'.'}{(indexTitle)} {parse(titleItem.title?titleItem.title: "")}</span>
                                  </Col>
                                  <Col xs={5} className="p-0 text-right">
                                    <span style={{fontSize: "0.9rem"}}>{parse(titleItem.titleValue?titleItem.titleValue: "")}</span>
                                  </Col>
                                </Row>
                                :null}
                                {titleItem.subTitleDescription?titleItem.subTitleDescription.map((subItem, subKey)=>{
                                  return(
                                    <div key={subKey} className="mt-2 ml-2 mb-2 mr-0">
                                      <Row className="m-2">
                                        <Col xs={7} className="p-0">
                                          <span style={{fontSize: "0.9rem"}}>- {parse(subItem.subTitle?subItem.subTitle: "")}</span>
                                        </Col>
                                        <Col xs={5} className="p-0 text-right">
                                          <span style={{fontSize: "0.9rem"}}>{parse(subItem.subTitleValue?subItem.subTitleValue: "")}</span>
                                        </Col>
                                      </Row>
                                      
                                    </div>
                                  )
                                }):''}
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                    )
                })}      
              </Row>
            </Row>
          </div>
        </SweetAlert>

        
      </div>
    )
  }
}

export default SM002;