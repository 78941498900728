import axios from "axios";
import React, { Component } from "react";

import { Row, Col, Image, Button, Card, Spinner, Form } from "react-bootstrap";
import { Collapse } from "@material-ui/core";
import SyncLoader from '../../customLib/react-spinners/SyncLoader';

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import logo2 from '../../images/logo.png';
import logocheckout0 from '../../images/notFound.svg';
import IcoCorrect from '../../images/ico_correct.png';
import IcoTelGreen from '../../images/ico_tel_green.png';
import IcoCrossSign from '../../images/ico_closesign.png';
import IcoError from '../../images/ico_error.png';
import IcoTel from '../../images/ico_tel.png';
import IcoFb from '../../images/ico_fb.png';
import IcoIg from '../../images/ico_ig.png';
import IcoLine from '../../images/ico_line.png';
import IcoLinkedin from '../../images/ico_linkedin.png';
import IcoYt from '../../images/ico_yt.png';
import IcoDBD from '../../images/logo_cf_dbd.png';
import IcoCS from '../../images/logo_pm_cs.png';
import IcoKbank from '../../images/logo_pm_kbank.png';
import IcoMC from '../../images/logo_pm_mc.png';
import IcoVS from '../../images/logo_pm_vs.png';



class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conetntHeight: 0,
            screen: "SM013",
            isLoading: false,
            isLoadingPage: true,
            serviceError: false,
            serviceKPayPlusError: false,
            css: sessionStorage.getItem("customCss"),
            logo: sessionStorage.getItem("channelLogo"),
            configDes:"ไม่พบข้อมูลแบบประกันที่ท่านเลือก กรุณาเลือกแบบประกันใหม่อีกครั้ง",
            channelName:sessionStorage.getItem("channelName"),
            logoWidth: 70,
            showDropLead: false,
            step: "1",
            name: "",
            phoneNumber: "",
            email: "",
            selectDate: "",
            validateDroplead: false,
            nameerror: "",
            emailerror: "",
            failPhone: "",
            failselectDate: "",
            checkboxDroplead: false,
            DropoffModal: false,
            checkInputname: false,
            checkInputemail: false,
            checkInputphoneNumber: false,
            styleButton:0,
            conetntStyleButton:0,
            

        };
    }


    componentDidMount() {
        window.addEventListener("resize", () => {
            this.calContentHeight();
        });
        // setTimeout(() => {
            this.calContentHeight();
        // }, 1000);
        

        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.id = 'customCss';
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = this.state.css;
        link.media = 'all';
        head.appendChild(link);
        // MainFunc.SetMainDataFromStorage();
        MainData.initialData.bearerAuth = sessionStorage.getItem("bearerAuth");
        MainData.initialData.labelDtos = JSON.parse(sessionStorage.getItem("labelDtos"));
        MainData.initialData.configDtos = JSON.parse(sessionStorage.getItem("configDtos"));
        MainData.dropleadDtos = JSON.parse(sessionStorage.getItem("dropleadDtos"));
        MainData.initialData.productId = parseInt(sessionStorage.getItem("productId"));
        MainData.initialData.channelCode = sessionStorage.getItem("channelName");
        if(window.location.origin.includes("localhost")){
            MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
        }
        else{
            MainData.serviceURL = window.location.origin + `${MainData.servicePath}api`;
        }
    }

    componentDidUpdate() {
        this.calContentHeight();
    }

    calContentHeight = () => {
        let screenHeight = window.innerHeight;
        if (this.state.serviceError === false) {
            if (this.state.conetntHeight !== (screenHeight - document.getElementById('logoSection').offsetHeight - document.getElementById('stepSection').offsetHeight)) {
                this.setState({ conetntHeight: screenHeight - document.getElementById('logoSection').offsetHeight - document.getElementById('stepSection').offsetHeight })
            }
        }
    }

    handleInputChange = (name, val) => {
        switch(name){
          case "name":
            if (val[0] === " ") { break; }
            let checkWord = /^[ก-์ ]+$/.test(val);
            this.setState((state) => ({name: !checkWord&&val?state.name:val}))
            this.setState({checkInputname: false})
            if(this.state.name.split(" ")[0] || this.state.name.split(" ")[1]){this.setState({nameerror: ""})}
            break;
          case "phoneNumber":
            val = val.replace(/[^\d]/g, ""); 
            if (val.length == 1 && val != "0") { this.setState({phoneNumber: "0"+val}) } 
            else if (val.length <= 10) { this.setState({ phoneNumber: val,failPhone: "" }) }
            this.setState({checkInputphoneNumber: false})
            break;
          case "email":
            let checkWord4 = /^[a-zA-Z0-9-@_.]+$/.test(val);
            this.setState((state) => ({email: !checkWord4&&val?state.email:val, emailerror: ""}))
            this.setState({checkInputemail: false})
            break;
          case "selectDate":
            this.setState({selectDate: val, failselectDate: ""})
            break;
          default:
            break;
        }
    }

    closeDroplead = () => {
        document.getElementById("dropLeadBox").click();
        setTimeout(() => {
            this.setState({showDropLead: false, step: "1", validateDroplead: false, checkboxDroplead: false, checkInputname: false, checkInputemail: false, checkInputphoneNumber: false })
            this.setState({name: "", phoneNumber: "", email: "", selectDate: "" })
            this.setState({nameerror: "", failPhone: "", emailerror: "", failselectDate: "" })
        }, 100);

    }

    serviceDroplead = async () => {
        let statusSystemConfig = await MainFunc.getSystemConfigData();
        if(statusSystemConfig){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
        }
        this.setState({nameerror: "",emailerror: "",failPhone:"",failselectDate:""})
        this.setState({ validateDroplead: true ,checkInputname: false,checkInputemail: false})
        let validate = false;
        if (!this.state.name || !this.state.phoneNumber || !this.state.email || !this.state.selectDate) {
            validate = true;
        }
        if (this.state.name === "")  {
            this.setState({ nameerror: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else if (!this.state.name.split(/ (.*)/s)[0] || !this.state.name.split(/ (.*)/s)[1]) {
            this.setState({ nameerror: 'กรุณาตรวจสอบข้อมูล' , checkInputname: true})
            validate = true;
        } 

        if (this.state.email === "") {
            this.setState({ emailerror: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else if (this.validateEmail(this.state.email) !== true) {
            this.setState({ emailerror: 'กรุณาตรวจสอบข้อมูล' , checkInputemail: true})
            validate = true;
        }

        if (this.state.phoneNumber == "") {
            this.setState({ failPhone: 'กรุณาระบุข้อมูล' })
            validate = true;
        } else  if (this.state.phoneNumber.length <= 9) {
            this.setState({ failPhone: 'กรุณาตรวจสอบข้อมูล' , checkInputphoneNumber: true})
            validate = true;
        }

        if (this.state.selectDate == "") {
            this.setState({ failselectDate: 'กรุณาระบุข้อมูล' })
            validate = true;
        }
        
        if (validate) {
            return;
        }

        this.setState({ step: "2" })

        const headers = { "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "channel": MainData.initialData.channelCode,
            "firstName": this.state.name.split(" ")[0].trim(),
            "lastName": this.state.name.split(" ").slice(1).filter(Boolean).join(" "),
            "phoneNumber": this.state.phoneNumber,
            "email": this.state.email,
            "productId": MainData.initialData.productId,
            "contactTimeName": this.state.selectDate,
        };

        
        axios.post(MainData.serviceURL + '/Customer/GetDropLead', data, {
            headers: headers
        })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data.data;
                if (res.data.success) {
                    this.setState({ step: "3" })
                    this.setState({ contactTimeId: data.contactTimeId, contactTimeCode: data.contactTimeCode, contactTimeName: data.contactTimeName });
                } else {
                    this.setState({ step: "2" })
                }

            })
            .catch(error => {
                if(error?.response?.status == 400){
                    this.props.loadController(false)
                    this.validateBadRequest(error.response.data.errors);
                }
                else{
                    this.setState({ step: "4" });
                }
            });

    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    render() {
        return (
            <>
                {this.state.isLoading ?
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: "99", position: "fixed", backgroundColor: "white", left: 0, top: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading" style={{ zIndex: "99", position: "fixed" }}>
                            <Row>
                                <Col xs={12} className="w-100 d-flex justify-content-center"><h3 className="csMainColor">Loading</h3></Col>
                                <Col xs={12} className="w-100 d-flex justify-content-center"><SyncLoader size={15} loading={true} /></Col>
                            </Row>
                        </div>
                    </div>
                    : null}
                    <div className="container-fulid">
                        <Row id="logoSection" className="pl-2 pt-2 pb-2 csMainColorHeaderBG" style={{ height: "90px" }}>
                            <Image src={!this.state.logo ? logo2 : this.state.logo} rounded style={this.state.channelName === 'KBANK' ? { height: '103px', position: 'absolute', left: '0', top: '-5px' } : this.state.channelName === 'MTL' ? { height: '103px', position: 'absolute', left: '0', top: '-5px' } : { height: '65px', position: "absolute" }} />
                            <div className="text-white" style={{ paddingLeft: "120px",fontSize: '20px' }}>
                                <h3 className="mb-0 font-weight-bold pt-3 mt-1" style={{ lineHeight: "1.3rem" }}>Online Sales</h3>
                                <small>{MainFunc.getLabelDtos("L0465")/* ซื้อประกันออนไลน์ที่เหมาะกับคุณ */}</small>
                            </div>
                        </Row>
                        <Row id="stepSection" className="bg-white" style={{ borderBottom: "2px solid rgb(221, 223, 227)" }}>
                            <Col xs={12} className={'d-none'} style={{ maxWidth: "1140px" }}>
                            </Col>
                        </Row>
                        <Row id="contentSection" style={{ height: this.state.conetntHeight, maxHeight: this.state.conetntHeight, overflowY: 'scroll' }}>
                            <Col xs={12} className="pb-4 pt-0 pl-0" >
                                <div className="p-3 mt-3 mb-3 w-100 container">
                                    <Row className="d-flex justify-content-center pt-3 text--shutdown text-center mt-5" >
                                        <h4 dangerouslySetInnerHTML={{ __html: this.state.configDes }}></h4>
                                    </Row>
                                    <Row className="d-flex justify-content-center pt-3">
                                        <Image className="mt-2 mb-2" src={logocheckout0} rounded style={{ width: '50%' }} />
                                    </Row>
                                    <Row className="d-flex justify-content-center pt-3 mt-5">
                                    <Button variant="" className="mainBtnRadiusV3 font-weight-bold"  onClick={()=>{this.setState({showDropLead: !this.state.showDropLead})}}>ต้องการให้เจ้าหน้าที่ติดต่อกลับ</Button>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} style={{ maxWidth: '100%', minHeight: '0px', maxHeight: '265px', height: this.state.conetntHeight - 688 }}></Col>
                            <Col xs={12} className="pl-4 pt-4 pr-4 w-100 oppositeBlackBlue">
                                    <Row>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0181")}</span><br></br>
                                            <small className="">{MainFunc.getLabelDtos("L0182")}</small><br></br>
                                            <Image className="mb-2 mr-2" src={IcoTel} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <span className="font-weight-bold mt-1" style={{ fontSize: "1.8rem" }}>{MainFunc.getLabelDtos("L0183")}</span><br></br>
                                            <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0184")}</span>
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">Payment Method</span><br></br>
                                            <Image className="mt-2 mb-2 mr-2" src={IcoKbank} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoVS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoMC} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <Image className="mt-2 mb-2 mr-2" src={IcoCS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /><br></br>
                                            <span className="font-weight-bold m-0">Follow us</span><br></br>
                                            <a href={MainFunc.getConfigDtos("CF012")}><Image className="mt-2 mb-2 mr-2" src={IcoYt} rounded style={{ height: '20px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF013")}><Image className="mt-2 mb-2 mr-2" src={IcoIg} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF014")}><Image className="mt-2 mb-2 mr-2" src={IcoFb} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF015")}><Image className="mt-2 mb-2 mr-2" src={IcoLine} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                            <a href={MainFunc.getConfigDtos("CF016")}><Image className="mt-2 mb-2 mr-2" src={IcoLinkedin} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                        </Col>
                                        <Col xs={12} lg={4} className="mb-3">
                                            <span className="font-weight-bold m-0">Certified</span><br></br>
                                            <Image className="mt-2 mb-2 mr-2" src={IcoDBD} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className="pl-4 pr-4 w-100 oppositeBlack">
                                    <Row>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a onClick={() => { this.setState({ TermAndConditionModal: true }) }} style={{ cursor: 'pointer' }}><span className=" m-0">{MainFunc.getLabelDtos("L0173")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a href={MainFunc.getConfigDtos("CF001")}><span className=" m-0">{MainFunc.getLabelDtos("L0171")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a href={MainFunc.getConfigDtos("CF002")}><span className=" m-0">{MainFunc.getLabelDtos("L0172")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={2} className="mt-2 mb-2">
                                            <a href={MainFunc.getConfigDtos("CF011")}><span className="m-0">{MainFunc.getLabelDtos("L0174")}</span></a><br></br>
                                        </Col>
                                        <Col xs={12} lg={4} className="mt-2 mb-2">
                                            <span className="m-0">{MainFunc.getLabelDtos("L0180")}</span><br></br>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} className={this.state.showfooter ? 'pt-3 pb-3 d-flex justify-content-center' : 'd-none'}>
                                    <small className="text--muted text-center">{MainFunc.getLabelDtos("L0008")}</small>
                                </Col>
                        </Row>
                        <Collapse in={this.state.showDropLead} timeout="auto" unmountOnExit>
                            <div className={"drop--lead--box"} id={"dropLeadBox"}>
                                <Card class="text-center csMainBG text-white card-header" border="light">
                                    <Card.Header class="text-center csMainBG text-white card-header">
                                        <Image src={IcoCrossSign} rounded onClick={() => this.closeDroplead()} style={{ position: "absolute", top: "0", right: "10px", fontSize: "2rem", width: 20, margin: 20, marginTop: 22 }}></Image>
                                        <Row id="logoSection" className="pl-2 pr-2 pt-2 pb-2 csMainBG d-flex align-items-center" style={{ height: "45px" }}>
                                            <Image id="channelLogo" src={!this.state.logo ? logo2 : this.state.logo} rounded style={{ height: '95px', position: 'absolute', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                            <div>
                                                <Form.Label className="" style={{  fontSize: "1.2rem", fontWeight: "bold", marginLeft: 90}}>{MainFunc.getLabelDtos("L0466") /* ให้เจ้าหน้าที่ติดต่อกลับ */}</Form.Label>
                                            </div>
                                        </Row>
                                    </Card.Header>
                                    {
                                        (this.state.step == "1") ?
                                            <Card.Body >
                                                <div style={{ height: "420px", overflow: "hidden", overflowY: "scroll", padding: "12px" }}>
                                                    <Form>
                                                        <Form.Group className="font-weight-bold " hasValidation>
                                                            <Form.Label>{MainFunc.getLabelDtos("L0467") /* ชื่อ - นามสกุล */}
                                                            </Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.name || this.state.checkInputname ? "input--error" : ""}`} type="text" name="name" value={this.state.name} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.nameerror}</span>
                                                        </Form.Group>
                                                        <Form.Group className="font-weight-bold " >
                                                            <Form.Label >{MainFunc.getLabelDtos("L0468") /* เบอร์มือถือ */}</Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.phoneNumber || this.state.checkInputphoneNumber ? "input--error" : ""}`} type="phoneNumber" name="phoneNumber" value={this.state.phoneNumber} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.failPhone}</span>
                                                        </Form.Group>
                                                        <Form.Group className="font-weight-bold " >
                                                            <Form.Label>{MainFunc.getLabelDtos("L0469") /* อีเมล */}</Form.Label>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.email || this.state.checkInputemail ? "input--error" : ""}`} type="text" name="email" placeholder="yourmail@mail.com" value={this.state.email} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)} autoComplete="off" />
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.emailerror}</span>
                                                        </Form.Group>
                                                        <Form.Label className="font-weight-bold ">{MainFunc.getLabelDtos("L0470") /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */}</Form.Label>
                                                        <Form.Group>
                                                            <Form.Control className={`input-background ${this.state.validateDroplead && !this.state.selectDate ? "input--error" : ""}`} as="select" name="selectDate" placeholder="ช่วงเวาลาที่สะดวกให้ติดต่อกลับ" value={this.state.selectDate} onChange={(e)=>this.handleInputChange(e.target.name, e.target.value)}>
                                                                <option value="" disabled selected>{MainFunc.getLabelDtos("L0470") /* ช่วงเวลาที่สะดวกให้ติดต่อกลับ */}</option>
                                                                {MainData.dropleadDtos.map((item, key) => {
                                                                    return (
                                                                        <option value={item.contactTimeName}>{item.contactTimeName}</option>
                                                                    )
                                                                })}
                                                            </Form.Control>
                                                            <span style={{ fontWeight: 'bold', color: 'red', }}>{this.state.failselectDate}</span>
                                                        </Form.Group>
                                                        <Form.Group style={{ marginTop: 20 }} className="mb-3" controlId="formBasicCheckbox">
                                                            <Form.Check type="checkbox" value={this.state.checkboxDroplead} onChange={(e) => { this.setState({ checkboxDroplead: e.target.checked }) }} label="ข้าพเจ้ายินยอมให้ บมจ.เมืองไทยประกันชีวิต ทำการเก็บรวบรวมและใช้ข้อมูลที่ข้าพเจ้าให้ไว้ นายหน้าประกันชีวิต และพันธมิตร/คู่ค้า เพื่อวัตถุประสงค์ในการนำเสนอขายประกันชีวิตแบบต่างๆ และ/หรือนำเสนอสิทธิประโยชน์อื่นๆ ให้แก่ข้าพเจ้าได้" />
                                                        </Form.Group>
                                                    </Form>
                                                    <Col className="d-flex justify-content-center pt-3">
                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem" }} onClick={() => { this.serviceDroplead() }} disabled={!this.state.checkboxDroplead} >{MainFunc.getLabelDtos("L0471") /* ส่งข้อมูลสอบถาม */}</Button>
                                                    </Col><br></br>
                                                    <span className="" style={{ fontSize: "1.1rem",paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0472") /* ติดต่อเจ้าหน้าที่ */}</span><br></br>
                                                    <span className="" style={{ fontSize: "1.1rem",paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0473") /* ทุกวันทำการ จันทร์ - ศุกร์ เวลา 08:00 - 18:00 */}</span><br></br>
                                                    <Row>
                                                        <Col xs={1} style={{ marginTop: 10 }}>
                                                            <Image className="mb-2 mr-2" src={IcoTelGreen} rounded style={{ height: '30px', left: '0', top: '-5px',paddingLeft: "1.25rem" }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                        </Col>
                                                        <Col className="drop--lead--call">
                                                            <a href={`tel:${MainFunc.getLabelDtos("L0183")}`} className="font-weight-bold mt-1 telClass" style={{ fontSize: "1.6rem", width: 75,paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0183")}</a>
                                                            <a className="font-weight-bold mt-1 callToClass" style={{ fontSize: "1.6rem", width: 75,paddingLeft: "1.25rem" }}>{MainFunc.getLabelDtos("L0183")}</a><br></br>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card.Body>
                                            :
                                            [
                                                (this.state.step == "2") ?
                                                    <Card.Body >
                                                        <div style={{ marginTop: 150 }}>
                                                            <Spinner className="img-center" animation="border" role="status" style={{ width: "60px", height: "60px" }}>
                                                                <span className="visually-hidden"></span>
                                                            </Spinner>
                                                        </div>
                                                    </Card.Body>
                                                    :
                                                    [
                                                        (this.state.step == "3") ?
                                                            <Card.Body >
                                                                <div style={{ margin: 40 }}>
                                                                    <Image className="img-center" src={IcoCorrect} ></Image>
                                                                    <div className="text-align-center ">
                                                                        <span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0474") /* บริษัทฯ ได้รับข้อมูลเรียบร้อยแล้ว กรุณารอเจ้าหน้าที่ติดต่อกลับภายใน 1-2 วันทำการ */}</span><br></br>
                                                                        <span className="font-weight-bold text-center font-collor1 " style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0475") /* ขอขอบคุณที่ท่านให้ความสนใจ บริษัท เมืองไทยประกันชีวิต จำกัด (มหาชน) */}</span><br></br>
                                                                    </div>
                                                                    <Col className="d-flex justify-content-center pt-3">
                                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width: 200 }} onClick={() => this.closeDroplead() } >{MainFunc.getLabelDtos("L0185") /** ตกลง */}</Button>
                                                                    </Col>
                                                                </div>
                                                            </Card.Body>
                                                            :
                                                            <Card.Body >
                                                                <div style={{ margin: 70 }}>
                                                                    <Image className="img-center" src={IcoError} ></Image>
                                                                    <div className="text-align-center " style={{ marginTop: 30 }}>
                                                                        <span className="font-weight-bold text-center" style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0476") /** ขออภัยในความไม่สะดวก */}</span><br></br>
                                                                        <span className="font-weight-bold text-center font-collor1 " style={{ fontSize: "1.2rem" }}>{MainFunc.getLabelDtos("L0477") /** กรุณาลองใหม่อีกครั้ง */}</span><br></br>
                                                                    </div>
                                                                    <Col className="d-flex justify-content-center pt-3" style={{ marginTop: 30 }}>
                                                                        <Button variant="" className="mainBtnRadius font-weight-bold" style={{ fontSize: "1.1rem", width: 200 }} onClick={() => this.closeDroplead()} >{MainFunc.getLabelDtos("L0188") /** ปิด */}</Button>
                                                                    </Col>
                                                                </div>
                                                            </Card.Body>

                                                    ]
                                            ]
                                    }
                                </Card>
                            </div>
                        </Collapse>
                    </div>
                    
            </>

        )
    }
}

export default NotFound;


