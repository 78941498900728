// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledDropdown = exports.StyledContent = exports.StyledContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    min-width: 6rem;\n    max-width: 20rem;\n    max-height: 372px;\n    margin-top: 0.125rem;\n    margin-bottom: 0.125rem;\n    border: solid 1px ", ";\n    border-radius: 0.875rem;\n    padding: 0.5rem 0;\n    font-size: 0.75rem;\n    background: ", ";\n    box-shadow: ", ";\n    display: block;\n    opacity: 1;\n    transition: opacity 0.1s linear, visibility 0.1s linear;\n    visibility: visible;\n    overflow-y: auto;\n    overflow-x: hidden;\n    top: 100%;\n\n    ", ";\n    ", "\n    ", "\n    ", "\n    ", "\n    ", ";    \n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    box-sizing: border-box;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: relative;\n    display: inline-block;\n\n    &:hover,\n    &:focus {\n        outline: 0;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledContainer = _styledComponents["default"].div(_templateObject());

exports.StyledContainer = StyledContainer;

var StyledContent = _styledComponents["default"].ul(_templateObject2());

exports.StyledContent = StyledContent;
var StyledDropdown = (0, _attachThemeAttrs["default"])(_styledComponents["default"].div)(_templateObject3(), function (props) {
  return props.palette.border.divider;
}, function (props) {
  return props.palette.background.main;
}, function (props) {
  return props.shadows.shadow_2;
}, function (props) {
  return props.isLoading && "\n            padding: 2rem;\n            height: 3rem;\n            width: 3rem;\n        ";
}, function (props) {
  return props.menuSize === 'xx-small' && 'min-width: 6rem;';
}, function (props) {
  return props.menuSize === 'x-small' && 'min-width: 12rem;';
}, function (props) {
  return props.menuSize === 'small' && 'min-width: 15rem;';
}, function (props) {
  return props.menuSize === 'medium' && 'min-width: 20rem;';
}, function (props) {
  return props.menuSize === 'large' && "\n            min-width: 25rem;\n            max-width: 512px;\n        ";
});
exports.StyledDropdown = StyledDropdown;