// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

var _fontSizes = require("../../../../styles/fontSizes");

var _margins = require("../../../../styles/margins");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    text-align: center;\n    color: ", ";\n    margin: 0;\n    margin-top: ", ";\n    padding: 0;\n    font-weight: inherit;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledEmptyDescription = (0, _attachThemeAttrs["default"])(_styledComponents["default"].h2)(_templateObject(), _fontSizes.FONT_SIZE_TEXT_MEDIUM, function (props) {
  return props.palette.text.label;
}, _margins.MARGIN_X_SMALL);
var _default = StyledEmptyDescription;
exports["default"] = _default;