// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../styles/helpers/attachThemeAttrs"));

var _fontSizes = require("../../../styles/fontSizes");

var _margins = require("../../../styles/margins");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    color: ", ";\n    margin-top: ", ";\n    position: absolute;\n    display: inline-table;\n white-space: nowrap;\n   top: 80%;\n    ", ";\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledLabel = (0, _attachThemeAttrs["default"])(_styledComponents["default"].span)(_templateObject(), "0.9rem", function (props) {
  return props.palette.text.label;
}, _margins.MARGIN_XX_SMALL, function (props) {
  return props.stepState === 'Active' ? "\n            color: var(--progressStepActiveColor) ; white-space: nowrap; " : props.stepState === 'Completed' ? "\n            color: var(--progressStepCompletedColor) ; white-space: nowrap; ":null;
}, function (props) {
  return props.stepState === 'Error' && "\n            color: ".concat(props.palette.error.main, "; \n        ");
});
var _default = StyledLabel;
exports["default"] = _default;