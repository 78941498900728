// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "StyledButtonGroup", {
  enumerable: true,
  get: function get() {
    return _buttonGroup["default"];
  }
});
exports.StyledContainer = exports.StyledHelpText = exports.StyledErrorText = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _errorText = _interopRequireDefault(require("../../Input/styled/errorText"));

var _helpText = _interopRequireDefault(require("../../Input/styled/helpText"));

var _buttonGroup = _interopRequireDefault(require("./buttonGroup"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin: 0;\n    padding: 0;\n    border: 0;\n    position: relative;\n    box-sizing: border-box;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    text-align: center;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    text-align: center;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledErrorText = (0, _styledComponents["default"])(_errorText["default"])(_templateObject());
exports.StyledErrorText = StyledErrorText;
var StyledHelpText = (0, _styledComponents["default"])(_helpText["default"])(_templateObject2());
exports.StyledHelpText = StyledHelpText;

var StyledContainer = _styledComponents["default"].fieldset(_templateObject3());

exports.StyledContainer = StyledContainer;