import axios from "axios";
import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import MainData from "../../models/MainData";
import CarouselBanner from "./CarouselBanner";
import CategoryTabs from "./CategoryTabs";
import FourSteps from "./FourSteps";
import LandingSkeleton from "./LandingSkeleton";
import InsuranceItem from "./InsuranceItem";
class ScLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
        screen: "ScLanding",
        eventKeyActive: null,
        activeId: null,
        bannerData:[],
        categoryData:[],
        currentCategoryTab:"",
        productData:[],
        // url: window.location.href.split('detail/'),
        // productBenefit: [ {data: [], groupCode: "", header: "" } ],
    };
  }

    componentDidMount = () =>{
        this.getBanner();
        this.getCategories();
        
    }

    getBanner = () => {
        const headers = { 
            "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
        }
        const data = {
            "channelCode" : MainData.initialData.channelCode,
            "source" : null,
            "productId": 0,
            "lang": "TH"
        }
        // let logData = data
        axios.post(MainData.serviceURL + '/Landing/getBanner', data, {
        headers: headers
    
        })
        .then(res => {
          if(res.status === 299){
            window.location.href = MainData.mainPath + 'shutdown'
            return;
          }
          let data = res.data.data;
          if(data.banner && data.banner.length > 0){
            this.setState({bannerData: data.banner})
          }
        })
        .catch(error => {
          this.props.loadController(false)
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
        });


    }
    getCategories = () => {
      const headers = { 
          "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      }
      const data = {
          "channelCode" : MainData.initialData.channelCode,
          "source" : null,
          "cateCode": "CA001",
          "lang": "TH"
      }
      // let logData = data
      axios.post(MainData.serviceURL + '/Landing/GetCategories', data, {
      headers: headers
  
      })
      .then(res => {
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        let data = res.data.data;
        if(data.categories && data.categories.length > 0){
          this.setState({currentCategoryTab:data.categories[0].cateCode,categoryData: data.categories});
          this.getProduct(data.categories[0].cateCode);
        }
      })
      .catch(error => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
        this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      });
    }

    getProduct = (cateCode) => {
      this.props.loadController(true);
      const headers = { 
          "Authorization": `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      }
      const data = {
          "channelCode" : MainData.initialData.channelCode,
          "source" : null,
          "cateCode": cateCode,
          "lang": "TH"
      }
      // let logData = data
      axios.post(MainData.serviceURL + '/Landing/GetProductsLanding', data, {
      headers: headers
  
      })
      .then(res => {
        if(res.status === 299){
          window.location.href = MainData.mainPath + 'shutdown'
          return;
        }
        this.props.loadController(false);
        let data = res.data.data;
        if(data.products && data.products.length > 0){
          this.setState({productData: data.products,currentCategoryTab:cateCode})
        }
      })
      .catch(error => {
          this.props.loadController(false);
          let errMsg = MainData.masterMessage.find(x=>x.msgCode === 'MSG1001');
          this.props.errorAlert(true, !errMsg?"พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ":errMsg.msgDescription.replace('{{Service}}',error.response.data.data?.messageDescription), ' ')
      });
    }
  render(){

    return (
    <>
      <div>
        <CarouselBanner
            images={this.state.bannerData}
            action={this.props.showMainPageOrDetail}
            clickView={this.props.showMainPageOrDetail}
          />
          <Container>
            <CategoryTabs
              tabsList={this.state.categoryData}
              currentTab={this.state.currentCategoryTab}
              onChangeTab={this.getProduct}
            />
            <Row>
              {!this.props.isLoading ? (
                this.state.productData !== undefined &&
                this.state.productData !== null &&
                this.state.productData.length !== 0 &&
                  this.state.productData.map((item, index) => (
                  <Col key={index} lg={4} sm={6} xs={12}>
                    <InsuranceItem data={item} clickView={this.props.showMainPageOrDetail}/>
                  </Col>
                ))
              ) : (
                  <LandingSkeleton />
                )}
            </Row>
            <FourSteps />
          </Container>
      </div>
    </>
    )
  }
}

export default ScLanding;
