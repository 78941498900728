// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledMonth = exports.StyledMonthsContainer = exports.StyledHeaderContainer = exports.StyledCalendar = exports.StyledControlsContainer = exports.StyledSection = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _attachThemeAttrs = _interopRequireDefault(require("../../../../styles/helpers/attachThemeAttrs"));

var _fontSizes = require("../../../../styles/fontSizes");

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    font-size: ", ";\n    color: ", ";\n    text-transform: capitalize;\n    font-weight: 500;\n    margin: 0 1rem 0 0;\n    padding: 0;\n    box-sizing: border-box;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    justify-content: stretch;\n    align-items: center;\n    flex-grow: 1;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-grow: 1;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    @media (max-width: 768px) {\n        &:nth-child(3) {\n            order: 2;\n        }\n\n        &:nth-child(4) {\n            order: 4;\n        }\n    }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: flex;\n    flex-grow: 1;\n    align-content: start;\n    justify-content: space-between;\n    padding: 0 0 8px 0;\n\n    @media (max-width: 768px) {\n        &:nth-child(1) {\n            order: 1;\n        }\n\n        &:nth-child(2) {\n            order: 3;\n            margin-top: 2rem;\n        }\n    }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-template-rows: 40px 1fr;\n    grid-column-gap: 2rem;\n    grid-row-gap: 0;\n    width: 100%;\n\n    @media (max-width: 768px) {\n        display: flex;\n        flex-direction: column;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledSection = _styledComponents["default"].section(_templateObject());

exports.StyledSection = StyledSection;

var StyledControlsContainer = _styledComponents["default"].div(_templateObject2());

exports.StyledControlsContainer = StyledControlsContainer;

var StyledCalendar = _styledComponents["default"].div(_templateObject3());

exports.StyledCalendar = StyledCalendar;

var StyledHeaderContainer = _styledComponents["default"].div(_templateObject4());

exports.StyledHeaderContainer = StyledHeaderContainer;

var StyledMonthsContainer = _styledComponents["default"].div(_templateObject5());

exports.StyledMonthsContainer = StyledMonthsContainer;
var StyledMonth = (0, _attachThemeAttrs["default"])(_styledComponents["default"].h3)(_templateObject6(), _fontSizes.FONT_SIZE_HEADING_MEDIUM, function (props) {
  return props.palette.text.main;
});
exports.StyledMonth = StyledMonth;