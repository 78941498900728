import axios from "axios";
import React, { Component, useEffect, useRef, useState, Fragment } from "react";
import { GoAlert } from 'react-icons/go';
import { FcAssistant } from "react-icons/fc";
import { FaCheckCircle } from "react-icons/fa";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Row, Col, Image, Button, Card } from "react-bootstrap";
import SyncLoader from '../../customLib/react-spinners/SyncLoader';
import * as workerInterval from 'worker-interval';
import { isBrowser } from "react-device-detect";
import { AiFillCloseSquare } from 'react-icons/ai';
import parse from "html-react-parser";

import MainData from "../../models/MainData";
import MainFunc from "../../models/MainFunc";

import logo2 from '../../images/logo.png';
import logocheckout0 from '../../images/logo_checkout_0.png';
import logocheckout1 from '../../images/logo_checkout_1.png';
import logocheckout2 from '../../images/logo_checkout_2.png';
import logocheckout3 from '../../images/logo_checkout_3.png';
import logocheckout4 from '../../images/logo_checkout_4.png';
import IcoTel from '../../images/ico_tel.png';
import IcoFb from '../../images/ico_fb.png';
import IcoIg from '../../images/ico_ig.png';
import IcoLine from '../../images/ico_line.png';
import IcoLinkedin from '../../images/ico_linkedin.png';
import IcoYt from '../../images/ico_yt.png';
import IcoDBD from '../../images/logo_cf_dbd.png';
import IcoCS from '../../images/logo_pm_cs.png';
import IcoKbank from '../../images/logo_pm_kbank.png';
import IcoMC from '../../images/logo_pm_mc.png';
import IcoVS from '../../images/logo_pm_vs.png';

import MTLClick_Banner_DownloadApp_01_adn from '../../images/MTLClick_Banner_DownloadApp_01_adn.jpeg';
import MTLClick_Banner_DownloadApp_01_ios from '../../images/MTLClick_Banner_DownloadApp_01_ios.jpeg';
import MTLClick_Banner_DownloadApp_01_web from '../../images/MTLClick_Banner_DownloadApp_01_web.jpeg';
import APP_Deep_Linked_AW_Thank_You_Page from '../../images/APP_Deep_Linked_AW_Thank_You_Page.png';


import IcoCrossSign from '../../images/ico_closesign.png';



class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            conetntHeight: 0,
            screen: "SM013",
            isLoading: false,
            isLoadingPage: true,
            serviceError: false,
            serviceKPayPlusError: false,
            cusId: sessionStorage.getItem("cusId"),
            // css: sessionStorage.getItem("customCss"),
            logo: "",
            // premium: sessionStorage.getItem("premium"),
            // premiumPayment: sessionStorage.getItem("premiumPayment"),
            // channelType: sessionStorage.getItem("channelType"),
            // channelCode: sessionStorage.getItem("channelCode"),
            // channelName: sessionStorage.getItem("channelName"),
            // applicationNumber: sessionStorage.getItem("applicationNumber"),
            // refOrder: sessionStorage.getItem("refOrder"),
            token: /token=([^&]+)/.exec(window.location.href) ? /token=([^&]+)/.exec(window.location.href)[1] : null,
            detailInsuranceTime: 1,
            detailPremium: 1500.00,
            detailFirstName: "ชื่อจริง",
            detailLastName: "นามสกุล",
            paymentChannelCode: "kbank",
            detailSumInsured: 40000000,
            detailDateStartCover: "2021-01-15",
            detailProductName: "product_name",
            paymentTime: "2021-01-15 17:12:00",
            detailApplicationNumber: "64xxxxxxxx",
            paymentChannelName:"",
            isTaxConsent:false,
            userId: [],
            connectionId: [],
            serviceKPayPlusError1Code: "",
            noPayment:false,
            cookieCode:"",
            cookieDetail:"",
            cookieDescription: "",
            cookieDescriptionCheck: false,
            cookieVersionDisplay: "",
            cookieVersionOrder: 0,
            _cookie: sessionStorage.getItem('_cookie'),
            // clientIP: sessionStorage.getItem('clientIP'),
            channelCodeIni: /channelCode=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/channelCode=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            productIdIni:  /productId=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/productId=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            sourceIni: /utm_source=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/utm_source=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            utm_mediumIni: /utm_medium=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/utm_medium=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            utm_campaignIni: /utm_campaign=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/utm_campaign=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            utm_contentIni: /utm_content=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/utm_content=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            utm_termIni: /utm_term=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/utm_term=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            cIni: /c=([^&]+)/.exec(sessionStorage.getItem('homePage'))?/c=([^&]+)/.exec(sessionStorage.getItem('homePage'))[1]:"",
            errMsg: "",
            errSubMsg: "",
            isError: false,
            device:"",
            appStatus:false,
            appStatusMsgCode:false
        };
    }


    componentDidMount() {
        window.addEventListener("resize", () => {
            this.calContentHeight();
        });
        this.calContentHeight();
        // var head = document.getElementsByTagName('head')[0];
        // var link = document.createElement('link');
        // link.id = 'customCss';
        // link.rel = 'stylesheet';
        // link.type = 'text/css';
        // link.href = this.state.css;
        // link.media = 'all';
        // head.appendChild(link);
        if(this.state.token){
            sessionStorage.setItem("cusId", this.state.token);
            if(!sessionStorage.getItem("MainData")){
                if(!sessionStorage.getItem('homePage')){
                    let ualparameter = `?channelCode=${/channelCode=([^&]+)/.exec(window.location.href)?/channelCode=([^&]+)/.exec(window.location.href)[1]:""}`;
                    ualparameter += `&productId=${/productId=([^&]+)/.exec(window.location.href)?/productId=([^&]+)/.exec(window.location.href)[1]:""}`;
                    window.location.href = window.location.origin + MainData.mainPath + "checkout/" + ualparameter; 
                    sessionStorage.setItem("homePage", window.location.origin + MainData.mainPath + ualparameter);
                }
                window.location.href = MainData.mainPath + 'checkout';
            }
            else{
                window.location.href = MainData.mainPath + 'checkout';
            }
        }
        else{
            if(sessionStorage.getItem("MainData")){
                MainFunc.SetMainDataFromStorage();
            }
            if(window.location.origin.includes("localhost")){
                MainData.serviceURL = `https://kbankonlinesale-uat.muangthai.co.th${MainData.servicePath}api`;
            }
            else{
                MainData.serviceURL = window.location.origin + `${MainData.servicePath}api`;
            }
            this.getData();
        }

    }

    componentDidUpdate() {
        this.calContentHeight();
    }

    calContentHeight = () => {
        let screenHeight = window.innerHeight;
        if (this.state.serviceError === false && this.state.appStatus === false) {
            if (this.state.conetntHeight !== (screenHeight - document.getElementById('logoSection')?.offsetHeight)) {
                this.setState({ conetntHeight: screenHeight - document.getElementById('logoSection')?.offsetHeight })
            }
        }
    }


    getCardInquiryData = () => {
        const headers = { "AuthId": this.state.cusId, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {};
        axios.post(MainData.serviceURL + '/Payment/GetInquiryData', data, {
            headers: headers
        })
            .then(res => {
                if(res.status === 299){
                    window.location.href = MainData.mainPath + 'shutdown'
                    return;
                }
                let data = res.data
                // console.log("res :",res)
                if (data.paymentStatus === true) {
                    this.setState({
                        paymentTime: data.paymentDetail.paymentTime,
                        premium: data.paymentDetail.premium,
                        paymentChannelCode: data.paymentDetail.paymentChannelCode,
                        detailFirstName: data.paymentDetail.detail.firstName,
                        detailLastName: data.paymentDetail.detail.lastName,
                        detailApplicationNumber: data.paymentDetail.detail.applicationNumber,
                        detailProductName: data.paymentDetail.detail.productName,
                        detailSumInsured: data.paymentDetail.detail.sumInsured,
                        detailInsuranceTime: data.paymentDetail.detail.insuranceTime,
                        detailDateStartCover: data.paymentDetail.detail.dateStartCover,
                        detailPremium: data.paymentDetail.detail.premium,
                        paymentChannelName: data.paymentDetail.paymentChannelName,
                        isLoadingPage: false,
                        isLoading: false,
                        isTaxConsent: data?.isTaxConsent
                    })
                    let newValue = {
                        paymentChannelName:data.paymentDetail.paymentChannelName,
                        paymentDate:data.paymentDetail.paymentTime,
                        applicationID:data.paymentDetail.detail.applicationNumber
                    }
                    if (data.messageCode=="MSG185" || data.messageCode=="MSG186") {
                        this.setState({
                            appStatus: true,
                            appStatusMsgCode: data.messageCode,
                            isLoadingPage: true
                        })
                    }
                    MainFunc.logPDPA('Add', "การชำระเงิน", "จ่ายเงินค่าเบี้ยประกัน", null, null, newValue, 'Success', )
                } 
                else {
                    this.setState({ serviceError: true, isLoading: false })
                    let newValue = {
                        paymentChannelName:data.paymentDetail.paymentChannelName,
                        paymentDate:data.paymentDetail.paymentTime,
                        applicationID:data.paymentDetail.detail.applicationNumber
                    }
                    MainFunc.logPDPA('Add', "การชำระเงิน", "จ่ายเงินค่าเบี้ยประกัน", null, null, null, 'Fail',MainData.masterMessage.find(x=>x.msgCode === 'MSG016').msgDescription)
                }
            })
            .catch(error => {
                this.setState({ serviceError: true, serviceKPayPlusError: true, isLoading: false })
                MainFunc.logPDPA('Add', "การชำระเงิน", "จ่ายเงินค่าเบี้ยประกัน", null, null, null, 'Fail',"ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง")
                sessionStorage.removeItem("bearerAuth");
            });
    }

    errorConfirm = () => {

    }

    getData = async (lang) => {
        this.setState({device:await this.getMobileOperatingSystem()})
        this.setState({ _cookie: 'true' }); 
        const headers = { "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "source": this.state.sourceIni,
            "lang": lang === 'en' ? "EN" : "TH",
            "channelCode": this.state.channelCodeIni,
            "productId": this.state.productIdIni,
            "utm_source": this.state.sourceIni,
            "utm_medium": this.state.utm_mediumIni,
            "utm_campaign": this.state.utm_campaignIni,
            "utm_content": this.state.utm_contentIni,
            "utm_term": this.state.utm_termIni,
            "c": this.state.cIni,
            "transUrl": window.location.href,
            "deviceType":this.state.device
        };
        axios.post(MainData.serviceURL + '/Sales/InitialSales', data, {
            headers: headers
        })
            .then(async res => {
                if(res.status === 299){
                    this.setState({ _cookie: 'true' }); 
                }
                else{
                    let data = res?.data?.data;
                    if (data?.cookies?.cookieDetail != null)
                    {
                        this.setState({cookieCode: data.cookies.cookieCode, cookieVersionDisplay: data.cookies.cookieVersionDisplay, cookieVersionOrder: data.cookies.cookieVersionOrder, cookieDetail: data.cookies.cookieDetail, cookieDescription: data.cookies.cookieDescription })
                        sessionStorage.setItem('_cookie', ''); this.setState({ _cookie: '' });
                    }
                    else
                    {
                        sessionStorage.setItem('_cookie', 'true'); this.setState({ _cookie: 'true' }); 
                    }
                    var head = document.getElementsByTagName('head')[0];
                    var link = document.createElement('link');
                    link.id = 'customCss';
                    link.rel = 'stylesheet';
                    link.type = 'text/css';
                    link.href = data.channelCode?.toUpperCase() == "KBANK" ? window.location.origin + MainData.mainPath+'/css/kbankcustom.css' : window.location.origin + MainData.mainPath+'/css/pmibcustom.css';
                    link.media = 'all';
                    head.appendChild(link);
                    sessionStorage.setItem("customCss", data.channelCode?.toUpperCase() == "KBANK" ? window.location.origin + MainData.mainPath+'/css/kbankcustom.css' : window.location.origin + MainData.mainPath+'/css/pmibcustom.css');
                    sessionStorage.setItem("channelLogo", data.channel.logo);
                    sessionStorage.setItem("channelName", data.channelCode);
                    MainData.initialData.labelDtos = data.labelDtos;
                    MainData.initialData.bearerAuth = data.authKey;
                    MainData.initialData.channelCode = data.channelCode;
                    MainData.initialData.configDtos = data.configDtos;
                    this.setState({
                        logo: data.channel.logo,
                    })
                    if(MainData.isPRD){
                        MainFunc.SetScriptGTM(data.channelCode);
                    }
                    axios.get(MainData.serviceURL + '/MasterData/getMessage', {
                        headers: {
                            Authorization: `Bearer ${data.authKey}`
                        }
                    })
                        .then(res => {
                            if(res.status === 299){
                                window.location.href = MainData.mainPath + 'shutdown'
                                return;
                            }
                            let newmasterDataRes = res.data.data;
                            if (!newmasterDataRes) {
                                this.setState({ isLoading: false, serviceError: true })
                                this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                                return;
                            }
                            MainData.masterMessage = newmasterDataRes;
                            if(sessionStorage.getItem("submitOrderPayment")){
                                let submitOrderPayment = JSON.parse(sessionStorage.getItem("submitOrderPayment"))?.paymentDetail;
                                this.setState({
                                    paymentTime: submitOrderPayment.paymentTime,
                                    premium: submitOrderPayment.premium,
                                    paymentChannelCode: submitOrderPayment.paymentChannelCode,
                                    detailFirstName: submitOrderPayment.detail.firstName,
                                    detailLastName: submitOrderPayment.detail.lastName,
                                    detailApplicationNumber: submitOrderPayment.detail.applicationNumber,
                                    detailProductName: submitOrderPayment.detail.productName,
                                    detailSumInsured: submitOrderPayment.detail.sumInsured,
                                    detailInsuranceTime: submitOrderPayment.detail.insuranceTime,
                                    detailDateStartCover: submitOrderPayment.detail.dateStartCover,
                                    detailPremium: submitOrderPayment.detail.premium,
                                    paymentChannelName: submitOrderPayment.paymentChannelName,
                                    isLoadingPage: false,
                                    isLoading: false,
                                    noPayment: true,
                                    isTaxConsent: JSON.parse(sessionStorage.getItem("submitOrderPayment"))?.isTaxConsent
                                })
                                let newValue = {
                                    paymentChannelName:submitOrderPayment.paymentChannelName,
                                    paymentDate:submitOrderPayment.paymentTime,
                                    applicationID:submitOrderPayment.detail.applicationNumber
                                }
                                MainFunc.logPDPA('Add', "การชำระเงิน", "จ่ายเงินค่าเบี้ยประกัน", null, null, newValue, 'Success', null)
                            }
                            else{
                                this.getCardInquiryData(); 
                            }
                        })
                        .catch(masterDataError => {
                            this.setState({ isLoading: false, serviceError: true })
                            this.errorAlert(true, 'พบปัญหาในการเรียกใช้งาน service กรุณาลองใหม่อีกครั้งครับ/ค่ะ', ' ')
                        });
                }
            })
            .catch(error => { 
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                this.setState({ isLoading: false, serviceError: true })
            });

    }

    goToWeb = () => {
        window.open("https://webmtl.co/46ZA6Tb", "_blank");
      }
    
    goToAndroid = () => {
        if(isBrowser){
            window.open("https://play.google.com/store/apps/details?id=com.thmtlphoenix", "_blank");
        }
        else{
            setTimeout(function () { 
                window.open("market://details?id=com.thmtlphoenix", "_system", "location=no"); 
            }, 25);
            window.open("phoenix://healthcare", "_system", "location=no");
        }
      }
    
    goToiOS = () => {
        if(isBrowser){
            window.open("https://apps.apple.com/th/app/id1440560140", "_blank");
        }else{
            setTimeout(function () { 
                window.open("itms-apps://itunes.apple.com/th/app/mtl-click/id1440560140", "_system", "location=no"); 
            }, 25);
            window.open("phoenix://", "_system", "location=no"); 
        }
      }
    
    openCookieDescription = () => {
        this.setState({cookieDescriptionCheck: true})
    }

    closeCookie = () => {
        sessionStorage.setItem('_cookie', 'close'); this.setState({ _cookie: 'close' });
    }

    SaveCookie = () => 
    {
        const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {
            "cusId": null,
            "cookieCode": this.state.cookieCode,
            "cookieVersionDisplay": this.state.cookieVersionDisplay,
            "cookieVersionOrder": this.state.cookieVersionOrder,
            "channelCode": this.state.channelName,
        };
        axios.post(MainData.serviceURL + '/Customer/AddCustomerCookie', data, {
            headers: headers
        })
        .then(async res => {
            if(res.status === 299){
                window.location.href = MainData.mainPath + 'shutdown'
                return;
            }
            if (!res.data.success)
            {
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                this.setState({ isLoading: false})
            }
            else
            {
                sessionStorage.setItem('_cookie', 'true'); 
                this.setState({ _cookie: 'true' }); 
            }
        })
        .catch(error => {
            if(error?.response?.status == 400){
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                this.setState({ isLoading: false})
            }
            else{
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                this.setState({ isLoading: false})
            }
        });
    }

    errorAlert = (isErr, msg, subMsg) => {
        this.setState({ isError: isErr, errMsg: msg, errSubMsg: !subMsg ? null : subMsg,appStatus:false,isLoading: false})
    }

    errorConfirm = () => {
        this.setState({ isError: false, errMsg: "ERROR!" })
    }

    backFirthPages = () => {
        sessionStorage.removeItem("MainData"); 
        sessionStorage.removeItem("paymentError"); 
        sessionStorage.removeItem("seqPage"); 
        if (this.state.channelName == "LINEBK") {
            window.location.href = sessionStorage.getItem("homePage");
        } else {
            let ualLanding = `?channelCode=${this.state.channelCodeIni}`;
            if(this.state.sourceIni){
                ualLanding += `&utm_source=${this.state.sourceIni}`;
            }
            if(this.state.utm_mediumIni){
                ualLanding += `&utm_medium=${this.state.utm_mediumIni}`;
            }
            if(this.state.utm_campaignIni){
                ualLanding += `&utm_campaign=${this.state.utm_campaignIni}`;
            }
            if(this.state.utm_contentIni){
                ualLanding += `&utm_content=${this.state.utm_contentIni}`;
            }
            if(this.state.utm_termIni){
                ualLanding += `&utm_term=${this.state.utm_termIni}`;
            }
            if(this.state.cIni){
                ualLanding += `&c=${this.state.cIni}`;
            }
            window.location.href = window.location.origin + MainData.mainPath + "Landing/" + ualLanding;    
        }
    }
    getMobileOperatingSystem = async() => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
          return "Android";
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
        return "Browser";
    };

    createAPP = () => {
        this.setState({isLoading: true})
        const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`,"AuthId": this.state.cusId, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {};
        axios.post(MainData.serviceURL + '/ctbapp/create', data, {
            headers: headers
        })
        .then(res => {
            let data = res?.data?.data;
            if(res.data.success === true){
              if(data?.appUrl){
                window.location.href = data.appUrl;
              }
            }
            else{
              let errMsg = MainData.masterMessage.find(x => x.msgCode === data.messageCode);
              this.errorAlert(true, "", errMsg.msgDescription)
            }
        })
        .catch(error => {
            this.setState({
                isLoading: false
            })
            if(error?.response?.status == 400){
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', "AddCustomerCookie"), ' ')
                this.setState({ isLoading: false})
            }
            else{
                let errMsg = MainData.masterMessage.find(x => x.msgCode === 'MSG1001');
                this.errorAlert(true, !errMsg ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ" : errMsg.msgDescription.replace('{{Service}}', error.response.data.data?.messageDescription), ' ')
                this.setState({ isLoading: false})
            }
        });
    }

    submitPayment = () => {
        this.setState({
            appStatus: false,
            appStatusMsgCode: "",
            isLoading: false,
            isLoadingPage:false
        })
        const headers = { Authorization: `Bearer ${MainData.initialData.bearerAuth}`,"AuthId": this.state.cusId, "Content-Type": "application/json;charset=UTF-8", "Access-Control-Allow-Origin": "*", "Access-Control-Allow-Credentials": "true", "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE", "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers" }
        const data = {};
        axios.post(MainData.serviceURL + '/payment/confirm', data, {
            headers: headers
        })
        .then(res => {

        })
        .catch(error => {

        });
    } 

    render() {
        let screenHeight = window.innerHeight;
        return (
            <div>
                {this.state.isLoading ?
                    <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ zIndex: "99", position: "fixed", backgroundColor: "white", left: 0, top: 0 }}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center mainLoading" style={{ zIndex: "99", position: "fixed" }}>
                            <Row>
                                <Col xs={12} className="w-100 d-flex justify-content-center"><h3 className="csMainColor">Loading</h3></Col>
                                <Col xs={12} className="w-100 d-flex justify-content-center"><SyncLoader size={15} loading={true} /></Col>
                            </Row>
                        </div>
                    </div>
                    : null}
                    {/* {this.state.serviceKPayPlusError1 === true ?
                                <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                            <SweetAlert show={true} title={!this.state.serviceKPayPlusError ?"ขออภัย":""} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false}
                                customButtons={
                                         <Row>
                                             <Button onClick={() => { sessionStorage.removeItem("MainData"); sessionStorage.removeItem("paymentError"); sessionStorage.removeItem("seqPage"); window.location.href = sessionStorage.getItem("homePage"); }} className="mainBtnRadius">กลับไปหน้าแรก</Button>
                                        </Row>
                                            } ><h5 className="text-danger">{!this.state.serviceKPayPlusError1 ?'ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง':MainData.masterMessage.find(x=>x.msgCode === this.state.serviceKPayPlusError1Code).msgDescription}</h5><span className="text--muted">{!this.state.serviceKPayPlusError ?"ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา":""}</span></SweetAlert>
                                    </div>
                   :null } */}
                {this.state.serviceError === true ?
                    <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                        <SweetAlert show={true} title={"ขออภัย"} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false}
                            customButtons={
                                <Row>
                                    <Button onClick={() => { sessionStorage.setItem("MainData", JSON.stringify(MainData)); sessionStorage.setItem("paymentError", 'true'); window.location.href = sessionStorage.getItem("homePage"); }} className="mainBtnRadius">กลับไปที่หน้าการชำระเงิน</Button>
                                </Row>
                            } ><h5 className="text-danger">{this.state.serviceKPayPlusError ?'ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีกครั้ง':MainData.masterMessage.find(x=>x.msgCode === 'MSG016').msgDescription}</h5><span className="text--muted">{"ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา"}</span></SweetAlert>
                    </div>
                    :this.state.appStatus === true ?
                    <div className="w-100 d-flex align-items-center justify-content-center" style={{ height: screenHeight }}>
                        <SweetAlert show={!this.state.isError} title={""} customIcon={this.state.appStatusMsgCode!="MSG185"?<GoAlert size={70} className="text-center w-100 text-danger pb-2" />:""} custom onConfirm={() => window.close()} showCancel closeOnClickOutside={false}
                            customButtons={
                                <Row>
                                    {this.state.appStatusMsgCode=="MSG185"?
                                    <Fragment>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { this.submitPayment() }} className="subBtnRadius">{MainFunc.getLabelDtos("L0409") /** ไม่ต้องการ */}</Button></Col>
                                        <Col xs={6} className="w-100 d-flex justify-content-center"><Button onClick={() => { this.createAPP() }} className="mainBtnRadius">{MainFunc.getLabelDtos("L0741") /** ทำรายการ */}</Button></Col>
                                    </Fragment>
                                    :
                                    <Button onClick={() => { this.setState({appStatus:false,isLoadingPage: false}) }} className="mainBtnRadius">{this.state.appStatusMsgCode=="MSG186"?MainFunc.getLabelDtos("L0167"):MainFunc.getLabelDtos("L0188")}</Button>
                                    }
                                </Row>
                            } >
                                {this.state.appStatusMsgCode=="MSG185" && (
                                    <div style={{color:"var(--mainColor)",fontSize:"24px"}} className="mb-1" dangerouslySetInnerHTML={{ __html: MainFunc.getLabelDtos("L0740") }}></div>
                                )}
                                <h5 className="" dangerouslySetInnerHTML={{ __html: MainData.masterMessage.find(x=>x.msgCode === this.state.appStatusMsgCode).msgDescription }}></h5>
                        </SweetAlert>
                        <SweetAlert show={this.state.isError} title={""} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={this.errorConfirm} showCancel closeOnClickOutside={false} customButtons={
                            <Row>
                                <Button onClick={this.errorConfirm} className="mainBtnRadius">ปิด</Button>
                            </Row>
                        } ><h5 className="text-danger" dangerouslySetInnerHTML={{ __html: this.state.errMsg }}></h5><span className="text--muted" dangerouslySetInnerHTML={{ __html: !this.state.errSubMsg ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา" : this.state.errSubMsg }} ></span></SweetAlert>
                    </div>
                    :
                     <div className="container-fulid">
                        <SweetAlert show={this.state.cookieDescriptionCheck} title={''} onConfirm={()=>{this.setState({cookieDescriptionCheck: false})}} onCancel={()=>{this.setState({cookieDescriptionCheck: false})}} customButtons={<></>} >
                            <div className="container-fulid">
                            <Image src={IcoCrossSign} rounded onClick={()=>this.setState({cookieDescriptionCheck: false})} style={{height: '10px', left: '0', position: "absolute", top: "0", right: "10px", fontSize: "2rem", width: 20, margin: 20, marginTop: 22 }}></Image>
                            <div onClick={()=>this.setState({cookieDescriptionCheck: false})} style={{position: "absolute", top: "0", right: "10px", fontSize: "2rem", color: 'rgb(212, 212, 212)' }}><AiFillCloseSquare/></div>
                                <span dangerouslySetInnerHTML={{__html: `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">นโยบายคุกกี้และการตั้งค่า</h4>`+ this.state.cookieDescription}} style={{textAlign: 'left'}}></span>
                            </div>
                        </SweetAlert>
                        <SweetAlert show={this.state.isError} title={""} customIcon={<GoAlert size={70} className="text-center w-100 text-danger pb-2" />} custom onConfirm={this.errorConfirm} showCancel closeOnClickOutside={false} customButtons={
                            <Row>
                                <Button onClick={this.errorConfirm} className="mainBtnRadius">ปิด</Button>
                            </Row>
                        } ><h5 className="text-danger" dangerouslySetInnerHTML={{ __html: this.state.errMsg }}></h5><span className="text--muted" dangerouslySetInnerHTML={{ __html: !this.state.errSubMsg ? "ขอบคุณที่ท่านสนใจในผลิตภัณฑ์ของเรา" : this.state.errSubMsg }} ></span></SweetAlert>
                        {!this.state.appStatus && (
                            <Row id="logoSection" className="pl-2 pt-2 pb-2 csMainColorHeaderBG" style={{ height: "90px" }}>
                                <Image src={!this.state.logo ? logo2 : this.state.logo} rounded style={this.state.channelName === 'KBANK' ? { height: '103px', position: 'absolute', left: '0', top: '-5px' } : this.state.channelName === 'MTL' ? { height: '103px', position: 'absolute', left: '0', top: '-5px' } : { height: '65px', position: "absolute" }} />
                                <div className="text-white" style={{ paddingLeft: "120px",fontSize: '20px' }}>
                                    <h3 className="mb-0 font-weight-bold pt-3 mt-1" style={{ lineHeight: "1.3rem" }}>Online Sales</h3>
                                    <small>{MainFunc.getLabelDtos("L0465")/* ซื้อประกันออนไลน์ที่เหมาะกับคุณ */}</small>
                                </div>
                            </Row>
                        )}
                        
                        {this.state.isLoadingPage === false ?
                            // รอชำระเงิน
                            // <Row style={{ height: this.state.conetntHeight, maxHeight: this.state.conetntHeight, overflowY: 'scroll' }}>
                            //     <Col xs={12}>
                            //         <Card className="p-3 heirBoxShadow mt-3 mb-3 w-100 container">
                            //             <Row className="d-flex justify-content-center pt-3">
                            //                 <Image className="mt-2 mb-2" src={logocheckout0} rounded style={{ height: '90px' }} />
                            //             </Row>
                            //             <Row>
                            //                 <Col xs={12} className="d-flex justify-content-center ">
                            //                     <span className="pt-2">รอการชำระเงิน</span>
                            //                 </Col>
                            //                 <Col xs={12} className="d-flex justify-content-center text-center pr-3 pl-3">
                            //                     <small className="pt-3 text--muted">กรุณาชำระเงินภายใน 10 นาที โดยความคุ้มครองจะเริ่มต้นนับจากวันที่ท่านชำระเบี้ยประกันภัย ท่านจะได้รับอีเมลยืนยันการชำระเบี้ยประกันภัย ไปยังอีเมลของท่านที่ได้ลงทะเบียนไว้ หลังจากที่ท่านชำระเบี้ยประกันภัย (หากท่านไม่ได้รับโปรดตรวจสอบใน Junk หรือ Spam ในอีเมลของท่าน หรือติดต่อที่อีเมล dm_contact@muangthai.co.th)</small>
                            //                 </Col>
                            //                 <Col xs={12} className="d-flex justify-content-center ">
                            //                     <small className="pt-3 text--muted">ชำระผ่าน</small>
                            //                 </Col>
                            //                 <Col xs={12} className="d-flex justify-content-center ">
                            //                     <h5 className="font-weight-bold">K PAY PLUS</h5>
                            //                 </Col>
                            //                 <Col xs={12} className="pt-3 pb-3 d-flex justify-content-center" style={{ backgroundColor: "rgba(237,2,131,0.07)" }}>
                            //                     <Row>
                            //                         <Col xs={12} className="d-flex justify-content-center">
                            //                             <small className="pt-3 text--muted">วิธีการชำระ</small>
                            //                         </Col>
                            //                         <Col xs={6}>
                            //                             <Row>
                            //                                 <Col xs={12} className="d-flex justify-content-center">
                            //                                     <Image className="mt-2 mb-2" src={logocheckout1} rounded style={{ height: '160px' }} />
                            //                                 </Col>
                            //                                 <Col xs={12} className="text-center">
                            //                                     <small className="pt-2">1.ระบบจะส่ง Notification ไปยังโทรศัพท์มือถือของท่าน</small>
                            //                                 </Col>
                            //                             </Row>
                            //                         </Col>
                            //                         <Col xs={6} className="d-flex justify-content-center">
                            //                             <Row>
                            //                                 <Col xs={12} className="d-flex justify-content-center">
                            //                                     <Image className="mt-2 mb-2" src={logocheckout2} rounded style={{ height: '160px' }} />
                            //                                 </Col>
                            //                                 <Col xs={12} className="text-center">
                            //                                     <small className="pt-2">2.กดที่ Notification เพื่อดำเนินการชำระเงินที่ K Plus</small>
                            //                                 </Col>
                            //                             </Row>
                            //                         </Col>
                            //                         <Col xs={6} className="d-flex justify-content-center">
                            //                             <Row>
                            //                                 <Col xs={12} className="d-flex justify-content-center">
                            //                                     <Image className="mt-2 mb-2" src={logocheckout3} rounded style={{ height: '160px' }} />
                            //                                 </Col>
                            //                                 <Col xs={12} className="text-center">
                            //                                     <small className="pt-2">3.ทำตามขั้นตอนการชำระเงินของ K Pay Plus</small>
                            //                                 </Col>
                            //                             </Row>
                            //                         </Col>
                            //                         <Col xs={6} className="d-flex justify-content-center">
                            //                             <Row>
                            //                                 <Col xs={12} className="d-flex justify-content-center">
                            //                                     <Image className="mt-2 mb-2" src={logocheckout4} rounded style={{ height: '160px' }} />
                            //                                 </Col>
                            //                                 <Col xs={12} className="text-center">
                            //                                     <small className="pt-2">4.ชำระเงินผ่าน K Plus สำเร็จจะได้รับอีเมลยืนยัน</small>
                            //                                 </Col>
                            //                             </Row>
                            //                         </Col>
                            //                     </Row>
                            //                 </Col>
                            //             </Row>
                            //         </Card>
                            //         <div className="p-3 mt-3 mb-3 w-100">
                            //             <Col xs={12} className="d-flex justify-content-center ">
                            //                 <Button variant="" className="mainBtnRadius font-weight-bold" onClick={() => { sessionStorage.removeItem("MainData"); sessionStorage.removeItem("paymentError"); sessionStorage.removeItem("seqPage"); window.location.href = sessionStorage.getItem("homePage"); }} >{MainFunc.getLabelDtos("L0164")}</Button>
                            //             </Col>
                            //         </div>
                            //     </Col>
                            // </Row> :
                            // ชำระเงินสำเร็จแล้ว
                            <Row style={{ height: this.state.conetntHeight, maxHeight: this.state.conetntHeight, overflowY: 'scroll' }}>
                                <Col xs={12}>
                                    <Card className="p-3 heirBoxShadow mt-3 mb-3 w-100 container">
                                        <Row className="d-flex justify-content-center pt-3">
                                            <FaCheckCircle size={50} className="csMainColor" />
                                        </Row>
                                        <Row>
                                            <Col xs={12} className="d-flex justify-content-center ">
                                                <span className="pt-2">{MainFunc.getLabelDtos("L0156")}</span>
                                            </Col>
                                            <Col xs={12} className="d-flex justify-content-center ">
                                                {!this.state.paymentTime ? null :
                                                    <span className="pt-1">{new Date(this.state.paymentTime.split(" ")[0]).getDate()} {MainFunc.setNewThaiMonth(new Date(this.state.paymentTime.split(" ")[0]).getMonth(), false)} {new Date(this.state.paymentTime.split(" ")[0]).getFullYear() + 543}, {this.state.paymentTime.split(" ")[1]}</span>
                                                }
                                            </Col>
                                            <Col xs={12} className="d-flex justify-content-center ">
                                                <small className="pt-3 text--muted">{MainFunc.getLabelDtos("L0157")}</small>
                                            </Col>
                                            <Col xs={12} className="d-flex justify-content-center ">
                                                <h3 className="csMainColor font-weight-bold">{MainFunc.numberWithCommas(this.state.detailPremium)} {MainFunc.getLabelDtos("L0104")}</h3>
                                            </Col>
                                            {!this.state.noPayment && this.state.paymentChannelName && (
                                                <Fragment>
                                                    <Col xs={12} className="d-flex justify-content-center ">
                                                        <small className="pt-3 text--muted">{MainFunc.getLabelDtos("L0158")}</small>
                                                    </Col>
                                                    <Col xs={12} className="d-flex justify-content-center ">
                                                        <h5 className="font-weight-bold">{this.state.paymentChannelName}</h5>
                                                    </Col>
                                                </Fragment>
                                            )}
                                            

                                            <Col xs={12}>
                                                <div className="pt-2 mb-2" style={{ borderBottom: "2px solid rgba(0,0,0,0.1)" }}></div>
                                            </Col>

                                            <Col xs={12} className="d-flex justify-content-center ">
                                                <span className="pt-2 text-center">{MainFunc.getLabelDtos("L0159")}</span>
                                            </Col>
                                            {!this.state.isTaxConsent && (
                                                <Col xs={12} className="d-flex justify-content-center ">
                                                    <span className="pt-2 text-center" style={{fontSize: "18px", color: "#ea5297", fontWeight: "bold"}}>{MainFunc.getLabelDtos("L0499")}</span>
                                                </Col>
                                            )}
                                            

                                            <Col xs={12}>
                                                <Card className="pt-4" style={{ border: "unset" }}>
                                                    <small className="text--muted">{MainFunc.getLabelDtos("L0160")}</small>
                                                    <span>{this.state.detailFirstName} {this.state.detailLastName}</span>
                                                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0106")}</small>
                                                    <span>{this.state.detailApplicationNumber}</span>
                                                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0161")}</small>
                                                    <span>{this.state.detailProductName}</span>
                                                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0113")}</small>
                                                    <span>{MainFunc.numberWithCommas(this.state.detailSumInsured)} {MainFunc.getLabelDtos("L0104")}</span>
                                                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0013")}</small>
                                                    <span>{this.state.detailInsuranceTime} </span>
                                                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0162")}</small>
                                                    <span>{new Date(this.state.detailDateStartCover).getDate()} {MainFunc.setNewThaiMonth(new Date(this.state.detailDateStartCover).getMonth(), true)} {new Date(this.state.detailDateStartCover).getFullYear() + 543}</span>
                                                    <small className="text--muted mt-3">{MainFunc.getLabelDtos("L0163")}</small>
                                                    <span>{MainFunc.numberWithCommas(this.state.detailPremium)} {MainFunc.getLabelDtos("L0104")}</span>
                                                    {/* {MainFunc.getLabelDtos("L0130")} */}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <div className="p-3 mt-3 mb-3 w-100">
                                        <Col xs={12} className="d-flex justify-content-center ">
                                        <Button onClick={() => { this.backFirthPages() }} className="mainBtnRadius">กลับไปหน้าแรก</Button>
                                        </Col>
                                    </div>
                                    <Row >
                                        <Col xs={12}  className="text-center">
                                            <Card className="w-100 container p-0" style={{border: "0px"}}>
                                                <Image className="appDeepLinkedAWThankYouPageImage" src={APP_Deep_Linked_AW_Thank_You_Page} onClick={() => { this.goToWeb() }}/>
                                                {/* <Image src={MTLClick_Banner_DownloadApp_01_ios} onClick={() => { this.goToiOS() }}/>
                                                <Image src={MTLClick_Banner_DownloadApp_01_adn} onClick={() => { this.goToAndroid() }}/> */}
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row className="mt-4 oppositeWhite" style={{ position: "relative", bottom: "0px"}}>
                                        <Col xs={12} className="pl-4 pt-4 pr-4 w-100 oppositeBlackBlue">
                                            <Row>
                                                <Col xs={12} lg={4} className="mb-3">
                                                    <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0181")}</span><br></br>
                                                    <small className="">{MainFunc.getLabelDtos("L0182")}</small><br></br>
                                                    <Image className="mb-2 mr-2" src={IcoTel} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                    <span className="font-weight-bold mt-1" style={{ fontSize: "1.8rem" }}>{MainFunc.getLabelDtos("L0183")}</span><br></br>
                                                    <span className="font-weight-bold m-0">{MainFunc.getLabelDtos("L0184")}</span>
                                                </Col>
                                                <Col xs={12} lg={4} className="mb-3">
                                                    <span className="font-weight-bold m-0">Payment Method</span><br></br>
                                                    <Image className="mt-2 mb-2 mr-2" src={IcoKbank} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                    <Image className="mt-2 mb-2 mr-2" src={IcoVS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                    <Image className="mt-2 mb-2 mr-2" src={IcoMC} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                    <Image className="mt-2 mb-2 mr-2" src={IcoCS} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /><br></br>
                                                    <span className="font-weight-bold m-0">Follow us</span><br></br>
                                                    <a href={MainFunc.getConfigDtos("CF012")}><Image className="mt-2 mb-2 mr-2" src={IcoYt} rounded style={{ height: '20px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                                    <a href={MainFunc.getConfigDtos("CF013")}><Image className="mt-2 mb-2 mr-2" src={IcoIg} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                                    <a href={MainFunc.getConfigDtos("CF014")}><Image className="mt-2 mb-2 mr-2" src={IcoFb} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                                    <a href={MainFunc.getConfigDtos("CF015")}><Image className="mt-2 mb-2 mr-2" src={IcoLine} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                                    <a href={MainFunc.getConfigDtos("CF016")}><Image className="mt-2 mb-2 mr-2" src={IcoLinkedin} rounded style={{ height: '24px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} /></a>
                                                </Col>
                                                <Col xs={12} lg={4} className="mb-3">
                                                    <span className="font-weight-bold m-0">Certified</span><br></br>
                                                    <Image className="mt-2 mb-2 mr-2" src={IcoDBD} rounded style={{ height: '40px', left: '0', top: '-5px' }} onLoad={(e) => this.setState({ logoWidth: e.target.width + 5 })} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className="pl-4 pr-4 w-100 oppositeBlack">
                                            <Row>
                                                <Col xs={12} lg={2} className="mt-2 mb-2">
                                                    <a onClick={() => { this.setState({ TermAndConditionModal: true }) }} style={{ cursor: 'pointer' }}><span className=" m-0">{MainFunc.getLabelDtos("L0173")}</span></a><br></br>
                                                </Col>
                                                <Col xs={12} lg={2} className="mt-2 mb-2">
                                                    <a href={MainFunc.getConfigDtos("CF001")}><span className=" m-0">{MainFunc.getLabelDtos("L0171")}</span></a><br></br>
                                                </Col>
                                                <Col xs={12} lg={2} className="mt-2 mb-2">
                                                    <a href={MainFunc.getConfigDtos("CF002")}><span className=" m-0">{MainFunc.getLabelDtos("L0172")}</span></a><br></br>
                                                </Col>
                                                <Col xs={12} lg={2} className="mt-2 mb-2">
                                                    <a href={MainFunc.getConfigDtos("CF011")}><span className="m-0">{MainFunc.getLabelDtos("L0174")}</span></a><br></br>
                                                </Col>
                                                <Col xs={12} lg={4} className="mt-2 mb-2">
                                                    <span className="m-0">{MainFunc.getLabelDtos("L0180")}</span><br></br>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className={this.state.showfooter ? 'pt-3 pb-3 d-flex justify-content-center' : 'd-none'}>
                                            <small className="text--muted text-center">{MainFunc.getLabelDtos("L0008")}</small>
                                        </Col>
                                    </Row>
                                </Col>
                                
                            </Row>
                            
                           : null}

                    </div>}
                    {this.state._cookie === 'true' || this.state.appStatus ? null 
                            :
                            [
                                this.state._cookie === 'close' ? null :
                                <div className="text-white w-100 d-flex justify-content-center" style={{ position: "absolute", bottom: 0, backgroundColor: "rgba(0,0,0,0.8)", left: 0 }}>
                                    <Image src={IcoCrossSign} rounded style={{position: "absolute", right: "0", padding: "10px", filter: "opacity(0.4) drop-shadow(0 0 0 #DDDEE0)", width: "30px", height: "30px"}} onClick={()=>this.closeCookie()} ></Image>
                                        <div className="pl-3 pr-3 pb-4 pt-3 mt-4">
                                            <span className="justify-content-center">{parse(this.state.cookieDetail?this.state.cookieDetail:"")}</span><span className="spanLink" onClick={()=>this.openCookieDescription()}>{MainFunc.getLabelDtos("L0440")}</span>
                                        <div className="w-100 d-flex justify-content-center pt-2">
                                            <Button variant="info pl-4 pr-4" style={{ borderRadius: "25px" }} onClick={() => {this.SaveCookie()}}>ยอมรับ</Button>
                                        </div>
                                    </div>
                                </div>
                                ]
                        }
                    
            </div>
            
        )
    }
}

export default Checkout;


