import axios from "axios";
import { notify } from "react-noti";
import React, { Component } from "react";
import { FaRegAddressCard } from "react-icons/fa";
import { AiFillCloseSquare } from "react-icons/ai";
import TextField from "@material-ui/core/TextField";
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Button, Form } from "react-bootstrap";
import { GoAlert } from "react-icons/go";
import MainFunc from "../../models/MainFunc";
import MainData from "../../models/MainData";
import moment from "moment";
import { Container } from "@material-ui/core";

class IdCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "IdCard",
      agreeTerms: false,
      failIdCard: false,
      agreeAcceptSensitive: false,
      TermAndConditionModal: false,
      AcceptSensitiveDataModal: false,
      TermAndConditionConfigModal: false,
      isErrorBirthday: false,
      isErrorBirthdayDesc: "",
      termsAndConditionsDetail: null,
      termsAndConditionsConfigUrl: null,
      acceptPrivateDetail: null,
      acceptPrivateConfigDescription: null,
      termsAndConditionsConfigDescription: null,
      termsDescription: null,
      idCard: !MainData.screenSM005.idCard ? null : MainData.screenSM005.idCard,
    };
  }

  componentDidMount() {
    this.props.loadController(true);
    this.getData();
    // var elmnt = document.getElementById("titelForScreen");
    // elmnt.scrollIntoView();
  }

  getData = () => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      channel: MainData.initialData.channelCode,
    };
    axios
      .post(MainData.serviceURL + "/VerifyIDCard/GetTermAndCondition", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (!data) {
          let errMsg = MainData.masterMessage.find(
            (x) => x.msgCode === "MSG1001"
          );
          this.props.errorAlert(
            true,
            !errMsg
              ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
              : errMsg.msgDescription,
            " "
          );
          return;
        }
        this.setState({
          termsDescription: data.configTerms.description,
          termsAndConditionsDetail: data.termsAndConditionsDetail,
          termsAndConditionsConfigUrl: data.config.url,
          termsAndConditionsConfigDescription: data.config.description,
          acceptPrivateDetail: data.acceptPrivateDetail,
          acceptPrivateConfigDescription: data.configPrivateDetail.description,
        });
        this.props.loadController(false);
      })
      .catch((error) => {
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  handleChange = (val) => {
    val = val.replace(/[^\d]/g, "");
    if (val.length <= 13) {
      this.setState({ idCard: val });
    }
  };

  processNext = async () => {
    this.props.loadController(true);
    let statusSystemConfig = await MainFunc.getSystemConfigData();
    if (statusSystemConfig) {
      window.location.href = MainData.mainPath + "shutdown";
      return;
    } else {
      this.props.loadController(false);
    }
    this.callServiceAddOrUpdate(this.state.idCard);
  };

  callServiceAddOrUpdate = async (p_iPID) => {
    if (!p_iPID) {
      this.setState({ failIdCard: true });
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG007");
      this.props.errorAlert(
        true,
        !errMsg ? "กรุณาระบุเลขบัตรประชาชน" : errMsg.msgDescription,
        " "
      );
      if (MainData.screenSM005.idCard != null) {
        MainFunc.logPDPA(
          "Edit",
          "SMS",
          "IdCard",
          null,
          { idCard: MainData.screenSM005.idCard },
          { idCard: this.state.idCard },
          "Fail",
          "กรุณาระบุเลขบัตรประชาชน"
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          "SMS",
          "IdCard",
          null,
          null,
          { idCard: this.state.idCard },
          "Fail",
          "กรุณาระบุเลขบัตรประชาชน"
        );
      }
      MainData.screenSM005.idCard = this.state.idCard;
      return;
    } else if (p_iPID.length !== 13) {
      this.setState({ failIdCard: true });
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG017");
      this.props.errorAlert(
        true,
        !errMsg ? "หมายเลขบัตรประชาชนไม่ถูกต้อง" : errMsg.msgDescription,
        " "
      );
      if (MainData.screenSM005.idCard != null) {
        MainFunc.logPDPA(
          "Edit",
          "SMS",
          "IdCard",
          null,
          { idCard: MainData.screenSM005.idCard },
          { idCard: this.state.idCard },
          "Fail",
          "หมายเลขบัตรประชาชนไม่ถูกต้อง"
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          "SMS",
          "IdCard",
          null,
          null,
          { idCard: this.state.idCard },
          "Fail",
          "หมายเลขบัตรประชาชนไม่ถูกต้อง"
        );
      }
      MainData.screenSM005.idCard = this.state.idCard;
      return;
    } else {
      this.checkOrderHistorySms(p_iPID);
    }
  };

  checkOrderHistorySms = (p_iPID) => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    };
    const data = {
      citizenNo: p_iPID,
      channelCode: MainData.initialData.channelCode,
    };
    axios
      .post(MainData.serviceURL + "/SMS/CheckOrderHistorySms", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success === true) {
          if (MainData.screenSM005.idCard) {
            MainFunc.logPDPA(
              "Edit",
              "SMS",
              "IdCard",
              null,
              { citizenNo: MainData.screenSM005.idCard },
              { citizenNo: p_iPID },
              "Success",
              null
            );
          } else {
            MainFunc.logPDPA(
              "Add",
              "SMS",
              "IdCard",
              null,
              null,
              { citizenNo: p_iPID },
              "Success",
              null
            );
          }
          MainData.screenSM005.cusId = data.token;
          MainData.screenSM005.idCard = p_iPID;
          this.verifyOTP(data.phoneNo);
        } else {
          if (res.data.message == "MSG181") {
            this.props.setPage("NoInformationFound");
          } else {
            this.props.loadController(false);
            this.props.errorAlert(
              true,
              "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ",
              " "
            );
          }
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  verifyOTP = (phoneNumber) => {
    const headers = {
      Authorization: `Bearer ${MainData.initialData.bearerAuth}`,
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
      "Access-Control-Allow-Headers":
        "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
      AuthId: !MainData.screenSM005.cusId ? 0 : MainData.screenSM005.cusId,
    };
    const data = {
      phoneNumber: phoneNumber,
      productId: MainData.initialData.productId,
      channelCode: MainData.initialData.channelCode,
      isChangeNumber: false,
      isSmsHistory: true
    };
    axios
      .post(MainData.serviceURL + "/VerifyOTP/OTP", data, {
        headers: headers,
      })
      .then((res) => {
        if (res.status === 299) {
          window.location.href = MainData.mainPath + "shutdown";
          return;
        }
        let data = res.data.data;
        if (res.data.success === true) {
          if (MainData.screenSM006.phoneNumber) {
            MainFunc.logPDPA(
              "Edit",
              "SMS",
              "IdCard",
              null,
              { phoneNumber: MainData.screenSM006.phoneNumber },
              { phoneNumber: MainData.screenSM006Preview.phoneNumber },
              "Success",
              null
            );
          } else {
            MainFunc.logPDPA(
              "Add",
              "SMS",
              "IdCard",
              null,
              null,
              { phoneNumber: MainData.screenSM006Preview.phoneNumber },
              "Success",
              null
            );
          }
          MainData.screenSM006.isChangeNumber = false;
          MainData.screenSM006.phoneNumber = phoneNumber;
          MainData.screenSM006.refCode = data.refCode;
          MainData.screenSM006.otpCode = data.otpCode;
          this.props.setPage("Otp");
        } else {
          if (data.messageCode == "MSG022" || data.messageCode == "MSG023") {
            this.props.loadController(false);
          } else {
            //MainFunc.sendLogToService("IdCard", 'Insert', 'Fail', 'เก็บข้อมูลเบอร์โทรศัพท์และการเรียก Service SMS เพื่อส่ง OTP ไปยังเบอร์ของผู้ใช้งาน', {phoneNumber: MainFunc.blurPhoneNum(MainData.screenSM006.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode}, {phoneNumber: MainFunc.blurPhoneNum(this.state.phoneNumber), productId: this.state.productId, channelCode: this.state.channelCode});
            this.props.loadController(false);
            this.props.errorAlert(
              true,
              "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ",
              " "
            );
          }
        }
      })
      .catch((error) => {
        this.props.loadController(false);
        let errMsg = MainData.masterMessage.find(
          (x) => x.msgCode === "MSG1001"
        );
        this.props.errorAlert(
          true,
          !errMsg
            ? "พบปัญหาในการเชื่อมต่อไปยัง service กรุณาติดต่อผู้ดูแลระบบ"
            : errMsg.msgDescription.replace(
                "{{Service}}",
                error.response.data.data?.messageDescription
              ),
          " "
        );
      });
  };

  validateBadRequest = (fieldNameList) => {
    if (fieldNameList.find((data) => data.field === "CitizenNo")) {
      this.setState({ failIdCard: true });
      let errMsg = MainData.masterMessage.find((x) => x.msgCode === "MSG017");
      this.props.errorAlert(
        true,
        MainFunc.getLabelDtos("L0165"),
        !errMsg ? "หมายเลขบัตรประชาชนไม่ถูกต้อง" : errMsg.msgDescription
      );
      if (MainData.screenSM005.idCard != null) {
        MainFunc.logPDPA(
          "Edit",
          "SMS",
          "IdCard",
          null,
          {
            birthDate: MainFunc.getUserBirthDate("ac"),
            idCard: MainData.screenSM005.idCard,
          },
          {
            birthDate: MainFunc.getUserBirthDate("ac"),
            idCard: this.state.idCard,
          },
          "Fail",
          "หมายเลขบัตรประชาชนไม่ถูกต้อง"
        );
      } else {
        MainFunc.logPDPA(
          "Add",
          "SMS",
          "IdCard",
          null,
          null,
          {
            birthDate: MainFunc.getUserBirthDate("ac"),
            idCard: this.state.idCard,
          },
          "Fail",
          "หมายเลขบัตรประชาชนไม่ถูกต้อง"
        );
      }
    }
  };

  opentermsAndConditionsConfigModal = () => {
    this.setState({ TermAndConditionConfigModal: true });
    MainFunc.sendLogToService(
      "IdCard",
      "Insert",
      "Success",
      "เก็บข้อมูลการดูรายละเอียด นโยบายความเป็นส่วนตัว",
      {},
      {}
    );
  };

  openTermAndConditionModal = () => {
    this.setState({ TermAndConditionModal: true });
    MainFunc.sendLogToService(
      "IdCard",
      "Insert",
      "Success",
      "เก็บข้อมูลการดูรายละเอียด เงื่อนไขการสมัครเอาประกันภัย",
      {},
      { isOpen: true }
    );
  };

  openAcceptSensitiveDataModal = () => {
    this.setState({ AcceptSensitiveDataModal: true });
    MainFunc.sendLogToService(
      "IdCard",
      "Insert",
      "Success",
      "เก็บข้อมูลการดูรายละเอียด เงื่อนไขการเปิดเผยข้อมูล",
      {},
      { isOpen: true }
    );
  };

  render() {
    return (
      <div className="container-fulid">
        <Row>
          {/* <Col id="titelForScreen" xs={12} className="oppositeWhite pt-1 pb-1">
            <span className="font-weight-bold">{"SMS"}</span>
            {this.props.maxPage>1?<span className="font-weight-bold csMainColor pl-1">({this.props.nowPage}/{this.props.maxPage})</span>:null}
          </Col> */}

          <Col xs={12} className="d-flex justify-content-center pt-3">
            <FaRegAddressCard className="csMainColor" size={50} />
          </Col>
          <Col xs={12} className="d-flex justify-content-center pt-3">
            <h5 className="font-weight-bold">
              {MainFunc.getLabelDtos("L0137")}
            </h5>
          </Col>
          <Col xs={12} className="d-flex justify-content-center">
            <small className="text--muted">
              {MainFunc.getLabelDtos("L0023")}
            </small>
          </Col>

          <Col xs={12} className="pt-3">
            <TextField
              inputProps={{ autocomplete: "off" }}
              fullWidth
              id="idCard"
              defaultValue=""
              autoComplete={"off"}
              error={this.state.failIdCard}
              label={MainFunc.getLabelDtos("L0024")}
              placeholder={MainFunc.getLabelDtos("L0025")}
              onChange={(e) => {
                this.handleChange(e.target.value);
              }}
              helperText={
                this.state.failIdCard ? "หมายเลขบัตรประชาชนไม่ถูกต้อง" : ""
              }
              value={
                !this.state.idCard
                  ? ""
                  : MainFunc.idcardFormat(this.state.idCard)
              }
              disabled={
                this.state.idCard && MainData.screenMenuKPlus.onKPlus
                  ? true
                  : false
              }
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>

          <Col xs={12} className="pt-4 pb-4">
            <div
              className="pt-4 pb-2 pl-3 pr-3"
              style={{ backgroundColor: "#e8e8e8" }}
            >
              <Form noValidate validated>
                <Form.Group controlId="terms">
                  <Form.Check
                    checked={this.state.agreeTerms}
                    onChange={(e) => {
                      this.setState({ agreeTerms: e.target.checked });
                    }}
                    type="checkbox"
                    label={
                      <>
                        {" "}
                        <span>{MainFunc.getLabelDtos("L0139")}</span>{" "}
                      </>
                    }
                    custom
                    style={{ display: "initial" }}
                  />
                  <span
                    className="spanLink-pink"
                    onClick={() => this.openTermAndConditionModal()}
                  >
                    {" "}
                    {MainFunc.getLabelDtos("L0138")}{" "}
                  </span>
                  <span>{MainFunc.getLabelDtos("L0140")} </span>
                  <a
                    className="spanLink-pink"
                    onClick={() => this.opentermsAndConditionsConfigModal()}
                  >
                    {this.state.termsAndConditionsConfigDescription}
                  </a>
                  <span className="text-danger"> *</span>
                </Form.Group>
                {/* <Form.Group controlId="acceptSensitive" >
                  <Form.Check checked={this.state.agreeAcceptSensitive} onChange={(e)=>{this.setState({agreeAcceptSensitive: e.target.checked})}} type="checkbox" label={<>  </>} custom style={{display: 'initial'}} />
                  <span>{MainFunc.getLabelDtos("L0357")}</span>
                  <span className="spanLink-pink " onClick={()=>this.openAcceptSensitiveDataModal()}> {MainFunc.getLabelDtos("L0356")} </span>
                </Form.Group> */}
              </Form>
            </div>
          </Col>

          {/* MODAL */}
          {/* เงื่อนไข */}
          <SweetAlert
            show={this.state.TermAndConditionModal}
            title={""}
            onConfirm={() => {
              this.setState({ TermAndConditionModal: false });
            }}
            onCancel={() => {
              this.setState({ TermAndConditionModal: false });
            }}
            customButtons={<></>}
          >
            <div className="container-fulid">
              <div
                onClick={() => this.setState({ TermAndConditionModal: false })}
                style={{
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  fontSize: "2rem",
                  color: "rgb(212, 212, 212)",
                }}
              >
                <AiFillCloseSquare />
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.acceptPrivateConfigDescription}</h4>` +
                    MainFunc.getConfigDtos("CF004"),
                }}
                style={{ textAlign: "left" }}
              ></span>
            </div>
          </SweetAlert>
          {/* รายละเอียด */}
          <SweetAlert
            show={this.state.AcceptSensitiveDataModal}
            title={""}
            onConfirm={() => {
              this.setState({ AcceptSensitiveDataModal: false });
            }}
            onCancel={() => {
              this.setState({ AcceptSensitiveDataModal: false });
            }}
            customButtons={<></>}
          >
            <div className="container-fulid">
              <div
                onClick={() =>
                  this.setState({ AcceptSensitiveDataModal: false })
                }
                style={{
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  fontSize: "2rem",
                  color: "rgb(212, 212, 212)",
                }}
              >
                <AiFillCloseSquare />
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.termsDescription}</h4>` +
                    this.state.acceptPrivateDetail,
                }}
                style={{ textAlign: "left" }}
              ></span>
            </div>
          </SweetAlert>
          {/* นโยบาย */}
          <SweetAlert
            show={this.state.TermAndConditionConfigModal}
            title={""}
            onConfirm={() => {
              this.setState({ TermAndConditionConfigModal: false });
            }}
            onCancel={() => {
              this.setState({ TermAndConditionConfigModal: false });
            }}
            customButtons={<></>}
          >
            <div className="container-fulid">
              <div
                onClick={() =>
                  this.setState({ TermAndConditionConfigModal: false })
                }
                style={{
                  position: "absolute",
                  top: "0",
                  right: "10px",
                  fontSize: "2rem",
                  color: "rgb(212, 212, 212)",
                }}
              >
                <AiFillCloseSquare />
              </div>
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    `<h4 class="d-flex justify-content-center csMainColor font-weight-bold text-center ">${this.state.termsAndConditionsConfigDescription}</h4>` +
                    this.state.termsAndConditionsConfigUrl,
                }}
                style={{ textAlign: "left" }}
              ></span>
            </div>
          </SweetAlert>
          <SweetAlert
            show={this.state.isErrorBirthday}
            title={""}
            customIcon={
              <GoAlert
                size={70}
                className="text-center w-100 text-danger pb-2"
              />
            }
            custom
            onConfirm={() => {
              this.setState({ isErrorBirthday: false });
            }}
            showCancel
            closeOnClickOutside={false}
            customButtons={
              <Row>
                <Col xs={6}>
                  <Button
                    onClick={() => this.skipFirthPage()}
                    className="subBtnRadius"
                  >
                    {MainFunc.getLabelDtos("L0151")}
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button
                    onClick={() => {
                      this.setState({ isErrorBirthday: false });
                    }}
                    className="mainBtnRadius"
                  >
                    {MainFunc.getLabelDtos("L0188") /** ปิด */}
                  </Button>
                </Col>
              </Row>
            }
          >
            <h5
              className="text-danger"
              dangerouslySetInnerHTML={{
                __html: MainFunc.getLabelDtos("L0165"),
              }}
            ></h5>
            <span
              className="text--muted"
              dangerouslySetInnerHTML={{
                __html: this.state.isErrorBirthdayDesc,
              }}
            ></span>
          </SweetAlert>

          <Col xs={12} className={"d-flex justify-content-center pt-3 pl-2"}>
            <Button
              variant=""
              className="mainBtnRadius font-weight-bold"
              onClick={() => {
                this.processNext();
              }}
              disabled={!this.state.agreeTerms}
            >
              {MainFunc.getLabelDtos("L0021")}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default IdCard;
