// "use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getHeaderRowFromWorkbook;

var _xlsx = _interopRequireDefault(require("xlsx"));

/* eslint-disable id-length */
function getHeaderRowFromWorkbook(workbook) {
  try {
    var sheet = workbook.Sheets[workbook.SheetNames[0]];
    var headers = [];

    var range = _xlsx["default"].utils.decode_range(sheet['!ref']);

    var C;
    /* start in the first row */

    var R = range.s.r;
    /* walk every column in the range */
    // eslint-disable-next-line no-plusplus

    for (C = range.s.c; C <= range.e.c; ++C) {
      /* find the cell in the first row */
      var cell = sheet[_xlsx["default"].utils.encode_cell({
        c: C,
        r: R
      })];

      var hdr = "UNKNOWN ".concat(C); // <-- replace with your desired default

      if (cell && cell.t) hdr = _xlsx["default"].utils.format_cell(cell);
      headers.push(hdr);
    }

    return headers;
  } catch (error) {
    // eslint-disable-next-line no-console
    return [];
  }
}